// eslint-disable-next-line
export default {
  fname: {
    required: true,
    label: 'fname',
  },
  address: {
    required: false,
    label: 'Address',
  },
  age: {
    required: false,
    label: 'age',
  },
  subdomain: {
    required: true,
    label: 'Sub domain',
  },
  contact: {
    required: true,
    label: 'Contact no.',
  },
  lname: {
    required: false,
    label: 'Nick Name.',
  }
};
