import React, { useState, useEffect, useRef } from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import { Button, InputLabel } from "@material-ui/core";
import NepaliDate from "nepali-date-converter";
import Search from "@material-ui/icons/Search";
import CustomInput from "components/CustomInput/CustomInput";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";
import { GetRequest } from "plugins/axios";
import api from "api/api";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { VictoryLabel, VictoryPie, VictoryTheme } from "victory";
import { colorScale } from "utils/Colors";
import { Grid } from "@mui/material";
import { useHistory } from "react-router";

const useStyles = makeStyles(styles);

const columns = [
  { id: "id", label: "Pid", minWidth: 170 },
  { id: "model", label: "Item Name", minWidth: 170 },
  { id: "Exp", label: "Amount", minWidth: 100 },
  { id: "stock", label: "Quantity Out", minWidth: 100 },
  { id: "stockin", label: "Quantity in", minWidth: 100 },
  // {
  //   id: "population",
  //   label: "Population",
  //   minWidth: 170,
  //   align: "right",
  //   format: (value) => value.toLocaleString("en-US"),
  // },
  // {
  //   id: "size",
  //   label: "Size\u00a0(km\u00b2)",
  //   minWidth: 170,
  //   align: "right",
  //   format: (value) => value.toLocaleString("en-US"),
  // },
  // {
  //   id: "density",
  //   label: "Density",
  //   minWidth: 170,
  //   align: "right",
  //   format: (value) => value.toFixed(2),
  // },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData("India", "IN", 1324171354, 3287263),
  createData("China", "CN", 1403500365, 9596961),
  createData("Italy", "IT", 60483973, 301340),
  createData("United States", "US", 327167434, 9833520),
  createData("Canada", "CA", 37602103, 9984670),
  createData("Australia", "AU", 25475400, 7692024),
  createData("Germany", "DE", 83019200, 357578),
  createData("Ireland", "IE", 4857000, 70273),
  createData("Mexico", "MX", 126577691, 1972550),
  createData("Japan", "JP", 126317000, 377973),
  createData("France", "FR", 67022000, 640679),
  createData("United Kingdom", "GB", 67545757, 242495),
  createData("Russia", "RU", 146793744, 17098246),
  createData("Nigeria", "NG", 200962417, 923768),
  createData("Brazil", "BR", 210147125, 8515767),
];

export default function Sales({startDate,endDate,BILLTYPE}) {
  const componentRef = useRef();
const history = useHistory();
const classes = useStyles();
  const [billDate, setBillDate] = useState("");
  const subdomain = useSelector((state) => state.authReducer.user.subdomain);
  const [monthReport, setMonthReport] = useState([]);
  const [reports, setReports] = useState([]);
  const [monthExpenseReport, setmonthExpenseReport] = useState([]);
  const [pieData, setPieData] = useState([{ x: "Sales", y: 100 },
  { x: "Saving", y: 150 },
  { x: "Credit", y: 75 },]);
  const nepaliDate = new NepaliDate();
  let date = "2078/04/04";
  const options = ["This Month", "Last Month", "Jan"];
  const dataTypes = ["All Forms", "Sales", "Purchase"];
  const defaultSelector = dataTypes[0];
  const defaultOption = options[0];
  useEffect(() => {
    let d=[];
    let total=0;
    let toptotal=0;
    reports?.map((row, index) => {
        total += row.Exp;
    })
    
    const filtered = reports.slice(0, 10);
    
    
    filtered?.sort((a, b) => a - b).reverse().map((row, index) => {
         d.push({x:(row.model),y:((row.Exp/total)*100)})
         toptotal += ((row.Exp/total)*100);
    })
    // d.push({x:'Others',y:(100-toptotal)})
    setPieData(d);
}, [reports]);
  useEffect(() => {
    date = `${nepaliDate.getYear()}/${
      nepaliDate.getMonth() + 1 > 9
        ? nepaliDate.getMonth() + 1
        : `0${nepaliDate.getMonth() + 1}`
    }/${
      nepaliDate.getDate() > 9
        ? nepaliDate.getDate()
        : `0${nepaliDate.getDate()}`
    }`;
    setBillDate(date + "");
  }, []);

  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const rows = [
  //     'a', 'b', 'c'
  // ];

  // const columns = [
  //     's', 'd', 'f'
  // ];
  
  const fetchData = (subdomain) => {
    setLoading(true);
    GetRequest(`${api.expensesTotalAPI}`, {
      subdomain,
      startDate,
      endDate,
      BR:BILLTYPE,
    })
      .then((res) => {
        setLoading(false);
        setReports(res.data.content);
        console.log("🚀 ~ file: totalSales.js ~ line 141 ~ .then ~ res.data.content", res.data.content)
      })
      .catch((err) => {
        console.log({ text: 'Error Greeting balance' });
      });

  };
  useEffect(() => {
    fetchData();
  }, [subdomain,startDate]);

  return (
    <>
    <Grid container >
    <Grid sx={6}>
    <div>Report for {startDate} - {endDate}</div>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
              // typeof monthReport == "array" &&
              reports?.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                        onClick={() => history.push({ pathname: '/main/productDetail/', product_id: row["id"] })}

                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell 
                            key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Grid>
      <Grid sx={6} >
      
      <div >
      <VictoryPie
            theme={VictoryTheme.material}
            // labelPosition={({ index }) => index
            //   ? "centroid"
            //   : "startAngle"
            // }
            labelPlacement={({ index }) => index
               ? "parallel"
               : "parallel"
             }
            colorScale={colorScale}
        standalone={true}
      labels={({ datum }) => `${datum.x}:${Math.round(datum.y)}%`}
      width={450}
        height={450}
        data={pieData}
        animate={{
            duration: 2000
          }}
        // startAngle={45}
        // radius={({ datum }) => 20 + datum.y * 20}
        // padAngle={5}
        // innerRadius={120}
        labelRadius={50}
        style={{ labels: { fontSize: 12, fill: 'white' } }}
       labelComponent={
      <VictoryLabel
    //   angle={-45}

      labelPlacement={({ index }) => index
               ? "parallel"
               : "parallel"
             }
        standalone={true}
        // textAnchor='middle'
        // style={{ fontSize: 20,fill: 'red' }}
        // x={120}
        // y={120}
        // text='Pie!'
      />
    }
    /> 
      </div>
    
    </Grid>
    </Grid>
    </>
  );
}
