import { Button, Grid } from '@material-ui/core';
import api from '../../../api/api'
import { GetRequest } from 'plugins/axios';
import React, { useEffect,useStyles, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import { Add } from '@material-ui/icons';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import { MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { FormControl } from '@material-ui/core';
import { Select } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import AddNote from './components/AddNote';
import * as actions from '../../store/DashboardActions';
import { ContentState, convertFromRaw, Editor, EditorState } from 'draft-js';

class DraftViewer extends React.Component {
  
  constructor(props) {
      super(props)
  }

  render() {
      let content = this.props.children
      let contentJson = this.props.children
      // legacy; might be a string, not object
      try {
          contentJson = JSON.parse(content)
      }
      catch(e) {
      }
      if (contentJson.hasOwnProperty('entityMap')) {
        console.log("Content_____Json",contentJson);

        // contentJson = EditorState.createWithContent(
        //       ContentState.createFromBlockArray(
        //           convertFromRaw({rawState:contentJson,})))
        // contentJson =EditorState.convertFromRaw({rawState:contentJson,});

          return <>
            {/* Json Data:{content} */}
           {contentJson.blocks.map(({text})=> <div>{text}</div>)}</>
      }
      return <p>{content}</p>
  }

}

const AllNotes = ({ navigation,search }) => {
  const [chatList, setChatList] = useState([]);
  const [schatList, setSChatList] = useState([]);
  const [vendorId, setVendorId] = useState('');
  const note = useSelector((state) => state.dashboardReducer.note);
  const [today, setToday] = useState("2078/12/09");
  const [thisweek, setThisweek] = useState("2078/12/09");
  const [thismonth, setThismonth] = useState("2078/12/09");
  const [thisyear, setThisyear] = useState("2078/12/09");
  const [dateswitch, setDateswitch] = useState(today);
  const [startDate, setStartDate] = useState("2078/01/01");
  const [endDate, setEndDate] = useState("2078/12/32");
  const [currentApp, setCurrentApp] = useState('');
  const [addOpen, setAddOpen] = useState(false); 
  const [floatingSearch, setFloatingSearch] = useState(true);
  const dispatch=useDispatch();
 
  const openAddDialog = (app) => {
      setCurrentApp(app);
      setAddOpen(true);
    };
    const closeAddDialog = () => {
      setCurrentApp('');
      setAddOpen(false);
    };
    const DurationSelectorHandler = (dates) => {
      setDateswitch(dates);
      let date = dates.split("||");
      console.log("///datesfrom", date[0]);
      console.log("///datesto", date[1]);
      setStartDate(date[0]);
      setEndDate(date[1]);
    };
    const classes = makeStyles((theme) => ({
      root: {
        display: 'flex',
        '& > * + *': {
          marginLeft: theme.spacing(2),
        },
      },
      seeMore: {
        marginTop: theme.spacing(3),
      },
      paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
      },
      headerButton: {
        margin: 10,
        color:"#fff",
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 100,
      },
  
      button: {
        margin: 10,
      },
    

    }));
  const fetchChatList = () => {
    GetRequest(`${api.noteAPI}`, { id: 'a.telephone' })
      .then((data) => {
        // setChatList([
        //   ...data.data.content,
         
          
        // ]);
        setSChatList([
          ...data.data.content,
          
          
        ]);
        // console.log(data.data.content);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {

        setVendorId('9843762294');
        // fetchChatList();
        if(note){

          dispatch(actions.get_note(true,note,false));
        }
   
  }, []);
  
  useEffect(() => {
    let searchvalue = schatList.filter((cas) =>
    cas.note?.toLowerCase().includes(search) || cas.topic?.toLowerCase().includes(search) 
      // // console.log(cas)
    );
    setChatList(searchvalue);
  }, [search]);
  return (
    <Card>
    <CardHeader style={{display:'flex',justifyContent:'space-between',flexDirection:'column'}} color="primary">
    <h4 style={{ color: "#fff", fontWeight: "400" }}>Notes List</h4>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="justify-between">
              <Button onClick={openAddDialog} style={{color:"#fff"}} className={classes.headerButton}>
              <Add color="#fff"/>
              </Button>
              
              <Button
                className={classes.headerButton} style={{color:"#ffff"}}
              >
                <DownloadForOfflineIcon/>
              </Button>

              <Button onClick={()=>setFloatingSearch(!floatingSearch)} className={classes.headerButton}>
          <Search color="#ffff"/>
          </Button>
            </div>
            <span className="date-selector" style={{ margin: "10px" }}>
    <FormControl fill="#ffff" variant="filled" >
  {/* <InputLabel id="bill_date-label" style={{ paddingTop: "10px" }}>
    Bill Date
  </InputLabel> */}
  <Select
    labelId="bill_date-label"
    id="bill_date"
    placeholder='Bill Time'
    defaultValue="Today"
    style={{ height: "40px",fill:'#fff' }}
    fill="#fff"
    selectedValue={dateswitch}
    onValueChange={(itemValue,) => DurationSelectorHandler(itemValue)}
  >
    <MenuItem selected label="Today" value={today}>
      Today
    </MenuItem>
    <MenuItem label="This Week" value={thisweek}>
      This Week
    </MenuItem>
    <MenuItem label="This Month" value={thismonth}>
      This Month
    </MenuItem>
    <MenuItem label="This Year" value={thisyear}>
      This Year
    </MenuItem>
  </Select>
</FormControl>
  <ArrowBackIosIcon
    style={{ height: "15px", marginTop: "10px" }}
  />
  <Calendar
    style={{
      width: "271px",
      borderColor: "#0000",
      backgroundColor: "#0000",
      borderBottom: "1px solid #929292",
      fontWeight: 700,
    }}
    language="en"
    theme="default"
    dateFormat="YYYY/MM/DD"
    onChange={(date) => {
      setStartDate(date.bsDate);
    }}
    //defaultDate={startDate}
  />
  <Button style={{color:'#fff'}}>To</Button>
  <Calendar
    style={{
      width: "271px",
      borderColor: "#0000",
      backgroundColor: "#0000",
      borderBottom: "1px solid #929292",
      fontWeight: 700,
    }}
    language="en"
    theme="default"
    dateFormat="YYYY/MM/DD"
    onChange={(date) => {
      setEndDate(date.bsDate);
    }}
    minDate={startDate}
    //defaultDate={endDate}
  />

  {/* <DateRangePicker
    ranges={[selectionRange]}
    onChange={this.handleSelect}
  /> */}
  <ArrowForwardIosIcon
    style={{ height: "15px", marginTop: "10px" }}
    
  />
</span>
          </div>
</CardHeader>
      
    <div>
  
      <div
      >
        
        <Button onClick={()=>fetchChatList()}>fetch</Button>
        <Grid container spacing={0}>
        {/* <Grid item md={8} xs={12}> */}
        {note.length > 0 ? (
          note.map((chat, i) => (
            // <ChatItem
            //   key={i}
            //   userId={vendorId}
            //   chat={chtopicat}
            //   navigation={navigation}
            // />
            <div style={{display:'flex',flexDirection:'column',backgroundColor:'#fff',padding:12,margin:3,borderRadius:4,boxShadow:'0 1px 4px 0 rgb(0 0 0 / 14%)'}}>
            <div style={{padding:10,margin:10,}}>
            <h4>{chat.topic}</h4>
            {/* <div>{chat.note}</div> */}
        <DraftViewer>{chat.note}</DraftViewer>

            </div>
            </div>
          ))
        ) : (
          <div padder>
            <div>No Notes</div>
          </div>
        )}
        {/* </Grid> */}
        </Grid>
      </div>
    </div>
    <AddNote
          open={addOpen}
          handleClose={closeAddDialog}
        
        />
    </Card>
  );
};

export default AllNotes;

