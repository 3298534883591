import { TableRow,TableCell } from "@mui/material";
import NumberFormat from "utils/NumberFormat";


export default function SingleRow({data}) { 

    return(
        <TableRow  
            style={{
            margin: 5,
            padding: 5,
            borderRadius: 5,
            flexDirection: 'row',
            // flex: 1,
            // justifyContent: 'space-between',
            alignItems: 'center',
            
          }} key={data.product_id}>
              <TableCell>{data.BR == 'C' ? `${data.particular}` : `${data.particular} ` }</TableCell>
              <TableCell align='right' style={{color:data.head=='dr'? 'red' : 'green',textAlign:'right'}}><NumberFormat amount={data.amount}/></TableCell>
              </TableRow>
    )
}