import React, {  useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Title from '../../../Dashboard/Components/Title';
import { Button, Grid, Paper, TextField } from '@material-ui/core';
import * as actions from '../../../store/DashboardActions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

export default function Viber() {
  const classes = useStyles();
  const [url, setUrl] = useState('');
  const dispatch = useDispatch();

  const setWebHook = () => {
    dispatch(actions.set_viber_web_hook(url));
  };

  return (
    <Paper className={classes.paper}>
      <Title>Viber Web Hook</Title>

      <Grid container style={{ alignItems: 'center' }}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            onChange={(e) => setUrl(e.target.value)}
            label='Enter Viber Web Hook Url'
            variant='outlined'
            helperText={'Empty url will remove web hook'}
          />
        </Grid>
        <Grid item xs={12} style={{ margin: 10, justifyContent: 'center' }}>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            onClick={setWebHook}
          >
            Set Web Hook
          </Button>
          {
            // <Button
            //   type='submit'
            //   variant='contained'
            //   color='secondary'
            //   onClick={setWebHook}
            // >
            //   Remove Web Hook
            // </Button>
          }
        </Grid>
      </Grid>
    </Paper>
  );
}
