import React from 'react';
import routeList from './routeList';
import AuthRoute from './AuthRoute';
import { Switch } from 'react-router';

export default function AuthRouter() {

  return (
    <>
      <React.Suspense fallback={<div>Loading...</div>}>
        <Switch>
          {routeList.map((route, key) => (
            <AuthRoute
              path={`/${route.path}`}
              name={route.name}
              component={route.component}
              exact={route.exact}
              key={key}
            />
          ))},
        </Switch>
      </React.Suspense>
    </>
  );
}
