import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import { CDN_URL } from '../../../../config';

const useStyles = makeStyles((theme) => ({
  image: {
    width: '100%',
    height: '100%',
  },
}));

export default function ImageDialog({ open, handleClose, image }) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle id='form-dialog-title'>Image</DialogTitle>
      <DialogContent>
        <img className={classes.image} src={`${CDN_URL}/${image}`} alt='' />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='default'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
