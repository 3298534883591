import React, { useState, useEffect, useRef } from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import { Button, InputLabel } from "@material-ui/core";
import NepaliDate from "nepali-date-converter";
import Search from "@material-ui/icons/Search";
import CustomInput from "components/CustomInput/CustomInput";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";
import { GetRequest } from "plugins/axios";
import api from "api/api";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ChartistGraph from "react-chartist";
import { dailySalesChart } from "variables/charts";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { ArrowUpward } from "@material-ui/icons";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import NumberFormat from "utils/NumberFormat";
import { VictoryBar, VictoryChart, VictoryTheme } from "victory";
import { colorScale } from "utils/Colors";

const useStyles = makeStyles(styles);

const columns = [
  { id: "bill_date", label: "Months", minWidth: 170 },
  { id: "net_amount", label: "Sales", minWidth: 100 },
  // {
  //   id: "population",
  //   label: "Population",
  //   minWidth: 170,
  //   align: "right",
  //   format: (value) => value.toLocaleString("en-US"),
  // },
  // {
  //   id: "size",
  //   label: "Size\u00a0(km\u00b2)",
  //   minWidth: 170,
  //   align: "right",
  //   format: (value) => value.toLocaleString("en-US"),
  // },
  // {
  //   id: "density",
  //   label: "Density",
  //   minWidth: 170,
  //   align: "right",
  //   format: (value) => value.toFixed(2),
  // },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData("India", "IN", 1324171354, 3287263),
  createData("China", "CN", 1403500365, 9596961),
  createData("Italy", "IT", 60483973, 301340),
  createData("United States", "US", 327167434, 9833520),
  createData("Canada", "CA", 37602103, 9984670),
  createData("Australia", "AU", 25475400, 7692024),
  createData("Germany", "DE", 83019200, 357578),
  createData("Ireland", "IE", 4857000, 70273),
  createData("Mexico", "MX", 126577691, 1972550),
  createData("Japan", "JP", 126317000, 377973),
  createData("France", "FR", 67022000, 640679),
  createData("United Kingdom", "GB", 67545757, 242495),
  createData("Russia", "RU", 146793744, 17098246),
  createData("Nigeria", "NG", 200962417, 923768),
  createData("Brazil", "BR", 210147125, 8515767),
];
var Chartist = require("chartist");

export default function MonthlyReports({BILLTYPE}) {
  const componentRef = useRef();
  const classes = useStyles();
  const [billDate, setBillDate] = useState("");
  const subdomain = useSelector((state) => state.authReducer.user.subdomain);
  const [monthReport, setMonthReport] = useState([]);
  const [date, setDate] = useState([]);
  const [amount, setAmount] = useState([]);
  const [maxAmount, setMaxAmount] = useState(1000);
    const [averageData, setAverageData] = useState(0);
    const [salesTotal, setSalesTotal] = useState(0);
  const [pieData, setPieData] = useState([{ x: "Sales", y: 100 },
    
  { x: "Saving", y: 150 },
  { x: "Credit", y: 75 },]);
  const nepaliDate = new NepaliDate();
  const options = ["This Month", "Last Month", "Jan"];
  const dataTypes = ["All Forms", "Sales", "Purchase"];
  const defaultSelector = dataTypes[0];
  const defaultOption = options[0];
  var dateNp ='';
  useEffect(() => {
    dateNp = `${nepaliDate.getYear()}/${
      nepaliDate.getMonth() + 1 > 9
        ? nepaliDate.getMonth() + 1
        : `0${nepaliDate.getMonth() + 1}`
    }/${
      nepaliDate.getDate() > 9
        ? nepaliDate.getDate()
        : `0${nepaliDate.getDate()}`
    }`;
    setBillDate(dateNp + "");
  }, []);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const rows = [
  //     'a', 'b', 'c'
  // ];

  // const columns = [
  //     's', 'd', 'f'
  // ];
  useEffect(() => {
    let d=[];
    let total=0;
    monthReport?.map((row, index) => {
        total += row.net_amount;
    })
    monthReport?.map((row, index) => {
        d.push({x:MonthFromDate(row.bill_date),y:(row.net_amount)})
        // d.push({x:MonthFromDate(row.bill_date),y:((row.net_amount/total)*100)})
        console.log("pie data",d);
    })
    // const sum = monthReport.reduce((a, b) => a + b, 0);
    const avg = (total / monthReport.length) || 0;
    console.log("🚀 ~ file: Sales.js ~ line 58 ~ useEffect ~ avg", avg)
    setAverageData(avg*.50);
    setPieData(d);
    setSalesTotal(total);
}, [monthReport]);
  const MonthFromDate = (npDate)=>{
    let date = npDate.split('/');
    console.log("npdate",npDate);
    let newDate = new NepaliDate(
        parseInt(date[0]),
        parseInt(date[1]) - 1,
        parseInt(date[2])
        );
        switch (newDate.getMonth()){
            case 0:
                return newDate.getYear() + '/Baisakh';
            case 1:
                return newDate.getYear() + '/Jestha';
            case 2:
                return newDate.getYear() + '/Asar';
            case 3:
                return newDate.getYear() + '/Shrawan';
            case 4:
                return newDate.getYear() + '/Bhadra';
            case 5:
                return newDate.getYear() + '/Aswin';
            case 6:
                return newDate.getYear() + '/Kartik';
            case 7:
                return newDate.getYear() + '/Mangsir';
            case 8:
                return newDate.getYear() + '/Poush';
            case 9:
                return newDate.getYear() + '/Magh';
            case 10:
                return newDate.getYear() + '/Falgun';
            case 11:
                return newDate.getYear() + '/Chaitra';
            default:
                return newDate.getYear() + '/Other';
            
        }
        
    }
  const getMonthReport = () => {
    GetRequest(`${api.monthlyReportsAPI}`, { subdomain, bill_type: BILLTYPE })
      .then((res) => {
        setMonthReport(res.data.content);
        console.log("🚀 ~ file: totalSales.js ~ line 129 ~ .then ~ res.data", res.data)
      })
      .catch((err) => {
        alert(`🚀 ~ file: totalSales.js ~ line 133 ~ getMonthReport ~ ${err}`);
      });
    // GetRequest(`${api.monthlyReportsAPI}`, { subdomain,bill_type:'EXPENSE' })
    // .then((res) => {
    //     setmonthExpenseReport(res.data);
    //     console.log('Monthly Report',res.data);
    // })
    // .catch((err) => {
    //     alert( 'Cannot get MonthReport');
    // });
  };
  useEffect(() => {
    getMonthReport();
  }, [subdomain,billDate]);
  useEffect(() => {
    if (monthReport) {
      let amount = [];
      let date = [];
      monthReport.reverse().forEach((data, index) => {
        amount.push(data.net_amount);
        date.push(MonthFromDate(data.bill_date));
      });
      setDate(date);
      setAmount(amount);
      setMaxAmount(Math.max.apply(null, amount));
    }
  }, [monthReport]);
  return (
    <>
  <GridContainer>
  <GridItem xs={12} sm={12} md={4}>
    <div sx={{ width: "60%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
              // typeof monthReport == "array" &&
                monthReport?.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        <TableCell>
                        {MonthFromDate(row.bill_date)}
                        </TableCell>
                        <TableCell>
                        <NumberFormat amount={row.net_amount} />
                        </TableCell>
                        {/* {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })} */}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      </GridItem>
    <GridItem xs={12} sm={12} md={4}>
    <VictoryChart
            horizontal
            theme={VictoryTheme.material}
            domainPadding={50}
            >
            <VictoryBar
            //   animate={{
            //     duration: 2000,
            //     onLoad: { duration: 1000 }
            //   }}
            style={ { data: {fill: ({ datum }) => datum.y > averageData ? "#3cb891" : "#c43a31", } }}
            // style={ averageData<1 ? { data: { fill: "#3cb891" } } : { data: { fill: "#c43a31" } }}
            data={pieData}
            colorScale={colorScale}
            labels={({ datum }) => `${datum.y}`}
            
            />
            </VictoryChart>
      
    </GridItem>
    
  </GridContainer>
     
    </>
  );
}
