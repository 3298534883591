import React, { useEffect, useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setBillAdd,
  setBillClear,
  setBillUpdate,
  setRemoveAddItem,
} from "./store";
import NepaliDate from "nepali-date-converter";
import api from "../../../api/api";
import { GetRequest, PutRequest } from "../../../plugins/axios";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import {
  CardHeader,
  FormControl,
  Icon,
  MenuItem,
  Select,
  Switch,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  InputLabel,
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Input,
  InputAdornment,
  Container,
} from "@material-ui/core";
import { Col, Divider, Row as div, Table } from "antd";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import AddBillItems, { AddBtn } from "./components/AddBillItems";
import { useHistory } from "react-router";
import AddCustomer from "../../Dashboard/modules/Customer/Components/AddCustomer";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import * as actions from "modules/store/DashboardActions";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import AddProduct from "../Product/Components/AddProduct";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import BillDetaildiv from "../daybook/Components/BillDetailView";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import { setToasterState } from "common/Toast/services/ToasterAction";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { ToggleButton } from "@material-ui/lab";
import Toast from "common/Toast";
import { LabelImportant } from "@material-ui/icons";
import MostSold from "./components/MostSold";
import { setDisplayStock } from "modules/store/DashboardActions";
import UnstyledSwitcher from "components/UnstyledSwitcher";
import "./index.css";
import { API_URL } from "config";
import { Checkbox, FormControlLabel, FormGroup, TextareaAutosize } from "@mui/material";
import Card from "components/Card/Card";

// const useStyles = makeStyles(() => ({
//   button: {
//     margin: 10,
//   },
// }));

const filter = createFilterOptions();

export default function BillEdit(route) {
  console.log("🚀 ~ file: index.js ~ line 74 ~ BillEdit ~ route", route)
  const dispatch = useDispatch();
  const { location } = route;
    let { sales_data_id, date, BR,casid } = route?.location?.state || {sales_data_id:0,casid:0,date:0,BR:0};
    // let sales_data_id = 0;
    // let casid = location.cas_id;
    // let date = "2078/04/04";
    // let BR = "C";
  
  console.log("🚀 ~ file: index.js ~ line 77 ~ BillEdit ~ sales_data_id", sales_data_id)

  // const classes = useStyles();
  const displayStock = useSelector(
    (state) => state.dashboardReducer.displayStock
  );
  const [modalopen, setModalOpen] = React.useState(false);
  const [stock, setStock] = useState(false);

  const handleClickModalOpen = () => {
    setModalOpen(true);
  };
  const onChangeStock = (e) => {
    console.log(e.target.value);
    setStock(e.target.value);
  };
  const handleStock = (event) => {
    console.log("ran--", event);
    setStock(event.target.value);
    dispatch(setDisplayStock(event.target.value));
    dispatch(
      setToasterState({
        open: true,
        title: "success",
        name: "Get Stock!",
        message: `Successfully Get Stock!${displayStock}`,
      })
    );
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const StockSelector = (onChangeStock) => {
    console.log("stock", onChangeStock);
    return (
      <Select
        style={{ padding: "10px" }}
        id="demo-simple-select"
        value={stock}
        onChange={onChangeStock}
        fullWidth
        name="vendor_id"
      >
        <MenuItem value={"stock"}>Stock</MenuItem>
        <MenuItem value={"all"}>All</MenuItem>
      </Select>
    );
  };
  // const { location } = route;
  // console.log('route', location);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const gridRef = useRef();
  const onGridReady = (params) => {
    // params.api.sizeColumnsToFit();
    // var allColumnIds = [];
    // gridOptions.columnApi.getAllColumns().forEach(function (column) {
    //   allColumnIds.push(column.colId);
    // });

    // gridOptions.columnApi.autoSizeColumns(allColumnIds);

    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const [open, setOpen] = useState(false);
  const [dopen, setDopen] = useState(false);
  const [snack, setSnack] = useState(false);
  const TEXT_LENGTH = 120;
  const TEXT_HEIGHT = 30;
  const OFFSET = TEXT_LENGTH / 2 - TEXT_HEIGHT / 2;

  // let { sales_data_id, date, BR,casid } = route.params;
  // let sales_data_id = 0;
  // let casid = location.cas_id;
  // let date = "2078/04/04";
  // let BR = "C";
  const [items, setItems] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [stockOut, setStockOut] = useState([]);
  const [totalData, setTotalData] = useState({
    stockout: 0,
    price: 0,
    pricewithtax: 0,
  });
  const [billdetail, setBilldetail] = useState(false);
  const [paidAmount, setPaidAmount] = useState(0);
  const [billId, setBillid] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [bill_type, setBill_type] = useState("SALE");
  const [customers, setCustomers] = useState([]);
  const customersList = useSelector(
    (state) => state.dashboardReducer.customers
  );
  const subdomain = useSelector((state) => state.authReducer.user.subdomain);
  const user = useSelector((state) => state.authReducer.user.vendorId);

  const [payment_type, setPayment_type] = useState("C 0");

  let tempcas = casid ? casid : 0;
  // console.log("tempcas", tempcas);
  const [color, setColor] = useState("tempcas");
  const [cas_id, setCas_id] = useState(tempcas);
  const [search, setSearch] = useState("");
  const [cas_name, setCas_name] = useState("Cash Cash");
  const [salesId, setSalesId] = useState("");
  const [billDate, setBillDate] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [mostSold, setMostSold] = useState([]);
  const [tax, setTax] = useState(0);
  const [trunTax, setTrunTax] = useState(false);
  const [cashCr, setCashCr] = useState(true);
  const user_id = useSelector((state) => state.authReducer.user.id);
  console.log('user_id',user_id);
  const bill = useSelector((state) => state.bill);
  // console.log('bill data', bill);
  const { addItems, updateItems } = bill;
  const [savings, setSavings] = useState([]);

  const [addOpen, setAddOpen] = useState(false);
  const [timeout, setTimeout] = useState(5);
  const [currentApp, setCurrentApp] = useState("");
  const [loading, setLoading] = useState(true);

  const { current: instance } = useRef({});

  const nepaliDate = new NepaliDate();

  const [fullName, setFullName] = useState("");

  // console.log("user", user);
  const openAddDialog = (app) => {
    setCurrentApp(app);
    setAddOpen(true);
  };
  const closeAddDialog = () => {
    setCurrentApp("");
    setFullName("");
    setAddOpen(false);
  };

  // const openpAddDialog = (app) => {
  //   setCurrentApp(app);
  //   setAddpOpen(true);
  // };
  // const closepAddDialog = () => {
  //   setCurrentApp('');
  //   setAddpOpen(false);
  // };

  // let addItems=[0,0];
  // let updateItems=[0,0];
  const [edit, setEdit] = useState(true);

  useEffect(() => {
    getAccounts(subdomain);
  }, [subdomain]);

  const getLastId = () => {
    GetRequest(`${API_URL}/sales/get_max_sales_id`, { subdomain })
      .then((res) => {
        let id = parseFloat(res.data.content[0].id);
        sales_data_id = id;
        setSalesId(id);
        // console.log("id", salesId);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const getMostSold = () => {
    GetRequest(`${API_URL}/product/most-sold`, { subdomain,bill_type })
      .then((res) => {
        // let id = parseFloat(res.data.content[0].id);
        // sales_data_id = id;
        setMostSold(res.data.content);
        // console.log("most Sold", mostSold);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  useEffect(() => {
    // console.log(bill);
    let copyList = items;
    bill.updateItems.forEach((data) => {
      let objIndex = copyList.findIndex((obj) => obj.id === data.id);
      // console.log({ objIndex });
      if (objIndex > -1) {
        copyList.splice(objIndex, 1);
      }
    });
    // console.log('AFTER', copyList);
    setItems([...copyList]);
  }, [bill]);
  useEffect(() => {
    // console.log('items', items);
  }, [items]);

  useEffect(() => {
    // console.log('getting items');
    sales_data_id && subdomain && getItems();
  }, [sales_data_id, subdomain, salesId]);

  useEffect(() => {
    search && subdomain && dispatch(actions.get_customers(search, subdomain));
    setCustomers(customersList);
  }, [search, subdomain, dispatch]);

  // useEffect(() => {
  //   // console.log("CUSTOMERLIST",customersList.content)
  //   // setCustomers(customersList);
  // }, [customersList]);

  const getItems = () => {
    dispatch(setBillClear());
    GetRequest(`${API_URL}/sales/get_sales_items`, {
      sales_data_id,
      subdomain,
    })
      .then((res) => {
        // console.log("sales items ", res.data.content);
        let ids = [];
        let content = [];
        let sales_data = [];
        res.data.content.forEach((d) => {
          if (!ids.includes(d.id)) {
            ids.push(d.id);
            content.push(d);
          }
        });
        // console.log("prossesed sales items ", content);
        // setItems(content);
        setItems(res.data.content);
        setSalesData(res.data.sales_data);
        setCas_id(parseInt(res.data.sales_data[0].cas_id));
        setCas_name(res.data.sales_data.fname);
      })
      .catch((err) => {});
  };
  useEffect(()=>{
    let totalDiscount = 0;
    addItems.forEach((bill) => {
      bill.rate &&
        (totalDiscount += parseFloat(bill.discount) * parseFloat(bill.quantity));
    });
    setDiscount(totalDiscount ? totalDiscount : 0);
  },[items,addItems])
  useEffect(() => {
    // // console.log('UPDATE');
    let totalQuantity = 0;
    let totalPrice = 0;
    items.forEach((bill) => {
      bill && (totalQuantity += bill.stockout || bill.stockin);
      bill.amount &&
        (totalPrice +=
          (parseFloat(bill.stockin).toFixed(2) || parseFloat(bill.stockin).toFixed(2) ) *
          parseFloat(bill.rate).toFixed(2));
    });

    addItems.forEach((bill) => {
      bill.quantity && (totalQuantity += parseFloat(bill.quantity));
      bill.rate &&
        (totalPrice += parseFloat(bill.rate) * parseFloat(bill.quantity));
    });

    updateItems.forEach((bill) => {
      bill.quantity && (totalQuantity += parseFloat(bill.quantity));
      bill.rate &&
        (totalPrice += parseFloat(bill.rate) * parseFloat(bill.quantity));
    });

    setTotalData({
      ...totalData,
      price: totalPrice - discount,
      pricewithtax: totalPrice - discount + tax,
      stockout: totalQuantity,
    });
    setPaidAmount(totalPrice - discount + tax);
  }, [items, bill, discount, tax]);


  const setPaidTypeHandler = (e) => {
    setPayment_type(e.target.value);
    if (e.target.value == "CR 0") {
      setPaidAmount(0);
    } else if (e.target.value == "C 0") {
      setPaidAmount(totalData.pricewithtax);
    }else{
      setPaidAmount(0);
    }
  };

const setPaidAmountHandler = (e) => {
  setPaidAmount(e.target.value);
  // if (totalData.pricewithtax > paidAmount) {
  //   setPayment_type("C 0");
  // } else {
  //   setPayment_type("CR 0");
  // }
};
  useEffect(() => {
    // console.log('OK', sales_data_id, subdomain);
    if (!sales_data_id) {
      setEdit(false);
      subdomain && getLastId();
    } else {
      setSalesId(sales_data_id);
    }
  }, [sales_data_id, subdomain]);

  useEffect(()=>{
    subdomain && getMostSold();
  },[sales_data_id, subdomain,bill_type])

  useEffect(() => {
    let copyList = items;
    updateItems.forEach((data) => {
      let objIndex = copyList.findIndex((obj) => obj.id === data.id);
      if (objIndex > -1) {
        copyList.splice(objIndex, 1);
      }
    });
    setItems(copyList);
  }, [updateItems, items, addItems, salesId]);
  useEffect(() => {
    // console.log("date changed to defult", billDate);
    date = `${nepaliDate.getYear()}/${
      nepaliDate.getMonth() + 1 > 9
        ? nepaliDate.getMonth() + 1
        : `0${nepaliDate.getMonth() + 1}`
    }/${
      nepaliDate.getDate() > 9
        ? nepaliDate.getDate()
        : `0${nepaliDate.getDate()}`
    }`;
    setBillDate(date + "");
  }, []);
  const addBillItem = () => {
    // navigate(ADD_BILL_ITEM, { sales_data_id:salesId, subdomain });
  };
// ** test
  const history = useHistory();
  const editBillItem = (bill) => {
    history.push("inventory/BillEdit", {
      sales_data_id,
      subdomain,
      id: bill.id,
      name: bill.name,
      price: bill.amount,
      stockout: bill.stockout == 0 ? bill.stockin : bill.stockout,
      product_id: bill.product_id,
    });
    // navigate(ADD_BILL_ITEM, {
    //   sales_data_id,
    //   subdomain,
    //   id: bill.id,
    //   name: bill.name,
    //   price: bill.amount,
    //   quantity: bill.stockout == 0 ? bill.stockin : bill.stockout,
    //   product_id: bill.product_id,
    // });
  };
  const onCellValueChanged = (event) => {
    console.log(
      "onCellValueChanged: " + event.colDef.field + " = " + event.newValue
    );
  };
  // const onCellValueChanged = (event) => {
  //   // console.log('Data after change is', event.data);
  // };
  const onRowValueChanged = (event) => {
    var data = event.data;
    console.log(
      "onRowValueChanged: (" +
        data.make +
        ", " +
        data.model +
        ", " +
        data.price +
        ", " +
        data.field5 +
        ")"
    );
  };
  const updateBilledItems = (event) => {
    dispatch(
      setBillUpdate({
        ...event.data,
        sales_data_id,
        stockout: event.data.stockout,
      })
    );
  };
  const updateAddBilledItems = (event) => {
    // console.log("data for update", event.data);
    let a = addItems;
    let b = [event.data];
    let newA = a.reduce(
      (a, { product_id, ...rest }) => ({
        ...a,
        ...{ [product_id]: { product_id, ...rest } },
      }),
      {}
    );

    b.forEach(
      ({ product_id, stockout }) => (newA[product_id].stockout = stockout)
    );
    let c = Object.values(b);
    c.filter(function (itm) {
      return itm.product_id > 1;
    });
    // console.log("value of c", c);
    dispatch(setBillAdd(...addItems));
  };
  const onCasChange = (event, values) => {
    // console.log("vales after drop", values);
    dispatch(actions.get_customers(values, subdomain));
    setCustomers(customersList);
  };
  const getBalance = (subdomain) => {
    setLoading(true);
    GetRequest(`${API_URL}/report/saving-book`, { subdomain })
      .then((res) => {
        setLoading(false);

        setSavings(res.data.content);
      })
      .catch((err) => {
        Toast.show({ text: "Error Geting balance" });
      });
  };
  const saveDetails = () => {
    setBillid(sales_data_id);
    // console.log("billdateeeeeeeeeeee", billDate);
    setDopen(false);
    PutRequest(`${API_URL}/sales/updateData`, {
      amount: totalData.pricewithtax,
      vat_charge: tax,
      paid_amount: paidAmount,
      discount: discount,
      id: sales_data_id,
      cas_id,
      user_id,
      billDate,
      subdomain,
      remarks,
      payment_type,
      bill_type,
      addItems,
      updateItems,
    })
      .then((res) => {
        getItems();
        setBilldetail(true);
        setDiscount(0);
        setRemarks("");
        setSnack(true);
      })
      .catch((err) => {
        setSnack(true);
      });
  };
  const cancelTimer = (handle) => {
    if (instance.timer) {
      clearTimeout(instance.timer);
    }
    return 0;
  };
  // useEffect(()=>{
  //   let myInterval = setInterval(() => {
  //           if (timeout > 0) {
  //               setTimeout(timeout - 1);
  //           }
  //       }, 1000)
  //       return ()=> {
  //           clearInterval(myInterval);
  //         };
  //   },[]);
  const getAccounts = (subdomain) => {
    setLoading(true);
    GetRequest(`${API_URL}/report/saving-book`, { subdomain })
      .then((res) => {
        setLoading(false);

        // console.log("content", res.data.content);
        setAccounts(res.data.content);
      })
      .catch((err) => {
        // console.log({ text: 'Error Geting balance' });
      });
  };
  const timeoutsaveDetails = () => {
    instance.timer = cancelTimer(instance.timer);
    setTimeout(5);
    dispatch(
      setToasterState({
        open: true,
        title: "success",
        name: "Bill Saved!",
        message: `Click here to undo in ${timeout}`,
      })
    );
    instance.timer = setTimeout(() => {
      saveDetails();
    }, 5000);
  };
  const deleteRenderer = (props) => {
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    // // console.log("props for rm",props);
    return (
      <span>
        {/* <span>{props.data.name}</span>&nbsp; */}
        {/* <button onClick={() => removeAddItem(props.rowIndex)}>Delete</button> */}
        {/* <span onClick={()=>{
                        alert();
                      setModalOpen(true)}}> */}
        <DeleteForeverIcon
          color="primary"
          // onClick={() => handleClickModalOpen()}
          onClick={() => removeAddItem(props.rowIndex)}
        />
        {/* </span> */}
        <Dialog
          open={modalopen}
          onClose={handleModalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure, you want to delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModalClose}>Cancel</Button>
            <Button onClick={() => removeAddItem(props.rowIndex)}>Ok</Button>
          </DialogActions>
        </Dialog>
      </span>
    );
  };
  const removeAddItem = (position) => {
    dispatch(setRemoveAddItem(position));
  };
  function isFullWidth(data) {
    return ["Peru", "France", "Italy"].indexOf(data.name) >= 0;
  }
  const qty_rate_amount = (params) => {
    return (
      (params.data.rate - (params.data?.discount || 0)) *
      (params.data?.stockin || params.data?.stockout || params.data?.quantity)
    ).toFixed(2);
  };
  const get_qty = (params) => {
    // console.log("qty stopck", params.data);
    return (
      params.data?.stockin ||
      params.data?.stockout ||
      params.data?.quantity ||
      params.data?.stock_draft
    );
  };
  const valueSetter = (params) => {
    params.data.name = params.newValue;
    dispatch(
      setBillAdd({
        name: params.data.name,
        quantity: params.data.quantity,
        rate: params.data.rate,
        sales_data_id: params.data.sales_data_id,
        product_id: params.data.product_id,
        subdomain: params.data.subdomain,
      })
    );
    return true;
  };

  const closeBill = () => {
    setBilldetail(false);
    // location.reload(false);
  };
  useEffect(() => {
    if (trunTax) {
      let taxamount = 0.13 * totalData.price;
      setTax(taxamount);
    } else {
      setTax(0);
    }
  }, [totalData, trunTax]);
  const columnDefs = [
    // 1 - undefined - Grid renders the value as a string.
    {
      field: "stock",
      cellRenderer: "stockSelector",
      flex: 1,
    },
    {
      field: "addbillitems",
      width: 500,
      cellRenderer: "addBillItems",
      flex: 6,
      cellRendererParams: {
        stock: stock,
        subdomain,
        sales_data_id: salesId,
        id: false,
        price: 1,
        src: "test",
      },
    },
  ];
  const bottomOptions = {
    alignedGrids: [],
    defaultColDef: {
      editable: false,
      sortable: true,
      resizable: false,
      filter: true,
      flex: 7,
      minWidth: 10,
    },
  };
  const bottomData = [
    {
      product_id: "Stocks",
      name: "Search Items",
      quantity: "Chose Stock",
      rate: "Choose Scheme",
      action: "Clear",
    },
  ];

  function handleChange(event) {
    console.log("alert");
    console.log(event.target.value);
    setTrunTax(!trunTax);
  }
  function handleCredit(event) {
    console.log("alert");
    console.log(event.target.value);
    setCashCr(!cashCr);
  }
useEffect(()=>{
  if (!cashCr) {
    setPaidAmount(0);
  }else{
    setPaidAmount(totalData.pricewithtax);
  }
},[cashCr])
  return (
    <>
      <div>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormControl variant="outlined">
                <InputLabel id="bill_type-label">Bill Type</InputLabel>
                <Select
                  labelId="bill_type-label"
                  id="bill_type"
                  value={bill_type}
                  onChange={(e) => setBill_type(e.target.value)}
                  label="Bill Type"
                >
                  <MenuItem label="Sales" value="SALE">
                    Invoices
                  </MenuItem>
                  <MenuItem label="Purchase" value="PURCHASE">
                    Purchase
                  </MenuItem>
                  <MenuItem label="Expense" value="EXPENSE">
                    Expense
                  </MenuItem>
                  <MenuItem label="Saving" value="SAVING">
                    Saving
                  </MenuItem>
                  <MenuItem label="Withdraw" value="WITHDRAW">
                    Withdraw
                  </MenuItem>
                  <MenuItem label="VOUCHER" value="VOUCHER">
                    Payment
                  </MenuItem>
                  <MenuItem label="Receipt" value="RECEIPT">
                    Receipt
                  </MenuItem>
                  <MenuItem label="Sales Return" value="SALERETURN">
                    Sales Return/Cr Note
                  </MenuItem> <MenuItem label="Purchase Return" value="PURCHASERETURN">
                    Purchase Return/Dr Note
                  </MenuItem>
                  <MenuItem label="Estimate/Quotation" value="Quotation">
                    Estimate/Quotation
                  </MenuItem>
                  <MenuItem label="Delivery Challan" value="Challan">
                    Delivery Challan
                  </MenuItem>
                </Select>
              </FormControl>

              {/* <Autocomplete
                          onChange={(event, values) => { setCas_id(values?.cas_id); setCas_name(`${values?.fname} ${values?.lname}`) }}
                          onInputChange={onCasChange}
                          options={Customers}
                          getOptionLabel={(option) => option.fname + ' ' + option.lname + ' ' + option.contact}
                          style={{ width: 300 }}
                          renderInput={(params) => <TextField id="cas_id" {...params} value={search} label={cas_id} variant="outlined" onChangeText={e => setSearch(e.target.value)} />}
                        /> */}
              {/* <br /> */}
              <Autocomplete
                disablePortal={false}
                // onChange={
                //   (event, values) =>
                //   {
                //     setCas_id(values?.cas_id);
                //     setCas_name(`${values?.fname} ${values?.lname}`)
                //   }}

                onInputChange={(params) => setSearch(params.target.value)}
                // value={value}
                onChange={(event, newval) => {
                  console.log("newval", newval);
                  if (typeof newval === "string") {
                    setFullName(newval);
                    setTimeout(() => {
                      setAddOpen(true);
                    });
                  } else if (newval && newval.inputValue) {
                    setFullName(newval.inputValue);
                    setTimeout(() => {
                      setAddOpen(true);
                    });
                  } else {
                    setCas_id(newval?.cas_id);
                    setCas_name(`${newval?.fname} ${newval?.lname}`);
                  }
                }}
                options={customers}
                getOptionLabel={(option) =>
                  option.fname + " " + option.lname + " " + ( option.RC ? `(रु ${option.RC})` : ' ') + option.contact
                }
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  if (params.inputValue !== "") {
                    filtered.push({
                      inputValue: params.inputValue,
                      fname: `Add "${params.inputValue}"`,
                      lname: "",
                      contact: "",
                    });
                  }

                  return filtered;
                }}
                style={{ width: 300 }}
                // renderOption={(props, option) => <li {...props}>{option.fname}</li>}
                renderInput={(params) => (
                  <TextField
                    id="cas_id"
                    {...params}
                    value={search}
                    label={`Account: ${cas_id == 0 ? 'Cash Counter' : cas_id }`}
                    variant="outlined"
                    style={{marginTop:20}}
                    onChangeText={(e) => setSearch(e.target.value)}
                  />
                )}
                onChangeText={(e) => setSearch(e.target.value)}
              />
              
              {/* Cash<UnstyledSwitcher value={true} onChange={()=>setPayment_type('C 0')} />Credit */}
              {/* <div primary full style={{ flex: 1 }} onClick={openAddDialog}>
                        <Button > Add Customer </Button >
                      </div> */}
              {/* {search} */}
            </div>

            {/* Right Column */}
            <div>
              <div
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    float: "right",
                  }}
                >
                  {/* <Button variant='contained'
                    color='primary' onClick={() => setBilldetail(true)}>Open</Button> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    marginRight: "10px",
                    marginBottom: "5px",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => setDopen(true)}
                    color="primary"
                  >
                    {" "}
                    Save{" "}
                  </Button>
                </div>
              </div>

              {/* Group Inv no, Inv Date */}
              <div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ width: "30%" }}>
                    <label style={{ lineHeight: "3em" }}>Invoice Number:</label>
                  </div>
                  <div
                    style={{
                      width: "70%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Input
                      style={{
                        paddingLeft: "5px",
                        width: "300px",
                        fontWeight: "bold",
                      }}
                      keyboardType="number-pad"
                      value={salesId}
                      onChange={(e) => {
                        setSalesId(e.target.value);
                      }}
                      endAdornment={
                        <InputAdornment position="start">
                          <Button
                            variant="contained"
                            color="#ddd"
                            size="small"
                            onClick={() => setBilldetail(true)}
                          >
                            <b>Open</b>
                          </Button>
                        </InputAdornment>
                      }
                    />
                  </div>
                </div>

                {/* <div > */}
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ width: "30%" }}>
                    <label style={{ lineHeight: "4em" }}>Invoice Date:</label>
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      width: "70%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                    onPress={() => setOpen(!open)}
                  >
                    <Calendar
                      style={{
                        width: "300px",
                        borderColor: "#0000",
                        backgroundColor: "#0000",
                        borderBottom: "1px solid #929292",
                        fontWeight: 700,
                      }}
                      language="en"
                      theme="default"
                      dateFormat="YYYY/MM/DD"
                      onChange={(date) => {
                        setBillDate(date.bsDate + "");
                      }}
                    />
                    <span style={{ marginLeft: "-30px" }}>
                      <TodayOutlinedIcon />
                    </span>
                  </div>
                </div>
              </div>
              {/* Group Inv no, Inv Date */}
              <div style={{ flexDirection: "row" }}>
                <div
                  primary
                  full
                  style={{ flex: 1 }}
                  onClick={() => setDopen(true)}
                ></div>
                {/* <div primary full style={{ flex: 1 }} onClick={cancelTimer(instance.timer)}>
                  <Button  variant='contained'
                  color='primary' > cancel </Button >
                </div>    */}
                <br></br>
              </div>
            </div>
          </div>

          <br />
          <br />
          <br />
          <div />
          <div />
          <div />

          {/* <Table size='small'>
                <TableHead>
                <TableRow>
                <TableCell>#</TableCell>
                <TableCell>ITEM</TableCell>
                <TableCell>QTY</TableCell>
                <TableCell>PRICE/UNIT</TableCell>
                <TableCell >AMOUNT</TableCell>
                </TableRow>
              </TableHead> */}
          {bill_type != "RECEIPT" && (
            <div style={{display:'flex',flexDirection:'row',flex:4,}}>
              <div
                className="ag-theme-material"
                style={{ height: "100%", width: "100%",minHeight:'45vh' }}
              >
                <div style={{ flex: "1 1 auto" }}>
                  <AgGridReact
                  getRowStyle={(params)=>{
                    if (params.node.rowIndex % 2 === 0) {
                      return { background: '#f6f8fb' };
                  }
                  }}
                    key={items}
                    rowData={items.concat(addItems)}
                    onClick={(index) => removeAddItem(index)}
                    isFullWidthCell={function (rowNode) {
                      return isFullWidth(rowNode.data);
                    }}
                    fullWidthCellRenderer={"fullWidthCellRenderer"}
                    onGridReady={onGridReady}
                    onCellValueChanged={updateAddBilledItems}
                    frameworkComponents={{
                      stockSelector: StockSelector,
                      deleteRenderer: deleteRenderer,
                      showname: deleteRenderer,
                    }}
                    // editType={'fullRow'}
                    domLayout={"autoHeight"}
                    scrollbarWidth={true}
                    defaultColDef={{
                      flex: 1,
                      minWidth: 110,
                      minHeight:500,
                      editable: true,
                      resizable: false,
                      borders: true,
                    }}
                  >
                    <AgGridColumn
                      width={80}
                      headerName="#"
                      resizable={false}
                      field="product_id"
                    ></AgGridColumn>
                    <AgGridColumn
                      cellEditor="addBillItems"
                      cellEditorParams={{
                        stock,
                        subdomain,
                        sales_data_id: salesId,
                        id: false,
                        price: 1,
                        src: "test",
                      }}
                      width={150}
                      headerName="ITEM"
                      resizable={false}
                      field="name"
                    ></AgGridColumn>
                    <AgGridColumn
                      width={150}
                      headerName="QTY"
                      resizable={false}
                      valueGetter={get_qty}
                      field="quantity"
                    ></AgGridColumn>
                    <AgGridColumn
                      width={150}
                      headerName="RATE"
                      resizable={false}
                      field="rate"
                    ></AgGridColumn>
                    <AgGridColumn
                      width={150}
                      headerName="DISCOUNT"
                      resizable={false}
                      field="discount"
                    ></AgGridColumn>
                    <AgGridColumn
                      width={150}
                      headerName="AMOUNT"
                      resizable={false}
                      valueGetter={qty_rate_amount}
                    ></AgGridColumn>
                    <AgGridColumn
                      cellRenderer="deleteRenderer"
                      width={150}
                      headerName="ACTION"
                    ></AgGridColumn>
                  </AgGridReact>
                </div>
                {/* {products.map((row, index) => ( */}
                <div
                  style={{
                    display: "flex",
                    flex: 8,
                    flexDirection: "row",
                    height: "60px",
                  }}
                >
                  <div style={{ flex: 4 }}>
                    <FormControl
                      variant="standard"
                      style={{ width: "220px", marginRight: "5px" }}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Select Stock Type
                      </InputLabel>
                      <Select label="Cash" onChange={handleStock} picker>
                        <MenuItem label="Stock" value={true}>
                          Stock
                        </MenuItem>
                        <MenuItem label="All" value={false}>
                          All
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {/* <Select 
                    id="demo-simple-select"
                    value={displayStock ? 'Stock' : 'All'}
                    onChange={handleStock}
                    fullWidth name='vendor_id' >
                    <MenuItem value={true} >
                    Stock
                    </MenuItem>
                    <MenuItem value={false}>
                    All
                    </MenuItem>
                    </Select> */}
                  </div>
                  <AddBillItems
                    style={{ flex: 5 }}
                    subdomain={subdomain}
                    sales_data_id={salesId}
                    bill_type={bill_type}
                    id={false}
                    price={1}
                    src="test"
                  />
                </div>
              </div>

              <div style={{ flex: 2, }}>
                <MostSold data={mostSold} connect_id={salesId} subdomain />

              </div>
            </div>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: 10,
            }}
          >
            <div>
              <Card 
              style={{
                      padding: 10,
                      // borderRadius: 10,
                    //   borderLeft: `4px solid green`,
                      width: 'auto',
                      margin:2,
                      marginTop:0,
                      marginBottom: 0}}>
            <TextareaAutosize
            onChange={(e) => setRemarks(e.target.value)}
  aria-label="minimum height"
  minRows={3}
  placeholder="Bill Remarks"
  style={{ width: '50vw',padding:10,borderWidth:0, }}
/>
</Card>
              {/* <FormControl
                variant="outlined"
                style={{ width: "220px", marginRight: "5px", padding: "10px" }}
              >
                <Input
                  onChange={(e) => setRemarks(e.target.value)}
                  value={remarks}
                  placeholder="Bill Remarks"
                />
              </FormControl> */}
            </div>
            <div>
              <div
                style={{
                  display: "grid",
                  gridGap: "10px",
                  gridTemplate: '"a a"',
                  justifyContent: "space-between",
                  padding: "10px 20px",
                  textAlign: "right",
                }}
              >
                {bill_type != "RECEIPT" && (
                  <>
                    {" "}
                    <div>Qty Total: </div>
                    <div>{totalData.stockout}</div>
                    <div>Sub Total: </div>
                    <div>
                      {" "}
                      {parseFloat(totalData.price) + parseFloat(discount)}
                    </div>
                    <div className="add-tax" style={{ display: "flex" }}>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox onChange={()=>{alert('please use amoutn')}} />}
                          label="Discount"
                        />
                      </FormGroup>
                    </div>
                    <div>
                      {" "}
                      <TextField
                        style={{
                          flex: 1,
                          borderColor: "black",
                          borderWidth: 0,
                        }}
                        textAlign={"center"}
                        inputProps={{
                          min: 0,
                          style: { textAlign: "right", border: 0 },
                        }}
                        keyboardType="number-pad"
                        value={discount}
                        onChange={(e) => setDiscount(e.target.value)}
                      />
                    </div>
                    <div className="add-tax" style={{ display: "flex" }}>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox onChange={handleChange} />}
                          label="Tax 13%"
                        />
                      </FormGroup>
                    </div>
                    <div>{tax}</div>
                    <div>Total Amount</div>
                    <div>{totalData.pricewithtax}</div>
                  </>
                )}
                
                
                <div >
                <FormGroup>
                        <FormControlLabel
                          control={<Checkbox  checked={cashCr} onChange={handleCredit} />}
                          label="Paid"
                        />
                      </FormGroup>
                </div>
                
                <TextField
                  style={{ flex: 1, borderColor: "black", borderWidth: 0 }}
                  textAlign={"center"}
                  inputProps={{
                    min: 0,
                    style: { textAlign: "right", border: 0 },
                  }}
                  keyboardType="number-pad"
                  value={paidAmount}
                  onChange={(e) => setPaidAmountHandler(e)}
                />
                {bill_type == "VOUCHER" && (
                  <>
                    <div style={{ flex: 1 }}>Total</div>
                    <TextField
                      style={{ flex: 1, borderColor: "black", borderWidth: 0 }}
                      textAlign={"center"}
                      inputProps={{
                        min: 0,
                        style: { textAlign: "right", border: 0 },
                      }}
                      keyboardType="number-pad"
                      value={paidAmount}
                      onChange={(e) =>
                        setTotalData({
                          ...totalData,
                          pricewithtax: e.target.value,
                        })
                      }
                    />
                  </>
                )}

                {bill_type != "RECEIPT" && (
                  <>
                    <div>Balance Due</div>
                    <div>{totalData.pricewithtax - paidAmount}</div>
                  </>
                )}
                <div>Payment Method</div>
                <FormControl
                // variant="outlined"
                style={{ width: "220px", marginRight: "5px" }}
              >
                {/* <InputLabel id="demo-simple-select-outlined-label">
                  Payment Type
                </InputLabel> */}
                <Select
                  label="Cash"
                  picker
                  aria-selected={payment_type}
                  value={payment_type}
                  onChange={(e) => {
                    setPaidTypeHandler(e);
                  }}
                >
                  <MenuItem label="Cash" value="C 0">
                    Cash
                  </MenuItem>
                  <MenuItem label="Credit" value="CR 0">
                    Credit
                  </MenuItem>
                  {/* <MenuItem label='Bank' value='BR' >Bank/Fonepay</MenuItem> */}
                  {accounts.map((acc) => (
                    <MenuItem value={`BR ${acc.product_id}`}>
                      {acc.name} Bal-Rs.{acc.amount}
                    </MenuItem>
                  ))}
                  {/* <MenuItem label='Phonepay' value='FP' >FP</MenuItem> */}
                </Select>
              </FormControl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        //  fullScreen={true}
        open={dopen}
        onClose={() => setDopen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {" "}
          Are you sure to save ?
        </DialogTitle>
        <DialogContent>
          <div>
            <h1
              style={{
                backgroundColor:
                  payment_type == "CR 0" ? "rgb(255 79 79)" : "#05cb67",
                color: "#fff",
                textAlign: "center",
                borderRadius: "6px",
              }}
            >
              {payment_type == "CR 0"
                ? "CREDIT " + bill_type
                : "CASH " + bill_type}
            </h1>
          </div>
          <Table dataSource={items.concat(addItems)} pagination={false}>
            <Table.Column
              title="#"
              render={(value, item, index) => index + 1}
            />
            <Table.Column title="Items" dataIndex="name" />
            <Table.Column title="Pcode" dataIndex="product_id" />
            <Table.Column
              title="Quantity"
              render={(text, record) =>
                record.stockout || record.stockin || record.quantity
              }
            />
            <Table.Column title="Rate" dataIndex="rate" />
            <Table.Column
              title="Amount"
              render={(text, record) =>
                record.rate *
                (record.stockout || record.stockin || record.quantity)
              }
            />
          </Table>
          This {payment_type == "C 0" ? "Cash" : "Credit/Other"} bill is{" "}
          {paidAmount} in paid total.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDopen(false)} color="default">
            Cancel
          </Button>
          {/* <Download data={salesData?}/> */}
          <Button onClick={saveDetails} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <BillDetaildiv
        key={salesId}
        open={billdetail}
        connect_id={salesId}
        data={{ connect_id: salesId, bill_date: "2078/04/12" }}
        bill_date={"2078/04/12"}
        subdomain={subdomain}
        handleClose={() => {
          setBilldetail(false);
          getLastId();
        }}
      />
      {addOpen && (
        <AddCustomer
          open={addOpen}
          customerFullName={fullName}
          handleClose={closeAddDialog}
        />
      )}
      {/* // console.log(addModalValues) */}
      {/* <AddProduct
                          open={addpOpen}
                          handleClose={closepAddDialog}
                          editStockData={false}
                        /> */}
      <div
        ref={(el) => {
          let messagesEnd = el;
        }}
      ></div>
      {/* <SnackbarContent closeNotification={() => setSnack(false)} place="tl" open={snack} message={"This is a plain notification"} /> */}
    </>
  );
}
