import React, { useEffect, useState } from 'react';
import { ListItemButton } from '@mui/material';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from 'react-router-dom';


import { Icon, Tooltip } from '@material-ui/core';
import { API_URL } from '../../../config';
import { GetRequest } from '../../../plugins/axios';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../store/DashboardActions';
import axios from 'axios';
import  './style.css';
const sidebarList = [
  {
    id: 1,
    icon: 'home',
    name: 'Dashboard',
    to: '/dashboard',
  },
];

const MenuListItems = () => {
  const history = useHistory();
  const [axiosToken, setAxiosToken] = useState('');
  const [menuitems, setMenuitems] = useState([]);
  const pathname = window.location.pathname;
  console.log("🚀 ~ file: MainListItems.js ~ line 29 ~ MenuListItems ~ pathname", pathname)
  const gotoRoute = (to) => {
    history.push(to);
  };
  // const [permission, setPermission] = useState([]);
  
  const mfrmr=useSelector((state) => state.dashboardReducer.menu);
  const permission = useSelector((state) => state.authReducer.user.permission);
  const [filterData, setFilterData] = useState({
    permission,
    page: 0,
    limit: 10,
  });
  // useEffect(() => {
  //   onOpen();
  // }, [active]);
  const dispatch = useDispatch();
  useEffect(() => {
    // // console.log("getting permission",permission);
    const get_menu= () =>{
    if (axiosToken) {
      // Cancel the previous request before making a new request
      axiosToken.cancel('operation canceled due to new request.');
    }
    let token = axios.CancelToken.source();
    setAxiosToken(token);
    dispatch(actions.get_menu(filterData.page === 0, filterData, token));
  }
  get_menu();
  return () => {
    // console.log('clear');
  };
  }, [filterData,permission]);
  useEffect(() => {
    // console.log("mrfrm ",mfrmr.length);
    if(mfrmr.length!=0){
    setMenuitems(mfrmr);}else{
      let token = axios.CancelToken.source();
    setAxiosToken(token);
      dispatch(actions.get_menu(filterData.page === 0, filterData, token));
    }
  }, [mfrmr]);
  
  // const getMenus = () => {
    
  //   GetRequest(`${API_URL}/menu/`, {
  //     // permission:"101,103,106,107,109,110,113,114,115,116,120,121,701,702,703,704,705",
  //     permission,
  //   })
  //   .then((res) => {
  //     // console.log(res.data.content);
  //     setMenu(res.data.content);
  //   })
  //   .catch((err) => {});
  // };
  return (
    <div className='MenuList'>
      {/* {sidebarList.map((sideBar) => (
        <ListItem
          key={sideBar.id}
          selected={pathname === sideBar.to}
          button
          className='ListItem'
          onClick={() => gotoRoute(sideBar.to)}
        >
          <Tooltip title={sideBar.name}>
            <ListItemIcon style={{ color: '#fff' }}>
              <Icon>{sideBar.icon}</Icon>
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={sideBar.name} />
        </ListItem>
      ))} */}
      {menuitems.map((sideBar) => (
        <ListItem
          key={sideBar.id}
          selected={pathname === `/${sideBar.menutyp}/${sideBar.pnav}/`}
          button
          className='ListItem'
          onClick={() => gotoRoute(`/${sideBar.menutyp}/${sideBar.pnav}/`)}
        >
          <Tooltip title={sideBar.name}>
            <ListItemIcon  style={{ color: pathname === `/${sideBar.menutyp}/${sideBar.pnav}/` ? '#1A91DA' : '#fff' }}>
              <Icon>{sideBar.micon}</Icon>
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={sideBar.name} />
        </ListItem>
      ))}
    </div>
  );
};
export default MenuListItems;

