import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import ReactTagInput from "@pathofdev/react-tag-input";
// import { WithContext as ReactTags } from 'react-tag-input';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../store/DashboardActions';
import StockAppRules from './StockAppRules';
import { ValidateForm, ValidateInput } from '../../../../utils/validateForm';
import Title from '../../../Dashboard/Components/Title';
import { CATEGORIES } from './Categories';
const AddProduct = ({ open, handleClose, editStockData, productName }) => {
  const KeyCodes = {
    comma: 188,
    enter: 13
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  //taghandler
  // const [tags, setTags] = React.useState([
  //   { id: '0', text: 'None' },
  // ]);

  const handleDelete = i => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = tag => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = index => {
    // console.log('The tag at index ' + index + ' was clicked');
  };

  /////
  const manufacturerList = useSelector((state) => state.dashboardReducer.manufacturer);
  const vendorList = useSelector((state) => state.dashboardReducer.vendors);
  const vendorId = useSelector((state) => state.authReducer.user.vendorId);
  const subdomain = useSelector((state) => state.authReducer.user.subdomain);
  const [tags, setTags] = React.useState([0])
  const categoryList = useSelector(
    (state) => state.dashboardReducer.categories
  );
  // console.log(categoryList);
  const initialData = {
    vendorId,
    name: productName || '',
    image: false,
    filename: false,
    model: '',
    subdomain,
    quantity: 10,
    price: 0,
    cost: 0,
    discounted: 0,
    unit: 'pcs',
    description: '',
    subtract: 1,
    languageId: 1,
    manufacturer_id: 0,
    category_arr: tags
  };
  // console.log("vendoreeeeeeeeeee",vendorId);
  const dispatch = useDispatch();
  const [stockData, setStockData] = useState(initialData);
  const [errors, setErrors] = useState({});
  // console.log("Categories List",categoryList);
  const suggestions = categoryList.map(country => {
    // console.log(country);
    return {
      id: country.category_id,
      text: country.name
    };
  });
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const onChangeHandler = (e) => {
    setStockData({
      ...stockData,
      [e.target.name]: capitalizeFirstLetter(e.target.value),
    });
  };
  const onTextChangeHandler = (e) => {
    setStockData({
      ...stockData,
      [e.target.name]: (e.target.value),
    });
  };
  const onChangeImage = (e) => {
    setStockData({
      ...stockData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (manufacturerList.length === 0) {
      dispatch(actions.get_manufacturer);
    }
  }, [manufacturerList, dispatch]);

  useEffect(() => {
    if (categoryList.length === 0) {
      dispatch(actions.get_category(vendorId));
    }
    // console.log(vendorId);
  }, [dispatch, vendorId, ]);
  useEffect(() => {
    // console.log(editStockData);
    if (editStockData) {
      setStockData(editStockData);
    } else {
      setStockData(initialData);
    }
  }, [editStockData]);

  const inputValidation = (e) => {
    let errorMsg = ValidateInput(e.target.name, e.target.value, StockAppRules);
    setErrors({
      ...errors,
      [e.target.name]: errorMsg,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(stockData);
    let errorMsgs = ValidateForm(stockData, StockAppRules);
    setErrors({ ...errorMsgs });
    let validated = Object.values(errorMsgs).join('').length === 0;
    if (validated) {
      // console.log(stockData);
      dispatch(actions.add_product(stockData));
      handleClose();
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    // console.log(stockData);
    let errorMsgs = ValidateForm(stockData, StockAppRules);
    setErrors({ ...errorMsgs });
    let validated = Object.values(errorMsgs).join('').length === 0;
    if (validated) {
      dispatch(actions.update_product(stockData));
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      fullScreen={false}
      onClose={handleClose}
      aria-labelledby='responsive-dialog-title'
    >
      <DialogTitle id='responsive-dialog-title'>
        <Title>Add Products</Title>
      </DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <FormControl fullWidth error={errors.vendorId} >
                <div style={{ display: "flex" }}>
                
                <InputLabel shrink style={{ fontSize: "22px", paddingTop: "2px" }}>Vendor</InputLabel>
                <input
                style={{ margin: "0 65px" }}
                  name="image"
                  id="image"
                  type="file"
                  accept="image/*"
                  capture="camera"
                  onChange={onChangeHandler}
                />
                </div>
                <Select
                  disabled={true}
                  onBlur={inputValidation}
                  // displayEmpty
                  name='vendorId'
                  value={stockData.vendorId}
                  onChange={onChangeHandler}
                >
                  {vendorList.map((vendor) => (
                    <MenuItem value={vendor.vendor_id}>
                      {vendor.meta_title}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.vendorId}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <Button style={{ backgroundColor: "#3F51B5", color: "#fff", borderRadius: "5px" }}>Add</Button>
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                name='name'
                value={stockData.name}
                error={errors.name}
                helperText={errors.name}
                label='Product name'
                onChange={onChangeHandler}
                onBlur={inputValidation}
                type='text'
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth error={errors.model}>
                <TextField
                  margin='dense'
                  name='model'
                  value={stockData.model}
                  error={errors.model}
                  helperText={errors.model}
                  label='Product model'
                  onChange={onChangeHandler}
                  onBlur={inputValidation}
                  type='text'
                  fullWidth
                />
                <FormHelperText>{errors.apptyp}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth error={errors.manufacturer_id}>
                {/* <TextField
                  margin='dense'
                  name='manufacturer_id'
                  value={stockData.manufacturer_id}
                  error={errors.manufacturer_id}
                  helperText={errors.manufacturer_id}
                  label='Product Head'
                  onChange={onChangeHandler}
                  onBlur={inputValidation}
                  type='text'
                  fullWidth
                /> */}
                <InputLabel shrink>Type</InputLabel>
                <Select
                  onBlur={inputValidation}
                  name='manufacturer_id'
                  value={stockData.manufacturer_id}
                  onChange={onTextChangeHandler}
                >
                  {manufacturerList.map((list) => (
                    <MenuItem value={list.vendor_id}>
                      {list.meta_title}test
                    </MenuItem>
                  ))}
                  <MenuItem value={0}>
                    Stock
                  </MenuItem>
                  <MenuItem value={451}>
                    Account
                  </MenuItem>
                  <MenuItem value={394}>
                    Liablites
                  </MenuItem>
                  <MenuItem value={393}>
                    Assets
                  </MenuItem>
                </Select>
                <FormHelperText>{errors.apptyp}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth error={errors.manufacturer_id}>
                {/* <TextField
                  margin='dense'
                  name='manufacturer_id'
                  value={stockData.manufacturer_id}
                  error={errors.manufacturer_id}
                  helperText={errors.manufacturer_id}
                  label='Product Head'
                  onChange={onChangeHandler}
                  onBlur={inputValidation}
                  type='text'
                  fullWidth
                /> */}
                <InputLabel shrink>Stock</InputLabel>
                <Select
                  onBlur={inputValidation}
                  name='subtract'
                  value={stockData.subtract}
                  onChange={onTextChangeHandler}
                >
                  <MenuItem value={1}>
                    Yes/Keep Stock Count
                  </MenuItem>
                  <MenuItem value={0}>
                    No/Don't Count
                  </MenuItem>
                </Select>
                <FormHelperText>{errors.apptyp}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                label='Description'
                value={stockData.description}
                name='description'
                error={errors.description}
                helperText={errors.description}
                onChange={onChangeHandler}
                onBlur={inputValidation}
                type='text'
                fullWidth
              // InputLabelProps={{
              //   shrink: true,
              // }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                margin='dense'
                id='name'
                name='cost'
                value={stockData.cost}
                error={errors.cost}
                helperText={errors.cost}
                onChange={onChangeHandler}
                onBlur={inputValidation}
                label='Cost'
                type='text'
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                id='name'
                name='price'
                value={stockData.price}
                error={errors.price}
                helperText={errors.price}
                onChange={onChangeHandler}
                onBlur={inputValidation}
                label='Retail Price(MRP)'
                type='text'
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                name='discounted'
                id='discounted'
                value={stockData.discounted}
                error={errors.discounted}
                helperText={errors.discounted}
                onChange={onTextChangeHandler}
                onBlur={inputValidation}
                label='Selling Price'
                type='number'
                fullWidth
              // InputLabelProps={{
              //   shrink: true,
              // }}
              />
            </Grid>
            <Grid item xs={12}>
              {/* <ReactTagInput 
      tags={tags} 
      onChange={(newTags) => setTags(newTags)}
    /> */}
              <InputLabel shrink>Category</InputLabel>
              <Select
                onBlur={inputValidation}
                name='category_arr'
                value={stockData.category_arr}
                onChange={onTextChangeHandler}
              >
                <MenuItem value={0}>
                  Chose Category
                </MenuItem>
                {categoryList.map((category) => (
                  <MenuItem value={category.category_id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
              {/* <TextField
                  margin='dense'
                  id='category_arr'
                  name='category_arr'
                  value={stockData.category_arr}
                  error={errors.category_arr}
                  helperText={errors.category_arr}
                  onChange={onChangeHandler}
                  onBlur={inputValidation}
                  label='Categories'
                  type='text'
                  fullWidth
                /> */}
            </Grid>
          </Grid>
          <Grid item xs={6}>
              <TextField
                margin='dense'
                id='name'
                name='add category'
                value={stockData.addcategory}
                error={errors.price}
                helperText={errors.price}
                onChange={onChangeHandler}
                onBlur={inputValidation}
                label='Add Category'
                type='text'
                fullWidth
              />
            </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={handleClose}>
          Cancel
        </Button>
        {editStockData ? (
          <Button variant='contained' color='primary' onClick={handleUpdate}>
            Update
          </Button>
        ) : (
          <Button variant='contained' color='primary' onClick={handleSubmit}>
            Create
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddProduct;
