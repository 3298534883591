import axios from 'axios';
import promise from 'promise';
var axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  config => {
    const accessToken = localStorage.getItem("token");
    const subdomain = localStorage.getItem("subdomain");
    console.log("🚀 ~ file: interceptor.jsx ~ line 9 ~ subdomain", accessToken)
    if (accessToken) {
      config.headers.subdomain = subdomain;
      config.headers.authorization = accessToken;
    }else{
      config.headers.authorization = 'Not Valid';
    }
    return config;
  }, 
  error => {
    if (error.response && error.response.status === 401) {
      // console.log("An error occurred while logging in to the application.");
    } else {
      return promise.reject(error);
    }
  });

export default axiosInstance;