import React, { useState, useEffect, useRef } from 'react'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Calendar from '@sbmdkl/nepali-datepicker-reactjs';
import { Button, InputLabel } from '@material-ui/core';
import NepaliDate from 'nepali-date-converter';
import Search from "@material-ui/icons/Search";
import CustomInput from 'components/CustomInput/CustomInput';
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ReactToPrint from 'react-to-print';
import { useSelector } from 'react-redux';
import api from 'api/api';
import { GetRequest } from 'plugins/axios';
import addWeeks from 'date-fns/addWeeks';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import NumberFormat from "utils/NumberFormat";

const useStyles = makeStyles(styles);

function getWeeksAfter(date, amount) {
    return date ? addWeeks(date, amount) : undefined;
}

export default function ProfitByBills({startDate,endDate}) {
    const subdomain = useSelector(state => state.authReducer.user.subdomain);
    const componentRef = useRef();
    const [ProfitData, setProfitData] = useState([]);
    const classes = useStyles();
    const [billDate, setBillDate] = useState('');
    const nepaliDate = new NepaliDate();
    let date = '2078/04/04';
    const [loading, setLoading] = useState(true);
    const [today, setToday] = useState();
    const [thisweek, setThisweek] = useState();
    const [thismonth, setThismonth] = useState();
    const [thisyear, setThisyear] = useState();
    const options = [
        {
            label: 'Today',
            value: 'today'
        },
        {
            label: 'This Week',
            value: 'thisweek'
        },
        {
            label: 'This Month',
            value: 'thismonth'
        },
        {
            label: 'This Year',
            value: 'thisyear'
        }
    ];

    const dataTypes = [
        'All Forms', 'Sales', 'Purchase'
    ];
    const allTransactions = [
        'All Transactions', 'Sale', 'Purchase', 'Payment-in', 'Payment-out'
    ];
    const defaultSelector = dataTypes[0];
    const defaultOption = options[0];
    const defaultvalue = allTransactions[0];

    useEffect(() => {
        date = `${nepaliDate.getYear()}/${nepaliDate.getMonth() + 1 > 9
            ? nepaliDate.getMonth() + 1
            : `0${nepaliDate.getMonth() + 1}`
            }/${nepaliDate.getDate() > 9
                ? nepaliDate.getDate()
                : `0${nepaliDate.getDate()}`
            }`;
        setBillDate(date + "");
    }, []);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [customers, setCustomers] = useState([]);
    const data = useSelector((state) => state.DashboardReducer)


    useEffect(() => {
        setCustomers(data);
        getProfitLossByBills();
    }, [data,startDate,endDate]);
    const getProfitLossByBills = () => {
        //pass endDate and startDate from nepaliDate selector
        GetRequest(`${api.profitLossByDateAPI}`, { subdomain, startDate, endDate, })
            .then((res) => {
                setProfitData(res.data.content);
                console.log('profit loss data:::::::::', res.data.content);
            })
            .catch((err) => {
                alert({
                    text: 'Cannot get ProfitLoss',
                });
            });
    };

    const [value, setValue] = React.useState([null, null]);

    return (
        <div>
            {/* <div className='sales-topbar'>
                <Dropdown options={options}
                    value={defaultOption}
                    placeholder="Select an option" /> */}

                {/* <FormControl>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={value}
                        label="Date"
                        onChange={handleChange}
                    >
                        <MenuItem value={today}>Today</MenuItem>
                        <MenuItem value={thisweek}>This Week</MenuItem>
                        <MenuItem value={thismonth}>This Month</MenuItem>
                        <MenuItem value={thisyear}>This Year</MenuItem>
                    </Select>
                </FormControl> */}


                {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateRangePicker
                        disablePast
                        value={value}
                        maxDate={getWeeksAfter(value[0], 4)}
                        onChange={(newValue) => {
                            setValue(newValue);
                        }}
                        renderInput={(startProps, endProps) => (
                            <React.Fragment>
                                <TextField {...startProps} />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <TextField {...endProps} />
                            </React.Fragment>
                        )}
                    />
                </LocalizationProvider> */}
                {/* <span className='date-selector'>
                    <Calendar style={{ width: "271px", borderColor: '#0000', backgroundColor: "#0000", borderBottom: "1px solid #929292", fontWeight: 700 }} language="en" theme="default" dateFormat="YYYY/MM/DD"
                        onChange={(date) => { setBillDate(date.bsDate + "") }} />
                    <Button>
                        To
                    </Button>
                    <Calendar style={{ width: "271px", borderColor: '#0000', backgroundColor: "#0000", borderBottom: "1px solid #929292", fontWeight: 700 }} language="en" theme="default" dateFormat="YYYY/MM/DD"
                        onChange={(date) => { setBillDate(date.bsDate + "") }} />
                </span>
                <Dropdown options={dataTypes}
                    value={defaultSelector}
                    placeholder="Select an option" />
                <ReactToPrint
                    trigger={() => <Button color='primary'>
                        Print
                    </Button>}
                    content={() => componentRef.current}
                />
            </div>
            <div className='search-bar'>
                <Dropdown options={allTransactions}
                    value={defaultvalue}
                    placeholder="Select an option" />
            </div> */}
            {/* Profit data per bill */}
            {loading ? (
                <Table className='profit-table'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Party Name & Date</TableCell>
                            <TableCell>Invoice No.</TableCell>
                            <TableCell align='right'>Total Sale Amount</TableCell>
                            <TableCell align='right'>Profit(+)/Loss(-)</TableCell>
                        </TableRow>
                    </TableHead>
                    {ProfitData.map((item, index) => {
                        return (
                            <>
                                <TableBody className='profit-table-header'>
                                    <TableRow>
                                        <TableCell className='profit-table-header-left' style={{ display: "flex", flexDirection: "column" }}>
                                            <span style={{ fontSize: "16px" }}>{item.fullname}</span>
                                            <span>{item.bill_date}</span>
                                        </TableCell>
                                        <TableCell className='profit-table-header-right'>
                                            <span>#{item.connect_id}</span>
                                        </TableCell>
                                        <TableCell className='profit-table-body-left'>
                                                
                                                <NumberFormat amount={item.totalamount} />

                                        </TableCell>
                                        <TableCell>
                                            <span style={{ color: item.profitloss >= 0 ? '#00a53e' : "#f15e63" }}>
                                            <NumberFormat amount={item.profitloss} />
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>

                            </>
                        )
                    })
                    }
                </Table>) : (
                <Skeleton count={30} />
            )}
        </div>
    )
}

