import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../../Components/Title';
import * as actions from '../../../store/DashboardActions';
import { useDispatch, useSelector } from 'react-redux';
import {  Paper } from '@material-ui/core';
import ShowLoadingOrEmpty from '../../Components/ShowLoadingOrEmpty';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

export default function Order() {
  const classes = useStyles();
  const orderList = useSelector((state) => state.dashboardReducer.orders);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.get_orders());
  }, [dispatch]);

  return (
    <Paper className={classes.paper}>
      <Title>Orders</Title>
      {orderList.length > 0 ? (
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Telephone</TableCell>
              <TableCell>Payment Code</TableCell>
              <TableCell>Payment Addres 1</TableCell>
              <TableCell>Payment Addres 2</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Shipping Addres 1</TableCell>
              <TableCell>Shipping Addres 1</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderList.map((row) => (
              <TableRow key={row.order_id}>
                <TableCell>{row.order_id}</TableCell>
                <TableCell>
                  {row.firstname} {row.lastname}
                </TableCell>
                <TableCell>{row.order_status_name}</TableCell>
                <TableCell>{row.telephone}</TableCell>
                <TableCell>{row.payment_code}</TableCell>
                <TableCell>{row.payment_address_1}</TableCell>
                <TableCell>{row.payment_address_2}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.shipping_address_1}</TableCell>
                <TableCell>{row.shipping_address_2}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <ShowLoadingOrEmpty message='No Orders Found' />
      )}

      <div className={classes.seeMore}>
        <Link color='primary' href='#'>
          {orderList.length > 0
            ? 'Total Orders: ' + orderList.length
            : 'No Orders'}
        </Link>
      </div>
    </Paper>
  );
}
