import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { setToasterState } from '../common/Toast/services/ToasterAction';

const PrivateRoute = (props) => {
  const { component: Component, ...rest } = props;
  const isLoggedIn = !!localStorage.getItem('token');
  const dispatch = useDispatch();
  useEffect(() => {
    // console.log(isLoggedIn);

    const showToaster = () => {
      dispatch(
        setToasterState({
          open: true,
          title: 'error',
          name: 'Authentication Error!',
          message: 'Login First into the app!',
        })
      );
    };
    !isLoggedIn && showToaster();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? <Component {...props} /> : <Redirect to={'/'} />
      }
    />
  );
};

export default PrivateRoute;
