import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Title from '../../Components/Title';
import * as actions from '../../../store/DashboardActions';
import EditIcon from '@material-ui/icons/Edit';
import FeedIcon from '@mui/icons-material/Feed';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ShareIcon from '@mui/icons-material/Share';
import NepaliDate from "nepali-date-converter";
import { downloadCSVFromJson } from 'utils/DownloadCSV';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Button,
  FormControl,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import ShowLoadingOrEmpty from '../../Components/ShowLoadingOrEmpty';
import axios from 'axios';
import AddCustomer from './Components/AddAppointment';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { AgGridColumn } from 'ag-grid-react/lib/agGridColumn';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import AddAppointment from './Components/AddAppointment';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import { Add } from '@material-ui/icons';
const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  headerButton: {
    margin: 10,
    color:"#fff",
  },
}));
export default function Appointments() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [axiosToken, setAxiosToken] = useState('');
  const [search, setSearch] = useState('');
  const [addOpen, setAddOpen] = useState(false);
  const [currentApp, setCurrentApp] = useState('');
  const history = useHistory();

  const [startDate, setStartDate] = useState("2078/01/01");
  const [endDate, setEndDate] = useState("2079/12/32");

  const [customer, setCustomer] = useState([]);
  const [savedCustomer, setSavedCustomer] = useState([]);
  const [yesterdate, setYesterdate] = useState("");
  const [gridApi, setGridApi] = useState(null);

  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [nepaliDate, setNepaliDate] = useState(new NepaliDate());
  const [dateString, setDateString] = useState("");
  const [today, setToday] = useState("2078/12/09");
  const [thisweek, setThisweek] = useState("2078/12/09");
  const [thismonth, setThismonth] = useState("2078/12/09");
  const [thisyear, setThisyear] = useState("2078/12/09");
  const [dateswitch, setDateswitch] = useState(today);
  const customersList = useSelector(
    (state) => state.dashboardReducer.appointments
  );
  // console.log("subdomain",subdomain);
  const DurationSelectorHandler = (dates) => {
    setDateswitch(dates);
    let date = dates.split("||");
    console.log("///datesfrom", date[0]);
    console.log("///datesto", date[1]);
    setStartDate(date[0]);
    setEndDate(date[1]);
  };

  useEffect(()=>{
    let todaynp=`${nepaliDate.getYear()}/${
      nepaliDate.getMonth() + 1 > 9
      ? nepaliDate.getMonth() + 1
      : `0${nepaliDate.getMonth() + 1}`
    }/${
      nepaliDate.getDate() > 9
      ? nepaliDate.getDate()
      : `0${nepaliDate.getDate()}`
    }`;
    setStartDate(todaynp);
    setEndDate(todaynp);
  },[nepaliDate])
  
  useEffect(() => {
    setCustomer(customersList);
    // console.log("Customer Pageeeeeee",customersList);
    setSavedCustomer(customersList);
  }, [customersList])
  useEffect(() => {
    let newlist = savedCustomer.filter((cas) =>
      cas.fname?.toLowerCase().includes(search) || cas.contact?.toLowerCase().includes(search)
      // // console.log("creditors",cas)
    );
    // console.log(savedCustomer);
    // console.log("search...........",search);
    setCustomer(newlist);
  }, [search]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  const openAddDialog = (app) => {
    setCurrentApp(app);
    // console.log("app data",app);
    setAddOpen(true);
  };
  const closeAddDialog = () => {
    setCurrentApp(false);
    setAddOpen(false);
  };
  useEffect(() => {
    // const get_custumers = () => {
    //   // console.log(axiosToken);
    //   if (axiosToken) {
    //     // Cancel the previous request before making a new request
    //     axiosToken.cancel('operation canceled due to new request.');
    //   }
    //   let token = axios.CancelToken.source();
    //   setAxiosToken(token);

    // };

    // get_custumers();
    // return () => {
    //   // console.log('clear');
    //   setAxiosToken('');
    // };

    dispatch(actions.get_appointments({search,startDate,endDate}));
  }, [search,startDate,endDate]);

  const onCellValueChanged = (event) => {
    // console.log('Data after change is', event.data);
  };
  const onBtnExport = () => {
    gridApi.exportDataAsCsv();
  };
  const changeSearchText = (e) => {
    setSearch(e.target.value);
  };
  const OptionsRenderer = (props) => {
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    // console.log("props for rm",props);
    return (
      <span>
        <span>{cellValue}</span>&nbsp;
        {/* <Button variant='contained'
        color='primary' onClick={() => openAddDialog(props.data)}>Edit</Button>
        <Button variant='contained'
        color='primary' onClick={() => history.push({pathname:'/inventory/BillEdit',cas_id:props.data.cas_id})}>
        Bill
        </Button>
        <Button variant='contained'
        color='primary' onClick={() => history.push({pathname:'/main/customer/',cas_id:props.data.cas_id})}>
        View
        </Button> */}
           <Tooltip title="Edit here">
        <Button className={classes.button} startIcon={<EditIcon />} onClick={() => openAddDialog(props.data) || <Skeleton />} />
        </Tooltip>
        <Tooltip title="View here">
        <Button className={classes.button} startIcon={<FeedIcon />} onClick={() => history.push({ pathname: '/inventory/BillEdit', cas_id: props.data.cas_id }) || <Skeleton />} />
        </Tooltip>
        <Tooltip title="View Profile">
        <Button className={classes.button} startIcon={<VisibilityIcon />} onClick={() => history.push({ pathname: '/main/customer/', cas_id: props.data.cas_id }) || <Skeleton />} />
        </Tooltip>
      </span>
    );
  };



  return (
    <Card>
        <CardHeader  style={{display:'flex',justifyContent:'space-between',flexDirection:'column'}} color="primary">



          <h4 style={{ color: "#fff", fontWeight: "400" }}>Appointments</h4>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="justify-between">
              <Button onClick={()=>openAddDialog(false)} className={classes.headerButton}>
              <Add color="#fff"/>
              </Button>
              
              <Button
                className={classes.headerButton} 
                onClick={()=>onBtnExport()}
              >
                <DownloadForOfflineIcon color="#fff"/>
              </Button>
            </div>
            <span className="date-selector" style={{ margin: "10px" }}>

<FormControl fill="#ffff" variant="filled" >
  {/* <InputLabel id="bill_date-label" style={{ paddingTop: "10px" }}>
    Bill Date
  </InputLabel> */}
  <Select
    labelId="bill_date-label"
    id="bill_date"
    placeholder='Bill Time'
    defaultValue="Today"
    style={{ height: "40px",fill:'#fff' }}
    fill="#fff"
    selectedValue={dateswitch}
    onValueChange={(itemValue,) => DurationSelectorHandler(itemValue)}
  >
    <MenuItem selected label="Today" value={today}>
      Today
    </MenuItem>
    <MenuItem label="This Week" value={thisweek}>
      This Week
    </MenuItem>
    <MenuItem label="This Month" value={thismonth}>
      This Month
    </MenuItem>
    <MenuItem label="This Year" value={thisyear}>
      This Year
    </MenuItem>
  </Select>
</FormControl>
  <ArrowBackIosIcon
    style={{ height: "15px", marginTop: "10px" }}
  />
  <Calendar
    style={{
      width: "271px",
      borderColor: "#0000",
      backgroundColor: "#0000",
      borderBottom: "1px solid #929292",
      fontWeight: 700,
    }}
    language="en"
    theme="default"
    dateFormat="YYYY/MM/DD"
    onChange={(date) => {
      setStartDate(date.bsDate);
    }}
    //defaultDate={startDate}
  />
  <Button style={{color:'#fff'}}>To</Button>
  <Calendar
    style={{
      width: "271px",
      borderColor: "#0000",
      backgroundColor: "#0000",
      borderBottom: "1px solid #929292",
      fontWeight: 700,
    }}
    language="en"
    theme="default"
    dateFormat="YYYY/MM/DD"
    onChange={(date) => {
      setEndDate(date.bsDate);
    }}
    minDate={startDate}
    //defaultDate={endDate}
  />

  {/* <DateRangePicker
    ranges={[selectionRange]}
    onChange={this.handleSelect}
  /> */}
  <ArrowForwardIosIcon
    style={{ height: "15px", marginTop: "10px" }}
    
  />
</span>
          </div>
</CardHeader>
      {/* <Paper id="divToPrint" className={classes.paper}>*/}
        <div style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'center',
          alignItems: 'center',
        }}> 
          {/*<Title onClick={() => onBtnExport()}>Appointments</Title>
          <TextField
            id='outlined-basic'
            label='Appointment Search'
            value={search}
            style={{ margin: 10 }}
            variant='outlined'
            fullWidth
            onChange={changeSearchText}
          /> */}
          
          {/*<Button
            variant='contained'
            color='primary'
            style={{ marginLeft: '5px', padding: '14px 27px' }}
            startIcon={<AddCircleIcon />}
            onClick={() => onBtnExport()}
          >
            Export
        </Button>*/}
        </div>
        {/* <div  style={{
          display:'flex',
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <TextField
        id='outlined-basic'
        label='CAS ID'
        value={search}
        style={{ margin: 10 }}
        variant='outlined'
        onChange={changeSearchText}
        />
        <TextField
        id='outlined-basic'
        label='Contact'
        value={search}
        style={{ margin: 10 }}
        variant='outlined'
        onChange={changeSearchText}
        />
        <TextField
        id='outlined-basic'
        label='Name'
        value={search}
        style={{ margin: 10 }}
        variant='outlined'
        onChange={changeSearchText}
        />
        <TextField
        id='outlined-basic'
        label='Nick Name'
        value={search}
        style={{ margin: 10 }}
        variant='outlined'
        onChange={changeSearchText}
        />
        <TextField
        id='outlined-basic'
        label='age'
        value={search}
        style={{ margin: 10 }}
        variant='outlined'
        onChange={changeSearchText}
        />
        <TextField
        id='outlined-basic'
        label='Save'
        value={search}
        style={{ margin: 10 }}
        variant='outlined'
        onChange={changeSearchText}
        />
      </div> */}
       {/* </Paper> */}
      {customer.length > 0 ? (
        <div className="ag-theme-material" style={{
          height: '100vh',
          width: '100%'
        }}>
          <AgGridReact
            suppressExcelExport={true}
            onGridReady={onGridReady}
            rowData={customer}
            defaultColDef={{
              flex: 1,
              minWidth: 200,
              editable: false,
              resizable: true,
              sortable: true,
              floatingFilter: true,
            }}
            rowDragManaged={true}
            animateRows={true}
            frameworkComponents={{
              OptionsRenderer: OptionsRenderer,

            }}
            getRowStyle={(params)=>{
              if (params.node.rowIndex % 2 === 0) {
                return { background: '#f6f8fb' };
            }
            }}
            rowClassRules={{
              'sick-days-warning': function (params) {
                var numSickDays = params.data.sickDays;
                return numSickDays > 5 && numSickDays <= 7;
              },
              'sick-days-breach': 'data.sickDays >= 8',
            }}
          >
            <AgGridColumn width={150} headerName="First Name" suppressMenu={true} filter="agTextColumnFilter" resizable={true} field="patient" sortable= {true}></AgGridColumn>
            <AgGridColumn width={150} headerName="Date" suppressMenu={true} filter="agTextColumnFilter" resizable={true} field="time" sortable= {true} ></AgGridColumn>
            <AgGridColumn filter="agTextColumnFilter" suppressMenu={true} width={150} headerName="Department" resizable={true} field="department" sortable= {true} ></AgGridColumn>
            <AgGridColumn width={150} headerName="Notes" suppressMenu={true} filter="agTextColumnFilter" resizable={true} field="notes" sortable= {true} ></AgGridColumn>
            <AgGridColumn width={150} headerName="Contact" suppressMenu={true} filter="agTextColumnFilter" resizable={true} field="contact" sortable= {true} ></AgGridColumn>
            <AgGridColumn cellRenderer="OptionsRenderer" headerName="Action" ></AgGridColumn>
          </AgGridReact>
        </div>
      ) : (
        <Skeleton count={50} />
        // <ShowLoadingOrEmpty message='No Customers Found' />
      )}
      <br />
      <Paper className={classes.paper}>
        {/* {customersList.length > 0 ? (
            <Table size='small' onClick={openAddDialog}>
            <TableHead>
            <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Age</TableCell>
            <TableCell>Gender</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Contact</TableCell>
            <TableCell>Email</TableCell>
            </TableRow>
            </TableHead>
          <TableBody> */}
        {/* {customersList.map((row) => (
            <TableRow key={row.casid}>
            <TableCell>{row.cas_id}</TableCell>
            <TableCell>
            {row.fname} {row.lname}
            </TableCell>
            <TableCell>{row.age}</TableCell>
            <TableCell>{row.gender}</TableCell>
            <TableCell>{row.address}</TableCell>
            <TableCell>{row.contact}</TableCell>
            <TableCell>{row.email}</TableCell>
            </TableRow>
          ))} */}
        {/* </TableBody>
            </Table>
            ) : (
              <ShowLoadingOrEmpty message='No Customers Found' />
            )} */}

        <div className={classes.seeMore}>
          <Link color='primary'>
            {customersList.length > 0
              ? 'Total Appointments: ' + customersList.length
              : 'No Appointments'}
          </Link>
        </div>
        <AddAppointment
          open={addOpen}
          handleClose={closeAddDialog}
          editappointmentData={currentApp}Total
        />
       </Paper>

    </Card>
    
  )
  
  
}
