import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import Title from '../../../Components/Title';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../store/DashboardActions';
import { ValidateForm, ValidateInput } from '../../../../../utils/validateForm';
import BotMessageRules from './BotMessageRules';

const initialData = {
  keyword: '',
  type: '',
  message: '',
};
const exampleData = {
  keyboard: {
    Type: 'keyboard',
    Revision: 1,
    Buttons: [
      {
        Columns: 3,
        Rows: 2,
        BgColor: '#e6f5ff',
        BgMedia:
          'http://www.jqueryscript.net/images/Simplest-Responsive-jQuery-Image-Lightbox-Plugin-simple-lightbox.jpg',
        BgMediaType: 'picture',
        BgLoop: true,
        ActionType: 'reply',
        ActionBody: 'Yes',
      },
    ],
  },
  stricker:
    'Insert Stricker Id, find id in https://developers.viber.com/docs/tools/sticker-ids/',
  text: 'Insert text you want to reply, if wildcard enter {{wildcardname}}',
};

export default function AddBotMessage({ open, handleClose }) {
  const [botMessage, setBotMessage] = useState(initialData);
  const [errors, setErrors] = useState(initialData);
  const dispatch = useDispatch();
  const onChangeHandler = (e) => {
    setBotMessage({
      ...botMessage,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(botMessage);
    let errorMsgs = ValidateForm(botMessage, BotMessageRules);
    setErrors({ ...errorMsgs });
    let validated = Object.values(errorMsgs).join('').length === 0;
    if (validated) {
      dispatch(actions.add_bot_message(botMessage));
      handleClose();
    }
  };

  const inputValidation = (e) => {
    let errorMsg = ValidateInput(
      e.target.name,
      e.target.value,
      BotMessageRules
    );
    setErrors({
      ...errors,
      [e.target.name]: errorMsg,
    });
  };

  return (
    <Dialog
      open={open}
      fullScreen={false}
      onClose={handleClose}
      aria-labelledby='responsive-dialog-title'
    >
      <DialogTitle id='responsive-dialog-title'>
        <Title>Add Bot Message</Title>
      </DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                margin='dense'
                id='name'
                name='keyword'
                label='Keyword'
                variant='outlined'
                error={errors.keyword}
                helperText={errors.keyword}
                onChange={onChangeHandler}
                onBlur={inputValidation}
                type='text'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                onBlur={inputValidation}
                fullWidth
                variant='outlined'
                error={errors.type}
              >
                <InputLabel shrink>Type</InputLabel>
                <Select name='type' onChange={onChangeHandler}>
                  <MenuItem value='text'>Text</MenuItem>
                  <MenuItem value='stricker'>Stricker</MenuItem>
                  <MenuItem value='keyboard'>Keyboard</MenuItem>
                </Select>
                <FormHelperText>{errors.type}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin='dense'
                multiline
                rowsMax={3}
                rows={3}
                variant='outlined'
                name='message'
                error={errors.message}
                helperText={errors.message}
                onChange={onChangeHandler}
                onBlur={inputValidation}
                label='Message'
                type='text'
                fullWidth
              />
            </Grid>
            <div>Data Example:</div>
            <div style={{ padding: 10, background: '#e6f5ff' }}>
              {botMessage.type && JSON.stringify(exampleData[botMessage.type])}
            </div>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button autoFocus color='primary' onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          autoFocus
          onClick={handleSubmit}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
