import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

export default function ShowLoadingOrEmpty({ message }) {
  const loading = useSelector((state) => state.dashboardReducer.loading);
  return (
    <div className='flex justify-center'>
      {loading ? <CircularProgress /> : message}
    </div>
  );
}
