import { Button,Table, TableCell, TableHead, TableRow } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import api from 'api/api'
import AddProduct from "modules/inventory/Product/Components/AddProduct";
import { GetRequest } from "plugins/axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import NumberFormat from "utils/NumberFormat";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function TableList() {
  const [savings, setSavings] = useState([]);
  const subdomain = useSelector((state) => state.authReducer.user.subdomain);
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [addOpen, setAddOpen] = useState(false);
  const [currentApp, setCurrentApp] = useState('');

  useEffect(() => {
    if (subdomain) {
      getBalance(subdomain);
    } else {
      // console.log('subdomain not found');
    }
  }, [subdomain]);

  const getBalance = (subdomain) => {
    setLoading(true);
    GetRequest(`${api.savingBookAPI}`, { subdomain })
      .then((res) => {
        setLoading(false);

        setSavings(res.data.content);
      })
      .catch((err) => {
        // // console.log(({ text: 'Error Geting balance' });
      });
  };
  const openAddDialog = (app) => {
    setCurrentApp(app);
    setAddOpen(true);
  };
  const closeAddDialog = () => {
    setCurrentApp('');
    setAddOpen(false);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Accounts</h4>
              <p className={classes.cardCategoryWhite}>
                Accounts: Banks, cooperatives, Share Holders list's here.
              </p>
              <Button
                variant='contained'
                color='primary'
                startIcon={<AddCircleIcon />}
                onClick={() => openAddDialog()}
              >Add Account</Button>
            </CardHeader>
            <CardBody style={{ display: 'flex', flexDirection: 'row', }}>
              {/* <Table
              tableHeaderColor="primary"
              tableHead={["Name", "Country", "City", "Salary"]}
              tableData={[
                ["Dakota Rice", "Niger", "Oud-Turnhout", "$36,738"],
                ["Minerva Hooper", "Curaçao", "Sinaai-Waas", "$23,789"],
                ["Sage Rodriguez", "Netherlands", "Baileux", "$56,142"],
                ["Philip Chaney", "Korea, South", "Overland Park", "$38,735"],
                ["Doris Greene", "Malawi", "Feldkirchen in Kärnten", "$63,542"],
                ["Mason Porter", "Chile", "Gloucester", "$78,615"],
              ]}
            /> */}
              {/* <Table
              tableHeaderColor="primary"
              tableHead={["Name", "Country", "City", "Salary"]}
              tableData={[
                ["Dakota Rice", "Niger", "Oud-Turnhout", "$36,738"],
                ["Minerva Hooper", "Curaçao", "Sinaai-Waas", "$23,789"],
                ["Sage Rodriguez", "Netherlands", "Baileux", "$56,142"],
                ["Philip Chaney", "Korea, South", "Overland Park", "$38,735"],
                ["Doris Greene", "Malawi", "Feldkirchen in Kärnten", "$63,542"],
                ["Mason Porter", "Chile", "Gloucester", "$78,615"],
              ]}
            /> */}
            </CardBody>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Account Name</TableCell>
                  <TableCell >Total Deposit</TableCell>
                  <TableCell >Total Withdraw</TableCell>
                  <TableCell >Account Balance</TableCell>
                </TableRow>
              </TableHead>
              {savings.length > 0 ? (
                savings.map((save, index) => (
                  <TableRow
                    key={save.product_id}
                    style={{
                      borderRadius: 5,
                    }}
                  >
                    <TableCell style={{ padding: 10 }}>{index + 1}</TableCell>
                    <TableCell style={{ padding: 10 }}>{save.name}</TableCell>
                    <TableCell style={{ padding: 10 }}><NumberFormat amount={save.Deposit} /></TableCell>
                    <TableCell style={{ padding: 10 }}><NumberFormat amount={save.Widthdraw} /></TableCell>
                    <TableCell style={{ padding: 10 }}><NumberFormat amount={save.amount} /></TableCell>
                  </TableRow>
                ))
              ) : loading ? (
                <Skeleton count={50} />
                // <div height={60}> Loading</div>
              ) : (
                <span>No Savings found</span>
              )}
            </Table>
          </Card>
        </GridItem>
        {/* <GridItem xs={12} sm={12} md={12}>
        <Card plain>
          <CardHeader plain color="primary">
            <h4 className={classes.cardTitleWhite}>
             Credit Transactions
            </h4>
            <p className={classes.cardCategoryWhite}>
              Here is a subtitle for this table
            </p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={["ID", "Name", "Country", "City", "Salary"]}
              tableData={[
                ["1", "Dakota Rice", "$36,738", "Niger", "Oud-Turnhout"],
                ["2", "Minerva Hooper", "$23,789", "Curaçao", "Sinaai-Waas"],
                ["3", "Sage Rodriguez", "$56,142", "Netherlands", "Baileux"],
                [
                  "4",
                  "Philip Chaney",
                  "$38,735",
                  "Korea, South",
                  "Overland Park",
                ],
                [
                  "5",
                  "Doris Greene",
                  "$63,542",
                  "Malawi",
                  "Feldkirchen in Kärnten",
                ],
                ["6", "Mason Porter", "$78,615", "Chile", "Gloucester"],
              ]}
            />
          </CardBody>
        </Card>
      </GridItem> */}
      </GridContainer>
      <AddProduct
        open={addOpen}
        handleClose={closeAddDialog}
        editStockData={currentApp}
      />
    </>
  );
}
