import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Title from '../../Components/Title';
import * as actions from '../../../store/DashboardActions';
import EditIcon from '@material-ui/icons/Edit';
import FeedIcon from '@mui/icons-material/Feed';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import { Add, Search } from '@material-ui/icons';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import {
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  FormControl,
  MenuItem,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import ShowLoadingOrEmpty from '../../Components/ShowLoadingOrEmpty';
import axios from 'axios';
import AddCustomer from './Components/AddCustomer';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { AgGridColumn } from 'ag-grid-react/lib/agGridColumn';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  headerButton: {
    margin: 10,
    color:"#fff",
  },
}));
export default function Customer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [axiosToken, setAxiosToken] = useState('');
  const [search, setSearch] = useState('');
  const [addOpen, setAddOpen] = useState(false);
  const [searchable, setSearchable] = useState(false);
  const [currentApp, setCurrentApp] = useState('');
  const history = useHistory();
  const [today, setToday] = useState("2078/12/09");
  const [dateswitch, setDateswitch] = useState(today);
  const [thisweek, setThisweek] = useState("2078/12/09");
  const [thismonth, setThismonth] = useState("2078/12/09");
  const [thisyear, setThisyear] = useState("2078/12/09");
  const [customer, setCustomer] = useState([]);
  const [savedCustomer, setSavedCustomer] = useState([]);
  const [startDate, setStartDate] = useState("2078/01/01");
  const [endDate, setEndDate] = useState("2078/12/32");

  const [gridApi, setGridApi] = useState(null);

  const [gridColumnApi, setGridColumnApi] = useState(null);

  const customersList = useSelector(
    (state) => state.dashboardReducer.customers
  );
  // console.log("subdomain",subdomain);
  useEffect(() => {
    setCustomer(customersList);
    // console.log("Customer Pageeeeeee",customersList);
    setSavedCustomer(customersList);
  }, [customersList])
  useEffect(() => {
    let newlist = savedCustomer.filter((cas) =>
      cas.fname?.toLowerCase().includes(search) || cas.contact?.toLowerCase().includes(search)
      // // console.log("creditors",cas)
    );
    // console.log(savedCustomer);
    // console.log("search...........",search);
    setCustomer(newlist);
  }, [search]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };
  const DurationSelectorHandler = (dates) => {
    setDateswitch(dates);
    let date = dates.split("||");
    console.log("///datesfrom", date[0]);
    console.log("///datesto", date[1]);
    setStartDate(date[0]);
    setEndDate(date[1]);
  };
  const gridRef = useRef();
  const sizeToFit = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);
  const openAddDialog = (app) => {
    setCurrentApp(app);
    // console.log("app data",app);
    setAddOpen(true);
  };
  const closeAddDialog = () => {
    setCurrentApp('');
    setAddOpen(false);
  };
  useEffect(() => {
    // const get_custumers = () => {
    //   // console.log(axiosToken);
    //   if (axiosToken) {
    //     // Cancel the previous request before making a new request
    //     axiosToken.cancel('operation canceled due to new request.');
    //   }
    //   let token = axios.CancelToken.source();
    //   setAxiosToken(token);

    // };

    // get_custumers();
    // return () => {
    //   // console.log('clear');
    //   setAxiosToken('');
    // };
if(search){
    dispatch(actions.get_customers(search));}
  }, [search]);

  const onCellValueChanged = (event) => {
    // console.log('Data after change is', event.data);
  };
  const onBtnExport = () => {
    gridApi.exportDataAsCsv();
  };
  const changeSearchText = (e) => {
    setSearch(e.target.value);
  };
  const TotalValueRenderer = (props) => {
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    // console.log("props for rm",props);
    return (
      <span>
        <span>{cellValue}</span>&nbsp;
        {/* <Button variant='contained'
        color='primary' onClick={() => openAddDialog(props.data)}>Edit</Button>
        <Button variant='contained'
        color='primary' onClick={() => history.push({pathname:'/inventory/BillEdit',cas_id:props.data.cas_id})}>
        Bill
        </Button>
        <Button variant='contained'
        color='primary' onClick={() => history.push({pathname:'/main/customer/',cas_id:props.data.cas_id})}>
        View
        </Button> */}
         <Tooltip title="Edit here">
        <Button className={classes.button} startIcon={<EditIcon />} onClick={() => openAddDialog(props.data) || <Skeleton />} />
        </Tooltip>
        <Tooltip title="View here">
        <Button className={classes.button} startIcon={<FeedIcon />} onClick={() => history.push({ pathname: '/inventory/BillEdit', cas_id: props.data.cas_id }) || <Skeleton />} />
        </Tooltip>
        <Tooltip title="View details here">
        <Button className={classes.button} startIcon={<VisibilityIcon />} onClick={() => history.push({ pathname: `/main/customer/${props.data.cas_id}`, cas_id: props.data.cas_id }) || <Skeleton />} />
      </Tooltip>
      </span>
    );
  };



  return (
     <Card>
        <CardHeader  style={{display:'flex',justifyContent:'space-between',flexDirection:'column'}} color="primary">
          <h4 style={{ color: "#fff", fontWeight: "400" }}>Customer List</h4>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="justify-between">
              <Button onClick={()=>openAddDialog(false)} className={classes.headerButton}>
              <Add color="#fff"/>
              </Button>
              <Button onClick={()=>sizeToFit()} className={classes.headerButton}>
              <Add color="#fff"/>
              </Button>
              <Button onClick={()=>setSearchable(!searchable)} className={classes.headerButton}>
              <Search color="#fff"/>
              </Button>
              <Button
                className={classes.headerButton}
                onClick={()=>onBtnExport()}
              >
                <DownloadForOfflineIcon color="#fff"/>
              </Button>
            </div>
            <span className="date-selector" style={{ margin: "10px" }}>

<FormControl fill="#ffff" variant="filled" >
  {/* <InputLabel id="bill_date-label" style={{ paddingTop: "10px" }}>
    Bill Date
  </InputLabel> */}
  <Select
    labelId="bill_date-label"
    id="bill_date"
    placeholder='Bill Time'
    defaultValue="Today"
    style={{ height: "40px",fill:'#fff' }}
    fill="#fff"
    selectedValue={dateswitch}
    onValueChange={(itemValue,) => DurationSelectorHandler(itemValue)}
  >
    <MenuItem selected label="Today" value={today}>
      Customer
    </MenuItem>
    <MenuItem label="This Week" value={thisweek}>
      Staff
    </MenuItem>
    <MenuItem label="This Month" value={thismonth}>
      Supplier
    </MenuItem>
  </Select>
</FormControl>
  {/* <ArrowBackIosIcon
    style={{ height: "15px", marginTop: "10px" }}
  />
  <Calendar
    style={{
      width: "271px",
      borderColor: "#0000",
      backgroundColor: "#0000",
      borderBottom: "1px solid #929292",
      fontWeight: 700,
    }}
    language="en"
    theme="default"
    dateFormat="YYYY/MM/DD"
    onChange={(date) => {
      setStartDate(date.bsDate);
    }}
    //defaultDate={startDate}
  />
  <Button style={{color:'#fff'}}>To</Button>
  <Calendar
    style={{
      width: "271px",
      borderColor: "#0000",
      backgroundColor: "#0000",
      borderBottom: "1px solid #929292",
      fontWeight: 700,
    }}
    language="en"
    theme="default"
    dateFormat="YYYY/MM/DD"
    onChange={(date) => {
      setEndDate(date.bsDate);
    }}
    minDate={startDate}
    //defaultDate={endDate}
  />

  <ArrowForwardIosIcon
    style={{ height: "15px", marginTop: "10px" }}
    
  /> */}
</span>
          </div>
</CardHeader>
      {/* <Paper id="divToPrint" className={classes.paper}> */}
        {/* <div style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'center',
          alignItems: 'center',
        }}>
          <Title onClick={() => onBtnExport()}>Customers</Title>
          <TextField
            id='outlined-basic'
            label='Customer Search'
            value={search}
            style={{ margin: 10 }}
            variant='outlined'
            fullWidth
            onChange={changeSearchText}
          />
          <Button
            variant='contained'
            color='primary'
            style={{ padding: '1px 13px' }}
            startIcon={<AddCircleIcon />}
            onClick={() => openAddDialog()}
          >
            Add Customer
          </Button>
          <Button
            variant='contained'
            color='primary'
            style={{ marginLeft: '5px', padding: '14px 27px' }}
            startIcon={<AddCircleIcon />}
            onClick={() => onBtnExport()}
          >
            Export
          </Button>
        </div> */}
        {/* <div  style={{
          display:'flex',
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <TextField
        id='outlined-basic'
        label='CAS ID'
        value={search}
        style={{ margin: 10 }}
        variant='outlined'
        onChange={changeSearchText}
        />
        <TextField
        id='outlined-basic'
        label='Contact'
        value={search}
        style={{ margin: 10 }}
        variant='outlined'
        onChange={changeSearchText}
        />
        <TextField
        id='outlined-basic'
        label='Name'
        value={search}
        style={{ margin: 10 }}
        variant='outlined'
        onChange={changeSearchText}
        />
        <TextField
        id='outlined-basic'
        label='Nick Name'
        value={search}
        style={{ margin: 10 }}
        variant='outlined'
        onChange={changeSearchText}
        />
        <TextField
        id='outlined-basic'
        label='age'
        value={search}
        style={{ margin: 10 }}
        variant='outlined'
        onChange={changeSearchText}
        />
        <TextField
        id='outlined-basic'
        label='Save'
        value={search}
        style={{ margin: 10 }}
        variant='outlined'
        onChange={changeSearchText}
        />
      </div> */}
      {/* </Paper> */}
      {customer.length > 0 ? (
        <div className="ag-theme-material" style={{
          height: '100vh',
          // width: '100%'
        }}>
          <AgGridReact
          ref={gridRef}
            suppressExcelExport={true}
            onGridReady={onGridReady}
            rowData={customer}
            defaultColDef={{
              // flex: 1,
              // minWidth: 200,
              editable: false,
              resizable: true,
              sortable: true,
              floatingFilter: searchable,
            }}
            rowDragManaged={true}
            animateRows={true}
            frameworkComponents={{
              totalValueRenderer: TotalValueRenderer,

            }}
            getRowStyle={(params)=>{
              if (params.node.rowIndex % 2 === 0) {
                return { background: '#f6f8fb' };
            }
            }}
            rowClassRules={{
              'sick-days-warning': function (params) {
                var numSickDays = params.data.sickDays;
                return numSickDays > 5 && numSickDays <= 7;
              },
              'sick-days-breach': 'data.sickDays >= 8',
            }}
          >
            <AgGridColumn cellRenderer="totalValueRenderer" headerName="Action" ></AgGridColumn>
            <AgGridColumn cellClicked={(parms) => alert('0')} width={120} suppressMenu={true} filter="agTextColumnFilter" headerName="ID" resizable={true} field="cas_id" sortable= {true}></AgGridColumn>
            <AgGridColumn width={150} headerName="First Name" suppressMenu={true} filter="agTextColumnFilter" resizable={true} field="fname" sortable= {true}></AgGridColumn>
            <AgGridColumn width={150} headerName="Last Name" suppressMenu={true} filter="agTextColumnFilter" resizable={true} field="lname" sortable= {true} ></AgGridColumn>
            {/* <AgGridColumn filter="agTextColumnFilter" suppressMenu={true} width={150} headerName="Age" resizable={true} field="age" sortable= {true} ></AgGridColumn> */}
            <AgGridColumn width={150} headerName="Type" suppressMenu={true} filter="agTextColumnFilter" resizable={true} field="cas_type" sortable= {true} ></AgGridColumn>
            <AgGridColumn width={150} headerName="Address" suppressMenu={true} filter="agTextColumnFilter" resizable={true} field="address" sortable= {true} ></AgGridColumn>
            <AgGridColumn width={150} headerName="Contact" suppressMenu={true} filter="agTextColumnFilter" resizable={true} field="contact" sortable= {true} ></AgGridColumn>
            <AgGridColumn width={150} headerName="Balance" suppressMenu={true} filter="agTextColumnFilter" resizable={true} field="RC" sortable= {true} ></AgGridColumn>
            {/* <AgGridColumn width={150} headerName="Email" suppressMenu={true} filter="agTextColumnFilter" resizable={true} field="email" sortable= {true} ></AgGridColumn> */}
          </AgGridReact>
        </div>
      ) : (
        <Skeleton count={50} />
        // <ShowLoadingOrEmpty message='No Customers Found' />
      )}
      <br />
      <Paper className={classes.paper}>
        {/* {customersList.length > 0 ? (
            <Table size='small' onClick={openAddDialog}>
            <TableHead>
            <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Age</TableCell>
            <TableCell>Gender</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Contact</TableCell>
            <TableCell>Email</TableCell>
            </TableRow>
            </TableHead>
          <TableBody> */}
        {/* {customersList.map((row) => (
            <TableRow key={row.casid}>
            <TableCell>{row.cas_id}</TableCell>
            <TableCell>
            {row.fname} {row.lname}
            </TableCell>
            <TableCell>{row.age}</TableCell>
            <TableCell>{row.gender}</TableCell>
            <TableCell>{row.address}</TableCell>
            <TableCell>{row.contact}</TableCell>
            <TableCell>{row.email}</TableCell>
            </TableRow>
          ))} */}
        {/* </TableBody>
            </Table>
            ) : (
              <ShowLoadingOrEmpty message='No Customers Found' />
            )} */}

        <div className={classes.seeMore}>
          <Link color='primary' to="#">
            {customersList.length > 0
              ? 'Total Customers: ' + customersList.length
              : 'No Customers'}
          </Link>
        </div>
        <AddCustomer
          open={addOpen}
          handleClose={closeAddDialog}
          editStockData={currentApp}
        />
      </Paper>
    
    </Card>
  );
}
