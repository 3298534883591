import { API_URL } from "config";

const api= {
    
    topsalesAPI: `${API_URL}/sales/get_top_sales/`,
    salesItemFromCasIdAPI: `${API_URL}/sales/get_sales_item_from_cas_id/`,
    maxSaleAPI: `${API_URL}/sales/get_max_sales_id`,
    //TODO
    mostSoldAPI: `${API_URL}/product/most-sold`,
    deleteSalesAPI: `${API_URL}/sales/deleteData`,
    updateProductAPI: `${API_URL}/sales/updateProductData`,

    saleItemAPI: `${API_URL}/sales/get_sales_items`,
    saleUpdateAPI: `${API_URL}/sales/updateData`,
    salesItemByProductIdAPI: `${API_URL}/sales/get_sales_items_by_product_id/`,

    productAPI: `${API_URL}/product`,
    productPostAPI: `${API_URL}/product/post`,
    productGetDetailsAPI: `${API_URL}/product/getDetails`,
    productBestSellAPI: `${API_URL}/product/best-sell`,
    productUpdateStatus: `${API_URL}/product/update-status`,
    productManufacturerAPI: `${API_URL}/product/manufacturer`,
    //not in reducer [TODO]
    productImportAPI: `${API_URL}/product/import`,
    productsTOEXCELAPI: `${API_URL}/product/products-to-excel`,

    adminLoginAPI: `${API_URL}/auth/admin/login`,
    registerAPI: `${API_URL}/auth/register`,
    login2API: `${API_URL}/auth/login2`,
    LoginAPI: `${API_URL}/auth/admin/login`,
    authRegister: `${API_URL}/auth/admin/vendor`,
    loginData: `${API_URL}/auth/admin/login_data`,
    

    profitLossAPI: `${API_URL}/report/profit_loss`,
    journalEntryAPI: `${API_URL}/report/journal_voucher`,
    profitLossByDateAPI: `${API_URL}/report/profit_loss_by_date`,
    lowstockAPI: `${API_URL}/product/lowstock`,
    openingAmountByDateAPI: `${API_URL}/report/get_opening_amount_by_date`,
    expensesTotalAPI: `${API_URL}/report/expenses-total`,
    transactionBillsAPI: `${API_URL}/report/transaction-bills`,
    monthlyReportsAPI: `${API_URL}/report/monthly-report`,
    reportSalesAPI: `${API_URL}/report/sales`,
    daybookAPI: `${API_URL}/report/day_book`,
    creditorsAPI: `${API_URL}/report/creditors`,
    savingBookAPI: `${API_URL}/report/saving-book`,
    adjustCashAPI: `${API_URL}/report/adjust-cash`,
    openingAmountAPI: `${API_URL}/report/opening_amount`,
    dashboardReportsAPI: `${API_URL}/report/dashboard-reports`,
    dashboardCardAPI: `${API_URL}/report/dashboard-card`,
    salesByDateAPI: `${API_URL}/report/sales_by_date`,

    viberCustomerAPI: `${API_URL}/customer/all_viber_customer`,
    customerAPI: `${API_URL}/customer/cas`,
    updateCustomerAPI: `${API_URL}/customer/cas/update`,
    customerPostAPI: `${API_URL}/customer/cas/post`,
    customerDetailAPI: `${API_URL}/customer/getDetails`,
    allViberCustomer: `${API_URL}/customer/all_viber_customer`,

    //TODO
    customerDetailsAPI: `${API_URL}/customer/getDetails/`,

    chatConversationAPI: `${API_URL}/chat/conversations`,
    chatAPI: `${API_URL}/chat`,
    chatPostAPI: `${API_URL}/chat/post`,

    menuAPI: `${API_URL}/menu`,
    menuAllAPI: `${API_URL}/menu/all`,
    
    vendorAPI: `${API_URL}/vendor`,
    appdataAPI: `${API_URL}/vendor/appdata`,
    vendorAppdataAPI: `${API_URL}/vendor/appdata`,
    stockAppMains: `${API_URL}/vendor/apps`,
    addContactToStockapp: `${API_URL}/customer/stockapp/post`,
    
    stockAppByPosts: `${API_URL}/vendor/apps/post`,
    updateStockAPI: `${API_URL}/vendor/apps/update`,
    
    categoryParentsAPI: `${API_URL}/category/getParents`,
    categoryAPI: `${API_URL}/category`,
    productsFromCategory: `${API_URL}/category/getProducts`,

   staffPostAPI: `${API_URL}/staff/post`,
   staffAPI: `${API_URL}/staff`,
   adminAPI: `${API_URL}/staff/admin`,
   staffAttendenceAPI: `${API_URL}/staff/attendance`,
   
   notePostAPI: `${API_URL}/note/post`,
   cashPostAPI: `${API_URL}/report/post-cash`,
   documentPostAPI: `${API_URL}/note/post_documents`,
   noteAppointmentAPI: `${API_URL}/note/appointment`,
   appointmentPostAPI: `${API_URL}/note/post_appointment`,
   updateAppointmentPostAPI: `${API_URL}/note/update_appointment`,
   noteAPI: `${API_URL}/note`,
   

   userAPI: `${API_URL}/user`,
   userPhotoAPI: `${API_URL}/user/photo`,
   resetPwdAPI: `${API_URL}/user/reset_pw`,
   newPwdAPI: `${API_URL}/user/receive_new_password`,

   bannerAPI: `${API_URL}/banner`,

   cartAPI: `${API_URL}/cart`,
   cartPostAPI: `${API_URL}/cart/post`,
   cartItemAPI: `${API_URL}/cart/cartitem`,

   favouriteListAPI: `${API_URL}/favoriteList`,
   favouriteListPostAPI: `${API_URL}/favoriteList/post`,
    favouriteListItemAPI: `${API_URL}/favoriteList/favitem`,

   orderAPI: `${API_URL}/order`,
   orderPostAPI: `${API_URL}/order/post`,

   botMessageAPI: `${API_URL}/viber/bot_message`,
   botMessageByPost: `${API_URL}/viber/bot_message/post`,
   viberWebhookAPI: `${API_URL}/viber/webhook`,

   envAPI: `${API_URL}/env`,
  };

export default api;
