import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './style.css';
import * as action from '../store/AuthActions';
import { useHistory } from 'react-router';
import Logo from '../../../assets/icon.png';
import { ValidateForm, ValidateInput } from '../../../utils/validateForm';
import LoginRules from './loginRules';
import { Paper } from '@material-ui/core';
const initialData = {
  username: '',
  password: '',
};

export default function Login() {
  const [data, setData] = useState(initialData);
  const [errors, setErrors] = useState(initialData);
  const hostdata = window?.location?.hostname;
  let hostarray = hostdata?.split('.');
  let hostname = hostarray[0].toLowerCase().replace(/\b[a-z]/g, function(letter) {
    return letter.toUpperCase();
}) || 'Business';
  console.log("🚀 ~ file: index.js ~ line 20 ~ Login ~ hostname", hostname)

  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );

  const history = useHistory();

  useEffect(() => {
    // console.log(isAuthenticated);
    if (isAuthenticated) {
      history.push('/dashboard');
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const dispatch = useDispatch();
  const loginAction = (e) => {
    e.preventDefault();
    // console.log(data);
    let errorMsgs = ValidateForm(data, LoginRules);
    setErrors({ ...errorMsgs });
    let validated = Object.values(errorMsgs).join('').length === 0;
    validated && dispatch(action.authenticate(data));
  };
  const changeHandler = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const inputValidation = (e) => {
    let errorMsg = ValidateInput(e.target.name, e.target.value, LoginRules);
    setErrors({
      ...errors,
      [e.target.name]: errorMsg,
    });
  };
  return (
    <div className='container'>
      <div className='sidecontent'>
        <div className='logoimg'>test</div>
      
        <h1>Pasal Stock Management</h1>
        <h6>
         Manage your stock Easily be with sync in business
        </h6>
      </div>
      <div className='content'>
        <div className='' style={{backgroundColor:'#ffffffd4',padding: '1%',borderRadius:6,
    paddingTop: '30px',
    width: '45%',
    marginTop: '5%',textAlign:'center'}}>
        <div className='title'>Welcome to {hostname} Management System</div>
      
      Login to your account</div>
       <div style={{backgroundColor:'#ffffffd4',padding: '1%',borderRadius:6,
    width: '45%',
    marginTop: '5%',}}>

      
        <div className='poster'>
          <center>
          <img src={Logo} className='img' alt='logo' />
          </center>
        </div>
          {/* <div className='title' style={{color:'#000'}}>Pasal - Stock </div> */}
          <form onSubmit={loginAction}>
            <div className='form-body flex column '>
            <div className='form-group my-sm'>
                <label className='sub-title'>Subdomain</label>
                <div className='input-area flex items-center'>
                  <input
                  style={{border:'1px solid #ccc'}}
                    className='input'
                    type='text'
                    name='subdomain'
                    placeholder='subdomain'
                    onBlur={inputValidation}
                    onChange={changeHandler}
                  />
                </div>
                {errors.subdomain !== '' ? (
                  <span className='error-label'>{errors.subdomain}</span>
                ) : (
                  ''
                )}
              </div>
              <div className='form-group my-sm'>
                <label className='sub-title'>USER</label>
                <div className='input-area flex items-center'>
                  <input
                  style={{border:'1px solid #ccc',textTransform:'capitalize'}}
                    className='input'
                    type='text'
                    name='username'
                    placeholder='USER'
                    onBlur={inputValidation}
                    onChange={changeHandler}
                  />
                </div>
                {errors.username !== '' ? (
                  <span className='error-label'>{errors.username}</span>
                ) : (
                  ''
                )}
              </div>
              <div className='form-group my-sm'>
                <label className='sub-title'>PIN</label>
                <div className='input-area flex items-center'>
                  <input
                  style={{border:'1px solid #ccc'}}
                    className='input'
                    type='password'
                    name='password'
                    onBlur={inputValidation}
                    onChange={changeHandler}
                    placeholder='****'
                  />
                </div>
                {errors.password !== '' ? (
                  <span className='error-label'>{errors.password}</span>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className='flex center' >
              <input className='btn btn-primary' style={{backgroundImage:'linear-gradient(to bottom right, #cf5ef5, #46a4c5)',padding:'10px 30px'}} type='submit' title='Login' />
            </div>
            <br />
           <center> Login to {hostname} Management</center>
          </form>
          </div>
      </div>
    </div>
  );
}
