export const GET_MANUFACTURER = 'GET_MANUFACTURER';
export const GET_VENDORS = 'GET_VENDORS';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_OPENINGS = 'GET_OPENINGS';
export const GET_DAYBOOK = 'GET_DAYBOOK';
export const GET_CREDITORS = 'GET_CREDITORS';
export const GET_MENU = 'GET_MENU';
export const GET_ADMIN = 'GET_ADMIN';
export const GET_STAFF = 'GET_STAFF';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_DRAWER_OPEN = 'GET_DRAWER_OPEN';
export const GET_DRAWER_OPENER = 'GET_DRAWER_OPENER';
export const GET_HAM_OPENER = 'GET_HAM_OPENER';
export const GET_APPS = 'GET_APPS';
export const GET_BOT_MESSAGES = 'GET_BOT_MESSAGES';
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_APPOINTMENTS = 'GET_APPOINTMENTS';
export const GET_SALES_CASID = 'GET_SALES_CASID';
export const GET_ORDERS = 'GET_ORDERS';
export const GET_ATTENDANCE = 'GET_ATTENDANCE';
export const GET_LOADING = 'GET_LOADING';
export const GET_DISPLAY_STOCK = 'GET_DISPLAY_STOCK';
export const GET_OPEN_ENV = 'GET_OPEN_ENV';
export const GET_ENV_DATA = 'GET_ENV_DATA';
export const GET_MONTHLY_REPORT = 'GET_MONTHLY_REPORT';
export const GET_NOTE = 'GET-NOTE';
export const GET_PROFIT_BY_BILLS = 'GET-PROFIT_BY_BILLS';
export const GET_STAFFDETAILS= 'GET_STAFFDETAILS';
export const GET_CUSTOMERPROFILE='GET_CUSTOMERPROFIL';

