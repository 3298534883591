import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
  } from '@material-ui/core';
  import React, { useEffect, useState } from 'react';
  import { useDispatch, useSelector } from 'react-redux';
  import * as actions from '../../store/DashboardActions';
  import Title from './Title';
  
  export default function Calculator() {
    const dispatch = useDispatch();
    const [calculatorData, setCalculatorData] = useState({
        1000: "",
        500: "",
        100: "",
        50: "",
        20: "",
        10: "",
        5: "",
        1: "",
      });
    
      const [total, setTotal] = useState(0);
    const open = useSelector((state) => state.dashboardReducer.openenv);
    const envdata = useSelector((state) => state.dashboardReducer.envdata);
    const dataChanged = (e) => {
        if (typeof parseInt(e.target.value) === 'number') {
          setCalculatorData({
            ...calculatorData,
            [e.target.name]: e.target.value,
          });
        // console.log("calculatorData",calculatorData);

        }
      };

      useEffect(() => {
        let total = 0;
        Object.keys(calculatorData).map((key) => {
          total += calculatorData[key] * parseInt(key);
        });
        setTotal(total);
      }, [calculatorData]);
      
   
  
    const handleClose = () => {
      dispatch(actions.setOpenEnv(false));
    };
    const handleOpen = () => {
      dispatch(actions.setOpenEnv(true));
    };
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle>Calculator</DialogTitle>
        <DialogContent>
        <div
          style={{
            backgroundColor: '#ccc0',
            padding: 20,
            justifyContent: 'center',
            alignItems: 'center',
            textAlign:'center',
            margin:20
          }}
        >
          <div style={{ fontSize: 20, fontWeight: 'bold' }}>Amount: </div>
          <div style={{ fontSize: 20, fontWeight: 'bold' }}>{total}</div>
          <TextField
          
           variant='outlined'
                    value={calculatorData[1000]}
           name='1000'
            keyboardType='number-pad'
            // value={calculatorData}
            onChange={(num) => dataChanged(num)}
            placeholder='1000X'
          />
          <TextField
           variant='outlined'
                    value={calculatorData[500]}
           name='500'
            keyboardType='number-pad'
            onChange={(num) => dataChanged(num)}
            placeholder='500X'
          />

          <TextField
           variant='outlined'
                    value={calculatorData[100]}
           name='100'
            keyboardType='number-pad'
            onChange={(num) => dataChanged(num)}
            placeholder='100X'
          />

          <TextField
           variant='outlined'
                    value={calculatorData[50]}
           name='50'
            keyboardType='number-pad'
            onChange={(num) => dataChanged(num)}
            placeholder='50X'
          />

          <TextField
           variant='outlined'
                    value={calculatorData[20]}
           name='20'
            keyboardType='number-pad'
            onChange={(num) => dataChanged(num)}
            placeholder='20X'
          />

          <TextField
           variant='outlined'
                    value={calculatorData[10]}
           name='10'
            keyboardType='number-pad'
            onChange={(num) => dataChanged(num)}
            placeholder='10X'
          />

          <TextField
           variant='outlined'
                    value={calculatorData[5]}
           name='5'
            keyboardType='number-pad'
            onChange={(num) => dataChanged(num)}
            placeholder='5X'
          />

          <TextField
           variant='outlined'
                    value={calculatorData[1]}
           name='1'
            keyboardType='number-pad'
            onChange={(num) => dataChanged(num)}
            placeholder='1X'
          />
        </div>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='default'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  