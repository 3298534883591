import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuListItems from './Components/MainListItems';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { setDrawerOpen, setDrawerOpener } from '../store/DashboardActions';
import img from '../../assets/icon.png';
import icon from '../../assets/icon.png';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../store/action';
import AuthRouter from './router/index';
import { useHistory } from 'react-router';
import EnvDialog from './Components/EnvDialog';
import Navbar from "components/Navbars/Navbar.js";
import routes from "modules/Dashboard/router/routeList";
import { styled } from '@mui/system';
import SwitchUnstyled, { switchUnstyledClasses } from '@mui/base/SwitchUnstyled';
// import PerfectScrollbar from "perfect-scrollbar";
import Calculator from './Components/calc';
let ps;
function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://pasal.com.np/'>
        Pasal.com.np
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const black = {
  500: '#444444',
};

const darkblue = {
  400: '#29304B',
  500: '#AAB4BE',
};

const Root = styled('span')`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin: 10px;
  cursor: pointer;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    background: ${darkblue[400]};
    border-radius: 10px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    border: 1px solid #fff;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 14px;
    height: 14px;
    top: 3px;
    left: 3px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: ${darkblue[500]};
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 22px;
      top: 3px;
      background-color: #fff;
    }

    .${switchUnstyledClasses.track} {
      background: ${black[500]};
      border: 1px solid #fff;
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }

  
`;

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // padding: '0 8px',
    ...theme.mixins.toolbar,
    backgroundColor: '#183142',
  },
  appBar: {
    backgroundColor: '#183142',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    height: '100vh',
    background: 'linear-gradient(177.65deg, #247BFF 4.31%, #000000 85.05%)',
    color: '#fff',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    height: '100vh',
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  logo: {
    objectFit: 'contain',
    width: 60,
    padding: 8,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));
export default function Dashboard() {
  const classes = useStyles();
  const mainPanel = React.createRef();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const open = useSelector((state) => state.dashboardReducer.drawerOpen);
  const opener = useSelector((state) => state.dashboardReducer.drawerOpener);
  const hamOpener = useSelector((state) => state.dashboardReducer.hamOpener);
  const vendor = useSelector((state) => state.authReducer.user.vendor);
  const dispatch = useDispatch();

  const history = useHistory();

  const handleDrawerOpen = () => {
    if (opener == true) {
      dispatch(setDrawerOpen(true));
    } else {
      dispatch(setDrawerOpen(true));
    }
  };
  const handleDrawerClose = () => {
    if (opener == false) {
      dispatch(setDrawerOpen(false));
    } else {
      dispatch(setDrawerOpen(true));
    }
  };
  console.log(opener);
  const handleDrawerOpener = (event) => {

    if (opener == true) {
      dispatch(setDrawerOpener(false));
    } else {
      dispatch(setDrawerOpener(true));
    }
  };

  const handleDrawerCloser = (event) => {
    if (event.target.opener) {
      dispatch(setDrawerOpener(false));
    } else {
      dispatch(setDrawerOpener(true));
    }
  };

  const logout = () => {
    dispatch(actions.logout());
    history.push('/');
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // React.useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(mainPanel.current, {
  //       suppressScrollX: true,
  //       suppressScrollY: false,
  //     });
  //     document.body.style.overflow = "hidden";
  //   }
  //   window.addEventListener("resize", resizeFunction);
  //   // Specify how to clean up after this effect:
  //   return function cleanup() {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //     }
  //     window.removeEventListener("resize", resizeFunction);
  //   };
  // }, [mainPanel]);

  // const [checked, setChecked] = React.useState(true);


  function drawerContent() {
    return (
      <>
        <div className={classes.toolbarIcon}>
          <div className='flex'>
            {/* <img className={classes.logo} src={img} alt='' onMouseOver={handleDrawerOpen} /> */}
            <img className={classes.logo} src={`https://cdn.pasal.com.np/${vendor?.applogo? vendor?.applogo:'/catalog/logo/logo.jpg'}`}  alt='' onMouseOver={handleDrawerOpen} />
            <div className='title' style={{color:'#fff'}}>{vendor?.app_short_name}</div>
          </div>
          <SwitchUnstyled className='dashboard-switcher' component={Root} checked={opener}
            inputProps={{ 'aria-label': 'controlled' }}
            onClick={handleDrawerOpener}
            size="small" //style={{backgroundColor:'white',position:'relative',left:12,zIndex:1000}}
          >
            {/* <ChevronLeftIcon style={{ color: 'white' }} color={"white"} /> */}
          </SwitchUnstyled>
        </div>
        <List>
          <MenuListItems key={routes} />
        </List>
      </>
    )
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* <AppBar
        position='absolute'
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <img className={classes.logo} src={icon} alt='' />
          </IconButton>
          <Typography
            component='h1'
            variant='h6'
            color='inherit'
            noWrap={false}
            className={classes.title}
          >
          </Typography>
          <IconButton color='inherit' onClick={logout}>
            <Badge color='secondary'>
              <ExitToAppIcon />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar> */}
      {hamOpener != true ? (
        handleDrawerOpener != true ? (
          <Drawer
            variant={hamOpener ? 'temporary' : 'permanent'}
            classes={{
              paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
            }}
            open={open}
            onMouseLeave={handleDrawerClose}
            onMouseOver={handleDrawerOpen}
            key={opener}
            anchor='bottom'
          > {drawerContent()}
          </Drawer>) : drawerContent()

      ) : (
<></>
      )
}
      {/* <EnvDialog /> */}
      <main className={classes.content}>
        <Container maxWidth='xl' className={classes.container}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className={classes.mainPanel} ref={mainPanel}>
                <Navbar
                  routes={routes}
                // handleDrawerToggle={handleDrawerToggle}
                // {...rest}
                />
                <AuthRouter style={{ backgroundColor: 'red' }} />
              </div>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
