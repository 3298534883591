import React, { useEffect, useRef, useState } from 'react';
import Title from 'modules/Dashboard/Components/Title';
import { useParams } from "react-router-dom";
import IndividualCrSales from 'modules/inventory/CustomerReport/Components/IndividualCrSales';

import { useDispatch, useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import ReactToPrint from "react-to-print";
import { downloadCSVFromJson } from "utils/DownloadCSV";
import { Button, Paper, TextField, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "react-loading-skeleton/dist/skeleton.css";
import Link from "@material-ui/core/Link";
import NepaliDate from "nepali-date-converter";
// import * as actions from "../../../store/DashboardActions";

import api from "api/api";
import { GetRequest } from "plugins/axios";



export default function PublicLedgerView({BILLTYPE}) {
    let { subdomain, connect_id } = useParams();
    let casId =connect_id;
    console.log("subdomain",subdomain);
    console.log("connect id",connect_id);
    const token = 'eyJ0eXAiO... /// jwt token';
    // const decoded = jwt_decode(token);
    // console.log(decoded);

    const myRef = useRef();
    const handleClose = () => {
        return true;
    }
    const [axiosToken, setAxiosToken] = useState("");
    const classes = useStyles();
    //   const salescasid = useSelector((state) => state.dashboardReducer.salescasid);
    const componentRef = useRef();
    const [customer, setCustomer] = useState([]);
    const [salescasid, setSalesCasid] = useState([]);
    const [salesChart, setSalesChart] = useState([
      { sales: "Credit", net_amount: 12 },
      { sales: "Cash", net_amount: 7 },
    ]);
    const [salesBarChart, setSalesBarChart] = useState([]);
    const [bill_type, setBill_type] = useState(BILLTYPE);
    const nepaliDate = new NepaliDate();
  
    const [image, setImage] = useState("");
    const [openBill, setOpenBill] = useState(false);
    const [casCr, setCasCr] = useState(0);
    const [casPa, setCasPa] = useState(0);
    const [open, setOpen] = useState(false);
    const [savedCreditors, setSavedCreditors] = useState([]);
    const [creditTotal, setCreditTotal] = useState(0);
    const [paidTotal, setPaidTotal] = useState(0);
  
    const [products, setProducts] = useState([0, 0, 0, 0, 0, 0]);
    //   const [casId, setCasId] = useState(0);
    const [remaningTotal, setRemaningTotal] = useState(0);
    const dispatch = useDispatch();
    useEffect(() => {
      let remaingTotal = 0;
      let creditTotal = 0;
      let paidTotal = 0;
      salescasid
      .filter((person) =>
      person.BR == "CR" ||
      person.bill_type == "RECEIPT"
    )
        .filter((person) =>
          bill_type == "PURCHASE"
            ? person.bill_type == "PURCHASE" ||
              person.bill_type == "VOUCHER"
            : person.bill_type == "SALE" ||
              person.bill_type == "RECEIPT"
        )
        .forEach((d) => {
        remaingTotal += parseInt(d.net_amount);
        creditTotal += parseInt(d.net_amount);
        paidTotal += parseInt(d.paid_amount);
      });
  
      setRemaningTotal(remaingTotal-paidTotal);
      setCreditTotal(creditTotal);
      setPaidTotal(paidTotal);
      setCasCr(creditTotal)
      setCasPa(paidTotal)
      setSalesChart([
        { sales: "Credit", net_amount: 12 },
        { sales: "Cash", net_amount: 13 },
      ]);
    }, [salescasid]);
  
    useEffect(() => {
      let TotalCredit = 0;
      let TotalCash = 0;
      salescasid
        .filter((d) => "CR" == d.BR)
        .map((d) => {
          TotalCredit += parseInt(d.net_amount);
        });
      salescasid
        .filter((data) => "C" == data.BR)
        .map((d) => {
          TotalCash += parseInt(d.net_amount);
        });
  
      setSalesChart([
        { sales: "Credit", net_amount: TotalCredit },
        { sales: "Cash", net_amount: TotalCash },
      ]);
    }, [salescasid]);
  
    useEffect(() => {
      let bill_date= [];
      let amount = [];
      salescasid.map((d) => {
        console.log("🚀 ~ file: IndividualSales.js ~ line 134 ~ salescasid.map ~ d", d)
        // amount += Option.assign(d.bill_date);
        bill_date.push(d.bill_date);                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
        amount.push(d.net_amount);                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
      });
      console.log("🚀 ~ file: IndividualSales.js ~ line 148 ~ IndividualSales ~ amount", amount)
        console.log("🚀 ~ file: IndividualSales.js ~ line 147 ~ useEffect ~ bill_date", bill_date)
      // setSalesBarChart([
      //   // { argument: ["bill_date"], value: amount },
      //   {argument:...bill_date, ...amount}
      // ]);
    }, [salescasid]);
  
    const loadMore = (event) => {
      event.preventDefault();
      setFilterData({
        ...filterData,
        subdomain,
        page: filterData.page + 1,
      });
    };
    useEffect(()=>{
        GetRequest(`${api.salesItemFromCasIdAPI}?page=0`, { cas_id:casId,subdomain,startDate:'2077/01/01',endDate:'2080/01/01' })
        .then((res) => {
          // console.log(res.data);
          setSalesCasid(res.data.content);
        })
    },[])
  
    const [filterData, setFilterData] = useState({
      subdomain,
      bill_type,
      dateString: "2078/04/11",
      odString: "2078/04/01",
      page: 0,
      limit: 10,
    });
    useEffect(() => {
      if (bill_type) {
        setFilterData({
          ...filterData,
          subdomain,
          bill_type: bill_type == "PURCHASE" ? "SALE" : "PURCHASE",
        });
        // console.log('bill_type----------',bill_type);
      }
      //eslint-disable-next-line
    }, [bill_type]);
  

  
    const filterProducts = (data) => {
      setFilterData({
        ...filterData,
        subdomain,
        page: 0,
        ...data,
      });
      handleClose(!open);
    };
  
    const get_customer_details = (casId, subdomain) => {
      GetRequest(`${api.customerDetailsAPI}`, { cas_id: casId, subdomain })
        .then((res) => {
          setCustomer(res.data.content);
        })
        .catch((err) => {});
    };
    useEffect(() => {
      const get_sales_cas_id = () => {
        // console.log(filterData);
        if (axiosToken) {
          // Cancel the previous request before making a new request
          axiosToken.cancel("operation canceled due to new request.");
        }
        let token = axios.CancelToken.source();
        setAxiosToken(token);
        // dispatch(actions.get_sales_item_from_cas_id(casId, subdomain));
        get_customer_details(casId, subdomain);
        // console.log("salescasid",salescasid);
      };
      get_sales_cas_id();
      return () => {
        // console.log('clear');
      };
      // eslint-disable-next-line
    }, [casId, filterData]);
  
    return (
        <Paper >
            <Title>Customer Ledger </Title>
            <center>{subdomain}</center>
            {/* {true ? 'not found':'found'} */}
            <div style={{ display: "flex", flexDirection: "row" }}>
      <Grid container ref={componentRef}>
       
        <Grid item xs={12}>
          <div style={{ marginLeft: "20px", justifyContent: "space-between",borderLeft:'3px' }}>
            <div
              className={classes.paper}
              style={{ display: "flex", flexDirection: "column", }}
            >
              <div  style={{ display: "flex", flexDirection: "row",justifyContent:'space-between',alignItems:'center' }}>
              <div span={8} style={{ marginRight: "10px" }}>
                <h3 style={{ fontWeight: "400" }}>{customer?.fname} {customer?.lname}</h3>
                {/* <div>{vendor?.panno}</div>
                <div>Kathmandu</div> */}

                {/* <div>
                  Name:{customer?.fname} {customer?.lname}
                </div> */}
                <div>Cas id:{customer?.cas_id} </div>
                <div>Contact No:{customer?.contact}</div>
                <div>Address:{customer?.address}</div>
              </div>

              
              {/* <Chart width={120} data = {salesChart}>
              <PieSeries
                valueField={'net_amount'}
                argumentField={'sales'}
              />
              <Title text="Sales Data" />
            </Chart> */}
              <div span={4}>
                <h4 style={{ fontWeight: "400" }}>
                  Remaining Balance:Rs. {remaningTotal}
                  {/* {parseInt(
                  (customer.net_amount || 0) - (customer.paid_amount || 0)
                )} */}
                </h4>
              </div>
              </div>
              {/* <Chart data={salesChart}>
                <PieSeries valueField={"net_amount"} argumentField={"sales"} />
                <Title text="Sales Data" />
              </Chart>
              <Chart data={salesBarChart}>
                <ArgumentAxis />
                <ValueAxis />

                <BarSeries valueField="value" argumentField="argument" />
              </Chart> */}
              {true ? (
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Id</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Bill No</TableCell>
                      {/* <TableCell>Name</TableCell> */}
                      <TableCell align="right">Credit amount</TableCell>
                      <TableCell align="right">Paid Amount</TableCell>
                      <TableCell align="right">Remaining Balance</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {salescasid
                    .filter((person) =>
                    person.BR == "CR" ||
                    person.bill_type == "RECEIPT"
                  )
                      .filter((person) =>
                        bill_type == "PURCHASE"
                          ? person.bill_type == "PURCHASE" ||
                            person.bill_type == "VOUCHER"
                          : person.bill_type == "SALE" ||
                            person.bill_type == "RECEIPT"
                      )
                      .map((row, index, bal) => (
                        <TableRow key={index}  onClick={() => {
                            setImage(row.connect_id);
                            setOpenBill(true);
                          }}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{`${row.bill_type}(${row.BR})`}</TableCell>
                          <TableCell>{`${row.bill_date}`}</TableCell>
                          <TableCell
                           
                          >
                            {row.connect_id}
                          </TableCell>
                          {/* <TableCell>{`${row.fname} ${row.lname} `}</TableCell> */}
                          <TableCell align="right">
                            Rs.{row?.net_amount || 0}
                          </TableCell>
                          <TableCell align="right">
                            Rs.{row?.paid_amount || 0}
                          </TableCell>
                          <TableCell align="right">
                            Rs.
                            {
                              (bal = parseInt(
                                (row?.net_amount || 0) - (row?.paid_amount || 0)
                              ))
                            }
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>***</TableCell>
                      <TableCell>***</TableCell>
                      {/* <TableCell>***</TableCell> */}
                      <TableCell>Total</TableCell>
                      <TableCell align="right">{casCr}</TableCell>
                      <TableCell align="right">{casPa}</TableCell>
                      <TableCell align="right">{casCr - casPa}</TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              ) : (
                <></>
              )}

        
             
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
            {/* <BillFormat ref={myRef} open={open} connect_id={connect_id} handleClose={handleClose} subdomain={subdomain} /> */}
        </Paper>
    );
}


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  button: {
    margin: 10,
  },
}));

