import DashboardCard from '../Components/DashboardCard';
import Dashboard from '../../views/Dashboard/Dashboard';
import ProductUpload from '../Components/ProductUpload';
import StockAppMain from '../modules/StockAppMain';
import Vendor from '../modules/Vendor';
import Category from '../modules/Category';
import BotMessage from '../modules/BotMessage';
import Customer from '../modules/Customer';
import Order from '../modules/Orders';
import Viber from '../modules/Viber';
import Product from '../../inventory/Product';
import Daybook from '../../inventory/daybook';
import CustomerReport from '../../inventory/CustomerReport';
import StaffList from '../../staff/StaffList';
import BillEdit from '../../inventory/BillEdit';
import TableList from "modules/views/TableList/TableList.js";
import UserProfile from "modules/views/UserProfile/UserProfile.js";
import Sales from 'modules/inventory/Sales';
import Reports from 'modules/main/Reports';
import AllNotes from 'modules/main/Note/Note';
import Appointments from '../modules/Appointments';
import ProductDetail from 'modules/inventory/ProductDetail/ProductDetail';
import Attendance from '../modules/attendence';
import AdjustCash from 'modules/inventory/CashAdjust/CashAdjust';
import CompanyDetails from 'modules/CMS/Company/CompanyDetails';

// eslint-disable-next-line
export default [
  {
    path: 'dashboard/',
    name: 'dashboard',
    exact: true,
    component: Dashboard,
  },
  {
    path: 'main/dashboard',
    name: 'dashboard',
    exact: true,
    component: Dashboard,
  },
  {
    path: 'dashboard/products',
    name: 'admin.products',
    exact: true,
    component: Product,
  },
  {
    path: 'dashboard/attendance',
    name: 'admin.products',
    exact: true,
    component: Attendance,
  },
  {
    path: 'cascare/MyCustomers',
    name: 'admin.customers',
    exact: true,
    component: Customer,
  },
  {
    path: 'cascare/ALLAPPOINTMENTS',
    name: 'admin.customers',
    exact: true,
    component: Appointments,
  },
  {
    path: 'dashboard/vendors',
    name: 'admin.vendors',
    exact: true,
    component: Vendor,
  },
  {
    path: 'dashboard/categories',
    name: 'admin.categories',
    exact: true,
    component: Category,
  },  {
    path: 'inventory/Category',
    name: 'admin.categories',
    exact: true,
    component: Category,
  },
  {
    path: 'dashboard/categories/:categoryId',
    name: 'admin.categories.categoryId',
    exact: true,
    component: Category,
  },
  {
    path: 'dashboard/stockappmain',
    name: 'admin.stockappmain',
    exact: true,
    component: StockAppMain,
  },
  {
    path: 'dashboard/import',
    name: 'admin.importproduct',
    exact: true,
    component: ProductUpload,
  },
  {
    path: 'dashboard/botmessage',
    name: 'admin.botmessage',
    exact: true,
    component: BotMessage,
  },
  {
    path: 'dashboard/orders',
    name: 'admin.orders',
    exact: true,
    component: Order,
  },
  {
    path: 'dashboard/products/category/:categoryId',
    name: 'admin.product.catgory',
    exact: true,
    component: Product,
  },
  {
    path: 'dashboard/viber',
    name: 'admin.viber',
    exact: true,
    component: Viber,
  },
  {
    path: 'inventory/daybook',
    name: 'admin.viber',
    exact: true,
    component: Daybook,
  },
  {
    path: 'inventory/product',
    name: 'admin.viber',
    exact: true,
    component: Product,
  },
  {
    path: 'inventory/Creditors',
    name: 'admin.viber',
    exact: true,
    component: CustomerReport,
  },
  {
    path: 'main/Staff',
    name: 'admin.viber',
    exact: true,
    component: StaffList,
  },
  {
    path: 'main/notes',
    name: 'admin.viber',
    exact: true,
    component: AllNotes,
  },
  {
    path: 'inventory/BillEdit',
    name: 'admin.viber',
    exact: true,
    component: BillEdit,
  },
  {
    path: 'inventory/BillEdit/:sales_data_id',
    name: 'admin.viber',
    exact: true,
    component: BillEdit,
  }, 
   {
    path: 'main/chatlist',
    name: 'admin.viber',
    exact: true,
    component: Sales,
   }, 
   {
    path: 'inventory/CASH_FLOW',
    name: 'admin.viber',
    exact: true,
    component: Sales,
   }, 
   {
    path: 'main/customer/',
    name: 'admin.viber',
    exact: true,
    component: Customer,
   }, 
   {
    path: 'main/customer/:cas_id',
    name: 'admin.viber',
    exact: true,
    component: UserProfile,
   }, 
   {
    path: 'main/productDetail/',
    name: 'admin.viber',
    exact: true,
    component: ProductDetail,
   }, 
   {
    path: 'inventory/Bills/',
    name: 'admin.viber',
    exact: true,
    component: TableList,
   },
   {
    path: 'inventory/Savings/',
    name: 'admin.viber',
    exact: true,
    component: TableList,
   },
   {
    path: 'inventory/businesscards/',
    name: 'admin.viber',
    exact: true,
    component: AdjustCash,
   },
   {
    path: 'CMS/Company/',
    name: 'admin.viber',
    exact: true,
    component: CompanyDetails,
   },
   {
    path: 'main/Reports/',
    name: 'admin.viber',
    exact: true,
    component: Reports,
   }
];
