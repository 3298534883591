import React, { useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddContact from '../../Dashboard/modules/StockAppMain/Components/AddContact';
import {
  Avatar,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import FilterDaybook from './Components/FilterDaybook';
import EditIcon from '@material-ui/icons/Edit';
import * as actions from '../../store/DashboardActions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
// import ImageDialog from './Components/ImageDialog';
import ShowLoadingOrEmpty from '../../Dashboard/Components/ShowLoadingOrEmpty';
import Title from '../../Dashboard/Components/Title';
import { CDN_URL } from '../../../config';
import api from '../../../api/api'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddStockAppMain from '../../Dashboard/modules/StockAppMain/Components/AddStockAppMain';
import NepaliDate from 'nepali-date-converter';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { AgGridColumn } from 'ag-grid-react/lib/agGridColumn';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import AddStaff from './Components/AddStaff';
import { Add } from '@material-ui/icons';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import UnstyledSwitcher from 'components/UnstyledSwitcher';
import Search from '@material-ui/icons/Search';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import { ManageAccounts } from '@mui/icons-material';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
  headerButton: {
    margin: 10,
    color:"#fff",
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
      color:'#fff',
  },
  
  button: {
    margin: 10,
  },
  select: {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'red',
    },
  }
}));

export default function StaffList() {

  
  const [dateString, setDateString] = useState('');
  const [dateodString, setodString] = useState('');
  const [nepaliDate, setNepaliDate] = useState(new NepaliDate());
  const [od, setod] = useState(new NepaliDate());

  const [axiosToken, setAxiosToken] = useState('');
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const [open, setOpen] = useState(false);
  const [floatingSearch, setFloatingSearch] = useState(true);
  const [openImage, setOpenImage] = useState(false);
  const [image, setImage] = useState('');
  const dispatch = useDispatch();
  const [addOpen, setAddOpen] = useState(false);
  const [currentApp, setCurrentApp] = useState('');
  const [currentVendor, setCurrentVendor] = useState('');
  const { categoryId } = useParams();
  const vendor = useSelector((state) => state.authReducer.user.vendor);
  const state = useSelector((state) => state);
  console.log("🚀 ~ file: index.js ~ line 109 ~ StaffList ~ state", state)
  const staffListItems = useSelector((state) => state.dashboardReducer.staff);
  const [today, setToday] = useState("2078/12/09");
  const [thisweek, setThisweek] = useState("2078/12/09");
  const [thismonth, setThismonth] = useState("2078/12/09");
  const [thisyear, setThisyear] = useState("2078/12/09");
  const [dateswitch, setDateswitch] = useState(today);
  const [startDate, setStartDate] = useState("2078/01/01");
  const [endDate, setEndDate] = useState("2078/12/32");
  const history = useHistory();
  const [addOpenContact, setAddOpenContact] = useState(false);
  const DurationSelectorHandler = (dates) => {
    setDateswitch(dates);
    let date = dates.split("||");
    console.log("///datesfrom", date[0]);
    console.log("///datesto", date[1]);
    setStartDate(date[0]);
    setEndDate(date[1]);
  };

  const [filterData, setFilterData] = useState({
    dateString,
    odString: '2078/04/01',
    page: 0,
    limit: 10,
  });

  useEffect(() => {
    setDateString(
      `${nepaliDate.getYear()}/${
        nepaliDate.getMonth() + 1 > 9
        ? nepaliDate.getMonth() + 1
        : `0${nepaliDate.getMonth() + 1}`
      }/${
        nepaliDate.getDate() > 9
        ? nepaliDate.getDate()
        : `0${nepaliDate.getDate()}`
      }`
      );
      setodString(
      `${od.getYear()}/${
        od.getMonth() + 1 > 9
        ? od.getMonth() + 1
        : `0${od.getMonth() + 1}`
      }/${
        od.getDate() > 9
        ? od.getDate()
        : `0${od.getDate()}`
      }`
      );
      
    }, [nepaliDate]);

  useEffect(() => {
    if (categoryId) {
      setFilterData({
        ...filterData,
        categoryId: categoryId,
      });
    }
    //eslint-disable-next-line
  }, [categoryId]);
  
  useEffect(() => {
    setProducts(staffListItems);
    // console.log("Daybook?",staffListItems);
  }, [staffListItems]);
  
  const handleClose = (value) => {
    setOpen(value);
  };
  
  const loadMore = (event) => {
    event.preventDefault();
    setFilterData({
      ...filterData,
      page: filterData.page + 1,
    });
  };
  
  useEffect(() => {
    const get_daybook = () => {
      // console.log(filterData);
      if (axiosToken) {
        // Cancel the previous request before making a new request
        axiosToken.cancel('operation canceled due to new request.');
      }
      let token = axios.CancelToken.source();
      setAxiosToken(token);
      dispatch(actions.get_staff(filterData.page === 0, filterData, token));
    };
    get_daybook();
    return () => {
      console.log('clear');
    };
    // eslint-disable-next-line
  }, [filterData]);
  
  const filterProducts = (data) => {
    setFilterData({
      ...filterData,
      page: 0,
      ...data,
    });
    handleClose(!open);
  };
  
  
  const handleDataChange = (e) => {
    setFilterData({
      ...filterData,
      page: 0,
      [e.target.name]: e.target.value,
    });
  };
  const openImageDialog = (image) => {
    // console.log(image);
    if (image) {
      setImage(image);
      setOpenImage(true);
    }
    
  };


  const TotalValueRenderer = (props, index) => {
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    return (
      <span style={{ display: "flex" }}>
        <span>{cellValue}</span>&nbsp;
       
        <Tooltip title="Assign access to this contact" >
       <Button className={classes.button} startIcon={<AddIcCallIcon/>} onClick={() => openAddContact(props.data) || <Skeleton />} />
    
                  </Tooltip>

       <Tooltip title="Edit here">
       <Button className={classes.button} startIcon={<EditIcon />} onClick={() => openAddDialog(props.data) || <Skeleton />} />
                  </Tooltip>
        
          
      </span>
    );
  };
  
  const handleChange = (e, productId, index) => {
    // console.log(products);
    // console.log(e.target.checked, { index });
    const newList = products;
    newList[index] = { ...newList[index], status: e.target.checked ? 1 : 0 };
    setProducts([...newList]);
    dispatch(
      actions.update_product_status(productId, e.target.checked ? 1 : 0)
      );
    };
    const openAddDialog = (app) => {
      setCurrentApp(app);
      setAddOpen(true);
    };
    const closeAddDialog = () => {
      setCurrentApp(false);
      setAddOpen(false);
    };
    const openAddContact = (app) => {
      setCurrentApp(app);
      console.log("🚀 ~ file: index.js ~ line 284 ~ openAddContact ~ {...vendor,...app}", {...vendor,...app})
      setCurrentVendor({...vendor,...app});
      setAddOpenContact(true);
    };
    const closeAddContact = () => {
      setCurrentApp('');
      setAddOpenContact(false);
    };
    return (
      <Card>
        <CardHeader  style={{display:'flex',justifyContent:'space-between',flexDirection:'column'}} color="primary">



          <h4 style={{ color: "#fff", fontWeight: "400" }}>Staff List</h4>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="justify-between">
             <Tooltip title="Add Staff">
              <Button onClick={()=>openAddDialog(false)} className={classes.headerButton}>
              <Add color="#ffff"/>
              </Button>
              </Tooltip>
              <Tooltip title="View Attendence list here">

              <Button
                className={classes.headerButton}
                onClick={() => history.push({ pathname: '/dashboard/attendance/'}) || <Skeleton />} >

                <ManageAccounts color="#ffff"/>
              </Button>
              </Tooltip>

            </div>
            <span className="date-selector" style={{ margin: "10px", color:'#fff' }}>
{/* 
  
  <FormControl
      className={classes.formControl}
      sx={classes.select}
      style={{ margin: 10,color:'#fff' }}
      variant='outlined'
      >
      <InputLabel shrink>Limit</InputLabel>
      <Select
        color="error"
      sx={classes.select}
      name='limit'
      value={filterData.limit}
      style={{color:'white',borderColor:'white'}}
      onChange={handleDataChange}
      >
      <MenuItem value={10}>10</MenuItem>
      <MenuItem value={20}>20</MenuItem>
      <MenuItem value={30}>30</MenuItem>
      <MenuItem value={50}>50</MenuItem>
      <MenuItem value={100}>100</MenuItem>
      </Select>
      </FormControl> */}
      <Button 
      className={classes.headerButton}
      startIcon={<EditIcon />}
      onClick={() => handleClose(!open)}
      >
      {filterData.categoryId ||
        filterData.vendorId ||
        filterData.subtract ||
        filterData.status
        ? 'Edit Filter'
        : 'Filter'}
        </Button>
        <Button
        className={classes.headerButton}
        href={`${api.productAPI}?excel=true&page=${filterData?.page}&limit=${filterData?.limit}&search=${filterData?.search}&categoryId=${filterData?.categoryId}&vendorId=${filterData?.vendorId}&status=${filterData?.status}&price=${filterData?.price}`}
        >
        Export Excel
        </Button>
  
</span>
          </div>
</CardHeader>
      
      <Paper className={classes.paper}>

     {/*} <div className='flex justify-between'>Staff
      <Title>Staff List</Title>
      {/* <p>{JSON.stringify(filterData)}</p> 
      <Button
      variant='contained'
      color='primary'
      startIcon={<AddCircleIcon />}
      onClick={() => openAddDialog()}
      >
      Add Staff
      </Button>
      </div>
      
      <div className='flex justify-between'>
      <TextField
      id='outlined-basic'
      label='Search'
      fullWidth
      style={{ margin: 10 }}
      variant='outlined'
      name='search'
      onChange={handleDataChange}
      />
      
      <FormControl
      className={classes.formControl}
      style={{ margin: 10 }}
      variant='outlined'
      >
      <InputLabel shrink>Limit</InputLabel>
      <Select
      name='limit'
      value={filterData.limit}
      onChange={handleDataChange}
      >
      <MenuItem value={10}>10</MenuItem>
      <MenuItem value={20}>20</MenuItem>
      <MenuItem value={30}>30</MenuItem>
      <MenuItem value={50}>50</MenuItem>
      <MenuItem value={100}>100</MenuItem>
      </Select>
      </FormControl>
      <Button
      className={classes.button}
      startIcon={<EditIcon />}
      onClick={() => handleClose(!open)}
      >
      {filterData.categoryId ||
        filterData.vendorId ||
        filterData.subtract ||
        filterData.status
        ? 'Edit Filter'
        : 'Filter'}
        </Button>
        <Button
        className={classes.button}
        href={`${api.productAPI}?excel=true&page=${filterData?.page}&limit=${filterData?.limit}&search=${filterData?.search}&categoryId=${filterData?.categoryId}&vendorId=${filterData?.vendorId}&status=${filterData?.status}&price=${filterData?.price}`}
        >
        Export Excel
        </Button>
      </div>*/}
        {products.length > 0 ? (
        <div className="ag-theme-material" style={{
          height: '100vh',
          width: '100%'
        }} >
          <AgGridReact
          getRowStyle={(params)=>{
            if (params.node.rowIndex % 2 === 0) {
              return { background: '#f6f8fb' };
          }
          }}
            rowData={products}
            defaultColDef={{
              flex: 1,
              minWidth: 200,
              editable: false,
              resizable: true,
              sortable: true,
              floatingFilter: floatingSearch,
            }}
            frameworkComponents={{
              totalValueRenderer: TotalValueRenderer,
            }}
          >

            <AgGridColumn headerName="Id" suppressMenu={true} resizable={true} field="id" sortable={true} filter={true} ></AgGridColumn>
            <AgGridColumn headerName="Full Name" suppressMenu={true}  field="stafffullname" sortable={true} filter={true} ></AgGridColumn>
            <AgGridColumn headerName="Start Date" suppressMenu={true} resizable={true} field="startdate" sortable={true} filter={true} ></AgGridColumn>
            <AgGridColumn headerName="Contact" suppressMenu={true} resizable={true} field="contact" sortable={true} filter={true} ></AgGridColumn>
            <AgGridColumn headerName="Department" suppressMenu={true} resizable={true} field="department" sortable={true} filter={true} ></AgGridColumn>
            {/* <AgGridColumn headerName="Selling Price" suppressMenu={true} resizable={true} field="discounted" sortable={true} filter={true} ></AgGridColumn> */}
            <AgGridColumn headerName="Status" cellRendererParams={{productId: '1'}} cellRenderer="totalValueRenderer" ></AgGridColumn>

          </AgGridReact>
        </div>

      ) : (
        // <Skeleton count={50} />
        <ShowLoadingOrEmpty message='No Products Found' />
      )
      }
        
              <div className={classes.seeMore}>
              <Link color='primary' href='#' onClick={loadMore}>
              load More Staff
              </Link>
              </div>
              <AddStaff
                open={addOpen}
                handleClose={closeAddDialog}
                editStockData={currentApp}Total
              />
              {open && (
                <FilterDaybook
                open={open}
                data={filterData}
                handleClose={() => handleClose(!open)}
                filterProducts={filterProducts}
                />
                )}
                {/* {openImage && (
                  <ImageDialog
                  open={openImage}
                  handleClose={() => setOpenImage(false)}
                  image={image}
                  />
                )} */}

            <AddContact
            open={addOpenContact}
             handleClose={closeAddContact}
            editStockData={currentVendor}
              />
                </Paper>
                </Card>
                )
              }
              