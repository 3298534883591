import React, { useEffect, useRef, useState } from "react";
import Link from "@material-ui/core/Link";
import "react-tabs/style/react-tabs.css";
import axios from "axios";

import CardHeader from 'components/Card/CardHeader';
import {
  Tab,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
} from "@material-ui/core";
import FilterCreditors from "./Components/FilterCreditors";
import EditIcon from "@material-ui/icons/Edit";
import * as actions from "../../store/DashboardActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
// import ImageDialog from './Components/ImageDialog';
import ShowLoadingOrEmpty from "../../Dashboard/Components/ShowLoadingOrEmpty";
import Title from "../../Dashboard/Components/Title";
import { makeStyles } from '@material-ui/core/styles';
import PrintIcon from "@mui/icons-material/Print";
import api from "../../../api/api";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddStockAppMain from "../../Dashboard/modules/StockAppMain/Components/AddStockAppMain";
import NepaliDate from "nepali-date-converter";
import { GetRequest } from "plugins/axios";
import ProfitLoss from "./ProfitLoss";
import "./index.css";
// import AddProduct from './Components/AddProduct';
import Sales from "./Components/totalSales";
import Purchases from "./Components/totalPurchase";
import JournalEntry from "./Components/journalEntry";
import Ledger from "./Components/ledger";
import TrialBalance from "./Components/trialBalance";
import BalanceSheet from "./Components/balanceSheet";
import ProfitByBills from "./Components/ProfitByBills";
import MonthlyReports from "./Components/MonthlyReports";
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import Calendar from '@sbmdkl/nepali-datepicker-reactjs';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from "components/Card/Card";
import ReactToPrint from "react-to-print";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    marginBottom: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 50,
  },
  button: {
    margin: 10,
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

export default function Reports() {
  const history = useHistory();

  const [axiosToken, setAxiosToken] = useState("");
  const classes = useStyles();
  const [products, setProducts] = useState([0, 0, 0, 0, 0, 0]);
  const [creditTransaction, setCreditTransaction] = useState([]);
  const [casId, setcasId] = useState(0);
  const [savedCreditors, setSavedCreditors] = useState([]);
  const [casCr, setCasCr] = useState(0);
  const [casPa, setCasPa] = useState(0);
  const [salesTotal, setRemaningTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const [openBill, setOpenBill] = useState(false);
  const [creditTotal, setCreditTotal] = useState(0);
  const [paidTotal, setPaidTotal] = useState(0);
  const [reports, setReports] = useState([]);
  const [image, setImage] = useState("");
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const [addOpen, setAddOpen] = useState(false);
  const [currentApp, setCurrentApp] = useState("");
  const { categoryId } = useParams();
  const salescasid = useSelector((state) => state.dashboardReducer.salescasid);
  const [dateString, setDateString] = useState('');
  const [today, setToday] = useState("2078/12/09");
  const componentRef =useRef();
  const [dateswitch, setDateswitch] = useState();
  const [thismonth, setThismonth] = useState("2078/12/09");
  const [thisyear, setThisyear] = useState("2078/12/09");
  const [thisweek, setThisWeek] = useState("2078/12/09");
  const [baishakh, setBaishakh] = useState("2078/01/09");
  const [jestha, setJestha] = useState("2078/01/09");
  const [ashadh, setAshadh] = useState("2078/01/09");
  const [shrawan, setShrawan] = useState("2078/01/09");
  const [bhadra, setBhadra] = useState("2078/01/09");
  const [ashwin, setAshwin] = useState("2078/01/09");
  const [kartik, setKartik] = useState("2078/01/09");
  const [mangsir, setMangsir] = useState("2078/01/09");
  const [poush, setPoush] = useState("2078/01/09");
  const [magh, setMagh] = useState("2078/01/09");
  const [falgun, setFalgun] = useState("2078/01/09");
  const [chaitra, setChaitra] = useState("2078/01/09");
  const [yesterdate, setYesterdate] = useState("");
  const [twomorro, setTwomorro] = useState("");
  useEffect(() => {
    console.log("date string", dateString);
    setToday(`${dateString}||${dateString}`);
    setThisWeek(
      `${dateString.split("/")[0]}/${dateString.split("/")[1]}/${
        dateString.split("/")[2] - 7
      }||${dateString}`
    );
    setThismonth(
      `${dateString.split("/")[0]}/${dateString.split("/")[1]}/01||${
        dateString.split("/")[0]
      }/${dateString.split("/")[1]}/32`
    );
    
    setBaishakh(
      `${dateString.split("/")[0]}/01/01||${dateString.split("/")[0]}/01/32`
    );
    setJestha(
      `${dateString.split("/")[0]}/02/01||${dateString.split("/")[0]}/02/32`
    );
    setAshadh(
      `${dateString.split("/")[0]}/03/01||${dateString.split("/")[0]}/03/32`
    );
    setShrawan(
      `${dateString.split("/")[0]}/04/01||${dateString.split("/")[0]}/04/32`
    );
    setBhadra(
      `${dateString.split("/")[0]}/05/01||${dateString.split("/")[0]}/05/32`
    );
    setAshwin(
      `${dateString.split("/")[0]}/06/01||${dateString.split("/")[0]}/06/32`
    );
    setKartik(
      `${dateString.split("/")[0]}/07/01||${dateString.split("/")[0]}/07/32`
    );
    setMangsir(
      `${dateString.split("/")[0]}/08/01||${dateString.split("/")[0]}/08/32`
    );
    setPoush(
      `${dateString.split("/")[0]}/09/01||${dateString.split("/")[0]}/09/32`
    );
    setMagh(
      `${dateString.split("/")[0]}/10/01||${dateString.split("/")[0]}/10/32`
    );
    setFalgun(
      `${dateString.split("/")[0]}/11/01||${dateString.split("/")[0]}/11/32`
    );
    setChaitra(
      `${dateString.split("/")[0]}/12/01||${dateString.split("/")[0]}/12/32`
    );
    setThisyear(
      `${dateString.split("/")[0]}/01/01||${dateString.split("/")[0]}/12/32`
    );
  }, [dateString]);

  const DurationSelectorHandler=(event)=>{
    console.log("🚀 ~ file: index.jsx ~ line 129 ~ DurationSelectorHandler ~ dates", event.target.value)
  setDateswitch(event.target.value);
    let date = event.target.value.split('||')
    console.log('///datesfrom',date[0]);
    console.log('///datesto',date[1]);
    setFilterData({...filterData,subdomain,dateString:date[0],odString:date[0],endDate:date[1]})
  }

  const creditorsList = useSelector(
    (state) => state.dashboardReducer.creditors
  );
  const subdomain = useSelector((state) => state.authReducer.user.subdomain);
  const monthlyReport = useSelector(
    (state) => state.dashboardReducer.monthlyReport
  );
  // console.log("subdomain------------------",subdomain);

  const gotoRoute = (to) => {
    history.push(to);
  };
  const [filterData, setFilterData] = useState({
    subdomain,
    dateString: "2078/04/11",
    endDate: "2079/12/32",
    odString: "2079/04/01",
    page: 0,
    limit: 10,
    bill_type:'SALE'
  });

  useEffect(() => {
    if (categoryId) {
      setFilterData({
        ...filterData,
        subdomain,
        categoryId: categoryId,
      });
    }
    //eslint-disable-next-line
  }, [categoryId]);

  useEffect(() => {
    let remaingTotal = 0;
    let creditTotal = 0;
    let paidTotal = 0;
    reports
      .filter(
        (data) =>
          data.bill_type == "PURCHASE" ||
          data.bill_type == "EXPENSE" ||
          data.bill_type == "OPENING"
      )
      .forEach((d) => {
        paidTotal += parseInt(d.balance);
      });
    reports
      .filter(
        (data) =>
          data.bill_type == "SALE" ||
          data.bill_type == "CLOSING" ||
          data.bill_type == "SAVING"
      )
      .forEach((d) => {
        remaingTotal += parseInt(d.balance);
      });
    setRemaningTotal(remaingTotal);
    setPaidTotal(paidTotal);
  }, [reports]);

  useEffect(() => {
    setProducts(creditorsList);
    setSavedCreditors(creditorsList);
  }, [creditorsList]);

  useEffect(() => {
    console.log("monthlyReport", monthlyReport);
    setProducts(monthlyReport);
  }, [monthlyReport]);

  const handleClose = (value) => {
    setOpen(value);
  };

  const loadMore = (event) => {
    event.preventDefault();
    setFilterData({
      ...filterData,
      subdomain,
      page: filterData.page + 1,
    });
  };
  // const getReports = () => {
  //   const nepaliDate = new NepaliDate();

  //   GetRequest(`${api.profitLossAPI}`, {
  //     subdomain,
  //   })
  //     .then((res) => {
  //       setReports(res.data.content);
  //       // console.log(res.data.content);
  //       // setSavings(res.data.content);
  //     })
  //     .catch((err) => {
  //       alert("Error Geting Dashboard Card");
  //     });
  // };

  useEffect(() => {
    let newlist = savedCreditors.filter(
      (cas) => cas.fname?.toLowerCase().includes(search)
      // // console.log("creditors",cas)
    );
    // console.log(savedCreditors);
    // console.log("search...........",search);
    setProducts(newlist);
  }, [search]);


  const filterProducts = (data) => {
    setFilterData({
      ...filterData,
      subdomain,
      page: 0,
      ...data,
    });
    handleClose(!open);
  };

  const handleDataChange = (e) => {
    setFilterData({
      ...filterData,
      page: 0,
      [e.target.name]: e.target.value,
    });
  };
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    let salescas_cr = 0;
    let salescas_pa = 0;
    salescasid.map((row) => {
      salescas_cr += parseInt(row.net_amount ? row.net_amount : 0);
      salescas_pa += parseInt(row.paid_amount ? row.paid_amount : 0);
    });
    setCasCr(salescas_cr);
    setCasPa(salescas_pa);
  }, [salescasid]);
  const handleChange = (e, productId, index) => {
    // console.log(products);
    // console.log(e.target.checked, { index });
    const newList = products;
    newList[index] = { ...newList[index], status: e.target.checked ? 1 : 0 };
    setProducts([...newList]);
    dispatch(
      actions.update_product_status(productId, e.target.checked ? 1 : 0)
    );
  };
  const openAddDialog = (app) => {
    setCurrentApp(app);
    setAddOpen(true);
  };
  const closeAddDialog = () => {
    setCurrentApp("");
    setAddOpen(false);
  };
  const [value, setValue] = React.useState("1");

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
    <Card>
          <CardHeader style={{display:'flex',justifyContent:'flex-start',flexDirection:'column'}} color="primary">
            <div style={{display:'flex',justifyContent:'space-between'}}>
            <h4 className={classes.cardTitleWhite}>Report</h4>
            <p className={classes.cardCategoryWhite} style={{display:'flex',flexDirection:'row'}}>
            <Paper padder style={{display:'flex',flexDirection:'row',padding:6,paddingLeft:0,paddingRight:0,marginLeft:6}}>
              <SkipPreviousIcon sx={{ color: '#ccc'}}fontSize='large'/>
               
              <Calendar hideDefaultValue={false} placeholder="Start Date" value={filterData.dateString} key={filterData} language="en" theme="deepdark" style={{color:'#000',border:0,display:'inline',textAlign:'center',minWidth:'300px'}} dateFormat="YYYY/MM/DD" onChange={(date) => {setFilterData({...filterData,subdomain,dateString:date.bsDate,odString:date.bsDate})}}> </Calendar>
              </Paper>
              <Paper padder style={{display:'flex',flexDirection:'row',padding:5,paddingLeft:0,paddingRight:0,marginLeft:5}}>
              <Calendar hideDefaultValue={true} placeholder="End Date" value={filterData.endDate} key={filterData} language="en" theme="deepdark" style={{color:'#000',border:0,display:'inline',textAlign:'center',minWidth:'300px'}} dateFormat="YYYY/MM/DD" onChange={(date) => {setFilterData({...filterData,subdomain,endDate:date.bsDate})}}> </Calendar>
              <SkipNextIcon sx={{ color: '#ccc'}}fontSize='large'/>
               </Paper>
            </p>
              </div>
              <div style={{display:'flex',justifyContent:'flex-start'}}>
              <Paper>
              <ReactToPrint
                trigger={() => (
                  <Button className={classes.button}>
                    <PrintIcon color="#fff"/>
                  </Button>
                )}
                content={() => componentRef.current}
              />
              </Paper>
              <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', width: 400,marginRight:5, }}
    >
              <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="See Transaction"
        inputProps={{ 'aria-label': 'See Transaction' }}
      />
      <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      </Paper>
      <Paper padder style={{paddingLeft:10,paddingRight:10,marginLeft:10}}>
        <FormControl 
        variant='standard'
         sx={{ m: 1,padding:10, minWidth: 400,borderBottom:0 }} fullWidth>
         {/* <InputLabel id="demo-simple-select-label">Interval</InputLabel>  */}
        <Select
      selectedValue={dateswitch}
      value={dateswitch}
      style={{borderBottom:0}}
      onChange={(event)=>DurationSelectorHandler(event)}
      >
      <MenuItem selected label="Today" value={today}>
       Today
      </MenuItem>
      <MenuItem label="This Week" value={thisweek}>
       This Week
       </MenuItem>
       <MenuItem label="This Month" value={thismonth}>
        This Month
       </MenuItem>
       <MenuItem label="Baishakh" value={baishakh}>
        Baishakh
       </MenuItem>
       <MenuItem label="Jestha" value={jestha}>
        Jestha
       </MenuItem>
       <MenuItem label="Ashadh" value={ashadh}>
        Ashadh
       </MenuItem>
       <MenuItem label="Shrawan" value={shrawan}>
        Shrawan
       </MenuItem>
       <MenuItem label="Bhadra" value={bhadra}>
        Bhadra
       </MenuItem>
       <MenuItem label="Ashwin" value={ashwin}>
        Ashwin
       </MenuItem>
       <MenuItem label="Kartik" value={kartik}>
        Kartik
       </MenuItem>
       <MenuItem label="Mangsir" value={mangsir}>
        Mangsir
       </MenuItem>
       <MenuItem label="Poush" value={poush}>
        Poush
       </MenuItem>
       <MenuItem label="Magh" value={magh}>
        Magh
       </MenuItem>
       <MenuItem label="Falgun" value={falgun}>
        Falgun
       </MenuItem>
       <MenuItem label="Chaitra" value={chaitra}>
        Chaitra
       </MenuItem>

        <MenuItem label="This Year" value={thisyear}>
         This Year
        </MenuItem>
      </Select>

      </FormControl>
    
      </Paper>
    </div>
    </CardHeader>
    
    <Paper>
      <div ref={componentRef}> 
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange}>
              <Tab label={"Sales"} value="1" />
              <Tab label={"Purchase"} value="2" />
              <Tab label={"Expense"} value="2.1" />
              <Tab label={"Journal Entry"} value="3" />
              <Tab label={"Monthly Sales"} value="4"/>
              <Tab label={"Monthly Purchase"} value="4.1"/>
              <Tab label={"Monthly Expense"} value="4.2"/>
              <Tab label={"Profit By Bills"} value="5"/>
              <Tab label={"Trial Balance"} value="8" />
              <Tab label={"Profit And Loss"} value="7"/>
              <Tab label={"Balance Sheet"} value={"6"}/>
            </TabList>
          </Box>
          <TabPanel value="1">
            <Sales startDate={filterData.dateString} endDate={filterData.endDate} BILLTYPE="SALE"/>
          </TabPanel>
          <TabPanel value="2">
            <Sales startDate={filterData.dateString} endDate={filterData.endDate} BILLTYPE="PURCHASE"/>
          </TabPanel>
          <TabPanel value="2.1">
            <Sales startDate={filterData.dateString} endDate={filterData.endDate} BILLTYPE="EXPENSE"/>
          </TabPanel>
          <TabPanel value="3">
            <JournalEntry />
          </TabPanel>
          <TabPanel value="4">
            <MonthlyReports BILLTYPE="SALE" />
          </TabPanel> 
          <TabPanel value="4.1">
            <MonthlyReports BILLTYPE="PURCHASE" />
          </TabPanel> 
          <TabPanel value="4.2">
            <MonthlyReports BILLTYPE="EXPENSE" />
          </TabPanel> 
          {/* <TabPanel>
            <Ledger />
          </TabPanel>
          <TabPanel>
            <TrialBalance />
          </TabPanel>*/}
          <TabPanel value="7">
            <ProfitLoss startDate={filterData.dateString} endDate={filterData.endDate} />
          </TabPanel>
          <TabPanel value="6">
            <BalanceSheet />
          </TabPanel>
          <TabPanel value="5">
            <ProfitByBills startDate={filterData.dateString} endDate={filterData.endDate} />
          </TabPanel>
        </TabContext>
      </Box>
        </div>
      {/* <div style={{
          padding: 10,
          float: 'left',
          display: 'flex',
        }}>
          <Card style={{
            padding: 10,
            borderRadius: 10,
            borderLeft: `4px solid red`,
            width: '50%',
            margin: 2,
            marginBottom: 3,
          }}
          >
            <div onClick={() => gotoRoute('/inventory/CASH_FLOW')}>
              <div style={{ fontSize: 18 }}>Sale</div>
            </div>
          </Card>
          <Card style={{
            padding: 10,
            borderRadius: 10,
            borderLeft: `4px solid red`,
            width: '50%',
            margin: 2,
            marginBottom: 3,
          }}
          >
            <div onClick={() => gotoRoute('/inventory/CASH_FLOW')}>
              <div style={{ fontSize: 18 }}>Purchase</div>
            </div>
          </Card>
          <Card style={{
            padding: 10,
            borderRadius: 10,
            borderLeft: `4px solid red`,
            width: '50%',
            margin: 2,
            marginBottom: 3,
          }}
          >
            <div onClick={() => gotoRoute('/inventory/CASH_FLOW')}>
              <div style={{ fontSize: 18 }}>Journal Entry</div>
            </div>
          </Card>
          <Card style={{
            padding: 10,
            borderRadius: 10,
            borderLeft: `4px solid red`,
            width: '50%',
            margin: 2,
            marginBottom: 3,
          }}
          >
            <div onClick={() => gotoRoute('/inventory/CASH_FLOW')}>
              <div style={{ fontSize: 18 }}>Ledger</div>
            </div>
          </Card>
          <Card style={{
            padding: 10,
            borderRadius: 10,
            borderLeft: `4px solid red`,
            width: '50%',
            margin: 2,
            marginBottom: 3,
          }}
          >
            <div onClick={() => gotoRoute('/inventory/CASH_FLOW')}>
              <div style={{ fontSize: 18 }}>Trial Balance</div>
            </div>
          </Card>
          <Card style={{
            padding: 10,
            borderRadius: 10,
            borderLeft: `4px solid red`,
            width: '50%',
            margin: 2,
            marginBottom: 3,
          }}
          >
            <div onClick={() => gotoRoute('/inventory/CASH_FLOW')}>
              <div style={{ fontSize: 18 }}>Profit And Loss</div>
            </div>
          </Card>
          <Card style={{
            padding: 10,
            borderRadius: 10,
            borderLeft: `4px solid red`,
            width: '50%',
            margin: 2,
            marginBottom: 3,
          }}
          >
            <div onClick={() => gotoRoute('/inventory/CASH_FLOW')}>
              <div style={{ fontSize: 18 }}>Balance Sheet</div>
            </div>
          </Card>
        </div> */}
    </Paper>
    </Card>

    </>
  );
}
