import React, { useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../../../Dashboard/Components/Title';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/DashboardActions';
import {  Paper ,Button} from '@material-ui/core';
import ShowLoadingOrEmpty from '../../Components/ShowLoadingOrEmpty';
import Add from '@material-ui/icons/Add';
import { Search } from '@material-ui/icons';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Calendar from '@sbmdkl/nepali-datepicker-reactjs';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { AgGridColumn } from 'ag-grid-react/lib/agGridColumn';
import UnstyledSwitcher from 'components/UnstyledSwitcher';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  headerButton: {
    margin: 10,
    color:"#fff",
  },
}));


export default function Vendor() {
  const [floatingSearch, setFloatingSearch] = useState(true);
  const [startDate, setStartDate] = useState("2078/01/01");
  const [endDate, setEndDate] = useState("2078/12/32");
  const [addOpen, setAddOpen] = useState(false);
  const [currentApp, setCurrentApp] = useState('');
  const classes = useStyles();
  const [vendors, setVendors] = useState([]);
  const vendorList = useSelector((state) => state.dashboardReducer.vendors);
  const [products, setProducts] = useState([]);
  


  const TotalValueRenderer = (props, index) => {
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    return (
      <span style={{ display: "flex" }}>
        {/* <span>{cellValue}</span>&nbsp;
        <UnstyledSwitcher
          defaultValue={products.status === 1}
          checked={products.status === 1}
          onChange={(e) => handleChange(e, props.data.product_id, index)}
        />
        <Button
          className={classes.button}
          startIcon={<EditIcon />}
          onClick={() => openAddDialog(props.data)}
        />
        <Button className={classes.button} startIcon={<VisibilityIcon />} 
        // onClick={() => console.log({ pathname: '/main/productDetail/'}) 
        onClick={() => history.push({ pathname: '/main/productDetail/', product_id: props.data.product_id }) || <Skeleton />} /> */}

      </span>
    );
  };
  

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.get_vendors());
  }, [dispatch]);

  useEffect(() => {
    setVendors(vendorList);
  }, [vendorList]);

  const openAddDialog = (app) => {
    setCurrentApp(app);
    setAddOpen(true);
  };

  return (
    <Card>
    <CardHeader  style={{display:'flex',justifyContent:'space-between',flexDirection:'column'}} color="primary">



      <h4 style={{ color: "#fff", fontWeight: "400" }}>Vendor List</h4>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="justify-between">
          <Button onClick={()=>openAddDialog(false)} className={classes.headerButton}>
          <Add color="#ffff"/>
          </Button>
          
          <Button
            className={classes.headerButton}
          >
            <DownloadForOfflineIcon color="#ffff"/>
          </Button>
        </div>
        <span className="date-selector" style={{ margin: "10px" }}>

<ArrowBackIosIcon
style={{ height: "15px", marginTop: "10px" }}
/>
<Calendar
style={{
  width: "271px",
  borderColor: "#0000",
  backgroundColor: "#0000",
  borderBottom: "1px solid #929292",
  fontWeight: 700,
}}
language="en"
theme="default"
dateFormat="YYYY/MM/DD"
onChange={(date) => {
  setStartDate(date.bsDate);
}}
//defaultDate={startDate}
/>
<Button style={{color:'#fff'}}>To</Button>
<Calendar
style={{
  width: "271px",
  borderColor: "#0000",
  backgroundColor: "#0000",
  borderBottom: "1px solid #929292",
  fontWeight: 700,
}}
language="en"
theme="default"
dateFormat="YYYY/MM/DD"
onChange={(date) => {
  setEndDate(date.bsDate);
}}
minDate={startDate}
//defaultDate={endDate}
/>

{/* <DateRangePicker
ranges={[selectionRange]}
onChange={this.handleSelect}
/> */}
<ArrowForwardIosIcon
style={{ height: "15px", marginTop: "10px" }}

/>
</span>
      </div>
</CardHeader>
<Paper className={classes.paper}>
  <div className="ag-theme-material" style={{
          height: '100vh',
          width: '100%'
        }} >
<AgGridReact
getRowStyle={(params)=>{
  if (params.node.rowIndex % 2 === 0) {
    return { background: '#f6f8fb' };
}
}}
            rowData={vendorList}
            defaultColDef={{
              flex: 1,
              minWidth: 200,
              editable: false,
              resizable: true,
              sortable: true,
              floatingFilter: floatingSearch,
            }}
          >

            <AgGridColumn headerName="Id" suppressMenu={true} resizable={true} field="vendor_id" sortable={true} filter={true} ></AgGridColumn>
            <AgGridColumn headerName="Store Name" suppressMenu={true}  field="store_name" sortable={true} filter={true} ></AgGridColumn>
            <AgGridColumn headerName="Owner" suppressMenu={true} resizable={true} field="store_owner" sortable={true} filter={true} ></AgGridColumn>
            <AgGridColumn headerName="Meta Title" suppressMenu={true} resizable={true} field="meta_description" sortable={true} filter={true} ></AgGridColumn>
            <AgGridColumn headerName="Phone Number" suppressMenu={true} resizable={true} field="telephone" sortable={true} filter={true} ></AgGridColumn>
            {/* <AgGridColumn headerName="City" cellRendererParams={{productId: '1'}} cellRenderer="telephone" ></AgGridColumn> */}

          </AgGridReact>

      {/* <Title>ag end</Title>
      {vendors.length > 0 ? (
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Store Name</TableCell>
              <TableCell>Owner</TableCell>
              <TableCell>Meta Title</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>City</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vendors.map((row) => (
              <TableRow key={row.vendor_id}>
                <TableCell>{row.vendor_id}</TableCell>
                <TableCell>{row.store_name}</TableCell>
                <TableCell>{row.store_owner}</TableCell>
                <TableCell>{row.meta_description}</TableCell>
                <TableCell>{row.telephone}</TableCell>
                <TableCell>{row.city}</TableCell>
              </TableRow>
            ))}
          </TableBody>
            </Table>
      ) : (
        <ShowLoadingOrEmpty message='No Vendors Found' />
      )
      } */}
      </div>
    </Paper>
    </Card>
  );
  }
