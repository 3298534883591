import * as actionTypes from './AuthTypes';
const initialState = {
  isAuthenticated: false,
  loading: false,
  user: {},
  subdomain: {},
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('subdomain', action.payload.user.subdomain);
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.payload.user,
      };
    case actionTypes.SUBDOMAIN:
      localStorage.setItem('subdomain', action.payload.user.subdomain);
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        subdomain: action.payload.subdomain,
      };
    case actionTypes.LOGOUT:
      return initialState;
    case actionTypes.GET_USER:
      return {
        ...state,
        users: action.payload,
      };
    case actionTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
export default AuthReducer;
