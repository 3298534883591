import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
// core components
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import AddBillItems from "modules/inventory/BillEdit/components/AddBillItems";

const useStyles = makeStyles(styles);

export default function CustomInput(props) {
  const productList = useSelector((state) => state.dashboardReducer.products);
  const filter = createFilterOptions();

  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    success,
    rtlActive,
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
    [" " + classes.labelRTL]: rtlActive,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
  });
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined,
  });
  let newInputProps = {
    maxLength:
      inputProps && inputProps.maxLength ? inputProps.maxLength : undefined,
    minLength:
      inputProps && inputProps.minLength ? inputProps.minLength : undefined,
    step: inputProps && inputProps.step ? inputProps.step : undefined,
  };
  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + " " + classes.formControl}
    >
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      {/* <Input
        classes={{
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses,
        }}
        id={id}
        {...inputProps}
        inputProps={newInputProps}
      /> */}
      
      <Autocomplete
        fullWidth
        // popper={{width:1000,backgroundColor:'red'}}
        style={{ borderBottom: "none", }}
        options={productList}
        getOptionLabel={(option) => "#" + option.product_id?.toString() + " " + option.name?.toString() + "(" + option.model?.toString() + ") | Stock: " + ((option.stock == undefined) ? 0 : option.stock?.toString()) + " | SP : " + option.discounted?.toString()}

        selectOnFocus
        clearOnBlur
        handleHomeEndKeys


        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push(
              {
                inputValue: params.inputValue,
                product_id: "",
                name: `Add "${params.inputValue}"`,
                model: "",
                stock: "",
                discounted: ""
              }
            );
          }

          return filtered;
        }}
        renderInput={(params) =>

          <Input 
          // style={{ padding: "15px" }}
          classes={{
            root: marginTop,
            disabled: classes.disabled,
            underline: underlineClasses,
          }}
            {...params} 
            // value={product_id}
            // variant="outlined"
            // onChangeText={e => changeTextProductSearch(e.target.value)} 
            />}

      />
      {/* <AddBillItems style={{Width:'500px'}} classes={{
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses,
        }} />  */}
      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  rtlActive: PropTypes.bool,
};
