import {
  BILL_ADD,
  BILL_UPDATE,
  BILL_CLEAR,
  BILL_REMOVE_ADD_ITEMS,
  BILL_REMOVE_UPDATE_ITEMS,
} from './billTypes';

const initialState = {
  addItems: [],
  updateItems: [],
};

export const billReducer = (state = initialState, action) => {
  switch (action.type) {
    case BILL_ADD:
     let addItems= [...state.addItems, action.data]
      let newaddItems = addItems.filter((c, index) => {
        return addItems.indexOf(c) === index;
       }); 
      return {
        ...state,
        addItems: newaddItems,
      };
    case BILL_UPDATE:
      let newArray = state.updateItems;
      let objIndex = state.updateItems.findIndex(
        (obj) => obj.id === action.data.id
      );
      if (objIndex == -1) {
        objIndex = state.updateItems.length > 0 ? state.updateItems.length : 0;
      }
      newArray[objIndex] = action.data;
      console.log({
        ...state,
        updateItems: newArray,
      });
      return {
        ...state,
        updateItems: newArray,
      };

    case BILL_REMOVE_ADD_ITEMS:
      let newAddArray = state.addItems;
      newAddArray.splice(action.data, 1);
      return {
        ...state,
        addItems: [...newAddArray],
      };
    case BILL_REMOVE_UPDATE_ITEMS:
      let newupdateArray = state.addItems;
      newupdateArray.splice(action.data, 1);
      return {
        ...state,
        updateItems: newupdateArray,
      };
    case BILL_CLEAR:
      // console.log('CLEAR');
      return {
        ...state,
        addItems: [],
        updateItems: [],
      };
    default:
      return state;
  }
};
