import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import ReactToPrint from "react-to-print";
import { downloadCSVFromJson } from "utils/DownloadCSV";
import { Button, Paper, TextField, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ShowLoadingOrEmpty from "../../../Dashboard/Components/ShowLoadingOrEmpty";
import FilterCreditors from "./FilterCreditors";
import BillDetaildiv from "../../daybook/Components/BillDetailView";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Link from "@material-ui/core/Link";
import NepaliDate from "nepali-date-converter";
import * as actions from "../../../store/DashboardActions";
import {
  Chart,
  PieSeries,
  Title,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
} from "@devexpress/dx-react-chart-material-ui";
import api from "api/api";
import { GetRequest } from "plugins/axios";
import CustomerList from "./CustomerList";
import NumberFormat from "utils/NumberFormat";
import { numToWords } from "utils/NumberFormat";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  button: {
    margin: 10,
  },
}));

export default function IndividualLedger({ casId, setCasId, salescasid,BILLTYPE, startDate,
  endDate, }) {
  const [axiosToken, setAxiosToken] = useState("");
  const classes = useStyles();
  //   const salescasid = useSelector((state) => state.dashboardReducer.salescasid);
  const subdomain = useSelector((state) => state.authReducer.user.subdomain);
  const componentRef = useRef();
  const [customer, setCustomer] = useState([]);
  const [product, setProduct] = useState();
  const [salesChart, setSalesChart] = useState([
    { sales: "Credit", net_amount: 12 },
    { sales: "Cash", net_amount: 7 },
  ]);
  const [salesBarChart, setSalesBarChart] = useState([]);
  const [bill_type, setBill_type] = useState(BILLTYPE);
  const nepaliDate = new NepaliDate();

  const [image, setImage] = useState("");
  const [openBill, setOpenBill] = useState(false);
  const [casCr, setCasCr] = useState(0);
  const [creditArr, setCreditArr] = useState([]);
  const [casPa, setCasPa] = useState(0);
  const [open, setOpen] = useState(false);
  const [savedCreditors, setSavedCreditors] = useState([]);
  const [creditTotal, setCreditTotal] = useState(0);
  const [paidTotal, setPaidTotal] = useState(0);

  const [products, setProducts] = useState([0, 0, 0, 0, 0, 0]);
  //   const [casId, setCasId] = useState(0);
  const [remaningTotal, setRemaningTotal] = useState(0);
  const dispatch = useDispatch();
  console.log("salescasid:::", salescasid);
  useEffect(() => {
    let remaingTotal = 0;
    let creditTotal = 0;
    let paidTotal = 0;
    let creditArr = [];
    salescasid
  //   .filter((person) =>
  //   person.BR == "CR" ||
  //   person.bill_type == "RECEIPT"
  // )
    //   .filter((person) =>
    //     bill_type == "PURCHASE"
    //       ? person.bill_type == "PURCHASE" ||
    //         person.bill_type == "VOUCHER"
    //       : person.bill_type == "SALE" ||
    //         person.bill_type == "RECEIPT"
    //   )
      .forEach((d) => {
      remaingTotal += parseInt(d.bill_type == "SALE" || d.bill_type == "RECEIPT" ? parseInt(d?.net_amount) || 0 : parseInt(d?.paid_amount) || 0)|| 0;
      creditTotal += parseInt(d.bill_type == "SALE" || d.bill_type == "RECEIPT" ? parseInt(d?.net_amount) || 0 : parseInt(d?.paid_amount) || 0) || 0;
      paidTotal += parseInt(d.bill_type == "SALE" || d.bill_type == "RECEIPT" ? parseInt(d?.paid_amount) || 0 : parseInt(d?.net_amount) || 0) || 0;
      creditArr.push(creditTotal - paidTotal);
    });
    setRemaningTotal(remaingTotal);
    setCreditTotal(creditTotal);
    setPaidTotal(paidTotal);
    setCasCr(creditTotal)
    setCreditArr(creditArr)
    setCasPa(paidTotal)
    setSalesChart([
      { sales: "Credit", net_amount: 12 },
      { sales: "Cash", net_amount: 13 },
    ]);
  }, [salescasid]);

  useEffect(() => {
    let TotalCredit = 0;
    let TotalCash = 0;
    salescasid
      .filter((d) => "CR" == d.BR)
      .map((d) => {
        TotalCredit += parseInt(d.net_amount);
      });
    salescasid
      .filter((data) => "C" == data.BR)
      .map((d) => {
        TotalCash += parseInt(d.net_amount);
      });

    setSalesChart([
      { sales: "Credit", net_amount: TotalCredit },
      { sales: "Cash", net_amount: TotalCash },
    ]);
  }, [salescasid]);

  useEffect(() => {
    let bill_date= [];
    let amount = [];
    salescasid.map((d) => {
      console.log("🚀 ~ file: IndividualSales.js ~ line 134 ~ salescasid.map ~ d", d)
      // amount += Option.assign(d.bill_date);
      bill_date.push(d.bill_date);                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
      amount.push(d.net_amount);                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
    });
    console.log("🚀 ~ file: IndividualSales.js ~ line 148 ~ IndividualSales ~ amount", amount)
      console.log("🚀 ~ file: IndividualSales.js ~ line 147 ~ useEffect ~ bill_date", bill_date)
    // setSalesBarChart([
    //   // { argument: ["bill_date"], value: amount },
    //   {argument:...bill_date, ...amount}
    // ]);
  }, [salescasid]);

  const loadMore = (event) => {
    event.preventDefault();
    setFilterData({
      ...filterData,
      subdomain,
      page: filterData.page + 1,
    });
  };

  const [filterData, setFilterData] = useState({
    subdomain,
    bill_type,
    dateString: "2078/04/11",
    odString: "2078/04/01",
    page: 0,
    limit: 10,
  });
  useEffect(() => {
    if (bill_type) {
      setFilterData({
        ...filterData,
        subdomain,
        bill_type: bill_type == "PURCHASE" ? "SALE" : "PURCHASE",
      });
      // console.log('bill_type----------',bill_type);
    }
    //eslint-disable-next-line
  }, [bill_type]);

  const handleClose = (value) => {
    setOpen(value);
  };

  const filterProducts = (data) => {
    setFilterData({
      ...filterData,
      subdomain,
      page: 0,
      ...data,
    });
    handleClose(!open);
  };

  const get_customer_details = (casId, subdomain) => {
    GetRequest(`${api.customerDetailsAPI}`, { cas_id: casId, subdomain })
      .then((res) => {
        setCustomer(res.data.content);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    const get_sales_cas_id = () => {
      // console.log(filterData);
      if (axiosToken) {
        // Cancel the previous request before making a new request
        axiosToken.cancel("operation canceled due to new request.");
      }
      let token = axios.CancelToken.source();
      setAxiosToken(token);
      dispatch(actions.get_sales_item_from_cas_id(casId, subdomain));
      get_customer_details(casId, subdomain);
      // console.log("salescasid",salescasid);
    };
    get_sales_cas_id();
    return () => {
      // console.log('clear');
    };
    // eslint-disable-next-line
  }, [casId, filterData]);


  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Grid container ref={componentRef}>
      <Grid item xs={12}>
     
          <div style={{ marginLeft: "20px", justifyContent: "space-between",borderLeft:'3px' }}>
            <div
              className={classes.paper}
              style={{ display: "flex", flexDirection: "column", }}
            >
              <div  style={{ display: "flex", flexDirection: "row",justifyContent:'space-between',alignItems:'center' }}>
              <div span={8} style={{ marginRight: "10px" }}>
                <h3 style={{ fontWeight: "400" }}>{customer?.fname?.toUpperCase()}  {customer?.lname?.toUpperCase()}</h3>
                {/* <div>{vendor?.panno}</div>
                <div>Kathmandu</div> */}

                {/* <div>
                  Name:{customer?.fname} {customer?.lname}
                </div> */}
                <div>Cas id:{customer?.cas_id} </div>
                <div>Contact No:{customer?.contact}</div>
                <div>Address:{customer?.address}</div>
              </div>

              
              {/* <Chart width={120} data = {salesChart}>
              <PieSeries
                valueField={'net_amount'}
                argumentField={'sales'}
              />
              <Title text="Sales Data" />
            </Chart> */}
              <div span={4}>
                <h4 style={{ fontWeight: "400",textAlign:'right' }}>
                  Remaining Balance:<NumberFormat amount={creditTotal - paidTotal} /><br/>
                  
                  {/* {parseInt(
                  (customer.net_amount || 0) - (customer.paid_amount || 0)
                )} */}
                </h4>
                <div style={{ fontWeight: "400",textAlign:'right' }}>

                {numToWords(creditTotal - paidTotal)}
                </div>
              </div>
              </div>
              {/* <Chart data={salesChart}>
                <PieSeries valueField={"net_amount"} argumentField={"sales"} />
                <Title text="Sales Data" />
              </Chart>
              <Chart data={salesBarChart}>
                <ArgumentAxis />
                <ValueAxis />

                <BarSeries valueField="value" argumentField="argument" />
              </Chart> */}
               <p style={{ paddingTop: "15px", fontWeight: "400" }}>
                Report Date is {startDate} - {endDate}
              </p>
              {true ? (
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Id</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Bill No</TableCell>
                      {/* <TableCell>Name</TableCell> */}
                      <TableCell align="right">Dr</TableCell>
                      <TableCell align="right">Cr</TableCell>
                      <TableCell align="right">Balance</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {salescasid
                  //   .filter((person) =>
                  //   person.BR == "CR" 
                  //   // || person.bill_type == "RECEIPT"
                  // )
                    //   .filter((person) =>
                    //     bill_type == "PURCHASE"
                    //       ? person.bill_type == "PURCHASE" ||
                    //         person.bill_type == "VOUCHER"
                    //       : person.bill_type == "SALE" ||
                    //         person.bill_type == "RECEIPT"
                    //   )
                      .map((row, index, bal) => (
                        <TableRow key={index}   
                        onClick={() => {
                          if(row.bill_date !='0pening Balance'){
                          setImage(row.connect_id);
                          setOpenBill(true);}else{
                            return false;
                          }
                        }}>
                          <TableCell>{index + 1}</TableCell> 
                          {row.bill_date !='0pening Balance' ? (
                          <TableCell>{`${row.bill_type}(${row.BR})`}</TableCell>) : (<></>)}
                           <TableCell colspan={row.bill_date !='0pening Balance' ? 1 : 2}>{`${row.bill_date}`}</TableCell>
                          <TableCell
                           
                          >
                            {row.connect_id}
                          </TableCell>
                          {/* <TableCell>{`${row.fname} ${row.lname} `}</TableCell> */}
                          <TableCell align="right">
                          {row.bill_date !='0pening Balance' ? ( <NumberFormat amount={row.bill_type == "SALE" || row.bill_type == "RECEIPT" ? parseInt(row?.net_amount) || 0 : parseInt(row?.paid_amount) || 0} />) : (<></>)}
                          </TableCell>
                          <TableCell align="right">
                          {row.bill_date !='0pening Balance' ? (<NumberFormat amount={row.bill_type == "SALE" || row.bill_type == "RECEIPT" ? parseInt(row?.paid_amount) || 0 : parseInt(row?.net_amount) || 0} />) : (<></>)}
                          </TableCell>
                          <TableCell align="right">
                            <NumberFormat amount={
                              creditArr[index]
                            } />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>***</TableCell>
                      <TableCell>***</TableCell>
                      {/* <TableCell>***</TableCell> */}
                      <TableCell>Total</TableCell>
                      <TableCell align="right"><NumberFormat amount={casCr} /></TableCell>
                      <TableCell align="right"><NumberFormat amount={casPa} /></TableCell>
                      <TableCell align="right"><NumberFormat amount={casCr - casPa} /></TableCell>
                    </TableRow> 
                  </TableHead>
                </Table>
              ) : (
                <ShowLoadingOrEmpty message="No Items Found" />
              )}

              <div className={classes.seeMore}></div>
              {open && (
                <FilterCreditors
                  open={open}
                  data={filterData}
                  handleClose={() => handleClose(!open)}
                  filterProducts={filterProducts}
                />
              )}
              <BillDetaildiv
                open={openBill}
                connect_id={image}
                data={{ connect_id: image, bill_date: "2078/04/12" }}
                bill_date={"2078/04/12"}
                subdomain={subdomain}
                handleClose={() => setOpenBill(false)}
              />
              {/* <div className={classes.seeMore}>
                <Link color="primary" href="#" onClick={loadMore}>
                  See more orders
                </Link>
              </div> */}
              {open && (
                <FilterCreditors
                  open={open}
                  data={filterData}
                  handleClose={() => handleClose(!open)}
                  filterProducts={filterProducts}
                />
              )}
            </div>
          </div>
          </Grid>
      </Grid>
    </div>
  );
}
