import React, { useEffect, useState } from 'react';
import ShowLoadingOrEmpty from 'modules/Dashboard/Components/ShowLoadingOrEmpty';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { GetRequest } from 'plugins/axios';
import api from 'api/api';
import SingleRow from './Components/SingleRow';
import NumberFormat from 'utils/NumberFormat';
const { Grid, Paper,  } = require("@material-ui/core");

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
    seeMore: {
      marginTop: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      marginBottom:theme.spacing(1),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 100,
    },
    button: {
      margin: 10,
    },
  }));
export default function ProfitLoss({endDate,startDate}) {
    const [axiosToken, setAxiosToken] = useState('');
    const classes = useStyles();
    const [products, setProducts] = useState([0,0,0,0,0,0]);
    const [creditTransaction, setCreditTransaction] = useState([]);
    const [casId, setcasId] = useState(0);
    const [savedCreditors, setSavedCreditors] = useState([]);
    const [year, setCasCr] = useState('2078');
    const [month, setCasPa] = useState({id:'01',to:'12'});
    const [open, setOpen] = useState(false);
    const [openBill, setOpenBill] = useState(false);
    const [creditTotal, setCreditTotal] = useState(0);
    const [salesTotal, setSalesTotal] = useState(0);
    const [paidTotal, setPaidTotal] = useState(0);
    const [reports, setReports] = useState([]);
    const [image, setImage] = useState('');
    const [search, setSearch] = useState('');
    const dispatch = useDispatch();
    const [addOpen, setAddOpen] = useState(false);
    const [currentApp, setCurrentApp] = useState('');
    const { categoryId } = useParams();
    const salescasid = useSelector((state) => state.dashboardReducer.salescasid);
    const creditorsList = useSelector((state) => state.dashboardReducer.creditors);
    const subdomain = useSelector((state) => state.authReducer.user.subdomain);
    const [profitLossList, setProfitLossList] = useState([]);
    const [profitLossListSection, setProfitLossListSection] = useState([]);
    // console.log("subdomain------------------",subdomain);
    useEffect(() => {
      if (subdomain) {
        getProfitLoss();
      } 
    }, [subdomain,startDate,endDate]);
    useEffect(() => {
      let remaingTotal = 0;
      let salesTotal = 0;
      let paidTotal = 0;
      profitLossList.filter((data) => data.bill_type == 'PURCHASE' || data.bill_type == 'EXPENSE' || data.bill_type == 'OPENING').forEach((d) => {
        paidTotal += parseInt(d.amount);
      });
      profitLossList.filter((data) => data.bill_type == 'SALE' || data.bill_type == 'CLOSING'|| data.bill_type == 'SAVING').forEach((d) => {
        salesTotal += parseInt(d.amount);
      });
      
      setSalesTotal(salesTotal);
      setPaidTotal(paidTotal);
    }, [profitLossList]);
    const getProfitLoss = () => {
      GetRequest(`${api.profitLossAPI}`, { 
        subdomain,
        startDate,
        endDate, })
        .then((res) => {
          setProfitLossList(res.data.content);
        })
        .catch((err) => {
          console.log({
            text: 'Cannot get ProfitLoss',
          });
        });
    };
  return (

      <Grid
      //  item xs={8}
       >
      {/* <Paper className={classes.paper}>
       useEffect(() => {
        let remaingTotal = 0;
        let salesTotal = 0;
        let paidTotal = 0;
        profitLossList.filter((data) => data.bill_type == 'PURCHASE' || data.bill_type == 'EXPENSE' || data.bill_type == 'OPENING').forEach((d) => {
          paidTotal += parseInt(d.amount);
        });
        profitLossList.filter((data) => data.bill_type == 'SALE' || data.bill_type == 'CLOSING'|| data.bill_type == 'SAVING').forEach((d) => {
          salesTotal += parseInt(d.amount);
        });
        
        setSalesTotal(salesTotal);
        setPaidTotal(paidTotal);
      }, [profitLossList]);
      
      </Paper> */}
        Profit Loss for {startDate} to {endDate}

      <Paper style={{display:'flex',flexDirection:'row',}} className={classes.paper}>
      {/* <Button
        variant='contained'
        color='primary'
        startIcon={<AddCircleIcon />}
        onClick={() => openAddDialog()}
        >
        Add Items
      </Button> */}
      {/* {false ? (
        <Table sx={{ borderRight: 1}} size='small' style={{borderRight:'#000'}}>
        <TableHead>
        <TableRow>
        <TableCell>BILL</TableCell>
        <TableCell align='right'>AMOUNT</TableCell>
      </TableRow>
      </TableHead>
      <TableBody>
      {reports.filter((data) => data.bill_type == 'PURCHASE' || data.bill_type == 'EXPENSE' || data.bill_type == 'OPENING').map((row, index) => (
        <TableRow key={row.product_id}>
        <TableCell>{row.BR == 'C' ? `${row.bill_type}` : `${row.bill_type} ` }</TableCell>
        <TableCell align='right'>{row.balance}</TableCell>
      </TableRow>
      ))}
      <TableRow>
      <TableCell>Profit </TableCell>
      <TableCell align='right'>{paidTotal < salesTotal? salesTotal -paidTotal  : 0 }</TableCell>
      </TableRow>
      <TableRow>
      <TableCell> </TableCell>
      <TableCell align='right'>{paidTotal < salesTotal? salesTotal : paidTotal }</TableCell>
      </TableRow>
      </TableBody>
      </Table>
      ) : (
        <ShowLoadingOrEmpty message='No Products Found' />
        )} */}
        
        {true ? (
          <Table size='small'>
          <TableHead>
          <TableRow>
          {/* <TableCell>ID</TableCell> */}
          <TableCell>BILL</TableCell>
          {/* <TableCell>Bill Type</TableCell> */}
          {/* <TableCell align='right'>Recieved Amount</TableCell> */}
          {/* <TableCell align='right'>BILL NO</TableCell> */}
          <TableCell align='right'>AMOUNT</TableCell>
          {/* <TableCell align='right'>Net Amount</TableCell>
        <TableCell align='right'>Balancee</TableCell> */}
        </TableRow>
        </TableHead>
        <TableBody>
        {profitLossList.map((row, index) => (
                <SingleRow data={row} />
                ))}
      {/* <TableRow>
        <TableCell>- </TableCell>
        <TableCell align='right'>-</TableCell>
      </TableRow> */}
      <TableRow>
      <TableCell>Loss </TableCell>
      <TableCell align='right'><NumberFormat amount={paidTotal > salesTotal? paidTotal - salesTotal : 0 } /></TableCell>
      </TableRow>
      <TableRow>
      <TableCell> Profit</TableCell>
      <TableCell align='right'><NumberFormat amount={paidTotal < salesTotal? salesTotal -paidTotal  : 0 } /></TableCell>
      </TableRow>
      </TableBody>
      </Table>
      ) : (
        <ShowLoadingOrEmpty message='No Products Found' />
        )}
        <div className={classes.seeMore}>
        </div>
        {/* <AddProduct
          open={addOpen}
          handleClose={closeAddDialog}
          editStockData={currentApp}
        /> */}
          </Paper>
          </Grid>
  );
}