import React, { useEffect, useState, useRef } from 'react';
import logo from '../../../../assets/icon.png';
import { useDispatch, useSelector } from 'react-redux';
import DialogContentText from '@mui/material/DialogContentText';
import api from '../../../../api/api'
import ReactDOMServer from "react-dom/server";
// import {
//   EmailShareButton,
//   FacebookShareButton,
//   HatenaShareButton,
//   InstapaperShareButton,
//   LineShareButton,
//   LinkedinShareButton,
//   LivejournalShareButton,
//   MailruShareButton,
//   OKShareButton,
//   PinterestShareButton,
//   PocketShareButton,
//   RedditShareButton,
//   TelegramShareButton,
//   TumblrShareButton,
//   TwitterShareButton,
//   ViberShareButton,
//   VKShareButton,
//   WhatsappShareButton,
//   WorkplaceShareButton
// } from "react-share";
import {
    Avatar,
    Button,
    FormControl,
    TextFieldLabel,
    MenuItem,
    Paper,
    Select,
    Switch,
    TextField,
    DialogContent,
    Dialog,
    DialogTitle,
    DialogActions,
    Container,
} from '@material-ui/core';
import { Col, Divider, Row as div, Row, Table } from 'antd';
import { GetRequest, PutRequest } from '../../../../plugins/axios';
import { makeStyles } from '@material-ui/core/styles';
import Document from './Document';
import Download from './DownloadPage';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Page } from '@react-pdf/renderer';
import ReactToPrint from 'react-to-print';
import capitalizeFirstLetter from 'utils/CapitalizeFirstLetter';
import { setBillAdd } from 'modules/inventory/BillEdit/store';
import { setToasterState } from 'common/Toast/store/ToasterAction';
import { useHistory } from 'react-router';
import NumberFormat from 'utils/NumberFormat';
import * as actions from 'modules/store/DashboardActions';
import { numToWords } from 'utils/NumberFormat';
var converter = require('number-to-words');
const SingleBill = ({heading,letterHead,vendor,salesData,items,discount}) =>{
  let days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
    const user = useSelector((state) => state.authReducer.user);
  const customerDetails =useSelector((state)=>state.dashboardReducer.customerProfile);
  const dispatch = useDispatch();
  let toDate = new Date();
    useEffect(() => {
        dispatch(actions.get_customerProfile({cas_id:salesData?.cas_id,page:0}));
        // dispatch(actions.get_staff(0,filterData,token));
    
      }, [salesData]);
    return(
        <div>
{letterHead ?(
        <div style={{ position: "relative" }}>
                    <b>{vendor?.mainappname}</b>
                    <table style={{ display: "flex", flexDirection: "column", fontSize: "initial", fontWeight: "100" }}>
                    <tr>
                    <th>{salesData?.BR} {salesData?.bill_type}</th>
                    </tr>
                    <tr>
                    <th>Date :</th>
                    <td>{salesData?.bill_date}</td>
                    </tr>
                    <tr>
                    <th>Invoice :</th>
                    <td>#{salesData?.connect_id}</td>
                    </tr>
                    </table>
                    <span>
                    <img  src={`https://cdn.pasal.com.np/${vendor?.applogo}`} alt='' style={{ position: "absolute",width:'90px',height:'90px', right: "5px", top: "-14px" }} />
                    </span>
                    </div>) : (<></>)}

                    <center>Estimate Bill / <b>{heading}</b></center>
        <div style={{ position: "relative" }}>
                    </div>
                    <div style={{ height: 'auto', display: 'block' }} >
                    <div style={{ overflow: 'visible', display: 'block' }} >
                    <div className="printElement1" >
                    <div gutter={24} style={{ marginTop: 0, display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}   >
                    <div span={8}>
                    <div>BILL To</div>
                    <div><strong>{`${customerDetails?.fname} ${customerDetails?.lname} `}</strong>/({`${customerDetails?.cas_id}`})</div>
                    <div></div>
                    {salesData?.bill_date}
                    </div>
                    <div span={8} >
                    <div>PAY TO</div>
                    <b>{vendor?.mainappname}</b>
                    <div>{`${vendor?.pan ? vendor.pan : ''}`}</div>
                    </div>
                    </div>
                    <div style={{ marginTop: 2 }}>
                    <Table dataSource={items}
                    pagination={false}
                    >
                    {/* <Table.Column title="#" render={(value, item, index) => index + 1} />
                    <Table.Column title="Items" dataIndex='name' />
                <Table.Column title="Pcode" dataIndex='product_id' /> */}
                <Table.Column title="Code" dataIndex="product_id" />
                <Table.Column title="Description" dataIndex="name" />
               
                <Table.Column title="Quantity" render={(text, record) => record.stockout || record.stockin || record.stock_draft} />
                <Table.Column align='right' title="Rate" dataIndex='rate' />
                {discount ? (
                <Table.Column align='right' title="Discount" dataIndex='amount_less' />) : null}
                <Table.Column  align='right' title="Subtotal" render={(text, record) => Number((record.rate -record.amount_less)* (record.stockout || record.stockin || record.stock_draft)).toFixed(2)} />
                
                </Table>
                </div>
                
                <div style={{ marginTop: 8 }}>
                <div>
                {/* <span><b>Additional Information</b></span> */}
                {/* <span style={{ float: "inline-end" }}><b>Totals</b></span> */}
                </div>
                <hr />
                <Row justify='space-between' >
                <Col span={8} offset={0}>
                <span style={{ fontWeight: 800 }}> रु.{(numToWords(salesData?.net_amount ? salesData?.net_amount : 0))}
                </span><br />
                <span style={{ fontWeight: 800 }}> Total Due :<NumberFormat amount={customerDetails?.RC} />
                </span>
                <br />
                {/* <span style={{ display: "flex", fontWeight: 800 }}>
                Terms:
                <p style={{ fontSize: '1em', lineHeight: '1.5em', fontWeight: "initial" }}> We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.
                </p>
                </span> */}
                </Col>
                <Col span={8} offset={0}>
                <table style={{ textAlign: 'right', float: "right" }}>
                <tr>
                <td>Subtotal :</td>
                <td style={{ float: "left" }}><NumberFormat amount={parseInt(salesData?.net_amount) + parseInt(salesData?.discount)} /></td>
                </tr>
                <tr>
                <td>Paid :</td>
                <td style={{ float: "left" }}><NumberFormat amount={salesData?.paid_amount} /></td>
                </tr>
                {salesData?.discount != 0 ? (
                    <tr>
                    <td>Discount :</td>
                    <td style={{ float: "left" }}><NumberFormat amount={salesData?.discount} /></td>
                    </tr>) : (null)}
                    <tr>
                    <td style={{ fontSize: '1.1em', fontWeight: 800, }}>Total :</td>
                    <td style={{ fontWeight: 800, float: "left" }}><NumberFormat amount={salesData?.net_amount} /></td>
                    </tr>
                    <tr>
                    <td style={{ fontSize: '1.1em', fontWeight: 800, }}>Due :</td>
                    <td style={{ fontWeight: 800, float: "left" }}><NumberFormat amount={salesData?.net_amount - salesData?.paid_amount} /></td>
                    </tr>
                    </table>
                    </Col>
                    </Row>
                    <hr />
                   
                    </div>
                    </div>
                    <div>
                    </div>
                    <div style={{ marginTop: 2, textAlign: 'left',whiteSpace:'normal' }}>
                    {salesData?.bill_no}
                    </div>
                    
                    <div style={{ marginTop: 2, textAlign: 'left' ,whiteSpace:'normal'}}>
                    {salesData?.remarks} Printed By-<b>{user?.username}</b> on {days[toDate.getDay()]} {toDate.toLocaleString()} 
                    </div>
                    <div style={{
                        display: " flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}>
                    
                    <span style={{ display: "flex", alignItems: "center" }}>
                    <FiberManualRecordIcon />
                    This is a Computer Generated Invoice #<span style={{ fontWeight: 800 }}> {salesData?.connect_id} </span> Thank You! - {`${salesData?.fname} ${salesData?.lname} `}
                    </span>
                    <span>
                    <br/>
                   <br/>
                    <br/>
                    </span>
                    </div>
                    </div>
                    <div gutter={24} style={{ marginTop: 10,display:"flex",flexDirection:'row',justifyContent:'space-between' }} >
                    <div>
                    <strong>Received By ...............</strong>
                    </div>
                    <div>
                    <strong>Verified By ...............</strong><br/>
                    
                    </div>
                    
                    
                    </div>
                    </div>
                    </div>
    )
}
const DoubleBillFormat = ({ open, bill_date, connect_id, handleClose, subdomain }) => {
    // console.log(user);
    
    const componentRef = useRef();
    const dispatch = useDispatch();
    // const handleShare = () => {
        const shareDetails = { url:`https://business.pasal.com.np/BillView/${subdomain}/${connect_id}`, title:`${subdomain} Shared bill of:${connect_id} with you`, text:'Bill Share' };
    // }
    const handleShare = async () => {
        if (navigator.share) {
          try {
            await navigator
              .share(shareDetails)
              .then(() =>
                console.log("Hooray! Your content was shared to tha world")
              );
          } catch (error) {
            console.log(`Oops! I couldn't share to the world because: ${error}`);
          }
        } else {
          // fallback code
          console.log(
            "Web share is currently not supported on this browser. Please provide a callback"
          );
        }
      };
    const handleDelete = () => {
        PutRequest(`${api.deleteSalesAPI}`, {
            id: connect_id,
            subdomain,
        })
        .then((res) => {
            // 
            let ids = [];
            let content = [];
            handleModalClose();
            handleClose();
            dispatch(
                setToasterState({
                    open: true,
                    title: 'success',
                    name: 'Bill Deleted!',
                    message: `The bill no and its all item has been deleted!`,
                })
                );
                // console.log("data content", res.data.content);
            })
        }
        const addBilledItems = () => {
            dispatch(
                setBillAdd({ ...items })
                )
            };
            const saveDocument = () => {
                const input = componentRef.current;
                html2canvas(input).then(canvas => {
                    let imgData = canvas.toDataURL('image/png');
                    const doc = new jsPDF('l');
                    doc.addImage(imgData, 'JPEG', 0, 0);
                    doc.save("bill.pdf");
                })
            }
            
            const printDocument = () => {
                const input = myRef;
                // console.log(input);
                html2canvas(input)
                .then((canvas) => {
                    let imgData = canvas.toDataURL('image/png');
                    // window.open(imgData);
                    // download(imgData,image);
                    const pdf = new jsPDF();
                    pdf.addImage(imgData, 'JPEG', 20, 20);
                    pdf.output('dataurlnewwindow');
                    pdf.save("download.pdf");
                })
                ;
            }
            //     const data={
            // connect_id:542,
            // bill_date:1
            //     };
            const vendor = useSelector((state) => state.authReducer.user.vendor);
            console.log('vendor', vendor);
            const [items, setItems] = useState([0, 0, 0, 0, 0]);
            const [salesData, setSalesData] = useState([]);
            const [pdfMode, setPDf] = useState(true);
            const [refresh, setRefresh] = useState(0);
            
            useEffect(() => {
                onOpen();
            }, [open]);
            
            useEffect(() => {
                GetRequest(`${api.saleItemAPI}`, {
                    sales_data_id: connect_id,
                    subdomain,
                })
                .then((res) => {
                    // 
                    let ids = [];
                    let content = [];
                    // console.log("data content", res.data.content);
                    res.data.content.forEach((d) => {
                        if (!ids.includes(d.id)) {
                            ids.push(d.id);
                            content.push(d);
                        }
                    });
                    setItems(content);
                    setSalesData(res.data.sales_data[0]);
                    console.log("saled data", salesData);
                    // console.log("saled item", content);
                })
                .catch((err) => {
                    
                });
            }, [open, connect_id, subdomain, refresh]);
            
            const modalizeRef = useRef(null);
            const [totalData, setTotalData] = useState({
                quantity: 0,
                price: 0,
            });
            const onOpen = () => {
                modalizeRef.current?.open();
            };
            const onClose = () => {
                modalizeRef.current?.close();
            };
            
            const switchDate = () => {
                onClose();
            };
            
            useEffect(() => {
                let totalQuantity = 0;
                let totalPrice = 0;
                items.forEach((bill) => {
                    totalQuantity += parseInt(bill.stockout);
                    totalPrice += parseInt(bill.stockout * bill.amount);
                });
                setTotalData({
                    ...totalData,
                    price: totalPrice,
                    quantity: totalQuantity,
                });
            }, [items]);
            
            const VIEW_LENGTH = 1200;
            const VIEW_HEIGHT = 30;
            const OFFSET = VIEW_LENGTH / 2 - VIEW_HEIGHT / 2;
            const myRef = useRef();
            const history = useHistory();

            const editBillDetails = () => {
                // navigate(DAY_BOOK_EDIT, {
                //   sales_data_id: data.connect_id,
                //   date: data.bill_date,
                // });
                history.push(`/inventory/BillEdit/${connect_id}`,{sales_data_id: connect_id, date: bill_date,casid:1,BR:'test'});
                onClose();
            };
            // const divCas = () => {
            //   navigate(CUSTOMER, {
            //     sales_data_id: data.connect_id,
            //     date: data.bill_date,
            //   });
            onClose();
            const [filterData, setFilterData] = useState({
                categoryId: '',
                status: '',
                vendorId: '',
                subtract: '',
                price: [0, 10000],
            });
            const TotalValueRenderer = (props) => {
                return (props.data.stockout * props.data.amount);
            }
            
            const useStyles = makeStyles((theme) => ({
                logo: {
                    objectFit: 'contain',
                    width: 115,
                    padding: 8,
                },
            }));
            
            const classes = useStyles();
            
            const [modalopen, setModalOpen] = React.useState(false);
            const [officeCopy, setOfficeCopy] = React.useState(true);
            const [letterHead, setLetterHead] = React.useState(true);
            const [discountState, setDiscountState] = React.useState(true);
            const handleClickModalOpen = () => {
                setModalOpen(true);
            };
            const handleOfficeCopy =()=>{
                setOfficeCopy(!officeCopy);
            }  
            const handleLetterHead =()=>{
                setLetterHead(!letterHead);
            }
            const handleDiscountState =()=>{
                setDiscountState(!discountState);
            }
            const handleModalClose = () => {
                setModalOpen(false);
            };
            
            const [bill, setDeleteBill] = React.useState();
            
            const deleteBill = () => {
                // setDeleteBill(bill.filter((item) => item.connect_id !== connect_id));
                handleModalClose();
                dispatch(
                    setToasterState({
                        open: true,
                        title: 'success',
                        name: 'Bill Deleted!',
                        message: 'The bill no and its all item has been deleted!',
                    })
                    );
                }
                
                return (
                    <Document>
                    {/* <Download  data={salesData}/> */}
                    <Page pdfMode={pdfMode}>
                    <Dialog
                    fullScreen={false}
                    // fullWidth
                    maxWidth="md"
                    minHeight="100vh"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby='form-dialog-title'
                    >
                    <DialogTitle id='form-dialog-title'>
                    {salesData?.bill_type} BILL
                    </DialogTitle>
                    <DialogContent  >
                    <div className="invoice-content" style={{display:!officeCopy ? 'flex' :'block',flexDirection:'row'}} ref={componentRef}>
                    <SingleBill discount={discountState} letterHead={letterHead}  heading={'Customer Copy'} classes={classes} vendor={vendor} salesData={salesData} items={items}/>   
                    {!officeCopy ? (
                        <>
                    <div style={{height:'100%',backgroundColor:'#0000',width:'50px',textAlign:"center",color:'#fff'}}> .... </div>
                    <SingleBill discount={discountState} letterHead={letterHead}  heading={'Office Copy'}  classes={classes} vendor={vendor} salesData={salesData} items={items}/></> )  : null
                 } </div>

                    
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => handleLetterHead()} color='default'>
                    {letterHead ? 'Show' :'Hide'} Letter Head
                    </Button>
                    <Button onClick={() => handleOfficeCopy()} color='default'>
                    {officeCopy ? 'Show' :'Hide'} Office Copy
                    </Button>
                    <Button onClick={() => handleDiscountState()} color='default'>
                    {!discountState ? 'Show' :'Hide'} Discount
                    </Button>
                    <Button onClick={handleClose} color='default'>
                    Close
                    </Button>
                    <span>
                    <Button onClick={() => handleClickModalOpen()} color='default'>
                    Delete
                    </Button>
                    <Dialog
                    open={modalopen}
                    onClose={handleModalClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Are you sure, you want to delete?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleModalClose}>Cancel</Button>
                    <Button onClick={handleDelete} >
                    Ok
                    </Button>
                    </DialogActions>
                    </Dialog>
                    </span>
                    {/* onClick={addBilledItems} */}
                    <Button onClick={saveDocument} color='default'>
                    Save
                    </Button>
                    {/* <Download data={salesData?}/> */
                }
                <Button onClick={handleShare} color='default'>
                Share
                </Button>
                <Button onClick={editBillDetails} color='default'>
                Edit
                </Button>
                {/* <ViberShareButton title="test"/> */}
                <ReactToPrint pageStyle={{ padding: "0 20px" }}
                trigger={() => <Button onClick={printDocument} color='primary'>
                Print
                </Button>}
                bodyClass={"printElement1"}
                content={() => componentRef.current}
                />
                {/* <Button onClick={printDocument} color='primary'>
                Save
            </Button> */}
            {/* <Button onClick={() => onClose(filterData)} color='primary'>
            Filter
        </Button> */}
        </DialogActions>
        </Dialog>
        </Page>
        </Document >
        );
    };
    export default DoubleBillFormat;
    