import { Box, Button, CardHeader, IconButton, Input, InputAdornment, MenuItem, Paper, Select, Switch, Table, TableCell, TableRow, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../../api/api'
import { GetRequest } from '../../../../plugins/axios';
import { setBillAdd, setBillUpdate } from '../store';
import { ToggleButton } from '@material-ui/lab';
import AddProduct from 'modules/inventory/Product/Components/AddProduct';
import * as actions from '../../../store/DashboardActions';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import EditIcon from '@material-ui/icons/Edit';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
}
  from '@material-ui/core';
import { display } from '@mui/system';
const initialData = {
  name: '',
  stockout: '',
  product_id: '',
  contact: '',
  subdomain: '',
};

export function AddBtn({ pr_name, sales_data_id, subdomain, id, price }) {
  const dispatch = useDispatch();
  const addBilledItems = () => {
    dispatch(
      setBillAdd({
      })
    );
  }

  return (
    <>
      <Button
        variant='contained'
        full
        style={{ flex: 1 }}
        onClick={addBilledItems}
      >
        <div> Add Items</div>
      </Button>
    </>
  )
}
export default function AddBillItems({ pr_name, stock, sales_data_id, subdomain, id, price,bill_type }) {
  const [axiosToken, setAxiosToken] = useState('');
  const [name, setName] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [rate, setRate] = useState(0);
  const [productList, setProductList] = useState([]);
  const [product_id, setProduct_id] = useState('');
  const [currentStock, setCurrentStock] = useState(true);
  const products = useSelector((state) => state.dashboardReducer.products);
  const displayStock = useSelector((state) => state.dashboardReducer.displayStock);
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [search, setSearch] = useState(pr_name);
  const [proceedAdd, setProceedAdd] = useState(false);
  const [showDropDown, setShowDropDown] = useState(true);
  const filter = createFilterOptions();
  const [filteredProductList, setFilteredProductList] = useState([]);
  const [addpOpen, setAddpOpen] = useState(false);
  const [filterData, setFilterData] = useState({
    subdomain,
    stock: displayStock,
    page: 0,
    limit: false,
    price: '',
    search,
  });
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  }
  const openpAddDialog = () => {
    setAddpOpen(true);
  };
  const closepAddDialog = () => {
    setFilterData({
      ...filterData,
      loading:addpOpen
    });
    setAddpOpen(false);
  };

  useEffect(() => {
    setFilterData({
      ...filterData,
      subdomain,
      stock: displayStock,
    });
    console.log('filterData::::::::::::', filterData);
  }, [displayStock]);
  useEffect(() => {
      if (axiosToken) {
        // Cancel the previous request before making a new request
        axiosToken.cancel('operation canceled due to new request.');
      }
      let token = axios.CancelToken.source();
      setAxiosToken(token);
      dispatch(actions.get_products(filterData.page === 0, filterData, token));
  }, [filterData,displayStock, dispatch]);



  const [pAddModalValues, setPAddModalValues] = useState({
    name: ''
  });


  useEffect(() => {
    if (id) {
      //   const { price, quantity, name, product_id } = route.params;
      setEdit(true);
      setRate(price + '');
      setQuantity(quantity + '');
      setName(name);
      setSearch(name);
      setProduct_id(product_id);
    }
  }, [id]);

  const forcedaddBilledItems = (force) => {
    handleModalClose();
    dispatch(
      setBillAdd({
        name,
        quantity,
        rate,
        sales_data_id,
        product_id,
        subdomain,
      })
    );
  };

  const addBilledItems = (force) => {
    if (currentStock != undefined) {
      console.log(currentStock);
      dispatch(
        setBillAdd({
          name,
          quantity,
          rate,
          sales_data_id,
          product_id,
          subdomain,
        })
      );
    } else {
      setModalOpen(true);
    }
  };
  const handleChange = (event) => {
    this.setState({
      value: event.target.value,
    });
  };
  const updateBilledItems = () => {
    dispatch(
      setBillUpdate({
        id,
        name,
        stockin: quantity,
        quantity,
        rate,
        sales_data_id,
        product_id,
        subdomain,
      })
    );
  };

  const itemSelected = (product) => {
    if (true) {
      console.log("selectred product", product);
      console.log("all product", productList);
      setProduct_id(product?.product_id);
      setShowDropDown(false);
      setSearch(product?.name);
      setName(product?.name);
      setCurrentStock(product?.stock);
      setRate(bill_type!="PURCHASE" ? product?.discounted + '' : product?.cost + '');
      setQuantity(1 + '');
      setProceedAdd(product?.product_id);
    }
    // if(edit){ updateBilledItems()}else{ addBilledItems()};
  };
  useEffect(() => {
    if (proceedAdd) {
      false ? updateBilledItems() : addBilledItems()
    }
  }, [proceedAdd])

  const changeTextProductSearch = (text) => {
    setShowDropDown(true);
    setSearch(text.target.value);
    console.log("logs:::::search",text.target.value);
  };

  useEffect(() => {
    setFilteredProductList(productList);
  }, [productList]);

  useEffect(() => {
    setProductList(products);
  }, [products]);

  useEffect(() => {
    let newProductlist = productList.filter((cas) =>
      cas.name?.toLowerCase().includes(search) 
    );
    setFilteredProductList(newProductlist);
  }, [search]);
  useEffect(() => {
    if (axiosToken) {
      // Cancel the previous request before making a new request
      axiosToken.cancel('operation canceled due to new request.');
    }
    let token = axios.CancelToken.source();
    setAxiosToken(token);
    if(filteredProductList.length<=1){
    dispatch(actions.get_products(filterData.page === 0, filterData, token));}
}, [search, dispatch]);
  return (
    <>
      <Autocomplete
        fullWidth
        onChangeText={changeTextProductSearch}
        onChange={(event, newValue, product) => {
          console.log(product.stock);
          if (typeof (newValue) === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {

              setPAddModalValues({
                name: newValue,
              });
              setAddpOpen(true);
            });
          }
          else if (newValue && newValue.inputValue) {

            setPAddModalValues({
              name: newValue.inputValue,
            });
            setAddpOpen(true);
          }

          else {
            // console.log("HLW")
          }
          itemSelected(newValue)
        }}
        options={filteredProductList}
        getOptionLabel={(option) => "#" + option.product_id?.toString() + " " + option.name?.toString() + "(" + option.model?.toString() + ") | Stock: " + ((option.stock == undefined) ? 0 : option.stock?.toString()) + " | SP : " + option.discounted?.toString()}
        //  onSelect={stockOut}
        //onChange={() => {
        //setModalOpen(true);
        // alert();
        //   stockOut == 0 && (

        //     <Dialog
        //     open={modalOpen}
        //     onClose={handleModalClose}
        //     aria-labelledby="alert-dialog-title"
        //     aria-describedby="alert-dialog-description"
        //     >
        //       <DialogContent>
        //         <DialogContentText id="alert-dialog-description">
        //           This item is not in stock. Do you want to add this item?
        //         </DialogContentText>
        //       </DialogContent>
        //       <DialogActions>
        //         <Button onClick={handleModalClose}>Cancel</Button>
        //         <Button onClick={handleModalClose} >
        //           Continue
        //         </Button>
        //       </DialogActions>
        //     </Dialog>
        //   )
        // }}
        // renderOption={( option) => (
        //   <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} >
        //     {option.product_id?.toString()} ({option.name}) +{option.model}
        //     <EditIcon onClick={setAddOpen(true)}/>
        //   </Box>
        // )}

        renderOption={(option) => (
          <div fullWidth style={{ display:"flex", width: '100%', flexDirection: "row", justifyContent: 'space-between', padding: 5, color: (option.stock || option.subtract == 0) ? '#00a53e' : "#f15e63" }}>
            <div style={{width: "10%"}}>#{option.product_id}</div>
            <div style={{width: "50%"}}>{option.name}<br />({option.model})</div>
            <div style={{width: "20%"}}>Stock:{((option.stock == undefined) ? 0 : option.stock?.toString())}</div>
            <div style={{width: "20%"}}>Price:{option.discounted?.toString()}</div>
          </div>
        )}

        selectOnFocus
        clearOnBlur
        handleHomeEndKeys


        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push(
              {
                inputValue: params.inputValue,
                product_id: "",
                name: `Add "${params.inputValue}"`,
                model: "",
                stock: "",
                discounted: ""
              }
            );
          }

          return filtered;
        }}

        // popper={{width:1000,backgroundColor:'red'}}
        style={{ borderBottom: "none", }}

        renderInput={(params) =>

          <TextField
            {...params} value={product_id}
            // variant="outlined"
          label={displayStock ? "Search Stock" : "Search All"}

            onChange={changeTextProductSearch} />}

      />
      {/* </Paper> */}
      {
        addpOpen &&
        <AddProduct
          open={addpOpen}
          productName={pAddModalValues.name}
          handleClose={closepAddDialog}
          editStockData={false}
        />
      }
      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This item is not in stock. Do you want to add this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>Cancel</Button>
          <Button onClick={forcedaddBilledItems} >
            Continue
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
}
