import { TextField } from '@material-ui/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Slider,
    Typography,
  } from '@material-ui/core';
  import React, { useEffect, useState } from 'react';
  import { useDispatch, useSelector } from 'react-redux';
  import { Switch } from 'react-router';
  import * as actions from '../../../store/DashboardActions';
  
  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
  
  export default function Daybook({
    open,
    handleClose,
    filterProducts,
    data,
  }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const categoryList = useSelector(
      (state) => state.dashboardReducer.categories
    );
    const vendorList = useSelector((state) => state.dashboardReducer.vendors);
  
    const [filterData, setFilterData] = useState({
      categoryId: '',
      status: '',
      vendorId: '',
      subtract: '',
      price: [0, 10000],
    });
  
    useEffect(() => {
      setFilterData({ ...filterData, ...data });
      // eslint-disable-next-line
    }, [data]);
    useEffect(() => {
      if (categoryList.length === 0) {
        dispatch(actions.get_category());
      }
    }, [categoryList, dispatch]);
  
    useEffect(() => {
      if (vendorList.length === 0) {
        dispatch(actions.get_vendors());
      }
    }, [vendorList, dispatch]);
  
    const handleChange = (e) => {
      // console.log(e.target);
      // console.log(filterData);
      setFilterData({
        ...filterData,
        [e.target.name]: e.target.value,
      });
    };
    const handleSliderChange = (e, newValue) => {
      setFilterData({
        ...filterData,
        price: newValue,
      });
    };
  
    function valuetext(value) {
      // console.log(value);
      if (value > 1000) {
        value = (value / 1000).toFixed(0);
        return `${value}k`;
      }
      return value;
    }
  
    const handleClear = () => {
      setFilterData({
        ...filterData,
        categoryId: '',
        status: '',
        vendorId: '',
        subtract: '',
      });
    };
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Subscribe</DialogTitle>
        <DialogContent>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel shrink id='demo-simple-select-placeholder-label-label'>
              Category
            </InputLabel>
            <Select
              labelId='demo-simple-select-placeholder-label-label'
              id='demo-simple-select-placeholder-label'
              name='categoryId'
              value={filterData?.categoryId}
              className={classes.selectEmpty}
              onChange={handleChange}
            >
              {categoryList.map((category) => (
                <MenuItem value={category.category_id}>{category.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel shrink id='demo-simple-select-placeholder-label-label'>
              Vendor
            </InputLabel>
            <Select
              labelId='demo-simple-select-placeholder-label-label'
              id='demo-simple-select-placeholder-label'
              displayEmpty
              name='vendorId'
              value={filterData?.vendorId}
              className={classes.selectEmpty}
              onChange={handleChange}
            >
              {vendorList.map((vendor) => (
                <MenuItem value={vendor.vendor_id}>{vendor.meta_title}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel shrink id='demo-simple-select-placeholder-label-label'>
            Day Book for
            </InputLabel>
            <TextField
              labelId='demo-simple-select-placeholder-label-label'
              id='demo-simple-select-placeholder-label'
              displayEmpty
              name='dateString'
              value={filterData?.dateString}
              className={classes.selectEmpty}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel shrink id='demo-simple-select-placeholder-label-label'>
              Subtract
            </InputLabel>
           <TextField
              labelId='demo-simple-select-placeholder-label-label'
              id='demo-simple-select-placeholder-label'
              displayEmpty
              name='odString'
              value={filterData?.odString}
              className={classes.selectEmpty}
              onChange={handleChange}
            />
          </FormControl>
  
          <Typography gutterBottom>Price</Typography>
          <Slider
            max={10000}
            value={filterData.price}
            name='price'
            onChange={handleSliderChange}
            valueLabelDisplay='auto'
            aria-labelledby='range-slider'
            getAriaValueText={valuetext}
            valueLabelFormat={valuetext}
          />
  
          <Switch inputProps={{ 'aria-label': 'primary checkbox' }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='default'>
            Cancel
          </Button>
          <Button onClick={handleClear} color='primary'>
            Clear
          </Button>
          <Button onClick={() => filterProducts(filterData)} color='primary'>
            Filter
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  