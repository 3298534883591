// eslint-disable-next-line
export default {
    vendor_id: {
      required: true,
      label: 'VendorId',
    },
    name: {
      required: true,
      label: 'App name',
    },
    model: {
      required: true,
      label: 'Type',
    },
    subdomain: {
      required: true,
      label: 'Sub domain',
    },
    price: {
      required: true,
      label: 'Database Name',
    },
    cost: {
      required: true,
      label: 'App Code',
    },
    discounted: {
      required: true,
      label: 'Start Date',
    },
    description: {
      required: true,
      label: 'End Date',
    },
  };
  