import React, { useState, useEffect, useRef } from 'react'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Calendar from '@sbmdkl/nepali-datepicker-reactjs';
import { Button, InputLabel } from '@material-ui/core';
import NepaliDate from 'nepali-date-converter';
import Search from "@material-ui/icons/Search";
import CustomInput from 'components/CustomInput/CustomInput';
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ReactToPrint from 'react-to-print';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(styles);


function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
}

const rows = [
    createData('India', 'IN', 1324171354, 3287263),
    createData('China', 'CN', 1403500365, 9596961),
    createData('Italy', 'IT', 60483973, 301340),
    createData('United States', 'US', 327167434, 9833520),
    createData('Canada', 'CA', 37602103, 9984670),
    createData('Australia', 'AU', 25475400, 7692024),
    createData('Germany', 'DE', 83019200, 357578),
    createData('Ireland', 'IE', 4857000, 70273),
    createData('Mexico', 'MX', 126577691, 1972550),
    createData('Japan', 'JP', 126317000, 377973),
    createData('France', 'FR', 67022000, 640679),
    createData('United Kingdom', 'GB', 67545757, 242495),
    createData('Russia', 'RU', 146793744, 17098246),
    createData('Nigeria', 'NG', 200962417, 923768),
    createData('Brazil', 'BR', 210147125, 8515767),
];

export default function BalanceSheet() {
    const [products, setProducts] = useState([]);
    const componentRef = useRef();
    const classes = useStyles();
    const [billDate, setBillDate] = useState('');
    const nepaliDate = new NepaliDate();
    let date = '2078/04/04';
    const options = [
        'This Month', 'Last Month', 'Jan'
    ];
    const dataTypes = [
        'All Forms', 'Sales', 'Purchase'
    ];
    const defaultSelector = dataTypes[0];
    const defaultOption = options[0];

    useEffect(() => {
        date = `${nepaliDate.getYear()}/${nepaliDate.getMonth() + 1 > 9
            ? nepaliDate.getMonth() + 1
            : `0${nepaliDate.getMonth() + 1}`
            }/${nepaliDate.getDate() > 9
                ? nepaliDate.getDate()
                : `0${nepaliDate.getDate()}`
            }`;
        setBillDate(date + "");
    }, []);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // const rows = [
    //     'a', 'b', 'c'
    // ];

    // const columns = [
    //     's', 'd', 'f'
    // ];

    return (
        <>
            {/* <div className='sales-topbar'>
                <Dropdown options={options}
                    value={defaultOption}
                    placeholder="Select an option" />
                <span className='date-selector'>
                    <Button>
                        Between
                    </Button>
                    <Calendar style={{ width: "271px", borderColor: '#0000', backgroundColor: "#0000", borderBottom: "1px solid #929292", fontWeight: 700 }} language="en" theme="default" dateFormat="YYYY/MM/DD"
                        onChange={(date) => { setBillDate(date.bsDate + "") }} />
                    <Button>
                        To
                    </Button>
                    <Calendar style={{ width: "271px", borderColor: '#0000', backgroundColor: "#0000", borderBottom: "1px solid #929292", fontWeight: 700 }} language="en" theme="default" dateFormat="YYYY/MM/DD"
                        onChange={(date) => { setBillDate(date.bsDate + "") }} />
                </span>
                <Dropdown options={dataTypes}
                    value={defaultSelector}
                    placeholder="Select an option" />
                <ReactToPrint
                    trigger={() => <Button color='primary'>
                        Print
                    </Button>}
                    content={() => componentRef.current}
                />
            </div>
            <div className='balancesheet-title'>
                <h4>Balance Sheet as on {date}</h4>
            </div>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Products</TableCell>
                                <TableCell align="right">Amount</TableCell>
                                <TableCell align="right">Products1</TableCell>
                                <TableCell align="right">Amount1</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="right">{row.products}</TableCell>
                                    <TableCell align="right">{row.amount}</TableCell>
                                    <TableCell align="right">{row.products1}</TableCell>
                                    <TableCell align="right">{row.amount}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />

                <div className={classes.seeMore}>
                    <Link color='primary' href='#'>
                        {products.length > 0
                            ? 'Total Products: ' + products.length
                            : 'No products'}
                    </Link>
                </div>
            </Paper> */}
        </>
    )
}
