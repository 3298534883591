import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    TextField,
    makeStyles,
    MenuItem,
    Select,
    Slider,
    Typography,
  } from '@material-ui/core';
import api from '../../../../api/api'
import { PutRequest } from 'plugins/axios';
  import React, { useEffect, useState } from 'react';
  import { useDispatch, useSelector } from 'react-redux';
  import { Switch } from 'react-router';
  import * as actions from '../../../store/DashboardActions';
  
  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
  
  export default function MergeProducts({
    open,
    handleClose,
    filterProducts,
    data,
  }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const categoryList = useSelector(
      (state) => state.dashboardReducer.categories
    );
  const subdomain = useSelector((state) => state.authReducer.user.subdomain);

    const vendorList = useSelector((state) => state.dashboardReducer.vendors);
  
    const [filterData, setFilterData] = useState({
      product_id: 0,
      new_product_id: 0,
      vendorId: '',
      subtract: '',
      limit:10,
      price: [0, 10000],
    });
  
    useEffect(() => {
      setFilterData({ ...filterData, ...data });
      // eslint-disable-next-line
    }, [data]);
    useEffect(() => {
      if (categoryList.length === 0) {
        dispatch(actions.get_category());
      }
    }, [categoryList, dispatch]);
  
    useEffect(() => {
      if (vendorList.length === 0) {
        dispatch(actions.get_vendors());
      }
    }, [vendorList, dispatch]);
  
    const handleChange = (e) => {
      // console.log(e.target);
      // console.log(filterData);
      setFilterData({
        ...filterData,
        [e.target.name]: e.target.value,
      });
    };
    const handleSliderChange = (e, newValue) => {
      setFilterData({
        ...filterData,
        price: newValue,
      });
    };
  
    function valuetext(value) {
      // console.log(value);
      if (value > 1000) {
        value = (value / 1000).toFixed(0);
        return `${value}k`;
      }
      return value;
    }
  
    const handleClear = () => {
      setFilterData({
        ...filterData,
        categoryId: '',
        status: '',
        limit: 10,
        vendorId: '',
        subtract: '',
      });
    };
    const handleMerge = () =>{
        PutRequest(`${api.updateProductAPI}`, {
          product_id: filterData?.product_id,
          new_product_id: filterData?.new_product_id,
          subdomain,
        })
          .then((res) => {
            // 
            let ids = [];
            let content = [];
            // console.log("data content",res.data.content);
          })
      }
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Merge Two Items</DialogTitle>
        <DialogContent>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel shrink id='demo-simple-select-placeholder-label-label'>
              Main Item
            </InputLabel>
            <TextField
              labelId='demo-simple-select-placeholder-label-label'
              id='demo-simple-select-placeholder-label'
              name='new_product_id'
              value={filterData?.new_product_id}
              className={classes.selectEmpty}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel shrink id='demo-simple-select-placeholder-label-label'>
              Duplicate Item
            </InputLabel>
            <TextField
              labelId='demo-simple-select-placeholder-label-label'
              id='demo-simple-select-placeholder-label'
              name='product_id'
              value={filterData?.product_id}
              className={classes.selectEmpty}
              onChange={handleChange}
            />
          </FormControl>
          
  
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='default'>
            Cancel
          </Button>
          <Button onClick={handleMerge} color='primary'>
            Merge
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  