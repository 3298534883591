import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import ReactToPrint from "react-to-print";
import { downloadCSVFromJson } from "utils/DownloadCSV";
import { Button, Paper, TextField, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ShowLoadingOrEmpty from "modules/Dashboard/Components/ShowLoadingOrEmpty";
import * as actions from "../../../store/DashboardActions";
import BillDetaildiv from "modules/inventory/daybook/Components/BillDetailView";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddCustomer from '../../../Dashboard/modules/Customer/Components/AddCustomer';
import NumberFormat from "utils/NumberFormat";


export default function CustomerList({limit,openBill,setOpenBill,image,remaningTotal,filterProducts,open,setCasId,FilterCreditors,handleClose,BILLTYPE}) {
    const [search, setSearch] = useState("");  
    const [addOpen, setAddOpen] = useState(false);
    const [totalValue, setTotalValue] = useState(0);
    const [customers, setCustomers] = useState([]);
    const [savedCreditors, setSavedCreditors] = useState([]);
    const [axiosToken, setAxiosToken] = useState("");
    const dispatch = useDispatch();
    const subdomain = useSelector((state) => state.authReducer.user.subdomain);
    const creditorsList = useSelector(
        (state) => state.dashboardReducer.creditors
      );
    console.log("🚀 ~ file: CustomerList.js ~ line 32 ~ CustomerList ~ creditorsList", creditorsList)

      const [filterData, setFilterData] = useState({
        subdomain,
        bill_type:BILLTYPE,
        dateString: "2078/04/11",
        odString: "2078/04/01",
        endDate: "2078/12/12",
        page: 0,
        limit:limit,
      });
      const openAddDialog = (app) => {
        // console.log("app data",app);
        setAddOpen(true);
      };
      const closeAddDialog = () => {
        setAddOpen(false);
      };
      useEffect(() => {
        const get_creditorList = () => {
          // console.log(filterData);
          if (axiosToken) {
            // Cancel the previous request before making a new request
            axiosToken.cancel("operation canceled due to new request.");
          }
          let token = axios.CancelToken.source();
          setAxiosToken(token);
          dispatch(actions.get_creditors(filterData?.page === 0, filterData, token));
        };
        get_creditorList();
        return () => {
          // console.log('clear');
        };
        // eslint-disable-next-line
      }, [filterData, subdomain]);
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  useEffect(()=>{
    let tV=0;
    creditorsList.forEach(element => {
      tV += element.RC;
    });
    setTotalValue(tV)
  },[creditorsList])
  // useEffect(() => {
  //   dispatch(actions.get_customers(search, subdomain));
  // }, [filterData, subdomain]);
  useEffect(() => {
    setCustomers(creditorsList);
    setSavedCreditors(creditorsList);
  }, [creditorsList]);
  useEffect(() => {
    let newlist = savedCreditors.filter((cas) =>
      cas.fname?.toLowerCase().includes(search) || cas.contact?.toLowerCase().includes(search)
    );
    setCustomers(newlist);
  }, [search]);
    return (
    <div>
      <div style={{display:'flex',flexDirection:'row'}}>
      <TextField
            id="outlined-basic"
            label="Search"
            fullWidth
            style={{ margin: 10 }}
            variant="outlined"
            name="search"
            onChange={handleSearchChange}
          />
      <Button
            variant='contained'
            color='primary'
            style={{ padding: '1px 6px',margin:'10px' }}
            startIcon={<AddCircleIcon />}
            onClick={() => openAddDialog()}
          >
          </Button>
      </div>

          {customers.length > 0 ? (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>CASID</TableCell>
                  <TableCell>NAME</TableCell>
                  <TableCell align="right">AMOUNT</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customers.map((row, index) => (
                  <TableRow
                    key={index}
                    onClick={() => {
                      setCasId(row.cas_id);
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.cas_id}</TableCell>
                    <TableCell>{`${row.fname} ${row.lname} `}</TableCell>
                    <TableCell align="right"><NumberFormat amount={!limit ? row.RC : row.total_sales}/></TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  {open && (
                    <FilterCreditors
                      open={open}
                      data={filterData}
                      handleClose={() => handleClose(!open)}
                      filterProducts={filterProducts}
                    />
                  )}
                  <BillDetaildiv
                    open={openBill}
                    connect_id={image}
                    data={{ connect_id: image, bill_date: "2078/04/12" }}
                    bill_date={"2078/04/12"}
                    subdomain={subdomain}
                    handleClose={() => setOpenBill(false)}
                  />
                  <TableCell>***</TableCell>
                  <TableCell align="right">{totalValue}</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          ) : (
            // <Skeleton count={50} />
            <ShowLoadingOrEmpty message="No Products Found" />
          )}
           <AddCustomer
          open={addOpen}
          handleClose={closeAddDialog}
          editStockData={''}
        />
    </div>
  )
}
