import React from 'react';
import { useDispatch } from 'react-redux';

import { Route, Redirect } from 'react-router';
import { setToasterState } from '../../../common/Toast/services/ToasterAction';

const AuthRoute = (props) => {
  const { component: Component, ...rest } = props;
  const user = localStorage.getItem('token') || '';
  const dispatch = useDispatch();

  const showToaster = () => {
    dispatch(
      setToasterState({
        open: true,
        title: 'error',
        name: 'Authentication Error!',
        message: 'Login First into the app!',
      })
    );
  };
  !user && showToaster();

  return (
    <Route
      {...rest}
      render={(props) =>
        user ? <Component {...props} /> : <Redirect to={'/login'} />
      }
    />
  );
};

export default AuthRoute;
