import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Title from '../../../Components/Title';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../store/DashboardActions';
import StockAppRules from './StockAppRules';
import { ValidateForm, ValidateInput } from '../../../../../utils/validateForm';
import NepaliDate from 'nepali-date-converter';
import CurrencyInput from 'react-currency-input-field';


const AddCustomer = ({ open, handleClose, editStockData,customerFullName}) => {
  const dispatch = useDispatch();
  const [nepaliDate, setNepaliDate] = useState(new NepaliDate());
  
  
  const [search, setSearch] = useState();
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  // console.log("OPENING2",customerFullName)
  const initialData = {
    fname: customerFullName||'',
    address:'',
    age:'',
    openingBalance:'',
    casType:'',
    billDate:'',
    contact:'',

    search,
  };
  const [stockData, setStockData] = useState(initialData);
  const [errors, setErrors] = useState({});

  const onChangeHandler = (e) => {
    // setSearch(e.target.value);
    setStockData({
      ...stockData,
      [e.target.name]: capitalizeFirstLetter(e.target.value),
    });
  };

  const setDefultDate = (e) =>{
    setStockData({
      ...stockData,
      billDate: `${nepaliDate.getYear()}/${
        nepaliDate.getMonth() + 1 > 9
        ? nepaliDate.getMonth() + 1
        : `0${nepaliDate.getMonth() + 1}`
      }/${
        nepaliDate.getDate() > 9
        ? nepaliDate.getDate()
        : `0${nepaliDate.getDate()}`
      }`,
    });
  }
  // useEffect(() => {
  //   if (vendorList.length === 0) {
  //     dispatch(actions.get_vendors());
  //   }
  // }, [vendorList, dispatch]);
  
  // useEffect(() => {
  //   setErrors(initialData);
  //   setStockData(initialData);
  // },[])
  useEffect(() => {
    dispatch(actions.get_customers(initialData));
    // console.log("get_customer",initialData);
  },[search,dispatch]);
  
  useEffect(() => {
    // console.log(editStockData);
    if (editStockData) {
      setStockData(editStockData);
    } else {
      setStockData(initialData);
    }
  }, [editStockData]);
  
  const inputValidation = (e) => {
    let errorMsg = ValidateInput(e.target.name, e.target.value, StockAppRules);
    setErrors({
      ...errors,
      [e.target.name]: errorMsg,
    });
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log('nottttttvalidateddddddddddddddddddddddddddd');
    // console.log(stockData);
    let errorMsgs = ValidateForm(stockData, StockAppRules);
    setErrors({ ...errorMsgs });
    let validated = Object.values(errorMsgs).join('').length === 0;
    if (validated) {
      // console.log('validateddddddddddddddddddddddddddd');
      dispatch(actions.add_customer(stockData));
      handleClose();
    }
  };
  
  const handleUpdate = (e) => {
    e.preventDefault();
    // console.log(stockData);
    let errorMsgs = ValidateForm(stockData, StockAppRules);
    setErrors({ ...errorMsgs });
    let validated = Object.values(errorMsgs).join('').length === 0;
    if (validated) {
      dispatch(actions.update_customer(stockData));
      handleClose();
    }
  };
  return (
    <Dialog
    open={open}
    fullScreen={false}
    onClose={handleClose}
    aria-labelledby='responsive-dialog-title'
    >
    <DialogTitle id='responsive-dialog-title'>
    <Title>Create Customer</Title>
    </DialogTitle>
    <DialogContent dividers>
    <form onSubmit={handleSubmit}>
    <Grid container spacing={2}>
    {/* <Grid item xs={10}>
    <FormControl fullWidth error={errors.vendor_id}>
    <InputLabel shrink>Vendor</InputLabel>
    <Select
    onBlur={inputValidation}
    displayEmpty
    name='vendor_id'
    value={stockData.vendor_id}
    onChange={onChangeHandler}
    >
    {vendorList.map((vendor) => (
      <MenuItem value={vendor.vendor_id}>
      {vendor.meta_title}
      </MenuItem>
      ))}
      </Select>
      <FormHelperText>{errors.vendor_id}</FormHelperText>
      </FormControl>
    </Grid> */}
    {/* <Grid item xs={2}>
    <Button>Add</Button>
  </Grid> */}
  <Grid item xs={6}>
  <TextField
  margin='dense'
  name='fname'
  value={`${stockData?.fname}`}
  error={errors.fname}
  helperText={errors.fname}
  label='FullName'
  onChange={onChangeHandler}
  onBlur={inputValidation}
  type='text'
  fullWidth
  />
  </Grid>
  <Grid item xs={6}>
  <TextField
  margin='dense'
  name='address'
  value={stockData.address}
  error={errors.address}
  helperText={errors.address}
  label='Fulladdress'
  onChange={onChangeHandler}
  onBlur={inputValidation}
  type='text'
  fullWidth
  />
  </Grid>
  <Grid item xs={6}>
  <FormControl fullWidth error={errors.casType}>
                <InputLabel shrink>Customer Type</InputLabel>
                <Select
                  onBlur={inputValidation}
                  name='casType'
                  value={stockData.casType}
                  onChange={onChangeHandler}
                >
                  {/* {manufacturerList.map((list) => (
                    <MenuItem value={list.vendor_id}>
                      {list.meta_title}test
                    </MenuItem>
                  ))} */}
                  <MenuItem value={'Customer'}>
                    Customer
                  </MenuItem>
                  <MenuItem value={'Supplier'}>
                    Supplier
                  </MenuItem>
                </Select>
                <FormHelperText>{errors.apptyp}</FormHelperText>
              </FormControl>
  </Grid>
  <Grid item xs={6}>
  <TextField
  margin='dense'
  id='contact'
  name='contact'
  value={stockData.contact}
  error={errors.contact}
  helperText={errors.contact}
  onChange={onChangeHandler}
  onBlur={inputValidation}
  label='Contact No'
  type='text'
  fullWidth
  />
  </Grid>
  <Grid item xs={6}>
  {/* <TextField
  margin='dense'
  id='openingBalance'
  name='openingBalance'
  value={stockData.openingBalance}
  error={errors.openingBalance}
  helperText={errors.openingBalance}
  onChange={onChangeHandler}
  onBlur={inputValidation}
  label='Opening Balance'
  type='number'
  fullWidth
  /> */}
<div class="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl MuiInputBase-marginDense MuiInput-marginDense">
  {/* <input aria-invalid="" name="billDate" type="text"  value=""
  /> */}
  <CurrencyInput
  class="MuiInputBase-input MuiInput-input MuiInputBase-inputMarginDense MuiInput-inputMarginDense"
  variant="filled"
  margin='dense'
  id="openingBalance"
  name="openingBalance"
  placeholder="Opening Balance"
  decimalsLimit={2}
  onValueChange={(value, name) => setStockData({
    ...stockData,
    [name]: value,
  })}
  fullWidth
  prefix="रु "
/>
  </div>
  

  </Grid>
  <Grid item xs={6}>
  <TextField
  margin='dense'
  name='billDate'
  value={stockData.billDate}
  error={errors.billDate}
  helperText={errors.billDate}
  onChange={onChangeHandler}
  onBlur={inputValidation}
  label='Opening Date'
  type='text'
  fullWidth
  />
  <Button color='primary' onClick={setDefultDate}>
     Today
     </Button>
  </Grid>
</Grid>
</form>
</DialogContent>
<DialogActions>
<Button color='primary' onClick={handleClose}>
Cancel
</Button>

{editStockData ? (
  <Button variant='contained' color='primary' onClick={handleUpdate}>
  Update
  </Button>
  ) : (
    <Button variant='contained' color='primary' onClick={handleSubmit}>
    Create
    </Button>
    
    )}
    </DialogActions>
    </Dialog>
    );
  };
  
  export default AddCustomer;
  