import React, { useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../../Components/Title';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/DashboardActions';
import {  InputLabel, Paper } from '@material-ui/core';
import ShowLoadingOrEmpty from '../../Components/ShowLoadingOrEmpty';
import { MenuItem, Select } from '@mui/material';
import axios from 'axios';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {FormControl} from '@material-ui/core';
import {Button} from '@material-ui/core';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import ProfileCard from 'components/ProfileCard/ProfileCard';
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import NepaliDate from "nepali-date-converter";
import Box from '@mui/material/Box';
const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

export default function Attendance() {
  const classes = useStyles();
  const [attendence, setAttendence] = useState([]);
  const user = useSelector((state) => state.authReducer.user);
  const staffData = useSelector((state) => state.dashboardReducer.staffDetails);
  const [bill_date, setBill_date] = useState("");
  const [userid, setUserID] = useState(user.id);
  const [staffid, setStaffId] = useState(user.staffid);
  const [axiosToken, setAxiosToken] = useState('');
  const subdomain = useSelector((state) => state.authReducer.user.subdomain);
  const attendance = useSelector((state) => state.dashboardReducer.attendance);
  const staff = useSelector((state) => state.dashboardReducer.admin);
  const [today, setToday] = useState();
  const [dateswitch, setDateswitch] = useState();
  const [thismonth, setThismonth] = useState("2078/12/09");
  const [thisyear, setThisyear] = useState("2078/12/09");
  const [startDate, setStartDate] = useState("2078/01");
  const [endDate, setEndDate] = useState("2078/02");
  const [nepaliDate, setNepaliDate] = useState(new NepaliDate());
  const [dateString, setDateString] = useState("");
  const [yesterdate, setYesterdate] = useState("");
  const [twomorro, setTwomorro] = useState("");
  const [thisweek, setThisweek] = useState("2078/12/09");
   
  const months = [
    { name: 'Baishakh', id: '01',to:'02' },
    { name: 'Jestha', id: '02',to:'03' },
    { name: 'Ashad', id: '03',to:'04' },
    { name: 'Shrawan', id: '04',to:'05' },
    { name: 'Bhadra', id: '05',to:'06' },
    { name: 'Aswin', id: '06',to:'07' },
    { name: 'Kartik', id: '07',to:'08' },
    { name: 'Mangsir', id: '08',to:'09' },
    { name: 'Poush', id: '09',to:'10' },
    { name: 'Magh', id: '10',to:'11' },
    { name: 'Falgun', id: '11',to:'12' },
    { name: 'Chaitra', id: '12',to:'12/31' },
  ];
  

  console.log("🚀 ~ file: index.jsx ~ line 36 ~ Attendance ~ staff", staff)
  

  const [filterData, setFilterData] = useState({
    subdomain,
    odString: '2078/04/01',
    page: 0,
    limit: 10,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    const get_daybook = () => {
      // console.log(filterData);
      if (axiosToken) {
        // Cancel the previous request before making a new request
        axiosToken.cancel('operation canceled due to new request.');
      }
      let token = axios.CancelToken.source();
      setAxiosToken(token);
      dispatch(actions.get_admin(filterData.page === 0, filterData, token));
    };
    get_daybook();
    return () => {
      console.log('clear');
    };
    // eslint-disable-next-line
  }, [filterData,subdomain]);
  useEffect(() => {
    let token = axios.CancelToken.source();
    dispatch(actions.get_attendance({userid,subdomain}));
    dispatch(actions.get_staffDetails({userid:staffid,subdomain}));
    // dispatch(actions.get_staff(0,filterData,token));
  }, [dispatch,userid]);
  useEffect(() => {
    setAttendence(attendance);
  }, [attendance]);
  
  useEffect(() => {
    console.log("date string", dateString);
    setToday(`${dateString}||${dateString}`);
    setThisweek(
      `${dateString.split("/")[0]}/${dateString.split("/")[1]}/${
        dateString.split("/")[2] - 7
      }||${dateString}`
    );
    setThismonth(
      `${dateString.split("/")[0]}/${dateString.split("/")[1]}/01||${
        dateString.split("/")[0]
      }/${dateString.split("/")[1]}/32`
    );
    setThisyear(
      `${dateString.split("/")[0]}/01/01||${dateString.split("/")[0]}/12/32`
    );
  }, [dateString]);

  useEffect(() => {
    setDateString(
      `${nepaliDate.getYear()}/${
        nepaliDate.getMonth() + 1 > 9
          ? nepaliDate.getMonth() + 1
          : `0${nepaliDate.getMonth() + 1}`
      }/${
        nepaliDate.getDate() > 9
          ? nepaliDate.getDate()
          : `0${nepaliDate.getDate()}`
      }`
    );
  }, [nepaliDate]);

  const DurationSelectorHandler = (event) => {
  console.log("🚀 ~ file: index.jsx ~ line 129 ~ DurationSelectorHandler ~ dates", event.target.value)
    setDateswitch(event.target.value);
    let date = event.target.value.split("||");
    console.log("///datesfrom", date[0]);
    console.log("///datesto", date[1]);
    setStartDate(date[0]);
    setEndDate(date[1]);
  };

  const [value, setValue] = React.useState("1");
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // console.log("fd", filterData.dateString );
    let date = filterData.dateString
      ? filterData.dateString.split("/")
      : "2078/12/12".split("/");
    // console.log("date string",date);
    let newDate = new NepaliDate(
      parseInt(date[0]),
      parseInt(date[1]) - 1,
      parseInt(date[2])
    );
    let nD = new NepaliDate(
      parseInt(date[0]),
      parseInt(date[1]) - 1,
      parseInt(date[2])
    );

    // if (position === 0) {
    newDate.setDate(newDate.getDate() - 1);
    nD.setDate(nD.getDate() + 1);
    // } else if (position === 2) {
    //   nD.setDate(nD.getDate());
    //   newDate.setDate(newDate.getDate() + 1);
    // }

    setYesterdate(
      `${newDate.getYear()}/${
        newDate.getMonth() + 1 > 9
          ? newDate.getMonth() + 1
          : `0${newDate.getMonth() + 1}`
      }/${newDate.getDate() > 9 ? newDate.getDate() : `0${newDate.getDate()}`}`
    );
    setTwomorro(
      `${nD.getYear()}/${
        nD.getMonth() + 1 > 9 ? nD.getMonth() + 1 : `0${nD.getMonth() + 1}`
      }/${nD.getDate() > 9 ? nD.getDate() : `0${nD.getDate()}`}`
    );
  }, [filterData]);
 const getDayFromDate =(date)=>{
  let days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
  let dateP = date.split("/");
  // data is fortmated into dateP= ['ram','shyam','hari'];
  
  let newDate = new NepaliDate(
    parseInt(dateP[0]),
    parseInt(dateP[1]) - 1,
    parseInt(dateP[2])
  );
  return days[newDate.getDay()];
 }
  return (
    <Card>
    <CardHeader  style={{display:'flex',justifyContent:'space-between',flexDirection:'column'}} color="primary">
      <h4 style={{ color: "#fff", fontWeight: "400" }}>Profile List</h4>
     {dateswitch}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
          
      <Paper>
            <Box sx={{ minWidth: 130}}>
            <FormControl fullWidth>
            <InputLabel>Select Month</InputLabel>
             <Select>
              {months.map((data)=>
              (
                <MenuItem>{data.name}</MenuItem>
              )
)}
        
        </Select>
      </FormControl>
    </Box>
    </Paper>
    
<Paper>
            <Box sx={{ minWidth: 130 }}>
            <FormControl fullWidth>
            <InputLabel>Select Year</InputLabel>
             <Select>
                <MenuItem>2075 B.S</MenuItem>
                <MenuItem>2076 B.S</MenuItem>
                <MenuItem>2077 B.S</MenuItem>
                <MenuItem>2078 B.S</MenuItem>
                <MenuItem>2079 B.S</MenuItem>
                <MenuItem>2080 B.S</MenuItem>
                <MenuItem>2081 B.S</MenuItem>
                <MenuItem>2082 B.S</MenuItem>

        </Select>
      </FormControl>
    </Box>
    </Paper>

      {/*<FormControl fill="#ffff" variant="filled">
            
              <Select
                labelId="bill_date-label"
                id="bill_date"
                placeholder='Bill Time'
                defaultValue="Month"
                style={{ height: "40px",fill:'#fff' }}
                fill="#fff"
                selectedValue={dateswitch}
                onChange={(event) => DurationSelectorHandler(event)}
              >
                <MenuItem selected label="Today" value={today}>
                  Today
                </MenuItem>
                <MenuItem label="This Week" value={thisweek}>
                  This Week
                </MenuItem>
                <MenuItem label="This Month" value={thismonth}>
                  This Month
                </MenuItem>
                <MenuItem label="This Year" value={thisyear}>
                  This Year
                </MenuItem>
              </Select>
              
            </FormControl>
            <ArrowBackIosIcon
    style={{ height: "15px", marginTop: "10px" }}
  />
  <Calendar
    style={{
      width: "271px",
      borderColor: "#0000",
      backgroundColor: "#0000",
      borderBottom: "1px solid #929292",
      fontWeight: 700,
    }}
    language="en"
    theme="default"
    dateFormat="YYYY/MM/DD"
    onChange={(date) => {
      setStartDate(date.bsDate);
    }}
    //defaultDate={startDate}
  />
  <Button style={{color:'#fff'}}>To</Button>
  <Calendar
    style={{
      width: "271px",
      borderColor: "#0000",
      backgroundColor: "#0000",
      borderBottom: "1px solid #929292",
      fontWeight: 700,
    }}
    language="en"
    theme="default"
    dateFormat="YYYY/MM/DD"
    onChange={(date) => {
      setEndDate(date.bsDate);
    }}
    minDate={startDate}
    //defaultDate={endDate}
  />

  <DateRangePicker
    ranges={[selectionRange]}
    onChange={this.handleSelect}
  /> 
  <ArrowForwardIosIcon
    style={{ height: "15px", marginTop: "10px" }}
    
  /> */}
    
            </div>
      </CardHeader>
    <GridContainer>
    <GridItem xs={12} sm={12} md={8}>
    
      
      
      {attendence?.length > 0 ? (
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>User ID</TableCell>
              <TableCell>Day</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Check in Time</TableCell>
              <TableCell>Check Type</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Wifi Name</TableCell>
              <TableCell>Remarks</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attendence.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.userid}</TableCell>
                <TableCell style={{backgroundColor:row.date==dateString ? `#f08080` : ''}}>{getDayFromDate(row.date)}</TableCell>
                <TableCell style={{backgroundColor:row.date==dateString ? `#f08080`: ''}}>{row.date}</TableCell>
                <TableCell>{row.checktime}</TableCell>
                <TableCell>{row.checktype}</TableCell> 
                <TableCell>{row.reviewdby}</TableCell>
                <TableCell>{row.sensorid}</TableCell>
                <TableCell>{row.text}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <ShowLoadingOrEmpty message='No attendence Found' />
      )}
    
    </GridItem>
    <GridItem xs={12} sm={12} md={4}>
   
<ProfileCard details={staffData} fullname={staffData.stafffullname} />
<InputLabel shrink>Staff List</InputLabel>
              <Select
                name='userid'
                value={userid}
                onChange={(e)=> {setUserID(e.target.value.ID); setStaffId(e.target.value.staffid)}}
              >
                <MenuItem value={1}>
                  Choose Staff
                </MenuItem>
                {staff.map((staffData) => (
                  <MenuItem value={staffData}>
                    {staffData.fullname}
                  </MenuItem>
                ))}
              </Select>

    </GridItem>
      </GridContainer>
    </Card>
  );
}
