// eslint-disable-next-line
export default {
  username: {
    required: true,
    minValue: 4,
    label: 'User Name',
  },
  password: {
    required: true,
    minValue: 4,
    label: 'Password',
  },
};
