import { combineReducers } from 'redux';
import AuthReducers from '../modules/auth/store/AuthReducers';
import DashboardReducers from '../modules/store/DashboardReducers';
import ToasterReducer from '../common/Toast/services/ToasterReducer';
import { billReducer } from '../modules/inventory/BillEdit/store';
const rootReducer = combineReducers({
  authReducer: AuthReducers,
  dashboardReducer: DashboardReducers,
  bill: billReducer,
  toasterReducer: ToasterReducer,
});
export default rootReducer;
