import {
  Button,
  Paper,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PrintIcon from "@mui/icons-material/Print";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ShareIcon from '@mui/icons-material/Share';
import axios from "axios";
import NepaliDate from "nepali-date-converter";
import { GetRequest } from "plugins/axios";
import React, { useEffect, useRef, useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import Dropdown from "react-dropdown";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import ReactToPrint from "react-to-print";
import { downloadCSVFromJson } from "utils/DownloadCSV";
import exportPdf from "../../../../src/assets/img/export-pdf.png";
import api from "../../../api/api";
import excelFormat from "../../../assets/img/excel-format.png";
import * as actions from "../../store/DashboardActions";
import IndividualPurchases from "./Components/IndividualPurchases";
import IndividualSales from "./Components/IndividualSales";
import ad2bs from "ad-bs-converter";
import IndividualCrSales from "./Components/IndividualCrSales";
import { Box, Grid, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./index.css";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CustomerList from "./Components/CustomerList";
import IndividualLedger from "./Components/IndividualLedger";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  seeMore: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  button: {
    margin: 10,
    color:"#fff",
  },
}));
var adbs = require("ad-bs-converter");
export default function CustomerReport() {
  const [axiosToken, setAxiosToken] = useState("");
  const classes = useStyles();
  const [products, setProducts] = useState([0, 0, 0, 0, 0, 0]);
  const [creditTransaction, setCreditTransaction] = useState([]);
  const [casId, setCasId] = useState(0);
  const [savedCreditors, setSavedCreditors] = useState([]);
  const [casCr, setCasCr] = useState(0);
  const [inCr, setinCr] = useState(0);
  const [casPa, setCasPa] = useState(0);
  const [remaningTotal, setRemaningTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const [openBill, setOpenBill] = useState(false);
  const [creditTotal, setCreditTotal] = useState(0);
  const [paidTotal, setPaidTotal] = useState(0);
  const [image, setImage] = useState("");
  const [bill_type, setBill_type] = useState("SALE");
  const vendor = useSelector((state) => state.authReducer.user.vendor);
  const componentRef = useRef();
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState([]);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const [addOpen, setAddOpen] = useState(false);
  const [currentApp, setCurrentApp] = useState("");
  const { categoryId } = useParams();
  const salescasid = useSelector((state) => state.dashboardReducer.salescasid);
  const [billDate, setBillDate] = useState("");
  const [startDate, setStartDate] = useState("2077/01/01");
  const [endDate, setEndDate] = useState("2078/12/32");
  const [yesterdate, setYesterdate] = useState("");
  const [twomorro, setTwomorro] = useState("");
  const [bill_date, setBill_date] = useState("");
  const [start, setStart] = useState(false);
  const [end, setEnd] = useState(false);
  const [nepaliDate, setNepaliDate] = useState(new NepaliDate());
  const [dateString, setDateString] = useState("");
  const [today, setToday] = useState("2078/12/09");
  const [thisweek, setThisweek] = useState("2078/12/09");
  const [thismonth, setThismonth] = useState("2078/12/09");
  const [thisyear, setThisyear] = useState("2078/12/09");
  const [dateswitch, setDateswitch] = useState(today);

  const creditorsList = useSelector(
    (state) => state.dashboardReducer.creditors
  );
  const subdomain = useSelector((state) => state.authReducer.user.subdomain);
  // console.log("subdomain------------------",subdomain);
  const [filterData, setFilterData] = useState({
    subdomain,
    bill_type,
    dateString: "2078/04/11",
    odString: "2078/04/01",
    endDate: "2078/12/12",
    page: 0,
    limit: 10,
  });
  useEffect(() => {
    if (bill_type) {
      setFilterData({
        ...filterData,
        subdomain,
        bill_type: bill_type,
      });
      // console.log('bill_type----------',bill_type);
    }
    //eslint-disable-next-line
  }, [bill_type]);
  useEffect(() => {
    if (categoryId) {
      setFilterData({
        ...filterData,
        subdomain,
        categoryId: categoryId,
      });
    }
    //eslint-disable-next-line
  }, [categoryId]);

  useEffect(() => {
    let remaingTotal = 0;
    let creditTotal = 0;
    let paidTotal = 0;
    savedCreditors.forEach((d) => {
      remaingTotal += parseInt(d.total_payment);
      creditTotal += parseInt(d.total_credit);
      paidTotal += parseInt(d.RC);
    });

    setRemaningTotal(remaingTotal);
    setCreditTotal(creditTotal);
    setPaidTotal(paidTotal);
  }, [savedCreditors]);

  useEffect(() => {
    setProducts(creditorsList);
    setSavedCreditors(creditorsList);
  }, [creditorsList]);

  const handleClose = (value) => {
    setOpen(value);
  };

  const loadMore = (event) => {
    event.preventDefault();
    setFilterData({
      ...filterData,
      subdomain,
      page: filterData.page + 1,
    });
  };
  useEffect(() => {
    let newlist = savedCreditors.filter(
      (cas) => cas.fname?.toLowerCase().includes(search)
      // // console.log("creditors",cas)
    );
    // console.log(savedCreditors);
    // console.log("search...........",search);
    setProducts(newlist);
  }, [search]);

  useEffect(() => {
    dispatch(actions.get_customers(search, subdomain));
  }, [filterData, subdomain]);

  useEffect(() => {
    const get_sales_cas_id = () => {
      // console.log(filterData);
      if (axiosToken) {
        // Cancel the previous request before making a new request
        axiosToken.cancel("operation canceled due to new request.");
      }
      let token = axios.CancelToken.source();
      setAxiosToken(token);
      dispatch(
        actions.get_sales_item_from_cas_id(casId, subdomain, startDate, endDate)
      );
      get_customer_details(casId, subdomain);
      console.log("salescasid123", salescasid);
    };
    get_sales_cas_id();
    return () => {
      // console.log('clear');
    };
    // eslint-disable-next-line
  }, [casId, filterData, startDate, endDate]);

  const filterProducts = (data) => {
    setFilterData({
      ...filterData,
      subdomain,
      page: 0,
      ...data,
    });
    handleClose(!open);
  };

  const handleDataChange = (e) => {
    setFilterData({
      ...filterData,
      page: 0,
      [e.target.name]: e.target.value,
    });
  };
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    let salescas_cr = 0;
    let salescas_pa = 0;
    salescasid
      .filter((person) =>
        bill_type == "PURCHASE"
          ? person.bill_type == "PURCHASE" || person.bill_type == "VOUCHER"
          : person.bill_type == "SALE" || person.bill_type == "RECEIPT"
      )
      .map((row) => {
        salescas_cr += parseInt(row.net_amount ? row.net_amount : 0);
        salescas_pa += parseInt(row.paid_amount ? row.paid_amount : 0);
      });
    setCasCr(salescas_cr);
    setCasPa(salescas_pa);
  }, [salescasid]);
  const get_customer_details = (casId, subdomain) => {
    setLoading(true);
    GetRequest(`${api.customerDetailsAPI}`, { cas_id: casId, subdomain })
      .then((res) => {
        setLoading(false);
        // console.log("content",res.data.content);
        setCustomer(res.data.content);
        // console.log("customer details::::",customer);
        // get_product_category(res.data.content.category_id);
      })
      .catch((err) => {});
  };
  const handleChange = (e, productId, index) => {
    // console.log(products);
    // console.log(e.target.checked, { index });
    const newList = products;
    newList[index] = { ...newList[index], status: e.target.checked ? 1 : 0 };
    setProducts([...newList]);
    dispatch(
      actions.update_product_status(productId, e.target.checked ? 1 : 0)
    );
  };
  const openAddDialog = (app) => {
    setCurrentApp(app);
    setAddOpen(true);
  };
  const closeAddDialog = () => {
    setCurrentApp("");
    setAddOpen(false);
  };

  useEffect(() => {
    console.log("date string", dateString);
    setToday(`${dateString}||${dateString}`);
    setThisweek(
      `${dateString.split("/")[0]}/${dateString.split("/")[1]}/${
        dateString.split("/")[2] - 7
      }||${dateString}`
    );
    setThismonth(
      `${dateString.split("/")[0]}/${dateString.split("/")[1]}/01||${
        dateString.split("/")[0]
      }/${dateString.split("/")[1]}/32`
    );
    setThisyear(
      `${dateString.split("/")[0]}/01/01||${dateString.split("/")[0]}/12/32`
    );
  }, [dateString]);

  useEffect(() => {
    setDateString(
      `${nepaliDate.getYear()}/${
        nepaliDate.getMonth() + 1 > 9
          ? nepaliDate.getMonth() + 1
          : `0${nepaliDate.getMonth() + 1}`
      }/${
        nepaliDate.getDate() > 9
          ? nepaliDate.getDate()
          : `0${nepaliDate.getDate()}`
      }`
    );
  }, [nepaliDate]);
  
  const DurationSelectorHandler = (dates) => {
    setDateswitch(dates);
    let date = dates.split("||");
    console.log("///datesfrom", date[0]);
    console.log("///datesto", date[1]);
    setStartDate(date[0]);
    setEndDate(date[1]);
  };

  const [value, setValue] = React.useState("1");
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // console.log("fd", filterData.dateString );
    let date = filterData.dateString
      ? filterData.dateString.split("/")
      : "2078/12/12".split("/");
    // console.log("date string",date);
    let newDate = new NepaliDate(
      parseInt(date[0]),
      parseInt(date[1]) - 1,
      parseInt(date[2])
    );
    let nD = new NepaliDate(
      parseInt(date[0]),
      parseInt(date[1]) - 1,
      parseInt(date[2])
    );

    // if (position === 0) {
    newDate.setDate(newDate.getDate() - 1);
    nD.setDate(nD.getDate() + 1);
    // } else if (position === 2) {
    //   nD.setDate(nD.getDate());
    //   newDate.setDate(newDate.getDate() + 1);
    // }

    setYesterdate(
      `${newDate.getYear()}/${
        newDate.getMonth() + 1 > 9
          ? newDate.getMonth() + 1
          : `0${newDate.getMonth() + 1}`
      }/${newDate.getDate() > 9 ? newDate.getDate() : `0${newDate.getDate()}`}`
    );
    setTwomorro(
      `${nD.getYear()}/${
        nD.getMonth() + 1 > 9 ? nD.getMonth() + 1 : `0${nD.getMonth() + 1}`
      }/${nD.getDate() > 9 ? nD.getDate() : `0${nD.getDate()}`}`
    );
  }, [filterData]);

  return (
    <>
      <div>
        {/* <Grid item style={{ justifyContent: "space-between" }}> */}
        <Card>
        <CardHeader  style={{display:'flex',justifyContent:'space-between',flexDirection:'column'}} color="primary">
        <h4 style={{ color: "#fff", fontWeight: "400" }}>Customer Wise Report</h4>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="justify-between">
              <Button className={classes.button}>
              <ShareIcon color="#fff"/>
              </Button>
              <ReactToPrint
                trigger={() => (
                  <Button className={classes.button}>
                    <PrintIcon color="#fff"/>
                  </Button>
                )}
                content={() => componentRef.current}
              />
              <Button
                className={classes.button}
                onClick={() =>
                  downloadCSVFromJson("Credit Ledger.csv", salescasid)
                }
              >
                <DownloadForOfflineIcon color="#fff"/>
              </Button>
            </div>

            <span className="date-selector" style={{ margin: "10px" }}>

            <FormControl fill="#ffff" variant="filled" >
              {/* <InputLabel id="bill_date-label" style={{ paddingTop: "10px" }}>
                Bill Date
              </InputLabel> */} 
              <Select
                labelId="bill_date-label"
                id="bill_date"
                value={bill_date}
                onChange={(e) => setBill_date(e.target.value)}
                placeholder='Bill Time'
                defaultValue="Today"
                style={{ height: "40px",fill:'#fff' }}
                fill="#fff"
                selectedValue={dateswitch}
                onValueChange={(itemValue,) => DurationSelectorHandler(itemValue)}
              >
                <MenuItem selected label="Today" value={today}>
                  Today
                </MenuItem>
                <MenuItem label="This Week" value={thisweek}>
                  This Week
                </MenuItem>
                <MenuItem label="This Month" value={thismonth}>
                  This Month
                </MenuItem>
                <MenuItem label="This Year" value={thisyear}>
                  This Year
                </MenuItem>
              </Select>
            </FormControl>
              <ArrowBackIosIcon
                style={{ height: "15px", marginTop: "10px" }}
                onClick={(date) => {
                  setFilterData({
                    ...filterData,
                    subdomain,
                    dateString: yesterdate,
                    odString: yesterdate,
                    endDate: yesterdate,
                  });
                }}
              />
              <Calendar
                style={{
                  width: "271px",
                  borderColor: "#0000",
                  backgroundColor: "#0000",
                  borderBottom: "1px solid #929292",
                  fontWeight: 700,
                }}
                language="en"
                theme="default"
                dateFormat="YYYY/MM/DD"
                onChange={(date) => {
                  console.log("asdasdasdasdasd");
                  setStartDate(date.bsDate);
                }}
                //defaultDate={startDate}
              />
              <Button style={{color:'#fff'}}>To</Button>
              <Calendar
                style={{
                  width: "271px",
                  borderColor: "#0000",
                  backgroundColor: "#0000",
                  borderBottom: "1px solid #929292",
                  fontWeight: 700,
                }}
                language="en"
                theme="default"
                dateFormat="YYYY/MM/DD"
                onChange={(date) => {
                  setEndDate(date.bsDate);
                }}
                minDate={startDate}
                //defaultDate={endDate}
              />

              {/* <DateRangePicker
                ranges={[selectionRange]}
                onChange={this.handleSelect}
              /> */}
              <ArrowForwardIosIcon
                style={{ height: "15px", marginTop: "10px" }}
                onClick={(date) => {
                  setFilterData({
                    ...filterData,
                    subdomain,
                    dateString: twomorro,
                    odString: twomorro,
                    endDate: twomorro,
                  });
                }}
              />
            </span>
          </div>
          </CardHeader>
          <div ref={componentRef}>
            {/* <Title>Customer Reports</Title> */}
            {/* <Grid item> */}
            <Grid container ref={componentRef}>
        <Grid xs={3}>
         <h3 style={{textAlign:"center"}}>  {(value == "2" || value =="1") ? 'Sales Reports' : 'Purchase Reports'}</h3>
          <CustomerList  
          BILLTYPE={(value == "2" || value =="1") ? "SALE" : "PURCHASE"} 
          key={value}
          setCasId={setCasId} 
          products={products} />
        </Grid>
        <Grid item xs={9}>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleTabChange}>
                    <Tab label={"Sales"} value="1" />
                    <Tab label={"Credit Book"} value="2" />
                    <Tab label={"Purchase"} value="3" />
                    <Tab label={"Purchase Credit Book"} value="4" />
                    <Tab label={"Ledger"} value="1.1"/>
                    {/* <Tab label={"Journal Entry" } value="5"/>
                    <Tab label={"Trial Balance" } />
                    <Tab label={"Profit And Loss" } />
                    <Tab label={"Balance Sheet" } />
                    <Tab label={"Profit By Bills" } /> */}
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <IndividualSales
                    BILLTYPE="SALE"
                    salescasid={salescasid}
                    casId={casId}
                    startDate={startDate}
                    endDate={endDate}
                    setCasId={setCasId}
                  />
                </TabPanel>
                <TabPanel value="1.1">
                  <IndividualLedger
                    BILLTYPE="SALE"
                    salescasid={salescasid}
                    casId={casId}
                    startDate={startDate}
                    endDate={endDate}
                    setCasId={setCasId}
                  />
                </TabPanel>
                <TabPanel value="2">
                  <IndividualCrSales
                    BILLTYPE="SALE"
                    salescasid={salescasid}
                    casId={casId}
                    startDate={startDate}
                    endDate={endDate}
                    setCasId={setCasId}
                  />
                </TabPanel>
                <TabPanel value="3">
                  <IndividualSales
                    BILLTYPE="PURCHASE"
                    salescasid={salescasid}
                    casId={casId}
                    startDate={startDate}
                    endDate={endDate}
                    setCasId={setCasId}
                  />
                </TabPanel>
                <TabPanel value="4">
                  <IndividualCrSales
                    BILLTYPE="PURCHASE"
                    salescasid={salescasid}
                    casId={casId}
                    startDate={startDate}
                    endDate={endDate}
                    setCasId={setCasId}
                  />
                </TabPanel>
                {/* <TabPanel></TabPanel>
                  <TabPanel></TabPanel>
                  <TabPanel></TabPanel>
                  <TabPanel></TabPanel> */}
              </TabContext>
            </Box>
            </Grid>
      </Grid>
            {/* </Grid> */}
          </div>
        </Card>
        {/* </Grid> */}
      </div>
    </>
  );
}
