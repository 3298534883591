import {
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Title from '../../Components/Title';
import * as actions from '../../../store/DashboardActions';
import AddBotMessage from './Components/AddBotMessage';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ShowLoadingOrEmpty from '../../Components/ShowLoadingOrEmpty';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

export default function BotMessage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    dispatch(actions.get_bot_messages());
  }, [dispatch]);

  const setDialog = (value) => {
    setOpen(value);
  };

  const botMessageList = useSelector(
    (state) => state.dashboardReducer.bot_messages
  );

  return (
    <Paper className={classes.paper}>
      <div className='flex justify-between'>
        <Title>Bot Message</Title>
        <Button
          variant='contained'
          color='primary'
          startIcon={<AddCircleIcon />}
          onClick={() => setDialog(true)}
        >
          Add Bot Message
        </Button>
      </div>
      {botMessageList.length > 0 ? (
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Keyword</TableCell>
              <TableCell>type</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Sattus</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {botMessageList.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.keyword}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.message}</TableCell>
                <TableCell>{row.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <ShowLoadingOrEmpty message='No Bot Messages Found' />
      )}
      <div className={classes.seeMore}>
        <Link color='primary' href='#'>
          {botMessageList.length > 0
            ? 'Total Messages: ' + botMessageList.length
            : 'No Bot Messages'}
        </Link>
      </div>

      <AddBotMessage open={open} handleClose={() => setDialog(false)} />
    </Paper>
  );
}
