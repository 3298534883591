import React, { useEffect, useRef, useState } from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import {
  Avatar,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import FilterCreditors from './Components/FilterCreditors';
import EditIcon from '@material-ui/icons/Edit';
import * as actions from '../../store/DashboardActions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
// import ImageDialog from './Components/ImageDialog';
import ShowLoadingOrEmpty from '../../Dashboard/Components/ShowLoadingOrEmpty';
import Title from '../../Dashboard/Components/Title';
import api from '../../../api/api'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddStockAppMain from '../../Dashboard/modules/StockAppMain/Components/AddStockAppMain';
import BillDetaildiv from '../daybook/Components/BillDetailView';
import ReactToPrint from 'react-to-print';
import { downloadCSVFromJson } from 'utils/DownloadCSV';
import { GetRequest } from 'plugins/axios';
import Calendar from '@sbmdkl/nepali-datepicker-reactjs';
import Reports from 'modules/main/Reports';
// import AddProduct from './Components/AddProduct';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  button: {
    margin: 10,
  },
}));

export default function Sales() {
  const [axiosToken, setAxiosToken] = useState('');
  const classes = useStyles();
  const [products, setProducts] = useState([0,0,0,0,0,0]);
  const [creditTransaction, setCreditTransaction] = useState([]);
  const [casId, setcasId] = useState(0);
  const [savedCreditors, setSavedCreditors] = useState([]);
  const [remaningTotal, setRemaningTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const [openBill, setOpenBill] = useState(false);
  const [creditTotal, setCreditTotal] = useState(0);
  const [casPa, setCasPa] = useState(0);
  const [casCr, setCasCr] = useState(0);
  const [customer, setCustomer] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paidTotal, setPaidTotal] = useState(0);
  const [image, setImage] = useState('');
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();
  const [bill_type, setBill_type] = useState('SALE');
  const [br_type, setBR_type] = useState('C');
  const [addOpen, setAddOpen] = useState(false);
  const [balance, setBalance] = useState([]);
  const componentRef = useRef();
  
  const [currentApp, setCurrentApp] = useState('');
  const [ledger, setLedger] = useState('');
  const { categoryId } = useParams();
  const salescasid = useSelector((state) => state.dashboardReducer.salescasid);
  const creditorsList = useSelector((state) => state.dashboardReducer.customers);
  const subdomain = useSelector((state) => state.authReducer.user.subdomain);
  // console.log("subdomain------------------",subdomain);
  const [filterData, setFilterData] = useState({
    subdomain,
    dateString:'2078/04/11',
    odString: '2078/04/01',
    page: 0,
    limit: 10,
  });
  const vendor = useSelector((state) => state.authReducer.user.vendor);

  useEffect(()=>{
    let salescas_cr= 0;
    let salescas_pa= 0;
    salescasid.map((row)=>{
      salescas_cr += parseInt(row.net_amount ? row.net_amount : 0 )
      salescas_pa += parseInt(row.paid_amount ? row.paid_amount  : 0);
    })
    setCasCr(salescas_cr);
    setCasPa(salescas_pa);
  },[salescasid])
  useEffect(() => {
    if (categoryId) {
      setFilterData({
        ...filterData,
        subdomain,
        categoryId: categoryId,
      });
    }
    //eslint-disable-next-line
  }, [categoryId]);
  useEffect(() => {
    let remaingTotal = 0;
    let creditTotal = 0;
    let paidTotal = 0;
    savedCreditors.map((d) => {
      remaingTotal += parseInt(d.total_payment ? d.total_payment : 0);
      creditTotal += parseInt(d.total_credit  ? d.total_credit : 0);
      paidTotal += parseInt((d.RC == null) ? d.RC : 0);
    });
    
    setRemaningTotal(remaingTotal);
    setCreditTotal(creditTotal);
    setPaidTotal(paidTotal);
  }, [savedCreditors]);
  
  
  useEffect(() => {
    setProducts(creditorsList);
    setSavedCreditors(creditorsList);
  }, [creditorsList]);
  
  const handleClose = (value) => {
    setOpen(value);
  };
  
  const loadMore = (event) => {
    event.preventDefault();
    setFilterData({
      ...filterData,
      subdomain,
      page: filterData.page + 1,
    });
  };
  useEffect(()=>{
    let newlist = savedCreditors.filter((cas) =>
    cas.fname?.toLowerCase().includes(search)
    // // console.log("creditors",cas)
    );
    // console.log(savedCreditors);
    // console.log("search...........",search);
    setProducts(newlist);
  },[search]);
  
  useEffect(() => {
    const get_daybook = () => {
      // console.log(filterData);
      if (axiosToken) {
        // Cancel the previous request before making a new request
        axiosToken.cancel('operation canceled due to new request.');
      }
      let token = axios.CancelToken.source();
      setAxiosToken(token);
      dispatch(actions.get_creditors(filterData.page === 0, filterData, token));

    };
        get_daybook();
    return () => {
      // console.log('clear');
    };
    
    // eslint-disable-next-line
  }, [filterData,subdomain]);

  useEffect(() => {
    dispatch(actions.get_customers(search,subdomain));
  }, [filterData,subdomain]);
  
  useEffect(() => {
    let balance = salescasid.map((curr, i, array) => {
      return array[i] += array[i-1] ? array[i-1] : 0
    })
    setBalance(balance);
    // console.log("balance",balance);
  }, [casId,filterData]);
  useEffect(() => {
    const get_sales_cas_id = () => {
      // console.log(filterData);
      if (axiosToken) {
        // Cancel the previous request before making a new request
        axiosToken.cancel('operation canceled due to new request.');
      }
      let token = axios.CancelToken.source();
      setAxiosToken(token);
      dispatch(actions.get_sales_item_from_cas_id(casId,subdomain));
      get_customer_details(casId,subdomain);
    };
    get_sales_cas_id();
    return () => {
      // console.log('clear');
    };
    // eslint-disable-next-line
  }, [casId,filterData]);
  
  const filterProducts = (data) => {
    setFilterData({
      ...filterData,
      subdomain,
      page: 0,
      ...data,
    });
    handleClose(!open);
  };
  const get_customer_details = (casId,subdomain) => {
    setLoading(true);
    GetRequest(`${api.customerDetailsAPI}`, { cas_id:casId,subdomain })
      .then((res) => {
        setLoading(false);
        // console.log("content",res.data.content);
        setCustomer(res.data.content);
      // console.log("customer details::::",customer);
      // get_product_category(res.data.content.category_id);
      })
      .catch((err) => {
        
      });
  };
  const handleDataChange = (e) => {
    setFilterData({
      ...filterData,
      page: 0,
      [e.target.name]: e.target.value,
    });
  };
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  
  const handleChange = (e, productId, index) => {
    // console.log(products);
    // console.log(e.target.checked, { index });
    const newList = products;
    newList[index] = { ...newList[index], status: e.target.checked ? 1 : 0 };
    setProducts([...newList]);
    dispatch(
      actions.update_product_status(productId, e.target.checked ? 1 : 0)
      );
    };
    const openAddDialog = (app) => {
      setCurrentApp(app);
      setAddOpen(true);
    };
    const closeAddDialog = () => {
      setCurrentApp('');
      setAddOpen(false);
    };
    
    return (
      <>
      <Reports/>
      <Grid container spacing={1}>
      <Grid item xs={4}>

      <Paper className={classes.paper}>
      {/* <Button
        variant='contained'
        color='primary'
        startIcon={<AddCircleIcon />}
        onClick={() => openAddDialog()}
        >
        Add Items
      </Button> */}
      
      <div className='flex justify-between'>
      <TextField
      id='outlined-basic'
      label='Search'
      fullWidth
      style={{ margin: 10 }}
      variant='outlined'
      name='search'
      onChange={handleSearchChange}
      />
      
      {/* <FormControl
      className={classes.formControl}
      style={{ margin: 10 }}
      variant='outlined'
      >
      <InputLabel shrink>Limit</InputLabel>
      <Select
      name='limit'
      value={filterData.limit}
      onChange={handleDataChange}
      >
      <MenuItem value={10}>10</MenuItem>
      <MenuItem value={20}>20</MenuItem>
      <MenuItem value={30}>30</MenuItem>
      <MenuItem value={50}>50</MenuItem>
      <MenuItem value={100}>100</MenuItem>
      </Select>
      </FormControl> */}
      {/* <Button
      className={classes.button}
      startIcon={<EditIcon />}
      onClick={() => handleClose(!open)}
      >
      {filterData.categoryId ||
        filterData.vendorId ||
        filterData.subtract ||
        filterData.status
        ? 'Edit Filter'
        : 'Filter'}
        </Button> */}
        {/* <Button
        className={classes.button}
        href={`${API_URL}/product?excel=true&page=${filterData?.page}&limit=${filterData?.limit}&search=${filterData?.search}&categoryId=${filterData?.categoryId}&vendorId=${filterData?.vendorId}&status=${filterData?.status}&price=${filterData?.price}`}
        >
        Export Excel
        </Button> */}
        </div>
        {products.length > 0 ? (
          <Table size='small'>
          <TableHead>
          <TableRow>
          <TableCell>Id</TableCell>
          <TableCell>CASID</TableCell>
          <TableCell>Name</TableCell>
          <TableCell align='right'>Contact</TableCell>
          </TableRow>
          </TableHead>
          <TableBody>
          {products.map((row, index) => (
            <TableRow key={index} onClick={()=> {setcasId(row.cas_id); }}>
            <TableCell>{index+1}</TableCell>
            <TableCell >{row.cas_id}</TableCell>
            <TableCell>{`${row.fname} ${row.lname} `}</TableCell>
            <TableCell align='right'>{row.contact}</TableCell>
            </TableRow>
            ))}
            </TableBody>
            <TableHead>
            <TableRow>
            <TableCell>#</TableCell>
            <TableCell>***</TableCell>
            <TableCell>***</TableCell>
            <TableCell align='right'>{remaningTotal}</TableCell>
            </TableRow>
            </TableHead>
            </Table>
            ) : (
              <ShowLoadingOrEmpty message='No customers Found' />
              )}
              
              <div className={classes.seeMore}>
              {/* <Link color='primary' href='#' onClick={loadMore}>
              See more orders
              </Link> */}
              </div>
              {/* <AddProduct
                open={addOpen}
                handleClose={closeAddDialog}
                editStockData={currentApp}
              /> */}
              {open && (
                <FilterCreditors
                open={open}
                data={filterData}
                handleClose={() => handleClose(!open)}
                filterProducts={filterProducts}
                />
                )}
                {/* {openImage && (
                  <ImageDialog
                  open={openImage}
                  handleClose={() => setOpenImage(false)}
                  image={image}
                  />
                )} */}
                </Paper>
                </Grid>
            
                <Grid item xs={8}>
                <div className='flex justify-between'>
                  <div>
      <Select
      name='limit'
      value={bill_type}
      onChange={(e)=>{setBill_type(e.target.value)}}
      >
      <MenuItem value={'PURCHASE'}>PURCHASE</MenuItem>
      <MenuItem value={'SALE'}>SALE</MenuItem>
      <MenuItem value={'EXPENSE'}>EXPENSE</MenuItem>
      <MenuItem value={'RECIEPT'}>RECIEPT</MenuItem>
      <MenuItem value={'VOUCHER'}>VOUCHER</MenuItem>
      </Select>
          {/* <p>{JSON.stringify(filterData)}</p> */}
      <Select
      name='BR'
      value={br_type}
      onChange={(e)=>{setBR_type(e.target.value)}}
      >
      <MenuItem value={'C'}>Cash</MenuItem>
      <MenuItem value={'CR'}>Credit</MenuItem>
      </Select>
          {/* <p>{JSON.stringify(filterData)}</p> */}
     
                {/* <Paper className={classes.paper}>
                person details
                </Paper> */}
                </div><div>
                <ReactToPrint
          trigger={() => <Button  color='primary'>
          Print
        </Button>}
          content={() => componentRef.current}
        />
         <Button
          className={classes.button}
          onClick={()=>downloadCSVFromJson('Ledger.csv',salescasid)}
          //href={`${API_URL}/product?excel=true&page=${filterData?.page}&limit=${filterData?.limit}&search=${filterData?.search}&categoryId=${filterData?.categoryId}&vendorId=${filterData?.vendorId}&status=${filterData?.status}&price=${filterData?.price}`}
        >
          Export Excel
        </Button>
        </div>
        </div>
                <Paper className={classes.paper} ref={componentRef}>
                  <center>
<h4>Ledger Account</h4>
<div gutter={24} style={{ marginTop: 32,display:"flex",flexDirection:'row',justifyContent:'space-between' }}   >
<div span={12} >
            <table>
              <tr>
                <th>Invoice # :</th>
                <td>{customer.cas_id}</td>
              </tr>
              <tr>
                <th>Customer :</th>
                <td>{customer?.fname} {customer?.lname}</td>
              </tr>
              <tr>
                <th>Contact :</th>
                <td>{customer?.contact}</td>
              </tr>
            </table>
          </div>
          <div span={4}>
            <h3>{vendor?.mainappname}</h3>
            <div>{vendor?.pan}</div>
            {/* <div>Vijaya Bank Layout,</div>
            <div>Bannerghatta Road,</div> */}
            {/* <div>{vendor?.pan}</div> */}
            <Calendar hideDefaultValue={true} placeholder="Chose Date" value={filterData.dateString} key={filterData} language="en" theme="deepdark" style={{color:'#000',display:'block',width:'300px'}} dateFormat="YYYY/MM/DD" onChange={(date) => {setFilterData({...filterData,subdomain,dateString:date.bsDate,odString:date.bsDate})}}> </Calendar>
            <Calendar hideDefaultValue={true} placeholder="Chose Date" value={filterData.dateString} key={filterData} language="en" theme="deepdark" style={{color:'#000',display:'block',width:'300px'}} dateFormat="YYYY/MM/DD" onChange={(date) => {setFilterData({...filterData,subdomain,dateString:date.bsDate,odString:date.bsDate})}}> </Calendar>
          </div>
         
        </div>
</center>
                {/* <Button
                  variant='contained'
                  color='primary'
                  startIcon={<AddCircleIcon />}
                  onClick={() => openAddDialog()}
                  >
                  Add Items
                </Button> */}
                {salescasid.length > 0 ? (
                  <Table size='small'>
                  <TableHead>
                  <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Bill No</TableCell>
                  {/* <TableCell>Name</TableCell> */}
                  <TableCell align='right'>Total amount</TableCell>
                  <TableCell align='right'>Paid Amount</TableCell>
                  <TableCell align='right'>Balance</TableCell>
                  </TableRow>
                  </TableHead>
                  <TableBody>
                  {salescasid.filter(person => person.bill_type == bill_type).filter(person => person.BR == br_type).map((row, index) => (
                    <TableRow key={index} onClick={()=>{setImage(row.connect_id); setOpenBill(true)}}>
                    <TableCell>{index+1}</TableCell>
                    <TableCell align='right'>{row.bill_date}</TableCell>
                    <TableCell>{`${row.bill_type}(${row.BR})`}</TableCell>
                    <TableCell >{row.connect_id}</TableCell>
                    {/* <TableCell>{`${row.fname} ${row.lname} `}</TableCell> */}
                    <TableCell align='right'>Rs.{row.net_amount}</TableCell>
                    <TableCell align='right'>Rs.{row.paid_amount}</TableCell>
                    <TableCell align='right'>Rs.{balance[row.index]}</TableCell>
                    </TableRow>
                    ))}
                    </TableBody>
                    <TableHead>
                    <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>***</TableCell>
                    <TableCell>***</TableCell>
                    <TableCell>***</TableCell>
                    <TableCell>***</TableCell>
                    <TableCell align='right'>{casCr}</TableCell>
                    <TableCell align='right'>{casPa}</TableCell>
                    <TableCell align='right'>{casCr - casPa}</TableCell>
                    </TableRow>
                    </TableHead>
                    </Table>
                    ) : (
                      <ShowLoadingOrEmpty message='No Products Found' />
                      )}
                      
                      {/* <div className={classes.seeMore}>
                      {/* <Link color='primary' href='#' onClick={loadMore}>
                      See more orders
                      </Link> 
                      </div> */}
                      {/* <AddProduct
                        open={addOpen}
                        handleClose={closeAddDialog}
                        editStockData={currentApp}
                      /> */}
                      {open && (
                        <FilterCreditors
                        open={open}
                        data={filterData}
                        handleClose={() => handleClose(!open)}
                        filterProducts={filterProducts}
                        />
                        )}
                        <BillDetaildiv open={openBill} connect_id={image} data={{connect_id:image, bill_date:'2078/04/12'}} bill_date={'2078/04/12'} subdomain={subdomain} handleClose={() => setOpenBill(false)} />
                        {/* {openImage && (
                          <ImageDialog
                          open={openImage}
                          handleClose={() => setOpenImage(false)}
                          image={image}
                          />
                        )} */}
                        </Paper>
                        </Grid>
                    </Grid>
                    </>
                        );
                      }
                      