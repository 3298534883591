import React from 'react'
import { useSelector } from 'react-redux';

export default function PrintHeader({startDate,endDate,title}) {
    const vendor = useSelector((state) => state.authReducer.user.vendor);
    console.log("🚀 ~ file: PrintHeader.js ~ line 6 ~ PrintHeader ~ vendor", vendor)

  return (
    <div>
        <div style={{ position: "relative",padding:10 }}>
                    <h3>{vendor?.mainappname}</h3>
                    <div>{vendor?.notice}</div>
                    <table style={{ display: "flex", flexDirection: "column", fontSize: "initial", fontWeight: "100" }}>
                    <tr>
                    <th>{title} </th>
                    </tr>
                    <tr>
                    <th>From :</th>
                    <td>{startDate}</td>
                    </tr>
                    <tr>
                    <th>To :</th>
                    <td>{endDate}</td>
                    </tr>
                    </table>
                    <span>
                    <img  src={`https://cdn.pasal.com.np/${vendor?.applogo}`} alt='' style={{ position: "absolute",width:'60px',height:'60px', right: "10px", top: "10px" }} />
                    </span>
                    </div>
    </div>
  )
}
