import * as actionTypes from './ToasterType';
const initialState = {
  open: false,
  title: '',
  name: '',
  message: '',
  aimation:'',
};

const ToasterReducer = (state = initialState, action) => {
  const { type, open, title, name, message,animation } = action;
  switch (type) {
    case actionTypes.SET_TOASTER:
      return {
        ...state,
        open: open,
        title: title,
        name: name,
        message: message,
        animation:animation,
      };
    default:
      return state;
  }
};

export default ToasterReducer;
