import * as actionTypes from './DashboardTypes';
const initialState = {
  categories: [],
  products: [],
  openings: [],
  daybook: [],
  creditors: [],
  staff: [],
  staffDetails: [],
  customerProfile:[],
  admin: [],
  menu: [],
  vendors: [],
  attendance: [],
  manufacturer: [],
  apps: [],
  bot_messages: [],
  customers: [],
  appointments: [],
  salescasid: [],
  orders: [],
  drawerOpen: true,
  drawerOpener: false,
  hamOpener: false,
  loading: false,
  openenv: false,
  envdata: {},
  displayStock: false,
  monthlyReport: [],
  profitByBills: [],
  note:[],

};

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
        loading: false,
      };
      case actionTypes.GET_PRODUCTS:
        return {
          ...state,
          products: action.clear
            ? action.payload
            : [...state.products, ...action.payload],
          loading: false,
        };
        case actionTypes.GET_OPENINGS:
        return {
          ...state,
          openings: action.clear
            ? action.payload
            : [...state.openings, ...action.payload],
          loading: false,
        };
    case actionTypes.GET_DAYBOOK:
      return {
        ...state,
        daybook: action.clear
          ? action.payload
          : [...state.daybook, ...action.daybook],
        loading: false,
      };
    case actionTypes.GET_CREDITORS:
      return {
        ...state,
        creditors: action.clear
          ? action.payload
          : [...state.creditors, ...action.creditors],
        loading: false,
      };
      case actionTypes.GET_STAFF:
        return {
          ...state,
          staff: action.clear
            ? action.payload
            : [...state.staff, ...action.staff],
          loading: false,
        };  
        case actionTypes.GET_STAFFDETAILS:
        return {
          ...state,
          staffDetails: action.payload,
          loading: false,
        }; 
         
        case actionTypes.GET_CUSTOMERPROFILE:
        return {
          ...state,
          customerProfile: action.payload,
          loading: false,
        };  
        case actionTypes.GET_NOTE:
        return {
          ...state,
          note: action.clear
            ? action.payload
            : [...state.note, ...action.note],
          loading: false,
        };  
        case actionTypes.GET_ADMIN:
          return {
            ...state,
            admin: action.clear
              ? action.payload
              : [...state.admin, ...action.admin],
            loading: false,
          }; 
        case actionTypes.GET_MENU:
        return {
          ...state,
          menu: action.clear
            ? action.payload
            : [...state.menu, ...action.menu],
          loading: false,
        };
    case actionTypes.GET_VENDORS:
      return {
        ...state,
        vendors: action.payload,
        loading: false,
      };
    case actionTypes.GET_ATTENDANCE:
      return {
        ...state,
        attendance: action.payload,
        loading: false,
      };
    case actionTypes.GET_MANUFACTURER:
    return {
      ...state,
      manufacturer: action.payload,
      loading: false,
    };
    case actionTypes.GET_DRAWER_OPEN:
      return {
        ...state,
        drawerOpen: action.payload,
        loading: false,
      };
      case actionTypes.GET_DRAWER_OPENER:
      return {
        ...state,
        drawerOpener: action.payload,
        loading: false,
      };
    case actionTypes.GET_HAM_OPENER:
      return {
        ...state,
        hamOpener: action.payload,
        loading: false,
      }
    case actionTypes.GET_APPS:
      return {
        ...state,
        apps: action.payload,
        loading: false,
      };
    case actionTypes.GET_BOT_MESSAGES:
      return {
        ...state,
        bot_messages: action.payload,
        loading: false,
      };
    case actionTypes.GET_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
        loading: false,
      };
      case actionTypes.GET_APPOINTMENTS:
        return {
          ...state,
          appointments: action.payload,
          loading: false,
        };
    case actionTypes.GET_SALES_CASID:
      return {
        ...state,
        salescasid: action.payload,
        loading: false,
      };
    case actionTypes.GET_ORDERS:
      return {
        ...state,
        orders: action.payload,
        loading: false,
      };
    case actionTypes.GET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.GET_OPEN_ENV:
      return {
        ...state,
        openenv: action.payload,
      };
    case actionTypes.GET_ENV_DATA:
      return {
        ...state,
        envdata: action.payload,
      };
      case actionTypes.GET_DISPLAY_STOCK:
        return {
          ...state,
          displayStock: action.payload,
        };
      case actionTypes.GET_MONTHLY_REPORT:
        return {
          ...state,
          displayMonthlyReport: action.payload,
        };
        case actionTypes.GET_PROFIT_BY_BILLS:
          return {
            ...state,
            displayProfitbyBills: action.payload,
          };
    default:
      return state;
  }
};

export default DashboardReducer;
