import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from './store/ToasterAction';
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Alert from '@material-ui/lab/Alert';
import { Snackbar, Typography } from '@mui/material';
import { LinearProgress, Slide } from '@material-ui/core';

function TransitionLeft(props) {
  return <Slide {...props} direction="up" />;
}
export default function Toast() {
  const loading = useSelector((state) => state.dashboardReducer.loading);

  const { message, open, title, name } = useSelector(
    (state) => state.toasterReducer
  );
  const showTime = 5000;
  const dispatch = useDispatch();

  const handleClose = () => {

    const toasterData = {
      open: false,
      type: '',
      name: '',
      message: '',
    };
    dispatch(actions.setToasterState(toasterData));
  };
  return (
    <>
   
   {loading && (<LinearProgress />)}
    <Snackbar
    anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
    TransitionComponent={TransitionLeft}
     open={open} autoHideDuration={showTime} onClose={handleClose}>
      <Alert onClose={handleClose} severity={title}>
        <div>{message}</div>
      </Alert>
    </Snackbar> 
    </>
  );
}
