import React, { useState, useEffect, useRef } from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import { Button, InputLabel } from "@material-ui/core";
import NepaliDate from "nepali-date-converter";
import Search from "@material-ui/icons/Search";
import CustomInput from "components/CustomInput/CustomInput";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";
import { GetRequest } from "plugins/axios";
import api from "api/api";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const useStyles = makeStyles(styles);

const columns = [
  { id: "bill_date", label: "Date", minWidth: 170 },
  { id: "Particular", label: "Particular", minWidth: 100 },
  {
    id: "DR",
    label: "Debit",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "CR",
    label: "Credit",
    minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData("India", "IN", 1324171354, 3287263),
  createData("China", "CN", 1403500365, 9596961),
  createData("Italy", "IT", 60483973, 301340),
  createData("United States", "US", 327167434, 9833520),
  createData("Canada", "CA", 37602103, 9984670),
  createData("Australia", "AU", 25475400, 7692024),
  createData("Germany", "DE", 83019200, 357578),
  createData("Ireland", "IE", 4857000, 70273),
  createData("Mexico", "MX", 126577691, 1972550),
  createData("Japan", "JP", 126317000, 377973),
  createData("France", "FR", 67022000, 640679),
  createData("United Kingdom", "GB", 67545757, 242495),
  createData("Russia", "RU", 146793744, 17098246),
  createData("Nigeria", "NG", 200962417, 923768),
  createData("Brazil", "BR", 210147125, 8515767),
];

export default function JournalEntry() {
  const componentRef = useRef();
  const classes = useStyles();
  const [billDate, setBillDate] = useState("");
  const subdomain = useSelector((state) => state.authReducer.user.subdomain);
  const [monthReport, setMonthReport] = useState([]);
  const [monthExpenseReport, setmonthExpenseReport] = useState([]);

  const nepaliDate = new NepaliDate();
  let date = "2078/04/04";
  const options = ["This Month", "Last Month", "Jan"];
  const dataTypes = ["All Forms", "Sales", "Purchase"];
  const defaultSelector = dataTypes[0];
  const defaultOption = options[0];

  useEffect(() => {
    date = `${nepaliDate.getYear()}/${
      nepaliDate.getMonth() + 1 > 9
        ? nepaliDate.getMonth() + 1
        : `0${nepaliDate.getMonth() + 1}`
    }/${
      nepaliDate.getDate() > 9
        ? nepaliDate.getDate()
        : `0${nepaliDate.getDate()}`
    }`;
    setBillDate(date + "");
  }, []);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const rows = [
  //     'a', 'b', 'c'
  // ];

  // const columns = [
  //     's', 'd', 'f'
  // ];
  const getJournalEntry = () => {
    GetRequest(`${api.journalEntryAPI}`, { subdomain, bill_type: "SALE",startDate:'2078/01/01',endDate:'2078/12/32' })
      .then((res) => {
        setMonthReport(res.data);
        console.log("Journal Report", res.data);
      })
      .catch((err) => {
        alert(`🚀 ~ file: totalSales.js ~ line 133 ~ getMonthReport ~ ${err}`);
      });
    // GetRequest(`${api.monthlyReportsAPI}`, { subdomain,bill_type:'EXPENSE' })
    // .then((res) => {
    //     setmonthExpenseReport(res.data);
    //     console.log('Monthly Report',res.data);
    // })
    // .catch((err) => {
    //     alert( 'Cannot get MonthReport');
    // });
  };
  useEffect(() => {
    getJournalEntry();
    console.log("Journal Report", monthReport);
  }, [subdomain]);

  return (
    <>
    {/* journal entry table */}
      {/* <div className="sales-topbar">
        <Dropdown
          options={options}
          value={defaultOption}
          placeholder="Select an option"
        />
        <span className="date-selector">
          <ArrowBackIosIcon style={{ height: "15px", marginTop: "20px" }} />
          <Button>Between</Button>
          <Calendar
            style={{
              width: "271px",
              borderColor: "#0000",
              backgroundColor: "#0000",
              borderBottom: "1px solid #929292",
              fontWeight: 700,
            }}
            language="en"
            theme="default"
            dateFormat="YYYY/MM/DD"
            onChange={(date) => {
              setBillDate(date.bsDate + "");
            }}
          />
          <Button>To</Button>
          <Calendar
            style={{
              width: "271px",
              borderColor: "#0000",
              backgroundColor: "#0000",
              borderBottom: "1px solid #929292",
              fontWeight: 700,
            }}
            language="en"
            theme="default"
            dateFormat="YYYY/MM/DD"
            onChange={(date) => {
              setBillDate(date.bsDate + "");
            }}
          />
          <ArrowForwardIosIcon style={{ height: "15px", marginTop: "20px" }} />
        </span>
        <Dropdown
          options={dataTypes}
          value={defaultSelector}
          placeholder="Select an option"
        />
        <ReactToPrint
          trigger={() => <Button color="primary">Print</Button>}
          content={() => componentRef.current}
        />
      </div>
      <div className="search-bar">
        <div className={classes.searchWrapper}>
          <InputLabel
            style={{
              color: "rgb(0,0,0)",
              marginTop: "20px",
              marginBottom: "4px",
            }}
          >
            TRANSACTIONS
          </InputLabel>
          <CustomInput
            formControlProps={{
              className: classes.margin + " " + classes.search,
            }}
            inputProps={{
              placeholder: "Search",
              inputProps: {
                "aria-label": "Search",
              },
            }}
          />
          <Button color="white" aria-label="edit" justIcon round>
            <Search />
          </Button>
        </div>
        <span style={{ alignItems: "center", display: "flex" }}>
          <Button
            variant="contained"
            color="primary"
            style={{ padding: "9px 13px" }}
            startIcon={<AddCircleIcon />}
            // onClick={() => openAddDialog()}
          >
            Add Customer
          </Button>
        </span>
      </div> */}
      <div >
        <TableContainer sx={{ maxHeight: '80vh' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    // style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
              // typeof monthReport == "array" &&
                monthReport?.map((row,index) => {
                    return (
                      <>
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {/* {columns.map((column) => { */}
                          {/* const value = row[column.id];
                          return ( */}
                            <TableCell > {index+1}</TableCell>
                            <TableCell > {row.Particular}</TableCell>
                            <TableCell align={'right'}> {row.DR}</TableCell>
                            <TableCell align={'right'}> -</TableCell>
                          {/* ); */}
                        {/* })} */}
                      </TableRow>
                      <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {/* {columns.map((column) => { */}
                        {/* const value = row[column.id];
                        return ( */}
                          <TableCell > </TableCell>
                          <TableCell > To {row.Crticular}/{row.cas_id}</TableCell>
                          <TableCell align={'right'}> -</TableCell>
                          <TableCell align={'right'}> {row.DR}</TableCell>
                        {/* ); */}
                      {/* })} */}
                    </TableRow>
                    <TableRow>
                    <TableCell > </TableCell>

                      <TableCell colSpan={3}>
                        <div className="total-sales">
                          <span>Being {(row.Particular).toLowerCase()} due {row.bill_date} </span>
                          <span>of Rs.{row.DR}</span>
                        </div>
                      </TableCell>
                    </TableRow>

                    </>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
}
