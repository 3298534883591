import PublicBillView from 'modules/PublicBill/PublicBillView';
import PublicLedgerView from 'modules/PublicBill/PublicLedgerView';
import Login from '../modules/auth/Login';
import Dashboard from '../modules/Dashboard';
const routeList = [
  {
    path: '/login',
    name: 'login',
    exact: true,
    isAuth: false,
    component: Login,
  },
  {
    path: '/',
    name: 'home',
    exact: true,
    isAuth: false,
    component: Login,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    exact: false,
    isAuth: true,
    component: Dashboard,
  },
  {
    path: '/inventory',
    name: 'dashboard',
    exact: false,
    isAuth: true,
    component: Dashboard,
  },
  {
    path: '/cascare/',
    name: 'dashboard',
    exact: false,
    isAuth: true,
    component: Dashboard,
  },
  {
    path: '/staff/',
    name: 'dashboard',
    exact: false,
    isAuth: true,
    component: Dashboard,
  },
  {
    path: '/main',
    name: 'dashboard',
    exact: false,
    isAuth: true,
    component: Dashboard,
  },
  {
    path: '/CMS',
    name: 'dashboard',
    exact: false,
    isAuth: true,
    component: Dashboard,
  },
  {
    path: '/Billview/:subdomain/:connect_id',
    name: 'bill',
    exact: true,
    isAuth: false,
    component: PublicBillView,
  },
  {
    path: '/Statement/:subdomain/:connect_id',
    name: 'statement',
    exact: true,
    isAuth: false,
    component: PublicLedgerView,
  },
];

export default routeList;
