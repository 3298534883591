import React, { useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { styled } from '@mui/system';
import SwitchUnstyled, { switchUnstyledClasses } from '@mui/base/SwitchUnstyled';
import axios from 'axios';
import './index.css'
import Skeleton from 'react-loading-skeleton'
import ContentLoader from "react-content-loader"
import 'react-loading-skeleton/dist/skeleton.css'
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { AgGridColumn } from 'ag-grid-react/lib/agGridColumn';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {
  Avatar,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Tooltip,
} from '@material-ui/core';
import FilterProducts from './Components/FilterProducts';
import EditIcon from '@material-ui/icons/Edit';
import * as actions from '../../store/DashboardActions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import ImageDialog from './Components/ImageDialog';
import ShowLoadingOrEmpty from '../../Dashboard/Components/ShowLoadingOrEmpty';
import Title from '../../Dashboard/Components/Title';
import { API_URL, CDN_URL } from '../../../config';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddStockAppMain from '../../Dashboard/modules/StockAppMain/Components/AddStockAppMain';
import AddProduct from './Components/AddProduct';
import BillDetailView from '../daybook/Components/BillDetailView';
import { downloadCSVFromJson } from 'utils/DownloadCSV';
import { PutRequest } from 'plugins/axios';
import MergeProducts from './Components/MergeProducts';
import UnstyledSwitcher from 'components/UnstyledSwitcher';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import { Add, Search } from '@material-ui/icons';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
    color:'#fff'
  },
  headerButton: {
    margin: 10,
    color:"#fff",
  },
  button: {
    margin: 10,
    
  },
}));

export default function Product({ vId, cId }) {
  const [axiosToken, setAxiosToken] = useState('');
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchable, setSearchable] = useState(false);
  const [openMerge, setOpenMerge] = useState(false)
  const [openImage, setOpenImage] = useState(false);
  const [image, setImage] = useState('');
  const dispatch = useDispatch();
  const [addOpen, setAddOpen] = useState(false);
  const [currentApp, setCurrentApp] = useState('');
  const { categoryId } = useParams();
  const productList = useSelector((state) => state.dashboardReducer.products);
  const vendorId = useSelector((state) => state.authReducer.user.vendorId);
  const [today, setToday] = useState("2078/12/09");
  const [thisweek, setThisweek] = useState("2078/12/09");
  const [thismonth, setThismonth] = useState("2078/12/09");
  const [thisyear, setThisyear] = useState("2078/12/09");
  const [dateswitch, setDateswitch] = useState(today);
  const [startDate, setStartDate] = useState("2078/01/01");
  const [endDate, setEndDate] = useState("2078/12/32");
  
  const [gridApi, setGridApi] = useState(null);

  const [gridColumnApi, setGridColumnApi] = useState(null);
  // console.log("subdomain------------------",subdomain);
  const [totalData, setTotalData] = useState({
    quantity: 0,
    price: 0,
  });
  const [filterData, setFilterData] = useState({
    categoryId: '',
    vendorId: '',
    status: '',
    subtract: 1,
    stock: true,
    image: false,
    search: '',
    page: 0,
    limit: false,
    price: '',
  });
  useEffect(() => {
    if (categoryId) {
      setFilterData({
        ...filterData,
        categoryId: categoryId,
        vendorId: vendorId,
      });
    }
    //eslint-disable-next-line
  }, [categoryId, vendorId]);

  useEffect(() => {
    setProducts(productList);
  }, [productList]);

  const DurationSelectorHandler = (dates) => {
    setDateswitch(dates);
    let date = dates.split("||");
    console.log("///datesfrom", date[0]);
    console.log("///datesto", date[1]);
    setStartDate(date[0]);
    setEndDate(date[1]);
  };

  const handleClose = (value) => {
    setOpen(value);
  };
  const handleMClose = (value) => {
    setOpenMerge(value);
  };
  const loadMore = (event) => {
    event.preventDefault();
    setFilterData({
      ...filterData,
      page: filterData.page + 1,
    });
  };
  useEffect(() => {
    let totalQuantity = 0;
    let totalPrice = 0;
    products.forEach((bill) => {
      totalQuantity += parseInt(bill.stock);
      totalPrice += parseInt(bill.stock * bill.cost);
    });
    setTotalData({
      ...totalData,
      price: totalPrice,
      quantity: totalQuantity,
    });
  }, [products]);

  const get_products = () => {
    // console.log(filterData);
    if (axiosToken) {
      // Cancel the previous request before making a new request
      axiosToken.cancel('operation canceled due to new request.');
    }
    let token = axios.CancelToken.source();
    setAxiosToken(token);
    dispatch(actions.get_products(filterData.page === 0, filterData, token));
    // dispatch(actions.get_products_from_category(filterData.page === 0, filterData, token));
  };
  useEffect(() => {

    get_products();
    return () => {
      // console.log('clear');
    };
    // eslint-disable-next-line
  }, [filterData, vendorId, categoryId]);

  const filterProducts = (data) => {
    setFilterData({
      ...filterData,
      page: 0,
      ...data,
    });
    handleClose(!open);
  };
  const handleMerge = () => {
    setOpenMerge(true);
  }
  const handleDataChange = (e) => {
    setFilterData({
      ...filterData,
      page: 0,
      [e.target.name]: e.target.value,
    });
  };
  const openImageDialog = (image) => {
    // console.log(image);
    if (image) {
      setImage(image);
      setOpenImage(true);
    }

  };

  const handleChange = (e, productId, index) => {
    // console.log(products);
    // console.log(e.target.checked, { index });
    const newList = products;
    newList[index] = { ...newList[index], status: e.target.checked ? 1 : 0 };
    setProducts([...newList]);
    dispatch(
      actions.update_product_status(productId, e.target.checked ? 1 : 0)
    );
  };
  const openAddDialog = (app) => {
    setCurrentApp(app);
    setAddOpen(true);
  };
  const history = useHistory();
  const openProduct = (app, to) => {
    setCurrentApp(app);
    history.push(to, { app });
  };
  const closeAddDialog = () => {
    setCurrentApp('');
    setAddOpen(false);
  };
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };
  const TotalValueRenderer = (props, index) => {
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    return (
      <span style={{ display: "flex" }}>
        <span>{cellValue}</span>&nbsp;
        <UnstyledSwitcher
          defaultValue={products.status === 1}
          checked={products.status === 1}
          onChange={(e) => handleChange(e, props.data.product_id, index)}
        />
       <Tooltip title="Edit here">
        <Button
          className={classes.button}
          startIcon={<EditIcon />}
          onClick={() => openAddDialog(props.data)}
        />
        </Tooltip>
        <Tooltip title="View product detail here">
        <Button className={classes.button} startIcon={<VisibilityIcon />} 
        // onClick={() => console.log({ pathname: '/main/productDetail/'}) 
        onClick={() => history.push({ pathname: '/main/productDetail/', product_id: props.data.product_id }) || <Skeleton />} />
        </Tooltip>
      </span>
    );
  };

  const ImageRenderer = (props) => {
    console.log("Image:",props);
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    return (
      <span onMouseOver={()=>openImageDialog(props.data.image)} >
        <span>{cellValue}</span>&nbsp;
        <img
          width='30px'
          src={`${CDN_URL}/${props.data.image}`}
        />
      </span>
    );
  };
  return (
    <Card>
        <CardHeader  style={{display:'flex',justifyContent:'space-between',flexDirection:'column'}} color="primary">
          <h4 style={{ color: "#fff", fontWeight: "400" }}>Stock List</h4>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="justify-between">
              <Button onClick={()=>openAddDialog(false)} className={classes.headerButton}>
              <Add color="#fff"/>
              </Button>
              <Button onClick={()=>setSearchable(!searchable)} className={classes.headerButton}>
              <Search color="#fff"/>
              </Button>
            </div>
            <span className="date-selector" style={{ margin: "10px",color:'#fff' }}>
            <FormControl
          className={classes.formControl}
          style={{ margin: 10 }}
          variant='outlined'
        >
          <InputLabel shrink>Limit</InputLabel>
          <Select
            name='limit'
            value={filterData.limit}
            onChange={handleDataChange}
            label="Limit"
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={1000}>1000</MenuItem>
          </Select>
        </FormControl>
        <div style={{ marginTop: "20px" }}>
          <UnstyledSwitcher
            className='dashboard-switcher'
            name="image"
            defaultValue={filterData.stock}
            checked={filterData.stock}
            onChange={(e) => setFilterData({ ...filterData, stock: !filterData.stock })}
          />
        
        </div>
        <Button
          className={classes.headerButton}
          // startIcon={<EditIcon />}
          onClick={() => handleClose(!open)}
        >
          {filterData.categoryId ||
            filterData.vendorId ||
            filterData.subtract ||
            filterData.status
            ? 'Edit Filter'
            : 'Filter'}
        </Button>
        <Button
          className={classes.headerButton}
          onClick={() => downloadCSVFromJson('products.csv', products)}
        //href={`${API_URL}/product?excel=true&page=${filterData?.page}&limit=${filterData?.limit}&search=${filterData?.search}&categoryId=${filterData?.categoryId}&vendorId=${filterData?.vendorId}&status=${filterData?.status}&price=${filterData?.price}`}
        >
          Export Excel
        </Button>
        <Button
          className={classes.headerButton}
          onClick={() => handleMerge('products.csv', products)}
        //href={`${API_URL}/product?excel=true&page=${filterData?.page}&limit=${filterData?.limit}&search=${filterData?.search}&categoryId=${filterData?.categoryId}&vendorId=${filterData?.vendorId}&status=${filterData?.status}&price=${filterData?.price}`}
        >
          Merge Products
        </Button>
</span>
          </div>
</CardHeader>
    <Paper className={classes.paper}>

      {/* <div className='flex justify-between'>
        <Title>Products</Title>
        {/* <p>{JSON.stringify(filterData)}</p> */}
       {/* <Button
          variant='contained'
          color='primary'
          startIcon={<AddCircleIcon />}
          onClick={() => openAddDialog()}
        >
          Add Items
        </Button>
      </div> */}
{/* 
      <div className='flex justify-between'>
        <TextField
          id='outlined-basic'
          label='Search'
          fullWidth
          style={{ margin: 10 }}
          variant='outlined'
          name='search'
          onChange={handleDataChange}
        />

        <FormControl
          className={classes.formControl}
          style={{ margin: 10 }}
          variant='outlined'
        >
          <InputLabel shrink>Limit</InputLabel>
          <Select
            name='limit'
            value={filterData.limit}
            onChange={handleDataChange}
            label="Limit"
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={1000}>1000</MenuItem>
          </Select>
        </FormControl>
        <div style={{ marginTop: "20px" }}>
          <UnstyledSwitcher
            className='dashboard-switcher'
            name="image"
            defaultValue={filterData.stock}
            checked={filterData.stock}
            onChange={(e) => setFilterData({ ...filterData, stock: !filterData.stock })}
          />
        </div>
        <Button
          className={classes.button}
          // startIcon={<EditIcon />}
          onClick={() => handleClose(!open)}
        >
          {filterData.categoryId ||
            filterData.vendorId ||
            filterData.subtract ||
            filterData.status
            ? 'Edit Filter'
            : 'Filter'}
        </Button>
        <Button
          className={classes.button}
          onClick={() => downloadCSVFromJson('products.csv', products)}
        //href={`${API_URL}/product?excel=true&page=${filterData?.page}&limit=${filterData?.limit}&search=${filterData?.search}&categoryId=${filterData?.categoryId}&vendorId=${filterData?.vendorId}&status=${filterData?.status}&price=${filterData?.price}`}
        >
          Export Excel
        </Button>
        <Button
          className={classes.button}
          onClick={() => handleMerge('products.csv', products)}
        //href={`${API_URL}/product?excel=true&page=${filterData?.page}&limit=${filterData?.limit}&search=${filterData?.search}&categoryId=${filterData?.categoryId}&vendorId=${filterData?.vendorId}&status=${filterData?.status}&price=${filterData?.price}`}
        >
          Merge Products
        </Button>

      </div> */}
      {products.length > 0 ? (
        <div className="ag-theme-material" style={{
          height: '100vh',
          width: '100%'
        }} >
          <AgGridReact
          getRowStyle={(params)=>{
            if (params.node.rowIndex % 2 === 0) {
              return { background: '#f6f8fb' };
          }
          }}
            rowData={products}
            onGridReady={onGridReady}
            defaultColDef={{
              flex: 1,
              minWidth: 200,
              editable: false,
              resizable: true,
              sortable: true,
              floatingFilter: searchable,
              stickyHeader:true
            }}
            frameworkComponents={{
              totalValueRenderer: TotalValueRenderer,
              imageRenderer: ImageRenderer,
            }}
          >
            <AgGridColumn headerName="Id" suppressMenu={true} resizable={true} field="product_id" sortable={true} filter={true} onCellClicked={(row) => openProduct(row,'/main/productDetail/')}></AgGridColumn>
            <AgGridColumn headerName="Action" cellRendererParams={{productId: '1'}} cellRenderer="totalValueRenderer" onClick={(row) => openProduct(row,'/main/productDetail/')}></AgGridColumn>
            <AgGridColumn headerName="Image" suppressMenu={true} cellRendererParams={{image:'text.jpg'}} cellRenderer="imageRenderer" sortable={true} filter={true} onClick={(row) => openProduct(row,'/main/productDetail/')}></AgGridColumn>
            <AgGridColumn headerName="Name" suppressMenu={true} resizable={true} field="name" sortable={true} filter={true} onClick={(row) => openProduct(row,'/main/productDetail/')}></AgGridColumn>
            <AgGridColumn headerName="Model" suppressMenu={true} resizable={true} field="model" sortable={true} filter={true} onClick={(row) => openProduct(row,'/main/productDetail/')}></AgGridColumn>
            <AgGridColumn headerName="Stock" suppressMenu={true} resizable={true} field="stock" sortable={true} filter={true} onClick={(row) => openProduct(row,'/main/productDetail/')}></AgGridColumn>
            <AgGridColumn headerName="Selling Price" suppressMenu={true} resizable={true} field="discounted" sortable={true} filter={true} onClick={(row) => openProduct(row,'/main/productDetail/')}></AgGridColumn>
          </AgGridReact>
        </div>

      ) : (
        // <Skeleton count={50} />
        <ShowLoadingOrEmpty message='No Products Found' />
      )
      }

      {/* <div className={classes.seeMore}>
        <Link color='primary' href='#' onClick={loadMore}>
          See more orders
        </Link>
      </div> */}
      <BillDetailView />
      <AddProduct
        open={addOpen}
        handleClose={closeAddDialog}
        editStockData={currentApp}
      />
      {
        open && (
          <FilterProducts
            open={open}
            data={filterData}
            handleClose={() => handleClose(!open)}
            filterProducts={filterProducts}
          />
        )
      }
      {
        openMerge && (
          <MergeProducts
            open={openMerge}
            data={filterData}
            handleClose={() => handleMClose(!openMerge)}
            filterProducts={filterProducts}
          />
        )
      }
      {
        openImage && (
          <ImageDialog
            open={openImage}
            handleClose={() => setOpenImage(false)}
            image={image}
          />
        )
      }
    </Paper >
    </Card>
  );
}
