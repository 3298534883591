import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
} from '@material-ui/core';
import axios from 'axios';
import api from '../../../api/api'
import MuiAlert from '@material-ui/lab/Alert';
import Title from './Title';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../store/DashboardActions';
function Alert(props) {
  // return null;
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const ProductUpload = () => {
  const [message, setMessage] = useState({});
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState('');
  const [vendor, setVendor] = useState('');
  const vendorList = useSelector((state) => state.dashboardReducer.vendors);
  const dispatch = useDispatch();
  const onChangeVendor = (e) => {
    setVendor(e.target.value);
  };

  useEffect(() => {
    if (vendorList.length === 0) {
      dispatch(actions.get_vendors());
    }
    // eslint-disable-next-line
    console.log(vendorList);
  }, [vendorList]);

  const submitForm = (e) => {
    e.preventDefault();
    // console.log(acceptedFiles, vendor);
    if (acceptedFiles[0] && vendor) {
      setErrors('');
      let formData = new FormData();
      formData.set('file', acceptedFiles[0]);
      formData.set('vendorId', vendor);
      axios
        .post(`${api.productImportAPI}`, formData)
        .then((res) => {
          setOpen(true);
          setMessage({
            type: 'success',
            text: 'Upload product Sucessfully',
          });
        })
        .catch((err) => {
          setOpen(true);
          // console.log(err?.response);
          setMessage({
            type: 'error',
            text:
              err?.response?.data?.message || 'Error occured please watch log',
          });
        });
    } else {
      if (vendor) {
        setErrors('');
        setOpen(true);
        setMessage({
          type: 'error',
          text: 'Please Choose a file to continue',
        });
      } else {
        setErrors('Vendor Cannot be empty');
      }
    }
  };
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  // const handleClick = () => {
  //   setOpen(true);
  // };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const downloadFormat = () => {
    axios
      .get(`${api.productsTOEXCELAPI}`, { responseType: 'blob' })
      .then((res) => {
        // console.log(res);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'products.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        setOpen(true);
        // console.log(err?.response?.data);
        setMessage({
          type: 'error',
          text:
            err?.response?.data?.message ||
            'Undefined error occur please check server',
        });
      });
  };

  const files = acceptedFiles.map((file) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));
  return (
    <Paper style={{ padding: 10 }}>
      <Title>Upload Product</Title>
      <form noValidate autoComplete='off' onSubmit={submitForm}>
        <Grid container style={{ alignItems: 'center' }}>
          <Grid item xs={12}>
            <FormControl fullWidth error={errors} variant='outlined'>
              <InputLabel shrink>Vendor</InputLabel>
              <Select displayEmpty name='vendor_id' onChange={onChangeVendor}>
                {vendorList.map((vendor) => (
                  <MenuItem value={vendor.vendor_id}>
                    {vendor.meta_title}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors}</FormHelperText>
            </FormControl>
            <section className='flex column  ma-sm'>
              <div
                {...getRootProps({ className: 'pa-md' })}
                style={{ border: '2px dotted #000' }}
              >
                <input {...getInputProps()} className='pa-lg' />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div>
              <aside>
                <h4>Files</h4>
                <ul>{files}</ul>
              </aside>
            </section>
          </Grid>
          <Grid item xs={12} style={{ margin: 10, justifyContent: 'center' }}>
            <Button type='submit' variant='contained' color='primary'>
              Upload
            </Button>
            <Button color='secondary' onClick={downloadFormat}>
              Download Format
            </Button>
          </Grid>
        </Grid>
      </form>

      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={message.type}>
          {message.text}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default ProductUpload;
