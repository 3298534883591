import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { VictoryArea, VictoryAxis, VictoryBar, VictoryChart, VictoryLine, VictoryTheme } from 'victory';
import NepaliDate from 'nepali-date-converter';
import api from 'api/api';
import { GetRequest } from 'plugins/axios';

export default function Graph({ heading,type }) {
  const [dataList, setDataList] = useState([{"amount":100,"bill_date":"2078/01/01","sales":0}]);
  // const [dataObject, setDataObject] = useState({
  //   amount: [],
  //   sales: [],
  //   credit: [],
  //   expense: [],
  //   saving: [],
  // });
const chartLineColor =['rgb(51, 156, 74)','hsl(354deg 100% 61%)','rgb(51, 156, 74)',];

  const [amount, setAmount] = useState([0,0,0,0,0]);
  const [date, setDate] = useState([]);
  const [bill_type, setbill_type] = useState('SALE');
  const [limit, setLimit] = useState(7);

  useEffect(() => {
    GetRequest(`${api.openingAmountAPI}`,{bill_type,limit})
      .then((res) => {
        let resdata=res.data.content;
        setDataList(resdata);
        console.log("GRAPH DATA:::::::::",res.data.content);
      })
      .catch((err) => {
        
      });
  }, [limit,bill_type]);

  useEffect(() => {
    if (heading === 'Business') {
      setbill_type('SALE');
    } else if (heading === 'Sales') {
      setbill_type('PURCHASE');
      setLimit(8)
    } else if (heading === 'Expenses') {
      setbill_type('EXPENSE');
    }
    
  }, [ heading]);
  useEffect(() => {
    if (dataList) {
      let amount = [];
      let sales = [];
      let date = [];
      let d=[];

      dataList.reverse()?.forEach((data, index) => {
        if (heading === 'Business') {
          amount.push(data?.amount);
          if(limit>7){ 
            let chartDate=data?.bill_date.split('/')
            chartDate=chartDate[2]
            d.push({x:(chartDate),y:(data?.amount)})
          } 
          else{
            d.push({x:DayFromDate(data?.bill_date),y:(data?.amount)})
          }

        } else if (heading === 'Sales') {
          sales.push(data?.amount);
          if(limit>7){ 
            d.push({x:(data?.bill_date),y:(data?.amount)})
        } 
          else{

            d.push({x:DayFromDate(data?.bill_date),y:(data?.amount)})
          }

        } else if (heading === 'Expenses') {
          amount.push(data?.amount);
         
if(limit>7){ d.push({x:(data?.bill_date),y:(data?.amount)})} else{
  d.push({x:DayFromDate(data?.bill_date),y:(data?.amount)})
}
        }

        date.push(data?.bill_date.slice(-5));
        // setAmount([...amount, { x: data.date, y: data.amount }]);
      });
      setDate(date?.slice(0,7));
      // setAmount(amount?.slice(0,7));
      setAmount(d);
      // console.log("🚀 ~ file: Graph.js ~ line 78 ~ Graph ~ d", d)
    }
  }, [dataList, heading]);

  // useEffect(() => {
  //   // 
  // }, [dataObject]);

  const DayFromDate = (npDate)=>{
    let date = npDate.split('/');
    let newDate = new NepaliDate(
      parseInt(date[0]),
      parseInt(date[1]) - 1,
      parseInt(date[2])
      );
      // let cDate = newDate.getYear() + '/';
      let cDate = '';
        switch (newDate.getDay()){
            case 0:
               return cDate + 'Sun';
            case 1:
               return cDate + 'Mon';
            case 2:
               return cDate + 'Tue';
            case 3:
               return cDate + 'Wed';
            case 4:
               return cDate + 'Thu';
            case 5:
               return cDate + 'Fri';
            case 6:
               return cDate + 'Sat';
            default:
               return cDate + 'Other';
            
        }
        
    }
  return (
    <div>
      {/* <span style={{ fontWeight: 'bold', fontSize: 20 }}>{heading} Chart</span>
      <div style={{ flexDirection:'row',height: 40,marginTop:12,marginBottom:0, width: '100%', alignItems:'center', justifyContent:'space-around' }}>
         <div onPress={()=> setLimit(7)}> 
            <div style={{height: 30, width:100, alignItems:'center',padding:4, justifyContent:'space-around', borderWidth:1, borderRadius:6, borderColor:"grey" }}>
              <span style={{ fontWeight: 'bold', fontSize: 14 }}>Last 7 Days</span>
            </div>
          </div>
         <div onPress={()=> setLimit(15)}> 
            <div style={{height: 30, width:100, alignItems:'center',padding:4, justifyContent:'space-around', borderWidth:1, borderRadius:6, borderColor:"grey" }}>
              <span style={{ fontWeight: 'bold', fontSize: 14 }}>Last 15 Days</span>
            </div>
          </div>
         <div onPress={()=> setLimit(30)}> 
            <div style={{height: 30, width:100, alignItems:'center',padding:4, justifyContent:'space-around', borderWidth:1, borderRadius:6, borderColor:"grey" }}>
              <span style={{ fontWeight: 'bold', fontSize: 14 }}>Last 30 Days</span>
            </div>
          </div>
        
      </div> */}
      {date?.length > 0 &&  false && (
        <VictoryChart
        name="series-1"
        theme={VictoryTheme.material}
        domainPadding={100}

        //  Animation

        // animate={{
        //   animationWhitelist: ["style", "data", "size"], // Try removing "size"
        //   onExit: {
        //     duration: 100,
        //     before: () => ({ opacity: 0.3, _y: 0 })
        //   },
        //   onEnter: {
        //     duration: 100,
        //     before: () => ({ opacity: 0.3, _y: 0 }),
        //     after: (datum) => ({ opacity: 1, _y: datum._y })
        //   }
        // }}
        
      >
        <VictoryLine
           interpolation="natural"
          
           labels={({ datum }) => limit<=15 ? datum.y: ''}
          data={amount}
          x={limit<=15 ?(d) =>  d.x: 'month'}
 
        />
      </VictoryChart>

      )}
      {date?.length > 0 && (
        <VictoryChart
        // theme={VictoryTheme.material}
        domainPadding={30}
        height={300}
        //  Animation

        // animate={{
        //   animationWhitelist: ["style", "data", "size"], // Try removing "size"
        //   onExit: {
        //     duration: 100,
        //     before: () => ({ opacity: 0.3, _y: 0 })
        //   },
        //   onEnter: {
        //     duration: 100,
        //     before: () => ({ opacity: 0.3, _y: 0 }),
        //     after: (datum) => ({ opacity: 1, _y: datum._y })
        //   }
        // }}
        
      >
       {type ? (<VictoryBar
       
           interpolation="natural"
           style={{ data: { fill: "rgb(51, 156, 74)"} }}
           labels={({ datum }) => limit<=15 ? datum.y: ''}
          data={amount}
          x={limit<=15 ?(d) =>  d.x: 'month'}
 
        />) : (<VictoryLine
          interpolation="natural"
          style={{ data: { stroke: "#F54147"} }}
          
          labels={({ datum }) => limit<=15 ? datum.y: ''}
         data={amount}
         x={limit<=15 ?(d) =>  d.x: 'month'}

       />)}
       <VictoryAxis dependentAxis
    // tickValues={[1, 2, 3, 4,5]}
  />
<VictoryAxis
    // tickValues={[1, 2, 3, 4,5]}
    style={{ tickLabels: {angle :45}}}
  />
      </VictoryChart>

      )}

      
      {
        // <span>Some Analyssis</span>
        // <LineChart
        //   data={{
        //     labels: ['January', 'February', 'March', 'April', 'May', 'June'],
        //     datasets: [
        //       {
        //         data: [
        //           Math.random() * 100,
        //           Math.random() * 100,
        //           Math.random() * 100,
        //           Math.random() * 100,
        //           Math.random() * 100,
        //           Math.random() * 100,
        //         ],
        //       },
        //     ],
        //   }}
        //   width={400} // from react-native
        //   height={220}
        //   chartConfig={{
        //     backgroundColor: '#fff',
        //     backgroundGradientFrom: '#fff',
        //     backgroundGradientTo: '#fff',
        //     decimalPlaces: 1, // optional, defaults to 2dp
        //     color: (opacity = 1) => `rgba(0 , 0, 0, .5)`,
        //     style: {
        //       borderRadius: 16,
        //     },
        //   }}
        //   bezier
        //   style={{
        //     marginVertical: 8,
        //     borderRadius: 16,
        //   }}
        // />
      }
    </div>
  );
}
