export default {
  primary: "#183142",
  primaryheader: "#183142",
  text: "#707070",
  grey: "#e5e5e5",
  blue: "#3c82fc",
  white: "#fff",
  light_grey: "#f8f8f8",
  dark: "#2a2c5c",
  bg: "#0f7e4a",
  yellow: "#fed922",
  light_bg: "#e0e0e2",
  blue_acent: "#0f3a8d",
  // light_green: "#18945a",
  shade_black: "#000",
  leave_green: "#18a561",
  purple: "#876aba",
  water: "#6f8bc8",
  green: "#1baa43",
  straw: "#e55d4b",
  red: "#e53b32",
  bluegreen: "#2CB9B0",
  black: "#000000",
};
export const colorScale=["#ED5282", "#ff7364", "#f38c29", "#a966cb" , "#3cb891", "#ff7364","#35b5e4"];