import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.js";
import RTLNavbarLinks from "./RTLNavbarLinks.js";
import Button from "components/CustomButtons/Button.js";
import MenuIcon from '@mui/icons-material/Menu';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

//hooks
import { useLocation } from 'react-router-dom'

import styles from "assets/jss/material-dashboard-react/components/headerStyle.js";
import { useDispatch, useSelector } from "react-redux";
import { setHamOpener } from "modules/store/DashboardActions.js";

const useStyles = makeStyles(styles);

export default function Header(props) {
  const APP_NAME = useSelector((state) => state.authReducer);
  let token = localStorage.getItem('token');
  const hamOpener = useSelector((state) => state.dashboardReducer.hamOpener);
  const vendor = useSelector((state) => state.authReducer.user.vendor);
  const classes = useStyles();
  const location = useLocation();
  console.log('token::::::::::::',token);
  const routeName = location.pathname;
  //useRouteName();
  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color,
  });
  const dispatch = useDispatch();

  const handleHamOpener = (event) => {
    
    if(hamOpener == true){
      dispatch(setHamOpener(false));
    }else{
      dispatch(setHamOpener(true));
    }
  };

  const handleHamCloser = (event) => {
    if(event.target.checked){
      setChecked(false);
    }else{
      setChecked(true);
    }
  };
  const [checked, setChecked] = React.useState(false);

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex} style={{ display: "flex", alignItems: "center" }}>
          <MenuIcon
           onClick={handleHamOpener}
          // onClick={setChecked(!checked)} 
          />
          {/* Here we create navbar brand, based on route name */}
          <Button color="transparent" href="#" className={classes.title}>
            {/* {routeName} */}
            {vendor?.mainappname}
          </Button>
        </div>
        <Hidden smDown implementation="css">
          {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
};
