import React, { useEffect, useRef, useState } from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import {
  Avatar,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import { Add, AddBox, AddIcCallRounded, PanoramaFishEyeTwoTone, RefreshOutlined, Search } from '@material-ui/icons';
import FilterDaybook from './Components/FilterDaybook';
import EditIcon from '@material-ui/icons/Edit';
import * as actions from '../../store/DashboardActions';
import PrintIcon from "@mui/icons-material/Print";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
// import ImageDialog from './Components/ImageDialog';
import ShowLoadingOrEmpty from '../../Dashboard/Components/ShowLoadingOrEmpty';
import Title from '../../Dashboard/Components/Title';
import { API_URL, CDN_URL } from '../../../config';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddStockAppMain from '../../Dashboard/modules/StockAppMain/Components/AddStockAppMain';
import NepaliDate from 'nepali-date-converter';
import BillDetaildiv from './Components/BillDetailView';
import { Grid } from '@material-ui/core';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import Calendar from '@sbmdkl/nepali-datepicker-reactjs';
import '@sbmdkl/nepali-datepicker-reactjs/dist/index.css';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import { dailySalesChart } from 'variables/charts';
import AccessTime from '@material-ui/icons/AccessTime';
import CardFooter from 'components/Card/CardFooter';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ChartistGraph from 'react-chartist';
import { CheckBox, FormatItalic, Refresh } from '@material-ui/icons';
import { GetRequest } from 'plugins/axios';
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import NumberFormat from 'utils/NumberFormat';
import ReactToPrint from 'react-to-print';
import PrintHeader from 'utils/PrintHeader';
// import AddProduct from './Components/AddProduct';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    minHeight:500,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  button: {
    margin: 10,
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

export default function Product() {
  const [daybookCustomDate, setDaybookCustomDate] = useState(false);
  const [dateString, setDateString] = useState('');
  const [dateodString, setodString] = useState('');
  const [nepaliDate, setNepaliDate] = useState(new NepaliDate());
  const [yesterdate, setYesterdate] = useState("");
  const [twomorro, setTwomorro] = useState("");
  const [od, setod] = useState(new NepaliDate());
  const [quote, setQuote] = useState([]);
  const [axiosToken, setAxiosToken] = useState('');
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const [filtredProducts, setFilteredProducts] = useState([]);
  const [cashins, setCashins] = useState([]);
  const [open, setOpen] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [image, setImage] = useState('');
  const [closingBalance, setClosingBalance] = useState(0);
  const [closingExpense, setCExpense] = useState(0);
  const [closingSales, setSales] = useState(0);
  const [crSales, setCRSales] = useState(0);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const dispatch = useDispatch();
  const [addOpen, setAddOpen] = useState(false);
  const [currentApp, setCurrentApp] = useState('');
  const [refresh, setRefresh] = useState(0);
  const { categoryId } = useParams();  
  const componentRef = useRef();
  const [today, setToday] = useState("2078/12/09");
  const [dateSwitch, setDateSwitch] = useState();
  const [difference, setDifference] = useState(0);
  const [thismonth, setThismonth] = useState("2078/12/09");
  const [thisyear, setThisyear] = useState("2078/12/09");
  const [thisweek, setThisWeek] = useState("2078/12/09");
  const [baishakh, setBaishakh] = useState("2078/01/09");
  const [jestha, setJestha] = useState("2078/01/09");
  const [ashadh, setAshadh] = useState("2078/01/09");
  const [shrawan, setShrawan] = useState("2078/01/09");
  const [bhadra, setBhadra] = useState("2078/01/09");
  const [ashwin, setAshwin] = useState("2078/01/09");
  const [kartik, setKartik] = useState("2078/01/09");
  const [mangsir, setMangsir] = useState("2078/01/09");
  const [poush, setPoush] = useState("2078/01/09");
  const [magh, setMagh] = useState("2078/01/09");
  const [falgun, setFalgun] = useState("2078/01/09");
  const [chaitra, setChaitra] = useState("2078/01/09");
  const [printHeader, setPrintHeader] = useState(false);
  const Daybookitems = useSelector((state) => state.dashboardReducer.daybook);
  const subdomain = useSelector((state) => state.authReducer.user.subdomain);
  const [search, setSearch] = useState('');
  // console.log("subdomain------------------",subdomain);
    useEffect(() => {
    console.log("date string", dateString);
    setToday(`${dateString}||${dateString}`);
    setThisWeek(
      `${dateString.split("/")[0]}/${dateString.split("/")[1]}/${
        dateString.split("/")[2] - 7
      }||${dateString}`
    );
    setThismonth(
      `${dateString.split("/")[0]}/${dateString.split("/")[1]}/01||${
        dateString.split("/")[0]
      }/${dateString.split("/")[1]}/32`
    );
    setBaishakh(
      `${dateString.split("/")[0]}/01/01||${dateString.split("/")[0]}/01/32`
    );
    setJestha(
      `${dateString.split("/")[0]}/02/01||${dateString.split("/")[0]}/02/32`
    );
    setAshadh(
      `${dateString.split("/")[0]}/03/01||${dateString.split("/")[0]}/03/32`
    );
    setShrawan(
      `${dateString.split("/")[0]}/04/01||${dateString.split("/")[0]}/04/32`
    );
    setBhadra(
      `${dateString.split("/")[0]}/05/01||${dateString.split("/")[0]}/05/32`
    );
    setAshwin(
      `${dateString.split("/")[0]}/06/01||${dateString.split("/")[0]}/06/32`
    );
    setKartik(
      `${dateString.split("/")[0]}/07/01||${dateString.split("/")[0]}/07/32`
    );
    setMangsir(
      `${dateString.split("/")[0]}/08/01||${dateString.split("/")[0]}/08/32`
    );
    setPoush(
      `${dateString.split("/")[0]}/09/01||${dateString.split("/")[0]}/09/32`
    );
    setMagh(
      `${dateString.split("/")[0]}/10/01||${dateString.split("/")[0]}/10/32`
    );
    setFalgun(
      `${dateString.split("/")[0]}/11/01||${dateString.split("/")[0]}/11/32`
    );
    setChaitra(
      `${dateString.split("/")[0]}/12/01||${dateString.split("/")[0]}/12/32`
    );
    setThisyear(
      `${dateString.split("/")[0]}/01/01||${dateString.split("/")[0]}/12/32`
    );
  }, [dateString]);

  useEffect(() => {
    setDateString(
      `${nepaliDate.getYear()}/${
        nepaliDate.getMonth() + 1 > 9
          ? nepaliDate.getMonth() + 1
          : `0${nepaliDate.getMonth() + 1}`
      }/${
        nepaliDate.getDate() > 9
          ? nepaliDate.getDate()
          : `0${nepaliDate.getDate()}`
      }`
    );
  }, [nepaliDate]);
  const DurationSelectorHandler=(event)=>{
    console.log("🚀 ~ file: index.jsx ~ line 129 ~ DurationSelectorHandler ~ dates", event.target.value)
  setDateSwitch(event.target.value);
    let date = event.target.value.split('||')
    console.log('///datesfrom',date[0]);
    console.log('///datesto',date[1]);
    setFilterData({...filterData,subdomain,dateString:date[0],odString:date[0],endDate:date[1]})
  }
  const [filterData, setFilterData] = useState({
    subdomain,
    dateString,
    odString: dateString,
    endDate:dateString,
    page: 0,
    limit: 10,
    itemwise:'false',
  });
  let balancebe=0;
  useEffect(() => {
    let todaynp=`${nepaliDate.getYear()}/${
      nepaliDate.getMonth() + 1 > 9
      ? nepaliDate.getMonth() + 1
      : `0${nepaliDate.getMonth() + 1}`
    }/${
      nepaliDate.getDate() > 9
      ? nepaliDate.getDate()
      : `0${nepaliDate.getDate()}`
    }`;
    let odTodaynp=
    `${od.getYear()}/${
      od.getMonth() + 1 > 9
      ? od.getMonth() + 1
      : `0${od.getMonth() + 1}`
    }/${
      od.getDate() > 9
      ? od.getDate()
      : `0${od.getDate()}`
    }`;
    setFilterData({...filterData,dateString:todaynp,odString:odTodaynp,subdomain});
      
    }, [nepaliDate]);

  useEffect(() => {
    if (categoryId) {
      setFilterData({
        ...filterData,
        subdomain,
        categoryId: categoryId,
      });
    }
    //eslint-disable-next-line
  }, [categoryId]);
  
  useEffect(() => {
    setProducts(Daybookitems);
    setFilteredProducts(Daybookitems);
  }, [Daybookitems]);
  
  useEffect(() => {
    let daybookList = products.filter((cas) =>
    cas.fname?.toLowerCase().includes(search)
  );
    setFilteredProducts(daybookList);
  }, [search]);

  const handleClose = (value) => {
    setOpen(value);
  };
  
  const loadMore = (event) => {
    event.preventDefault();
    setFilterData({
      ...filterData,
      subdomain,
      page: filterData.page + 1,
    });
  };
  const dayOfYear = date => Math.floor((date - new Date(date.getFullYear(), 0, 0)) / (1000 * 60 * 60 * 24));

// Example

  useEffect(() => {
    //get api call from https://type.fit/api/quotes to get random quote
    GetRequest("https://type.fit/api/quotes", {
      subdomain,
      vendorId:9,
      today: `${nepaliDate.getYear()}/${
        nepaliDate.getMonth() + 1 > 9
          ? nepaliDate.getMonth() + 1
          : `0${nepaliDate.getMonth() + 1}`
      }/${nepaliDate.getDate()}`,
    }).then((res) => {
        // console.log("res",res.data[dayOfYear(new Date(filterData.dateString))]);
        // console.log("res2",res.data[dayOfYear(new Date())]);
        let res2=res.data[dayOfYear(new Date(filterData.dateString))];
        let res1=res.data[dayOfYear(new Date())];
      setQuote(res2);
      // setSavings(res.data.content);
    })
   

  },[filterData])
  useEffect(() => {
    let cb=0;
    let cs=0;
    let crs=0;
    let ce=0;
    products.map((data)=>{
cb=  parseFloat(cb) - parseFloat(data.cashout ?data.cashout : 0) + parseFloat(difference ? difference : 0) + parseFloat(data.cashin ? data.cashin: 0);
    })
    filtredProducts.filter((data) => (data.bill_type == 'SALE' || data.bill_type == 'RECEIPT' || data.bill_type == 'PURCHASERETURN') & (data.BR !='BR') ).map((data)=>{
      cs= parseFloat(cs) - parseFloat(data.cashout ?data.cashout : 0) + parseFloat(data.cashin ? data.cashin: 0);
          })
          filtredProducts.filter((data) => (data.bill_type == 'SALE' || data.bill_type == 'RECEIPT' || data.bill_type == 'PURCHASERETURN') & (data.cashin ==0)).map((data)=>{
            crs= parseFloat(crs) + parseFloat(data.amount ? data.amount: 0);
                })
                filtredProducts.filter((data) => data.bill_type == 'EXPENSE' || data.bill_type == 'PURCHASE' || data.bill_type == 'SAVING' || data.bill_type == 'SALERETURN' || data.bill_type == 'VOUCHER').map((data)=>{
            ce= parseFloat(ce) + parseFloat(data.cashout ?data.cashout : 0);
                })
    setClosingBalance(cb );
    setCExpense(ce);
    setSales(cs);
    setCRSales(crs);

  },[filtredProducts,difference])


  useEffect(() => {
    const get_daybook = () => {
      // console.log("sent for daybook",filterData);
      if (axiosToken) {
        // Cancel the previous request before making a new request
        axiosToken.cancel('operation canceled due to new request.');
      }
      let token = axios.CancelToken.source();
      setAxiosToken(token);
      dispatch(actions.get_daybook(filterData.page === 0, filterData, token));
    };
    get_daybook();
    return () => {
      // console.log('clear');
    };
    // eslint-disable-next-line
  }, [filterData,subdomain,refresh]);

  
  const filterProducts = (data) => {
    setFilterData({
      ...filterData,
      subdomain,
      page: 0,
      ...data,
    });
    handleClose(!open);
  };
  
  const handleDataChange = (e) => {
    setFilterData({
      ...filterData,
      page: 0,
      [e.target.name]: e.target.value,
    });
  };
  const openImageDialog = (image) => {
    // console.log(image);
    if (true) {
      setImage(image);
      setOpenImage(true);
    }
    
  };
  
  const handleChange = (e, productId, index) => {
    // console.log(products);
    // console.log(e.target.checked, { index });
    const newList = products;
    newList[index] = { ...newList[index], status: e.target.checked ? 1 : 0 };
    setProducts([...newList]);
    dispatch(
      actions.update_product_status(productId, e.target.checked ? 1 : 0)
      );
    };
    const openAddDialog = (app) => {
      setCurrentApp(app);
      setAddOpen(true);
    };
    const closeAddDialog = () => {
      setCurrentApp('');
      setAddOpen(false);
    };
    useEffect(() => {
          
        // console.log("fd", filterData.dateString );
        let date = filterData.dateString ? filterData.dateString.split('/') : ('2078/12/12').split('/');
        // console.log("date string",date);
        let newDate = new NepaliDate(
          parseInt(date[0]),
          parseInt(date[1]) - 1,
          parseInt(date[2])
          );
        let nD = new NepaliDate(
          parseInt(date[0]),
          parseInt(date[1]) - 1,
          parseInt(date[2])
          );
          
          // if (position === 0) {
            newDate.setDate(newDate.getDate() - 1);
            nD.setDate(nD.getDate() + 1);
          // } else if (position === 2) {
          //   nD.setDate(nD.getDate());
          //   newDate.setDate(newDate.getDate() + 1);
          // }
          
          setYesterdate(`${newDate.getYear()}/${
            newDate.getMonth() + 1 > 9
            ? newDate.getMonth() + 1
            : `0${newDate.getMonth() + 1}`
          }/${
            newDate.getDate() > 9
            ? newDate.getDate()
            : `0${newDate.getDate()}`
          }`);
          setTwomorro(`${nD.getYear()}/${
            nD.getMonth() + 1 > 9
            ? nD.getMonth() + 1
            : `0${nD.getMonth() + 1}`
          }/${
            nD.getDate() > 9
            ? nD.getDate()
            : `0${nD.getDate()}`
          }`);
      }, [filterData,]);
    return (
      <>
      <GridContainer>
<GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader style={{display:'flex',justifyContent:'flex-start',flexDirection:'column'}} color="primary">
            <div  style={{display:'flex',justifyContent:'space-between'}}>
            <h4 className={classes.cardTitleWhite}>Day Book  <small style={{color:'#fff'}}>for {filterData.dateString} - {filterData.endDate}</small></h4>
            <p className={classes.cardCategoryWhite} style={{display:'flex',flexDirection:'row'}}>
              {/*Chose Start Date & end Date*/}
              {/* <CalendarMonthIcon /> */}
              {daybookCustomDate ? (
                <>
              <Paper padder style={{display:'flex',flexDirection:'row',padding:5,paddingLeft:0,paddingRight:0,marginLeft:5,alignItems:'center'}}>
              {/* <SkipPreviousIcon  onClick={(date) => {setFilterData({...filterData,subdomain,dateString:yesterdate,odString:yesterdate,endDate:yesterdate})}} sx={{ color: '#ccc'}}fontSize='large'/> */}
               
              <Calendar hideDefaultValue={false} placeholder="Start Date" value={filterData.dateString} key={filterData} language="en" theme="deepdark" style={{color:'#000',border:0,display:'inline',textAlign:'center',minWidth:'300px'}} dateFormat="YYYY/MM/DD" onChange={(date) => {setFilterData({...filterData,subdomain,dateString:date.bsDate,odString:date.bsDate})}}> </Calendar>
              </Paper>
              <Paper padder style={{display:'flex',flexDirection:'row',padding:5,paddingLeft:0,paddingRight:0,marginLeft:5,alignItems:'center'}}>
              <Calendar hideDefaultValue={true} placeholder="End Date" value={filterData.endDate} key={filterData} language="en" theme="deepdark" style={{color:'#000',border:0,display:'inline',textAlign:'center',minWidth:'300px'}} dateFormat="YYYY/MM/DD" onChange={(date) => {setFilterData({...filterData,subdomain,endDate:date.bsDate})}}> </Calendar>
              {/* <SkipNextIcon onClick={(date) => {setFilterData({...filterData,subdomain,dateString:twomorro,odString:twomorro,endDate:twomorro})}} sx={{ color: '#ccc'}}fontSize='large'/> */}
              </Paper>
              </>) : (<></>)
              }
              <Checkbox checked={daybookCustomDate} onChange={()=>{setDaybookCustomDate(!daybookCustomDate)}} />
              <Paper padder style={{display:'flex',flexDirection:'row',padding:5,paddingLeft:0,paddingRight:0,marginLeft:5,alignItems:'center'}}>
              <SkipPreviousIcon  onClick={(date) => {setFilterData({...filterData,subdomain,dateString:yesterdate,odString:yesterdate,endDate:yesterdate})}} sx={{ color: '#ccc'}}fontSize='large'/>

        <FormControl 
        variant='standard'
         sx={{ m: 1,padding:10, minWidth: 300,borderBottom:0 }} fullWidth>
         {/* <InputLabel id="demo-simple-select-label">Interval</InputLabel>  */}
        <Select
      selectedValue={filterData.dateString}
      value={filterData.dateString}
      style={{borderBottom:0}}
      onChange={(event)=>DurationSelectorHandler(event)}
      >
      <MenuItem selected label="Today" value={filterData.dateString}>
       {filterData.dateString}
      </MenuItem>
      <MenuItem onClick={()=>setDaybookCustomDate(!daybookCustomDate)} label="This Year" value={today}>
         Custom Range
        </MenuItem>
      <MenuItem label="This Week" value={thisweek}>
       This Week
       </MenuItem>
       <MenuItem label="This Month" value={thismonth}>
        This Month
       </MenuItem>
       <MenuItem label="Baishakh" value={baishakh}>
        Baishakh
       </MenuItem>
       <MenuItem label="Jestha" value={jestha}>
        Jestha
       </MenuItem>
       <MenuItem label="Ashadh" value={ashadh}>
        Ashadh
       </MenuItem>
       <MenuItem label="Shrawan" value={shrawan}>
        Shrawan
       </MenuItem>
       <MenuItem label="Bhadra" value={bhadra}>
        Bhadra
       </MenuItem>
       <MenuItem label="Ashwin" value={ashwin}>
        Ashwin
       </MenuItem>
       <MenuItem label="Kartik" value={kartik}>
        Kartik
       </MenuItem>
       <MenuItem label="Mangsir" value={mangsir}>
        Mangsir
       </MenuItem>
       <MenuItem label="Poush" value={poush}>
        Poush
       </MenuItem>
       <MenuItem label="Magh" value={magh}>
        Magh
       </MenuItem>
       <MenuItem label="Falgun" value={falgun}>
        Falgun
       </MenuItem>
       <MenuItem label="Chaitra" value={chaitra}>
        Chaitra
       </MenuItem>

        <MenuItem label="This Year" value={thisyear}>
         This Year
        </MenuItem>
      
      </Select>

      </FormControl>
      <SkipNextIcon onClick={(date) => {setFilterData({...filterData,subdomain,dateString:twomorro,odString:twomorro,endDate:twomorro})}} sx={{ color: '#ccc'}}fontSize='large'/>
    
      </Paper>
            </p>
              </div>
              
              <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center',alignContent:'center'}}>

           
              <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', width: 400,marginRight:5, }}
    >
     
              <InputBase
              onChange={(e)=> setSearch(e.target.value)}
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search Transaction"
        inputProps={{ 'aria-label': 'Search Transaction' }}
      />
      <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      </Paper>
              <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', width: 400,marginRight:5, }}
    >
     
              <InputBase
              onChange={(e)=> setDifference(e.target.value)}
        sx={{ ml: 1, flex: 1 }}
        placeholder="Set Actual Closing"
      />
      </Paper>
      
      <Paper
       onClick={(e)=> setRefresh(refresh+1)}
      component="form"
      sx={{ p: '2px 4px', display: 'flex', width: 400,marginRight:5, }}
    >
 <RefreshOutlined  />    
      </Paper>
     {/*} <Paper>

    </Paper>*/}
             {/* <Button variant='contained' color='primary' onClick={(date) => {setFilterData({...filterData,subdomain,dateString:yesterdate,odString:yesterdate,endDate:yesterdate})}}>Go to{yesterdate}</Button>
              <div style={{fontWeight:900,fontSize:17}}>
             Current Date: {filterData.dateString}
             </div>
              <Button variant='contained' color='primary' onClick={(date) => {setFilterData({...filterData,subdomain,dateString:twomorro,odString:twomorro,endDate:twomorro})}}>Go to{twomorro}</Button>
              </div>
<div style={{textAlign:'center',fontWeight:'bold',textTransform:'capitalize'}}>
"{quote?.text}"
    -<i>{quote?.author}</i>*/}

                  <AddBox onClick={()=>setPrintHeader(!printHeader)} style={{ color: '#fff' }} />
                  <PanoramaFishEyeTwoTone onClick={()=>setFilterData({...filterData,itemwise:filterData.itemwise=='false' ?  'true' : 'false'})} style={{ color: '#fff' }} />
              <ReactToPrint
                trigger={() => (
                 
                    <PrintIcon style={{ color: '#fff' }}/>
                 
                )}
                content={() => componentRef.current}
              />
    </div>

    </CardHeader>
            <div ref={componentRef}> 
           {printHeader && <PrintHeader title={'Daybook'} startDate={filterData.dateString} endDate={filterData.endDate}/>}
          <CardBody style={{display:'flex',flexDirection:'row',}} >
            {/* <Table
              tableHeaderColor="primary"
              tableHead={["Name", "Country", "City", "Salary"]}
              // tableHead={["Id", "Bill No", "Particular", "Paid Amount", "Net Amount"]}
              tableData={[
                ["Dakota Rice", "Niger", "Oud-Turnhout", "$36,738"],
                ["Minerva Hooper", "Curaçao", "Sinaai-Waas", "$23,789"],
                ["Sage Rodriguez", "Netherlands", "Baileux", "$56,142"],
                ["Philip Chaney", "Korea, South", "Overland Park", "$38,735"],
                ["Doris Greene", "Malawi", "Feldkirchen in Kärnten", "$63,542"],
                ["Mason Porter", "Chile", "Gloucester", "$78,615"],
              ]}
            />
          <Table
              tableHeaderColor="primary"
              tableHead={["Name", "Country", "City", "Salary"]}
              tableData={[
                ["Dakota Rice", "Niger", "Oud-Turnhout", "$36,738"],
                ["Minerva Hooper", "Curaçao", "Sinaai-Waas", "$23,789"],
                ["Sage Rodriguez", "Netherlands", "Baileux", "$56,142"],
                ["Philip Chaney", "Korea, South", "Overland Park", "$38,735"],
                ["Doris Greene", "Malawi", "Feldkirchen in Kärnten", "$63,542"],
                ["Mason Porter", "Chile", "Gloucester", "$78,615"],
              ]}
            /> */}
              {true ? (
          <Table sx={{ borderRight: 1}} size='small' style={{borderRight:'#000'}}>
          <TableHead>
          <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>BILL</TableCell>
          {/* <TableCell>Bill Type</TableCell> */}
          <TableCell>PARTICULAR</TableCell>
          <TableCell align='right'>RECIEVED AMOUNT</TableCell>
          {/* <TableCell align='right'>Amount Out</TableCell> */}
          {/* <TableCell align='right'>Net Amount</TableCell>
          <TableCell align='right'>Balancee</TableCell> */}
          </TableRow>
          </TableHead>
          <TableBody>
          {filtredProducts.filter((data) => (data.bill_type == 'SALE' || data.bill_type == 'RECEIPT' || data.bill_type == 'PURCHASERETURN') & (data.cashin !=0) ).map((row, index) => (
            <TableRow key={row.product_id} onClick={() => openImageDialog(row.connect_id)}>
            <TableCell>{row.connect_id}</TableCell>
            <TableCell>{row.BR == 'C' ? `CASH ${row.bill_type}` : `${row.bill_type} ` }</TableCell>
            {/* <TableCell>{row.bill_no}</TableCell> */}
            <TableCell><b>{` ${row.bill_no=="" ? row.name : row.bill_no  }`} </b><br /> {`${row.fname} ${row.lname}`} <small>-{row.bill_date}</small> </TableCell>
            <TableCell align='right'>
            <NumberFormat amount={row.cashin} />
</TableCell>
            {/* <TableCell align='right'>{row.cashout}</TableCell> */}
            {/* <TableCell align='right'>{row.amount}</TableCell>
            <TableCell align='right'>{balancebe = parseInt(balancebe) - parseInt(row.cashout) + parseInt(row.cashin)} </TableCell> */}
            </TableRow>
            ))}
             <TableRow>
          <TableCell>#</TableCell>
          <TableCell>#</TableCell>
          {/* <TableCell>Bill Type</TableCell> */}
          <TableCell>Total Collection</TableCell>
          <TableCell  align='right'><NumberFormat amount={closingSales - products[0]?.cashin} /></TableCell>
          {/* <TableCell align='right'>Amount Out</TableCell> */}
          {/* <TableCell align='right'>Net Amount</TableCell>
          <TableCell align='right'>Balancee</TableCell> */}
          </TableRow>
             <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
          {/* <TableCell>Bill Type</TableCell> */}
          <TableCell></TableCell>
          <TableCell style={{fontWeight:900}} align='right'><NumberFormat amount={closingSales} /></TableCell>
          {/* <TableCell align='right'>Amount Out</TableCell> */}
          {/* <TableCell align='right'>Net Amount</TableCell>
          <TableCell align='right'>Balancee</TableCell> */}
          </TableRow>
             <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
          {/* <TableCell>Bill Type</TableCell> */}
          <TableCell></TableCell>
          <TableCell style={{fontWeight:900}} align='right'></TableCell>
          {/* <TableCell align='right'>Amount Out</TableCell> */}
          {/* <TableCell align='right'>Net Amount</TableCell>
          <TableCell align='right'>Balancee</TableCell> */}
          </TableRow>
            </TableBody>
            </Table>
            ) : (
              <ShowLoadingOrEmpty message='No Products Found' />
              )}


{true ? (
          <Table size='small'>
          <TableHead>
          <TableRow>
          <TableCell>ID</TableCell>
          <TableCell align='left'>BILL NO</TableCell>
          <TableCell>BILL</TableCell>
          {/* <TableCell>Bill Type</TableCell> */}
          <TableCell>PARTICULAR</TableCell>
          {/* <TableCell align='right'>Recieved Amount</TableCell> */}
          <TableCell align='right'>AMOUNT OUT</TableCell>
          {/* <TableCell align='right'>Net Amount</TableCell>
          <TableCell align='right'>Balancee</TableCell> */}
          </TableRow>
          </TableHead>
          <TableBody>
          {filtredProducts.filter((data) => data.bill_type == 'EXPENSE' || data.bill_type == 'PURCHASE' || data.bill_type == 'SAVING' || data.bill_type == 'SALERETURN' || data.bill_type == 'VOUCHER' ).map((row, index) => (
            <TableRow key={row.product_id} onClick={() => openImageDialog(row.connect_id)}>
            <TableCell>{index+1}</TableCell>
            {/* <TableCell><Checkbox></Checkbox> </TableCell> */}
            <TableCell align='left'>#{row.connect_id}</TableCell>
            <TableCell>{row.BR == 'C' ? `CASH ${row.bill_type}` : `CREDIT ${row.bill_type}` }</TableCell>
            {/* <TableCell>{row.bill_no}</TableCell> */}
            <TableCell>{` ${row.name}`} <br />{`${row.fname} ${row.lname}`} <small>-{row.bill_date}</small></TableCell>
            <TableCell align='right'>
            <NumberFormat amount={row.cashout} /></TableCell>
            {/* <TableCell align='right'>{row.amount}</TableCell>
            <TableCell align='right'>{balancebe = parseInt(balancebe) - parseInt(row.cashout) + parseInt(row.cashin)} </TableCell> */}
            </TableRow>
            ))}
                <TableRow>
          <TableCell>#</TableCell>
          <TableCell>#</TableCell>
          {/* <TableCell>Bill Type</TableCell> */}
          <TableCell>Total Cashout</TableCell>
          <TableCell align='right'>#</TableCell>
          <TableCell align='right'><NumberFormat amount={closingExpense} /></TableCell>
          {/* <TableCell align='right'>Net Amount</TableCell>
          <TableCell align='right'>Balancee</TableCell> */}
          </TableRow>
                <TableRow>
          <TableCell>#</TableCell>
          <TableCell>#</TableCell>
          {/* <TableCell>Bill Type</TableCell> */}
          <TableCell>Closing Balance :</TableCell>
          <TableCell align='right'>#</TableCell>
          <TableCell style={{fontWeight:900}}  align='right'><NumberFormat amount={closingBalance} /></TableCell>
          {/* <TableCell align='right'>Net Amount</TableCell>
          <TableCell align='right'>Balancee</TableCell> */}
          </TableRow>
                <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
          {/* <TableCell>Bill Type</TableCell> */}
          <TableCell></TableCell>
          <TableCell align='right'></TableCell>
          <TableCell  style={{fontWeight:900}} align='right'><NumberFormat amount={closingBalance + closingExpense} /></TableCell>
          {/* <TableCell align='right'>Net Amount</TableCell>
          <TableCell align='right'>Balancee</TableCell> */}
          </TableRow>

          <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
          {/* <TableCell>Bill Type</TableCell> */}
          <TableCell></TableCell>
          <TableCell style={{fontWeight:900}} align='right'></TableCell>
          <TableCell style={{fontWeight:900}} align='right'></TableCell>
          {/* <TableCell align='right'>Amount Out</TableCell> */}
          {/* <TableCell align='right'>Net Amount</TableCell>
          <TableCell align='right'>Balancee</TableCell> */}
          </TableRow>
            </TableBody>
            </Table>
            ) : (
              <ShowLoadingOrEmpty message='No Products Found' />
              )}

          </CardBody>
</div>
        
        </Card>
      </GridItem>
      </GridContainer>

      <Grid container spacing={1}>
      {/* <Grid item xs={4}>
        callender
      </Grid> */}
                <Grid item xs={12}>
                {/* <Card chart>
            <CardHeader color="success">
              <ChartistGraph
                className="ct-chart"
                data={dailySalesChart.data}
                // data={{
                //   labels: [date],
                //   series: [amount],
                // }}
                type="Line"
                options={dailySalesChart.options}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Daily Sales</h4>
              <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                increase in today sales.
              </p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated 4 minutes ago
              </div>
            </CardFooter>
          </Card> */}
      <Paper className={classes.paper}>
        Credit Sales
      {true ? (
          <Table sx={{ borderRight: 1}} size='small' style={{borderRight:'#000'}}>
          <TableHead>
          <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>BILL</TableCell>
          {/* <TableCell>Bill Type</TableCell> */}
          <TableCell>PARTICULAR</TableCell>
          <TableCell align='right'>RECIEVED AMOUNT</TableCell>
          {/* <TableCell align='right'>Amount Out</TableCell> */}
          {/* <TableCell align='right'>Net Amount</TableCell>
          <TableCell align='right'>Balancee</TableCell> */}
          </TableRow>
          </TableHead>
          <TableBody>
          {filtredProducts.filter((data) => (data.bill_type == 'SALE' || data.bill_type == 'RECEIPT' || data.bill_type == 'PURCHASERETURN') & (data.cashin ==0)).map((row, index) => (
            <TableRow key={row.product_id} onClick={() => openImageDialog(row.connect_id)}>
            <TableCell>{row.connect_id}</TableCell>
            <TableCell>{row.BR == 'C' ? `CASH ${row.bill_type}` : `CREDIT ${row.bill_type} ` }</TableCell>
            {/* <TableCell>{row.bill_no}</TableCell> */}
            <TableCell>{`${row.fname} ${row.lname}`} <br />{` ${row.bill_no}`}</TableCell>
            <TableCell align='right'><NumberFormat amount={row.amount} /></TableCell>
            {/* <TableCell align='right'>{row.cashout}</TableCell> */}
            {/* <TableCell align='right'>{row.amount}</TableCell>
            <TableCell align='right'>{balancebe = parseInt(balancebe) - parseInt(row.cashout) + parseInt(row.cashin)} </TableCell> */}
            </TableRow>
            ))}
             <TableRow>
          <TableCell>#</TableCell>
          <TableCell>#</TableCell>
          {/* <TableCell>Bill Type</TableCell> */}
          <TableCell>Total Credit Sales</TableCell>
          {/* <TableCell  align='right'>{closingSales - products[0]?.cashin}</TableCell> */}
          {/* <TableCell align='right'>Amount Out</TableCell> */}
          <TableCell align='right'><NumberFormat amount={crSales} /></TableCell>
          </TableRow>
             <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
          {/* <TableCell>Bill Type</TableCell> */}
          <TableCell></TableCell>
          <TableCell style={{fontWeight:900}} align='right'></TableCell>
          {/* <TableCell align='right'>Amount Out</TableCell> */}
          {/* <TableCell align='right'>Net Amount</TableCell>
          <TableCell align='right'>Balancee</TableCell> */}
          </TableRow>
            </TableBody>
            </Table>
            ) : (
              <ShowLoadingOrEmpty message='No Products Found' />
              )}

           Sales Transfered To Other Accounts   
{true ? (
          <Table size='small'>
          <TableHead>
          <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>BILL</TableCell>
          {/* <TableCell>Bill Type</TableCell> */}
          <TableCell>PARTICULAR</TableCell>
          {/* <TableCell align='right'>Recieved Amount</TableCell> */}
          <TableCell align='right'>BILL NO</TableCell>
          <TableCell align='right'>AMOUNT OUT</TableCell>
          {/* <TableCell align='right'>Net Amount</TableCell>
          <TableCell align='right'>Balancee</TableCell> */}
          </TableRow>
          </TableHead>
          <TableBody>
          {filtredProducts.filter((data) => data.BR == 'BR').map((row, index) => (
            <TableRow key={row.product_id} onClick={() => openImageDialog(row.connect_id)}>
            <TableCell>{index+1}</TableCell>
            {/* <TableCell><Checkbox></Checkbox> </TableCell> */}
            <TableCell>{row.BR = 'C' ? `CASH ${row.bill_type}` : `CREDIT ${row.bill_type}` }</TableCell>
            {/* <TableCell>{row.bill_no}</TableCell> */}
            <TableCell>{` ${row.bill_no}`} <br />{`${row.fname} ${row.lname}`} </TableCell>
            <TableCell align='right'>{row.connect_id}</TableCell>
            <TableCell align='right'><NumberFormat amount={row.cashout} /></TableCell>
            {/* <TableCell align='right'>{row.amount}</TableCell>
            <TableCell align='right'>{balancebe = parseInt(balancebe) - parseInt(row.cashout) + parseInt(row.cashin)} </TableCell> */}
            </TableRow>
            ))}
                <TableRow>
          <TableCell>#</TableCell>
          <TableCell>#</TableCell>
          {/* <TableCell>Bill Type</TableCell> */}
          <TableCell>Total Expense</TableCell>
          <TableCell align='right'>#</TableCell>
          <TableCell align='right'><NumberFormat amount={closingExpense} /></TableCell>
          {/* <TableCell align='right'>Net Amount</TableCell>
          <TableCell align='right'>Balancee</TableCell> */}
          </TableRow>
                <TableRow>
          <TableCell>#</TableCell>
          <TableCell>#</TableCell>
          {/* <TableCell>Bill Type</TableCell> */}
          <TableCell>Closing Balance :</TableCell>
          <TableCell align='right'>#</TableCell>
          <TableCell style={{fontWeight:900}}  align='right'> <NumberFormat amount={closingBalance} /></TableCell>
          {/* <TableCell align='right'>Net Amount</TableCell>
          <TableCell align='right'>Balancee</TableCell> */}
          </TableRow>
                <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
          {/* <TableCell>Bill Type</TableCell> */}
          <TableCell></TableCell>
          <TableCell align='right'></TableCell>
          <TableCell  style={{fontWeight:900}} align='right'><NumberFormat amount={closingBalance + closingExpense} /></TableCell>
          {/* <TableCell align='right'>Net Amount</TableCell>
          <TableCell align='right'>Balancee</TableCell> */}
          </TableRow>

          <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
          {/* <TableCell>Bill Type</TableCell> */}
          <TableCell></TableCell>
          <TableCell style={{fontWeight:900}} align='right'></TableCell>
          <TableCell style={{fontWeight:900}} align='right'></TableCell>
          {/* <TableCell align='right'>Amount Out</TableCell> */}
          {/* <TableCell align='right'>Net Amount</TableCell>
          <TableCell align='right'>Balancee</TableCell> */}
          </TableRow>
            </TableBody>
            </Table>
            ) : (
              <ShowLoadingOrEmpty message='No Products Found' />
              )}
      {/* <Button
      variant='contained'
      color='primary'
      startIcon={<AddCircleIcon />}
      onClick={() => openAddDialog()}
      >
      Add Items
      </Button> */}
{/*     
      <div>
      <Title>Daybook</Title>
      <div>
      <Calendar language="en" theme="deepdark" dateFormat="YYYY/MM/DD" onChange={(date) => {setFilterData({...filterData,subdomain,dateString:date.bsDate,odString:date.bsDate})}}/>
      </div>
      </div>
      
      <div className='flex justify-between'>
      <TextField
      id='outlined-basic'
      label='Search'
      fullWidth
      style={{ margin: 10 }}
      variant='outlined'
      name='search'
      onChange={handleDataChange}
      />
      
      <FormControl
      className={classes.formControl}
      style={{ margin: 10 }}
      variant='outlined'
      >
      <InputLabel shrink>Limit</InputLabel>
      <Select
      name='limit'
      value={filterData.limit}
      onChange={handleDataChange}
      >
      <MenuItem value={10}>10</MenuItem>
      <MenuItem value={20}>20</MenuItem>
      <MenuItem value={30}>30</MenuItem>
      <MenuItem value={50}>50</MenuItem>
      <MenuItem value={100}>100</MenuItem>
      </Select>
      </FormControl>
      <Button
      className={classes.button}
      startIcon={<EditIcon />}
      onClick={() => handleClose(!open)}
      >
      {filterData.categoryId ||
        filterData.vendorId ||
        filterData.subtract ||
        filterData.status
        ? 'Edit Filter'
        : 'Filter'}
        </Button>
        <Button
        className={classes.button}
        href={`${API_URL}/product?excel=true&page=${filterData?.page}&limit=${filterData?.limit}&search=${filterData?.search}&categoryId=${filterData?.categoryId}&vendorId=${filterData?.vendorId}&status=${filterData?.status}&price=${filterData?.price}`}
        >
        Export Excel
        </Button>
        </div>
        {true ? (
          <Table size='small'>
          <TableHead>
          <TableRow>
          <TableCell>Id</TableCell>
          <TableCell>Bill</TableCell>
          <TableCell>Particular</TableCell>
          <TableCell align='right'>Recieved Amount</TableCell>
          <TableCell align='right'>Amount Out</TableCell>
          <TableCell align='right'>Net Amount</TableCell>
          <TableCell align='right'>Balancee</TableCell>
          </TableRow>
          </TableHead>
          <TableBody>
          {products.map((row, index) => (
            <TableRow key={row.product_id} onClick={() => openImageDialog(row.connect_id)}>
            <TableCell>{row.connect_id}</TableCell>
            <TableCell>{row.BR = 'C' ? `CASH ${row.bill_type}` : `CREDIT ${row.bill_type}` }</TableCell>
            <TableCell>{`${row.fname} ${row.lname} `}</TableCell>
            <TableCell align='right'>{row.cashin}</TableCell>
            <TableCell align='right'>{row.cashout}</TableCell>
            <TableCell align='right'>{row.amount}</TableCell>
            <TableCell align='right'>{balancebe = parseInt(balancebe) - parseInt(row.cashout) + parseInt(row.cashin)} </TableCell>
            </TableRow>
            ))}
            </TableBody>
            </Table>
            ) : (
              <ShowLoadingOrEmpty message='No Products Found' />
              )} */}
              
              {/* <div className={classes.seeMore}> */}
                {/* Closing Balance : <NumberFormat amount={balancebe} /> */}
              {/* <Link color='primary' href='#' onClick={loadMore}>
              See more orders
              </Link> */}
              {/* </div> */}
              <BillDetaildiv open={openImage} connect_id={image} data={{connect_id:image, bill_date:'2078/04/12'}} bill_date={'2078/04/12'} subdomain={subdomain} handleClose={() => setOpenImage(false)} />
              {/* <AddProduct
                open={addOpen}
                handleClose={closeAddDialog}
                editStockData={currentApp}
              /> */}
              {open && (
                <FilterDaybook
                open={open}
                data={filterData}
                handleClose={() => handleClose(!open)}
                filterProducts={filterProducts}
                />
                )}
                {/* {openImage && (
                  <ImageDialog
                  open={openImage}
                  handleClose={() => setOpenImage(false)}
                  image={image}
                  />
                )} */}
                </Paper>
                </Grid>
                    </Grid>
                    </>
                );
              }
              