import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
  } from '@material-ui/core';
  import React, { useEffect, useState } from 'react';
  import { useSelector, useDispatch } from 'react-redux';
  import * as actions from 'modules/store/DashboardActions';
  import CashRules from './CashRules';
import Title from 'modules/Dashboard/Components/Title';
import { ValidateInput} from 'utils/validateForm';
import Calendar from '@sbmdkl/nepali-datepicker-reactjs';

  
  const AddCash= ({ open, handleClose, editStockData }) => {
    const vendorList = useSelector((state) => state.dashboardReducer.vendors);
    const dispatch = useDispatch();
    const initialData = {
      amount: '',
      date: '',
    };
    const [stockData, setStockData] = useState(initialData);
    const [notes, setNotes] = useState(null);
    const [errors, setErrors] = useState(initialData);
    const onChangeHandler = (e) => {
      setStockData({
        ...stockData,
        [e.target.name]: e.target.value,
      });
    };
    const onChangeNotesHandler = (e) => {
      setStockData({
        ...stockData,
        ['note']: e,
      });
    };
  
    useEffect(() => {
      if (vendorList.length === 0) {
        dispatch(actions.get_vendors());
      }
    }, [vendorList, dispatch]);
  
    useEffect(() => {
      // console.log(editStockData);
      if (editStockData) {
        setStockData(editStockData);
      } else {
        setStockData(initialData);
      }
    }, [editStockData]);
  
    const inputValidation = (e) => {
      let errorMsg = ValidateInput(e.target.name, e.target.value, CashRules);
      setErrors({
        ...errors,
        [e.target.name]: errorMsg,
      });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // console.log(stockData);
      // let errorMsgs = ValidateForm(stockData, CashRules);
      // setErrors({ ...errorMsgs });
      // let validated = Object.values(errorMsgs).join('').length === 0;
      // if (validated) {
        dispatch(actions.add_cash(stockData));
        handleClose();
      // }
    };
    return (
      <Dialog
        open={open}
        fullScreen={false}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>
      <Grid container spacing={2} style={{justifyContent:'space-between',padding:10,}}>

        <Grid>
          <Title>Cash Adjust {stockData.mainappname}</Title>
          </Grid>
          <Grid>

          <Calendar hideDefaultValue={true} placeholder="*Select Date" value={stockData.date} key={stockData} language="en" theme="deepdark" style={{color:'#000',border:0,display:'inline',textAlign:'center',minWidth:'300px'}} dateFormat="YYYY/MM/DD" onChange={(date) => {setStockData({...stockData,date:date.bsDate})}}> </Calendar>
          </Grid>
          </Grid>

        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  margin='dense'
                  name='amount'
                  value={stockData.amount}
                  error={errors.amount}
                  helperText={errors.amount}
                  label='Amount'
                  onChange={onChangeHandler}
                  onBlur={inputValidation}
                  type='text'
                  fullWidth
                  disable
                />
              </Grid>
             {/*  <Grid item xs={6}>
                <TextField
                  margin='dense'
                  id='name'
                  name='date'
                  value={stockData.date}
                  error={errors.date}
                  helperText={errors.date}
                  onChange={onChangeHandler}
                  onBlur={inputValidation}
                  label='Date'
                  type='text'
                  fullWidth
                /> 
              </Grid>*/}
              <div>.</div>
              <div>.</div>
              <div>.</div>
              <div>.</div>
              <div>.</div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </Grid>  
          </form>
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={handleClose}>
            Cancel
          </Button>
          {editStockData ? (
            <Button variant='contained' color='primary' onClick={handleSubmit}>
              Update
            </Button>
          ) : (
            <Button variant='contained' color='primary' onClick={handleSubmit}>
              Create
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };
  
  export default AddCash;