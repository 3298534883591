// eslint-disable-next-line
export default {
  vendor_id: {
    required: true,
    label: 'VendorId',
  },
  mainappname: {
    required: true,
    label: 'App name',
  },
  apptyp: {
    required: true,
    label: 'Type',
  },
  subdomain: {
    required: true,
    label: 'Sub domain',
  },
  db_name: {
    required: true,
    label: 'Database Name',
  },
  contact: {
    required: true,
    label: 'Contact',
  },
  appcode: {
    required: true,
    label: 'App Code',
  },
  start_date: {
    required: true,
    label: 'Start Date',
  },
  valid_till: {
    required: true,
    label: 'End Date',
  },
};
