import { BILL_ADD, BILL_CLEAR, BILL_REMOVE_ADD_ITEMS, BILL_UPDATE } from './billTypes';

export const setBillAdd = (data) => {
  return async (dispatch) => {
    dispatch({
      type: BILL_ADD,
      data,
    });
  };
};

export const setBillUpdate = (data) => {
  return async (dispatch) => {
    dispatch({
      type: BILL_UPDATE,
      data,
    });
  };
};

export const setBillClear = (data) => {
  return async (dispatch) => {
    dispatch({
      type: BILL_CLEAR,
    });
  };
};

export const setRemoveAddItem = (position) => {
  return async (dispatch) => {
    dispatch({
      type: BILL_REMOVE_ADD_ITEMS,
      data: position,
    });
  };
};
