// eslint-disable-next-line
export default {
  keyword: {
    required: true,
    label: 'Keyword',
  },
  type: {
    required: true,
    label: 'Type',
  },
  message: {
    required: true,
    label: 'Message',
  },
};
