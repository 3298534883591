import Card from 'components/Card/Card';
import Cards from 'modules/Dashboard/Components/Cards';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import capitalizeFirstLetter from 'utils/CapitalizeFirstLetter';
import { setBillAdd } from '../store';

const cardColor = ['#0073E4', '#0D8319', '#FBB500', '#F54141', '#0073E4'];

const MostSold = ({data,connect_id,subdomain}) => {
    let cardColorIndex=0;
  const dispatch = useDispatch();
// console.log("data",data);
    const addBilledItems = (d) => {
        // console.log("///////////////");
        // console.log(d)
        dispatch(
          setBillAdd({
            name:d.name,
            quantity:1,
            rate:d.rate,
            sales_d_id:connect_id,
            product_id:d.product_id,
            subdomain,
          })
          );
        };
  return (
    <React.Fragment>
      <div style={{marginTop:'-15px',position:'relative',textAlign:'center'}}>
        ⚡ Quick Shortcuts
      </div>
         <div
            style={{
              marginLeft: 10,
              display: 'flex',
              flexDirection:'row',
              flexWrap: 'wrap',
              alignItems: 'left',
              alignContent:'start',
              justifyContent:'space-evenly',
              cursor:'pointer',
              width:'28vw',
              height:'45vh',
              overflow:'scroll',
            }}
          >
            {data.map((card, index) => {
                cardColorIndex++;
                return (
                    <Card
                    style={{
                      padding: 8,
                      // borderRadius: 10,
                    //   borderLeft: `4px solid green`,
                      width: 'auto',
                      maxWidth:'30%',
                      margin:2,
                      marginTop:20,
                      marginBottom: 0
                    }}
                    onClick={()=>addBilledItems(card)}
                    >
                    <div >
                    <div style={{ fontSize: 14 }}>{card.name }</div>
                    {/* <div style={{ fontSize: 14 }}>{card?.name[0] + card?.name[1] + card?.name[2] + card?.name[3] + card?.name[4] }...</div> */}
                    <div style={{ fontSize: 12 }}>Rate: {card.rate}</div>
                    </div>
                    </Card>
                );
              })}
          </div>
    </React.Fragment>
  );
};

export default MostSold;
