import { makeStyles } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import Update from "@material-ui/icons/Update";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";


const useStyles = makeStyles(styles);
function capitalizeFirstLetter(string) {
    let mySentence = (string.charAt(0).toUpperCase() + string.slice(1)).replace("_"," ");
    const words = mySentence.split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1) + " ";
  }
  return words;
  }
export default function TopCard({ title, value, borderColor,page,icon,postfix }){
  const classes = useStyles();

    return(
<GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color={borderColor} stats icon>
              <CardIcon color={borderColor}>
                <Icon>{icon}</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>{capitalizeFirstLetter(title)}</p>
              <h3 className={classes.cardTitle}>
              <small>रु </small> {value} 
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                {/* <Danger>
                  <Warning />
                </Danger> */}
                 <Update />
                 last 30 days
                {/* <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Get more space
                </a> */}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
    )
}