import * as actionTypes from './ToasterType';
export const setToasterState = (toasterData) => {
  return {
    type: actionTypes.SET_TOASTER,
    open: toasterData.open,
    title: toasterData.title,
    name: toasterData.name,
    message: toasterData.message,
  };
};
