import { IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Edit from "@material-ui/icons/Edit";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// core components
import GridItem from "components/Grid/GridItem.js";
import { CDN_URL } from "config";
import ShowLoadingOrEmpty from "modules/Dashboard/Components/ShowLoadingOrEmpty";
import BillDetaildiv from "modules/inventory/daybook/Components/BillDetailView";
import { GetRequest } from "plugins/axios";
import api from "api/api";
import ImageDialog from "../Product/Components/ImageDialog";


const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function ProductDetail(props) {
  const [loading, setLoading] = useState(false);
  const [openBill, setOpenBill] = useState(false);
  const [salesId, setSalesId] = useState(0);
  const [total, setTotal] = useState(0);
  const [openImage, setOpenImage] = useState(false);
  const [product, setProduct] = useState();
  const [ledger, setLedger] = useState([]);
  let productData = [{}];
  const subdomain = useSelector((state) => state.authReducer.user.subdomain);
  const classes = useStyles();
  const stateProp= props.history.location.state;
  const product_id=props.history.location.product_id;

useEffect(()=>{
  let totalData=0;
  ledger.map((data)=>{
totalData += data.amount;
  })
  setTotal(totalData);
},[ledger]);

  const get_product_details = () => {
    setLoading(true);
    GetRequest(`${api.productGetDetailsAPI}/${product_id}`, { subdomain })
      .then((res) => {
        
        setLoading(false);
        // console.log("content",res.data.content);
        setProduct(res.data.content);
      // console.log("product details::::",product);
      // get_product_category(res.data.content.category_id);
      })
      .catch((err) => {
        
      });
  };
  const openBillDialog = (image) => {
    // console.log("logs::::::::::::::::;",image);
    if (true) {
      setSalesId(image);
      setOpenBill(true);
    // console.log("logs::::::::::::::::;");

    }
    
  };

  const get_product_ledger = () => {
    setLoading(true);
    GetRequest(`${api.salesItemByProductIdAPI}`, { product_id:product_id,subdomain })
      .then((res) => {
        
        setLoading(false);
        setLedger(res.data.content);
        // console.log('ledger',res.data.content);
      })
      .catch((err) => {
        
      });
  };
  useEffect(() => {
    get_product_ledger();
      get_product_details();
  }, [subdomain,product_id]);
  useEffect(() => {
   productData = product; 
   // console.log('productData',productData);
}, [product]);
  return (
    <div>
      <GridContainer>
      <GridItem xs={12} sm={12} md={4}>
        <Tooltip
                id="tooltip-top"
                title="Edit Profile"
                placement="top-end"
                classes={{ tooltip: classes.tooltip }}
              >
                <IconButton
                  aria-label="Edit"
                  className={classes.tableActionButton}
                >
                  <Edit
                    className={
                      classes.tableActionButtonIcon + " " + classes.edit
                    }
                  />
                </IconButton>
              </Tooltip>
          <Card profile>
          
            <CardAvatar profile onClick={()=>{setOpenImage(true)}}>
              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                <img src={`${CDN_URL}/${product?.image}`} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile>
             <h6 className={classes.cardCategory}>{product?.product_id} / {product?.model}</h6>
              <h4 className={classes.cardTitle}>{product?.name}</h4>
              <p className={classes.description}>
Stock:	{product?.stock}<br/>
Rate:	{product?.price}<br/>
{/* NOTE* ITEM WAS LISTED ON {product?.date_added}<br/> */}
              </p> 
              <Button color="primary" round>
                More
              </Button>
              <Button color="primary" round>
                Appoint
              </Button>

            </CardBody>
            <CardFooter stats>
            <Button color="primary" round>
                Appoint
              </Button>
              </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Item Ledger </h4>
              {/* <p className={classes.cardCategoryWhite}>Complete your profile</p> */}
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                {ledger.length > 0 ? (
          <Table size='small'>
          <TableHead>
          <TableRow>
          <TableCell>Id</TableCell>
          <TableCell>Bill No</TableCell>
          <TableCell>Bill to</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Particular</TableCell>
          <TableCell>Bill Date</TableCell>
          <TableCell align='right'>Quantity In</TableCell>
          <TableCell align='right'>Quantity Out/For</TableCell>
          <TableCell align='right'>Amount</TableCell>
          </TableRow>
          </TableHead>
          <TableBody>
          {ledger.map((row, index) => (
            <TableRow key={index} onClick={() => openBillDialog(row.sales_data_id)}>
            <TableCell>{index+1}</TableCell>
            <TableCell>{`${row.connect_id}  `}</TableCell>
            <TableCell >{`${row.fname} ${row.lname}`} ({row.cas_id})</TableCell>
            <TableCell >{row.bill_type}</TableCell>
            <TableCell >{row.name}<br/>{row.bill_no}</TableCell>
            <TableCell>{`${row.bill_date}  `}</TableCell>
            <TableCell align='right'>{`${row.stockin==0 ? '--' : row.stockin + 'x' + row.rate } `}</TableCell>
            <TableCell align='right'>{`${row.stockout==0 ? '--' : row.stockout + 'x' + row.rate} `}</TableCell>
            <TableCell align='right'>{`${row.amount} `}</TableCell>
            </TableRow>
            ))}
            <TableRow>
            <TableCell>#</TableCell>
            <TableCell >#</TableCell>
            <TableCell >Remaning Balance</TableCell>
            <TableCell >----</TableCell>
            <TableCell>----</TableCell>
            <TableCell>----</TableCell>
            <TableCell align='right'>{product?.stock}</TableCell>
            <TableCell align='right'></TableCell>
            <TableCell align='right'>{total}</TableCell>
            {/* <TableCell align='right'>{product.stock}pcs</TableCell> */}
            </TableRow>
            </TableBody>
            <TableHead>
            <TableRow>
            <TableCell>#</TableCell>
            <TableCell>***</TableCell>
            {/* <Paper className={classes.paper}>
                <div>Name</div>
                <div>Cas id</div>
                <div>Contact No</div>
                <div>Address</div>

                </Paper> */}
                <Paper className={classes.paper}>
                {/* <Button
                  variant='contained'
                  color='primary'
                  startIcon={<AddCircleIcon />}
                  onClick={() => openAddDialog()}
                  >
                  Add Items
                </Button> */}
                      
                      {/* <div className={classes.seeMore}>
                      {/* <Link color='primary' href='#' onClick={loadMore}>
                      See more orders
                      </Link> */}
                  {/*     </div> */}
                      {/* <AddProduct
                        open={addOpen}
                        handleClose={closeAddDialog}
                        editStockData={currentApp}
                      /> */}
                        <BillDetaildiv open={openBill} connect_id={salesId} data={{connect_id:salesId, bill_date:'2078/04/12'}} bill_date={'2078/04/12'} subdomain={subdomain} handleClose={() => setOpenBill(false)} />
                        {openImage && (
                          <ImageDialog
                          open={openImage}
                          handleClose={() => setOpenImage(false)}
                          image={product?.image}
                          />
                        )}
                        </Paper>
            </TableRow>
            </TableHead>
            </Table>
            ) : (
              <ShowLoadingOrEmpty message='No Transaction Found' />
              )}
                  <InputLabel style={{ color: "#AAAAAA" }}>About Product</InputLabel>
                  <CustomInput
                    labelText=""
                    id="about-me"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5,
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary">Print Report</Button>
            </CardFooter>
          </Card>
        </GridItem>
       
      </GridContainer>
    </div>
  );
}
