import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import avatar from "assets/img/faces/marc.jpg";
import { IconButton, Tooltip } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import Sales from "modules/inventory/Sales";
import { useSelector } from "react-redux";
import AddDocuments from "modules/views/UserProfile/Components/AddDocuments";
import QRCode from "react-qr-code";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#000",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "1000",
    fontSize:25,
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  
};

const useStyles = makeStyles(styles);

export default function ProfileCard({details,fullname}) {
  const classes = useStyles();
  const staffData = details;

  const [addOpenContact, setAddOpenContact] = useState(false);
  const [currentApp, setCurrentApp] = useState(false);
  const openAddContact = (app) => {
    setCurrentApp(app);
    setAddOpenContact(true);
  };
  const closeAddContact = () => {
    setCurrentApp('');
    setAddOpenContact(false);
  };
  return (
    <div>
      
        <Tooltip
                id="tooltip-top"
                title="Edit Profile"
                placement="top-end"
                classes={{ tooltip: classes.tooltip }}
              >
                <IconButton
                  aria-label="Edit"
                  className={classes.tableActionButton}
                >
                  <Edit
                    className={
                      classes.tableActionButtonIcon + " " + classes.edit
                    }
                  />
                </IconButton>
              </Tooltip>
          <Card profile>
          
            <CardAvatar profile>
              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                {/* <img src={avatar} alt="..." /> */}
                <QRCode size={100} value={staffData?.id ? staffData?.id : 'null'} />
              </a>
            </CardAvatar>
            <CardBody profile>
            <h6 className={classes.cardCategory}>{staffData?.id} / {staffData?.department}</h6>
             <h4 className={classes.cardTitleWhite}>{fullname}</h4>
              <p  style={{textAlign: "left",fontWeight:800}}className={classes.description}>
                <table width='100%'>
                  <tr>
                    <td>ADDRESS:</td>
                    <td>{staffData?.address}</td>
                  </tr>
                  <tr>
                    <td>QUALIFICATION:</td>
                    <td>{staffData?.qualification}</td>
                  </tr>
                  <tr>
                    <td>DATE OF BIRTH:</td>
                    <td>{staffData?.dob}</td>
                  </tr>
                  <tr>
                    <td>CONTACT:</td>
                    <td>{staffData?.contact}</td>
                  </tr>
                  <tr>
                    <td>EMAIL:</td>
                    <td>{staffData?.email}</td>
                  </tr>
                </table>
                NOTE* USER WAS REGISTERD ON {staffData?.startdate} <br/>

              </p>
              <Button color="primary" round>
                More
              </Button>
              <Button color="primary" round>
                Appoint
              </Button>

            </CardBody>
            <CardFooter stats>
            <Button color="primary" round onClick={openAddContact}>
                Create Document
              </Button>
              </CardFooter>
          </Card>
       
          <AddDocuments
        open={addOpenContact}
        handleClose={closeAddContact}
        // editStockData={currentApp}
      />
    </div>
  );
}
