import { Card, Divider, Grid, makeStyles, Paper } from '@material-ui/core';
import React, { useState } from 'react';
import api from '../../../api/api'
import { GetRequest } from '../../../plugins/axios';
import Cards from './Cards';
import CreateList from './CreateList';
import ServiceItem from './ServiceItem';
import NepaliDate from 'nepali-date-converter';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const servicesList = [
  {
    id: 1,
    title: 'Home',
    icon: 'home',
  },
  {
    id: 2,
    title: 'Dashboard',
    icon: 'home',
  },
  {
    id: 3,
    title: 'Costumer',
    icon: 'home',
  },
  {
    id: 4,
    title: 'Current Stock',
    icon: 'home',
  },
  {
    id: 5,
    title: 'Credit',
    icon: 'home',
  },
  {
    id: 6,
    title: 'Chat',
    icon: 'home',
  },
  {
    id: 7,
    title: 'Sales',
    icon: 'home',
  },
  {
    id: 8,
    title: 'More Options',
    icon: 'home',
  },
];
const createList = [
  {
    id: 1,
    title: 'Sales Bill',
    icon: 'home',
  },
  {
    id: 2,
    title: 'Purchase Bill',
    icon: 'home',
  },
  {
    id: 3,
    title: 'Expenses Bill',
    icon: 'home',
  },
  {
    id: 4,
    title: 'Customer',
    icon: 'home',
  },
  {
    id: 5,
    title: 'Vendor',
    icon: 'home',
  },
  {
    id: 6,
    title: 'Product',
    icon: 'home',
  },
  {
    id: 7,
    title: 'Category',
    icon: 'home',
  },
  {
    id: 8,
    title: 'manufacturer',
    icon: 'home',
  },
];
const cardColor = ['#0073E4', '#0D8319', '#FBB500', '#F54141', '#0073E4'];
const DashboardCard = () => {
  const username = useSelector((state) => state.authReducer.user.name);
  // console.log(username);
  const getDashboardCardData = () => {
    const nepaliDate = new NepaliDate();

    GetRequest(`${api.dashboardCardAPI}`, {
      today: `${nepaliDate.getYear()}/${
        nepaliDate.getMonth() + 1 > 9
          ? nepaliDate.getMonth() + 1
          : `0${nepaliDate.getMonth() + 1}`
      }/${nepaliDate.getDate()}`,
    })
      .then((res) => {
        
        setCards(res.data.content);
        // setSavings(res.data.content);
      })
      .catch((err) => {
        alert('Error Geting Dashboard Card');
      });
  };
  const [cards, setCards] = useState([0,0,0,0]);
  let cardColorIndex = -1;
  const classes = useStyles();
  useEffect(()=>{
    getDashboardCardData();
  },[])
  return (
    <React.Fragment>
      Hello,
      <div style={{ fontSize: 25, fontWeight: '700' }}>
      {username}
      </div>
         <div
            style={{
              marginVertical: 5,
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {cards.map((card, index) =>
              Object.keys(card).map((key, i) => {
                cardColorIndex++;
                return (
                  <Cards
                    key={cardColorIndex}
                    page={'product'}
                    title={key}
                    value={card[key]}
                    borderColor={cardColor[cardColorIndex]}
                  />
                );
              })
            )}
          </div>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Paper className={classes.paper}>
            <form>
              <div className='form-group'>
                <input
                  className='input'
                  type='text'
                  name='search'
                  placeholder='Search'
                />
              </div>
            </form>

            <div>
              <div className={'flex space-between'}>
                <h3>Services</h3>
              </div>

              <div className='services flex wrap'>
                {servicesList.map((service) => (
                  <ServiceItem key={service.id} service={service} />
                ))}
              </div>
            </div>
            <Divider />
            <div>
              <div className={'flex space-between'}>
                <h3>Report</h3>
              </div>

              <div className='services flex wrap'>
                {servicesList.map((service) => (
                  <ServiceItem key={service.id} service={service} />
                ))}
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item md={4} xs={12}>
          <Paper className={classes.paper}>
            <h3>Create</h3>
            <div className='create-items'>
              {createList.map((create) => (
                <CreateList key={create.id} create={create} />
              ))}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default DashboardCard;
