import { setToasterState } from '../../../common/Toast/services/ToasterAction';
import { API_URL } from '../../../config';
import { PostRequest } from '../../../plugins/axios';

export const loginSuccess = (payload) => {
  return {
    type: 'LOGIN_SUCCESS',
    payload: payload,
  };
};
export const setSubdomain = (payload) => {
  return {
    type: 'SUBDOMAIN',
    payload: payload,
  };
};

export const setLoading = (payload) => {
  return {
    type: 'LOADING',
    payload: payload,
  };
};

export const loginFailed = () => {
  return {
    type: 'LOGIN_FAIL',
  };
};

export const logoutSuccess = () => {
  return {
    type: 'LOGOUT',
  };
};

export const authenticate =
  (payload = {}) =>
  (dispatch) => {
    dispatch(setLoading(true));
    PostRequest(
      API_URL + '/auth/admin/login',
      {
        username: payload.username,
        password: payload.password,
        subdomain: payload.subdomain,
      },
      {}
    )
      .then((response) => {
        // console.log(response.data);
        dispatch(loginSuccess(response.data));
        dispatch(
          setToasterState({
            open: true,
            title: 'success',
            name: 'Welcome Back!',
            message: 'Login Success!',
          })
        );
      })
      .catch((errors) => {
        dispatch(setLoading(false));
        dispatch(loginFailed());
        dispatch(
          setToasterState({
            open: true,
            title: 'error',
            name: 'Sorry!',
            message: errors?.response?.data?.message || 'Login Failed!',
          })
        );
      });
  };

export const reauthenticate = () => (dispatch) => {
  try {
    let token = localStorage.getItem('token');
    let user = localStorage.getItem('user');
    if (token && token !== 'token') {
      //skip this
      let userObject = JSON.parse(user);
      dispatch(loginSuccess({ token: token, user: userObject }));
      //   let decode = jwt_decode(token);
      //   // console.log(decode);
      //   if (decode?.expireTime > Date.now()) {
      //       // console.log('here');
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'Welcome Back!',
          message: 'Sucess Reauthentication!',
        })
      );

      //   } else {
      //     // console.log('token expires');
      //     logout()(dispatch);
      //   }
    }
  } catch (e) {
    // console.log('token expires');
    logout()(dispatch);
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('subdomain');
  dispatch(logoutSuccess());
  dispatch(
    setToasterState({
      open: true,
      title: 'success',
      name: 'See You!',
      message: 'Logout Success!',
    })
  );
};
