import { Button, Link, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Title from '../../../Dashboard/Components/Title';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddStockAppMain from './Components/AddStockAppMain';
import * as actions from '../../../store/DashboardActions';
import ShowLoadingOrEmpty from '../../Components/ShowLoadingOrEmpty';
import AddContact from './Components/AddContact';
import Add from '@material-ui/icons/Add';
import { Search } from '@material-ui/icons';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Calendar from '@sbmdkl/nepali-datepicker-reactjs';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { AgGridColumn } from 'ag-grid-react/lib/agGridColumn';
import EditIcon from '@material-ui/icons/Edit';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import { Tooltip } from '@material-ui/core';
import UnstyledSwitcher from 'components/UnstyledSwitcher';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  headerButton: {
    margin: 10,
    color:"#fff",
  },
}));

const StockAppMain = () => {
  const classes = useStyles();
  
  const [apps, setApps] = useState([]);
  const dispatch = useDispatch();
  const [addOpen, setAddOpen] = useState(false);
  const [addOpenContact, setAddOpenContact] = useState(false);
  const [currentApp, setCurrentApp] = useState('');
  const [floatingSearch, setFloatingSearch] = useState(true);
  const [startDate, setStartDate] = useState("2078/01/01");
  const [endDate, setEndDate] = useState("2078/12/32");
  const stockApps = useSelector((state) => state.dashboardReducer.apps);
  
  const OptionsRenderer = (props)=>{
    return(
      <>
      <Tooltip title="Edit here">
      <Button className={classes.button} startIcon={<EditIcon />} onClick={() => openAddDialog(props.data)} />
      </Tooltip>
      <Tooltip title="Assign access to this contact">
      <Button className={classes.button} startIcon={<AddIcCallIcon/>} onClick={() => openAddContact(props.data)} />
      </Tooltip>
      </>
  
      
    )
  }

  useEffect(() => {
    dispatch(actions.get_stock_app_mains());
  }, [dispatch]);
  
  useEffect(() => {
    setApps(stockApps);
  }, [stockApps]);
  
  const openAddDialog = (app) => {
    setCurrentApp(app);
    setAddOpen(true);
  };
  const openAddContact = (app) => {
    setCurrentApp(app);
    setAddOpenContact(true);
  };
  const closeAddDialog = () => {
    setCurrentApp('');
    setAddOpen(false);
  };
  const closeAddContact = () => {
    setCurrentApp('');
    setAddOpenContact(false);
  };
  
  return (
    <Card>
    <CardHeader  style={{display:'flex',justifyContent:'space-between',flexDirection:'column'}} color="primary">



      <h4 style={{ color: "#fff", fontWeight: "400" }}>Stock App Main</h4>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="justify-between">
          <Button onClick={()=>openAddDialog(false)} className={classes.headerButton}>
          <Add color="#ffff"/>
          </Button>
          
          <Button
            className={classes.headerButton}
           
          >
            <DownloadForOfflineIcon color="#ffff"/>
          </Button>
        </div>
        <span className="date-selector" style={{ margin: "10px" }}>

<ArrowBackIosIcon
style={{ height: "15px", marginTop: "10px" }}
/>
<Calendar
style={{
  width: "271px",
  borderColor: "#0000",
  backgroundColor: "#0000",
  borderBottom: "1px solid #929292",
  fontWeight: 700,
}}
language="en"
theme="default"
dateFormat="YYYY/MM/DD"
onChange={(date) => {
  setStartDate(date.bsDate);
}}
//defaultDate={startDate}
/>
<Button style={{color:'#fff'}}>To</Button>
<Calendar
style={{
  width: "271px",
  borderColor: "#0000",
  backgroundColor: "#0000",
  borderBottom: "1px solid #929292",
  fontWeight: 700,
}}
language="en"
theme="default"
dateFormat="YYYY/MM/DD"
onChange={(date) => {
  setEndDate(date.bsDate);
}}
minDate={startDate}
//defaultDate={endDate}
/>

{/* <DateRangePicker
ranges={[selectionRange]}
onChange={this.handleSelect}
/> */}
<ArrowForwardIosIcon
style={{ height: "15px", marginTop: "10px" }}

/>
</span>
      </div>
</CardHeader>
    <Paper className={classes.paper}>
  <div className="ag-theme-material" style={{
          height: '100vh',
          width: '100%'
        }} >
<AgGridReact
getRowStyle={(params)=>{
  if (params.node.rowIndex % 2 === 0) {
    return { background: '#f6f8fb' };
}
}}
            rowData={stockApps}
            defaultColDef={{
              flex: 1,
              minWidth: 200,
              editable: false,
              resizable: true,
              sortable: true,
              floatingFilter: floatingSearch,
            }}
            frameworkComponents={{
              OptionsRenderer:OptionsRenderer
            }}
          >

            <AgGridColumn headerName="Id" suppressMenu={true} resizable={true} field="id" sortable={true} filter={true} ></AgGridColumn>
            <AgGridColumn headerName="Vendor ID" suppressMenu={true}  field="vendor_id" sortable={true} filter={true} ></AgGridColumn>
            <AgGridColumn headerName="Subdomain" suppressMenu={true} resizable={true} field="subdomain" sortable={true} filter={true} ></AgGridColumn>
            <AgGridColumn headerName="DB Name" suppressMenu={true} resizable={true} field="db_name" sortable={true} filter={true} ></AgGridColumn>
            {/* <AgGridColumn headerName="Viber Api" suppressMenu={true} resizable={true} field="viber_api" sortable={true} filter={true} ></AgGridColumn> */}
           {/* <AgGridColumn headerName="App Type" cellRendererParams={{productId: '1'}} cellRenderer="apptyp" ></AgGridColumn>  */}
           {/* <AgGridColumn headerName="App Code" suppressMenu={true} resizable={true} field="appcode" sortable={true} filter={true} ></AgGridColumn> */}
            <AgGridColumn headerName="Main Appname" suppressMenu={true}  field="mainappname" sortable={true} filter={true} ></AgGridColumn>
            <AgGridColumn headerName="Notice" suppressMenu={true} resizable={true} field="notice" sortable={true} filter={true} ></AgGridColumn>
            <AgGridColumn headerName="Start Date" suppressMenu={true} resizable={true} field="start_date" sortable={true} filter={true} ></AgGridColumn>
            <AgGridColumn headerName="Valid Till" suppressMenu={true} resizable={true} field="valid_till" sortable={true} filter={true} ></AgGridColumn>
           <AgGridColumn headerName="Actions" cellRendererParams={{productId: '1'}} cellRenderer="OptionsRenderer" ></AgGridColumn> 

          </AgGridReact>

    {/*}  <div className='flex justify-between'>
        <Title>Stock App Main</Title>
        <Button
          variant='contained'
          color='primary'
          startIcon={<AddCircleIcon />}
          onClick={() => openAddDialog()}
        >
          Add Stock App Main
        </Button>
      </div>
      {apps.length > 0 ? (
        <Table size='medium'>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>VendorId</TableCell>
              <TableCell>Subdomain</TableCell>
              <TableCell>DB Name</TableCell>
              <TableCell>Viber Api</TableCell>
              <TableCell>App Type</TableCell>
              <TableCell>App Code</TableCell>
              <TableCell>Main Appname</TableCell>
              <TableCell>Notice</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>Valid Till</TableCell>
              <TableCell>Pin Code</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {apps.map((row) => (
              <TableRow key={row.id} 
              onClick={() => openAddDialog(row)}
              onContextMenu={(evt)=> {openAddContact(row); evt.preventDefault();}}
              >
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.vendor_id}</TableCell>
                <TableCell>{row.subdomain}</TableCell>
                <TableCell>{row.db_name}</TableCell>
                <TableCell>{row.viber_api}</TableCell>
                <TableCell>{row.apptyp}</TableCell>
                <TableCell>{row.appcode}</TableCell>
                <TableCell>{row.mainappname}</TableCell>
                <TableCell>{row.notice}</TableCell>
                <TableCell>{row.start_date}</TableCell>
                <TableCell>{row.valid_till}</TableCell>
                <TableCell>{row.pincode}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <ShowLoadingOrEmpty message='No Stock App Main Found' />
        )}
        <div className={classes.seeMore}>
        <Link color='primary'>Total: {apps.length}</Link>
      </div>*/}
        <AddStockAppMain
        open={addOpen}
        handleClose={closeAddDialog}
        editStockData={currentApp}
        />
        <AddContact
        open={addOpenContact}
        handleClose={closeAddContact}
        editStockData={currentApp}
      />
      </div>
    </Paper>
    </Card>
  );
};

export default StockAppMain;

