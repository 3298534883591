import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Portal,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Title from '../../../Components/Title';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../store/DashboardActions';
import StockAppRules from './StockAppRules';
import { ValidateForm, ValidateInput } from '../../../../../utils/validateForm';
import Calendar from '@sbmdkl/nepali-datepicker-reactjs';


const AddAppointment = ({ open, handleClose, editappointmentData, customerFullName }) => {
  const vendorList = useSelector((state) => state.dashboardReducer.vendors);
  const dispatch = useDispatch();


  const [search, setSearch] = useState();
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  // console.log("OPENING2",customerFullName)
  const initialData = {
    fullname: customerFullName || '',
    department:'',
    noteby: '',
    date: '',
    contact: '',
    note:'',
    topic:'',
  };
  //creating varaible for setting appointdata
  const [appointmentData, setAppointmentData] = useState(initialData);
  const [errors, setErrors] = useState({});
  //for geting and setting data formfullname input to appontment data
  const onChangeHandler = (e) => {
    setAppointmentData({
      ...appointmentData,
      [e.target.name]: (e.target.value),
    });
  };

  // useEffect(() => {
  //   if (vendorList.length === 0) {
  //     dispatch(actions.get_vendors());
  //   }
  // }, [vendorList, dispatch]);

  // useEffect(() => {
  //   setErrors(initialData);
  //   setappointmentData(initialData);
  // },[])
  useEffect(() => {
    dispatch(actions.get_customers(initialData));
    // console.log("get_customer",initialData);
  }, [search, appointmentData, dispatch]);


  const inputValidation = (e) => {
    let errorMsg = ValidateInput(e.target.name, e.target.value, StockAppRules);
    setErrors({
      ...errors,
      [e.target.name]: errorMsg,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errorMsgs = ValidateForm(appointmentData, StockAppRules);
    setErrors({ ...errorMsgs });
    let validated = Object.values(errorMsgs).join('').length === 0;
    if (validated) {
      dispatch(actions.add_appointment(appointmentData));
      handleClose();
    }
  };
useEffect(()=>{
if(editappointmentData){
  setAppointmentData(editappointmentData)
  console.log("🚀 ~ file: AddAppointment.jsx ~ line 95 ~ useEffect ~ editappointmentData", editappointmentData)
}else{
  setAppointmentData(initialData)
  console.log("🚀 ~ file: AddAppointment.jsx ~ line 97 ~ useEffect ~ initialData", initialData)
}
},[editappointmentData])
  const handleUpdate = (e) => {
    e.preventDefault();
    // console.log(appointmentData);
    let errorMsgs = ValidateForm(appointmentData, StockAppRules);
    setErrors({ ...errorMsgs });
    let validated = Object.values(errorMsgs).join('').length === 0;
    if (validated) {
      dispatch(actions.update_appointment(appointmentData));
      handleClose();
    }
  };
  return (
    <Dialog
      open={open}
      fullScreen={false}
      onClose={handleClose}
      aria-labelledby='responsive-dialog-title'
    >
      <DialogTitle id='responsive-dialog-title'>
      <Grid container spacing={2} style={{justifyContent:'space-between',padding:10,}}>
<Grid>

        <Title>Create Appointments</Title>
</Grid>
<Grid>

        <Calendar
                      style={{
                        width: "300px",
                        borderColor: "#0000",
                        backgroundColor: "#0000",
                        borderBottom: "0px solid #929292",
                        fontWeight: 700,
                        color:'#000',
                        textAlign:'right'
                      }}
                      language="en"
                      theme="default"
                      dateFormat="YYYY/MM/DD"
                      onChange={(date) => {
                        setAppointmentData(
                          {
                            ...appointmentData,
                            ['date']: date.bsDate + "",
                          } );
                        }}
                        />
                        </Grid>
                        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* <Grid item xs={10}>
    <FormControl fullWidth error={errors.vendor_id}>
    <InputLabel shrink>Vendor</InputLabel>
    <Select
    onBlur={inputValidation}
    displayEmpty
    name='vendor_id'
    value={appointmentData.vendor_id}
    onChange={onChangeHandler}
    >
    {vendorList.map((vendor) => (
      <MenuItem value={vendor.vendor_id}>
      {vendor.meta_title}
      </MenuItem>
      ))}
      </Select>
      <FormHelperText>{errors.vendor_id}</FormHelperText>
      </FormControl>
    </Grid> */}
            {/* <Grid item xs={2}>
    <Button>Add</Button>
  </Grid> */}
            <Grid item xs={6}>
              {/* //this is input for gtinng user input  */}
              <TextField
                margin='dense'
                name='fullname'
                //setting value for name or fname on input change
                onChange={onChangeHandler}
                //setting fnmae from currenly chnaged state 
                value={appointmentData.fullname || appointmentData.patient}




                error={errors.fname}
                helperText={errors.fname}
                label='FullName'
                onBlur={inputValidation}
                type='text'
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                name='department'
                value={appointmentData.department}
                error={errors.department}
                helperText={errors.department}
                label='department'
                onChange={onChangeHandler}
                onBlur={inputValidation}
                type='text'
                fullWidth
              />
            </Grid>
            {/* <Grid item xs={6}>
              <TextField
                disabled={true}
                margin='dense'
                id='name'
                name='subdomain'
                value={appointmentData.subdomain}
                onChange={onChangeHandler}
                onBlur={inputValidation}
                label='Subdomain'
                type='text'
                error={errors.subdomain}
                helperText={errors.subdomain}
                fullWidth
              />
            </Grid> */}
            <Grid item xs={6}>
              <TextField
                margin='dense'
                id='contact'
                name='contact'
                value={appointmentData.contact}
                error={errors.contact}
                helperText={errors.contact}
                onChange={onChangeHandler}
                onBlur={inputValidation}
                label='Contact No'
                type='text'
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                margin='dense'
                id='note'
                name='note'
                value={appointmentData.note}
                error={errors.note}
                helperText={errors.note}
                onChange={onChangeHandler}
                onBlur={inputValidation}
                label='Note'
                type='text'
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
         
              <TextField
                margin='dense'
                id='appointDate'
                name='date'
                //seting value form appointment data current state
                value={appointmentData.date}
                error={errors.date}
                helperText={errors.date}
                //seting input vlue to appointmentdata from name:onChangehandler
                onChange={onChangeHandler}
                onBlur={inputValidation}
                label='appointDate'
                type='text'
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
            <FormControl fullWidth error={errors.casType}>
                <InputLabel shrink>State</InputLabel>
                <Select
                  onBlur={inputValidation}
                  name='state'
                  value={appointmentData.state}
                  onChange={onChangeHandler}
                >
                  {/* {manufacturerList.map((list) => (
                    <MenuItem value={list.vendor_id}>
                      {list.meta_title}test
                    </MenuItem>
                  ))} */}
                  <MenuItem value={'0'}>
                    Pending
                  </MenuItem>
                  <MenuItem value={'2'}>
                    Done
                  </MenuItem>
                </Select>
                <FormHelperText>{errors.apptyp}</FormHelperText>
              </FormControl>
            </Grid>
            {/* <Grid item xs={6}>
  <TextField
  margin='dense'
  label='End Date'
  value={appointmentData.valid_till}
  name='valid_till'
  error={errors.valid_till}
  helperText={errors.valid_till}
  onChange={onChangeHandler}
  onBlur={inputValidation}
  type='date'
  fullWidth
  InputLabelProps={{
    shrink: true,
  }}
  />
</Grid> */}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={handleClose}>
          Cancel
        </Button>
        {editappointmentData ? (
          <Button variant='contained' color='primary' onClick={handleUpdate}>
            Update
          </Button>
        ) : (
          <Button variant='contained' color='primary' onClick={handleSubmit}>
            Create
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddAppointment;
