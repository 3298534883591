import React, { useEffect, useState, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
    Button,

    DialogContent,
    Dialog,
    DialogTitle,
    DialogActions,
  } from '@material-ui/core';
import { GetRequest, PutRequest } from '../../../../plugins/axios';
import api from '../../../../api/api'
import Document from './Document';
import Download from './DownloadPage';
import { AgGridColumn } from 'ag-grid-react/lib/agGridColumn';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Page } from '@react-pdf/renderer';
import ReactToPrint from 'react-to-print';
import { Divider } from '@mui/material';
var converter = require('number-to-words');
const VoucherFormat = ({ open,bill_date,connect_id,handleClose,subdomain }) => {
    const componentRef = useRef();
    const handleDelete = () =>{
      PutRequest(`${api.deleteSalesAPI}`, {
        id: connect_id,
        subdomain,
      })
        .then((res) => {
          // 
          let ids = [];
          let content = [];
          // console.log("data content",res.data.content);
        })
    }
    const printDocument = () => {
      const input = myRef;
      // console.log(input);
      html2canvas(input)
        .then((canvas) => {
          let imgData = canvas.toDataURL('image/png');
          // window.open(imgData);
          // download(imgData,image);
          const pdf = new jsPDF();
          pdf.addImage(imgData, 'JPEG', 0, 0);
          pdf.output('dataurlnewwindow');
          pdf.save("download.pdf");
        })
      ;
    }
  //     const data={
  // connect_id:542,
  // bill_date:1
  //     };
  const vendor = useSelector((state) => state.authReducer.user.vendor);
  // console.log('vendor',vendor);
  let toDate = new Date();
  let days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
    const [items, setItems] = useState([0,0,0,0,0]);
    const [salesData, setSalesData] = useState([]);
    const [pdfMode, setPDf] = useState(true);
    const [refresh, setRefresh] = useState(0);
  
    useEffect(() => {
      onOpen();
    }, [open]);
  
    useEffect(() => {
      GetRequest(`${api.saleItemAPI}`, {
        sales_data_id: connect_id,
        subdomain,
      })
        .then((res) => {
          // 
          let ids = [];
          let content = [];
          // console.log("data content",res.data.content);
          res.data.content.forEach((d) => {
            if (!ids.includes(d.id)) {
              ids.push(d.id);
              content.push(d);
            }
          });
          setItems(content);
          setSalesData(res.data.sales_data[0]);
          // console.log("saled data",salesData);
          // console.log("saled item",content);
        })
        .catch((err) => {
          
        });
    }, [open,connect_id,subdomain,refresh]);
  
    const modalizeRef = useRef(null);
    const [totalData, setTotalData] = useState({
      quantity: 0,
      price: 0,
    });
    const onOpen = () => {
      modalizeRef.current?.open();
    };
    const onClose = () => {
      modalizeRef.current?.close();
    };
  
    const switchDate = () => {
      onClose();
    };
  
    useEffect(() => {
      let totalQuantity = 0;
      let totalPrice = 0;
      items.forEach((bill) => {
        totalQuantity += parseInt(bill.stockout);
        totalPrice += parseInt(bill.stockout * bill.amount);
      });
      setTotalData({
        ...totalData,
        price: totalPrice,
        quantity: totalQuantity,
      });
    }, [items]);
  
    const VIEW_LENGTH = 1200;
    const VIEW_HEIGHT = 30;
    const OFFSET = VIEW_LENGTH / 2 - VIEW_HEIGHT / 2;
    const myRef =useRef();
    const editBillDetails = () => {
      // navigate(DAY_BOOK_EDIT, {
      //   sales_data_id: data.connect_id,
      //   date: data.bill_date,
      // });
      onClose();
    };
    // const divCas = () => {
    //   navigate(CUSTOMER, {
    //     sales_data_id: data.connect_id,
    //     date: data.bill_date,
    //   });
      onClose();
      const [filterData, setFilterData] = useState({
          categoryId: '',
          status: '',
          vendorId: '',
          subtract: '',
          price: [0, 10000],
        });
        const TotalValueRenderer= (props) => {
  return(props.data.stockout * props.data.amount);
        }
    return (
      <Document ref= {myRef} id="divToPrint">
        {/* <Download  data={salesData}/> */}
        <Page className="invoice-wrapper" pdfMode={pdfMode}>
       <Dialog
      //  fullScreen={true}
          open={open}
          onClose={handleClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>{salesData?.BR == 'C'  ? 'CASH VOUCHER' : salesData?.BR + 'VOUCHER'}</DialogTitle>
          <DialogContent ref={componentRef} >
  
              <div style={{ padding: 20 }}>
        <div>
          <div>
            <Divider onClick={()=>setRefresh(!refresh)}>VOUCHER</Divider>
          </div>
        </div>
      
  
        <div gutter={24} style={{ marginTop: 32,display:"flex",flexDirection:'row',justifyContent:'space-between' }} >
          <div span={8}>
          <h3><strong>{vendor?.mainappname}</strong></h3>
            {/* <div>{vendor?.mainappname}</div> */}
            {/* <div>Vijaya Bank Layout,</div>
            <div>Bannerghatta Road,</div> */}
            <p>Kathmandu</p>
          </div>
          <div span={8} >
            <table>
              <tr>
                <th>Invoice # :</th>
                <td>{connect_id}</td>
              </tr>
              <tr>
                <th>Invoice Date :</th>
                <td>{salesData?.bill_date}</td>
              </tr>
              <tr>
                <th>Due Date :</th>
                <td>{salesData?.bill_date}</td>
              </tr>
            </table>
          </div>
        </div>
  
        <div style={{ marginTop: 48 }}>
          <div>Cash Received From ({salesData?.cas_id}) <strong>{`${salesData?.fname} ${salesData?.lname} `}</strong>amount of <strong>Rs.{salesData?.paid_amount}</strong>.Amount in words <strong>{converter.toWords(salesData?.paid_amount ? salesData?.paid_amount : 0)} only</strong> has been recived on {salesData?.bill_date}</div>
          {/* <div>Bannerghatt Road,</div>
          <div>Bangalore - 560076</div> */}
        </div>
  
  
        <div style={{ marginTop: 48 }}>
        {/* <div className="ag-theme-material" style={{
            height: '100vh',
            width: '4in',
          }}>
        <AgGridReact rowData={items}
          defaultColDef={{
            flex: 1,
            resizable: true,
          }}
          frameworkComponents={{
            totalValueRenderer: TotalValueRenderer,
            
          }}
          owClassRules={{
            'sick-days-warning': function (params) {
              var numSickDays = params.data.sickDays;
              return numSickDays > 5 && numSickDays <= 7;
            },
            'sick-days-breach': 'data.sickDays >= 8',
          }}
          >
          <AgGridColumn cellClicked={(parms)=>alert('0')} width={80} suppressMenu={true} filter="agTextColumnFilter" headerName="#"  resizable={true} field="cas_id"></AgGridColumn>
          <AgGridColumn width={150}  headerName="Item Name" suppressMenu={true} filter="agTextColumnFilter" resizable={true} field="name"></AgGridColumn>
          <AgGridColumn suppressMenu={true} width={150}  headerName="Model"  resizable={true} field="model"></AgGridColumn>
          <AgGridColumn width={150}  headerName="Quantity" suppressMenu={true} filter="agTextColumnFilter" resizable={true} field="stockout"></AgGridColumn>
          <AgGridColumn width={150}  headerName="Rate" suppressMenu={true} filter="agTextColumnFilter" resizable={true}  field="rate"></AgGridColumn>
          <AgGridColumn cellRenderer="totalValueRenderer" headerName="Total" ></AgGridColumn>
          </AgGridReact>
          </div> */}
          {/* <Table dataSource={items}
          pagination={false}
          >
            <Table.Column title="Items" dataIndex='name' />
            <Table.Column title="Pcode" dataIndex='product_id' />
            <Table.Column title="Quantity" render={(text,record)=>record.stockout || record.stockin} />
            <Table.Column title="Rate" dataIndex='rate' />
            <Table.Column title="Amount" render={(text,record)=>record.rate * (record.stockout || record.stockin)} />
          </Table> */}
        </div>
  
        {/* <div style={{ marginTop: 48 }}>
          <Col span={8} offset={16}>
            <table>
              <tr>
                <th>Gross Total :</th>
                <td>Rs. {salesData?.net_amount}</td>
              </tr>
              <tr>
                <th>Paid Amount :</th>
                <td>Rs. {salesData?.paid_amount}</td>
              </tr>
              <tr>
                <th>Discount :</th>
                <td>Rs. {salesData?.discount}</td>
              </tr>
              <tr>
                <th>Nett Total :</th>
                <td>Rs. {salesData?.net_amount}</td>
              </tr>
            </table>
          </Col>
        </div> */}
  
        <div style={{ marginTop: 48, textAlign: 'center' }}>
        {salesData?.bill_no}
        </div>
  
        <div style={{ marginTop: 48, textAlign: 'center' }}>
        {salesData?.remarks}
        </div>
      </div>
      <div gutter={24} style={{ marginTop: 32,display:"flex",flexDirection:'row',justifyContent:'space-between' }} >
          <div>
          <strong>Received By ...............</strong>
              </div>
              <div>
              <strong>  Paid By ...............</strong>
              </div>
              <div>
              <strong> Verified By ...............</strong><br/>
              {toDate.toLocaleString()}<br/>
              {days[toDate.getDay()]}<br/>
              </div>
          </div>






          <div style={{ padding: 20 }}>
        <div>
          <div>
            <Divider onClick={()=>setRefresh(!refresh)}>VOUCHER</Divider>
          </div>
        </div>
      
  
        <div gutter={24} style={{ marginTop: 32,display:"flex",flexDirection:'row',justifyContent:'space-between' }} >
          <div span={8}>
            <h3><strong>{vendor?.mainappname}</strong></h3>
            {/* <div>{vendor?.mainappname}</div> */}
            {/* <div>Vijaya Bank Layout,</div>
            <div>Bannerghatta Road,</div> */}
            <p>Kathmandu</p>
          </div>
          <div span={8} >
            <table>
              <tr>
                <th>Invoice # :</th>
                <td>{connect_id}</td>
              </tr>
              <tr>
                <th>Invoice Date :</th>
                <td>{salesData?.bill_date}</td>
              </tr>
              <tr>
                <th>Due Date :</th>
                <td>{salesData?.bill_date}</td>
              </tr>
            </table>
          </div>
        </div>
  
        <div style={{ marginTop: 48 }}>
          <div>Cash Received From ({salesData?.cas_id}) <strong>{`${salesData?.fname} ${salesData?.lname} `}</strong>amount of <strong>Rs.{salesData?.paid_amount}</strong>.Amount in words <strong>{converter.toWords(salesData?.paid_amount ? salesData?.paid_amount : 0)} only</strong> has been recived on {salesData?.bill_date}</div>
          {/* <div>Bannerghatt Road,</div>
          <div>Bangalore - 560076</div> */}
        </div>
  
  
        <div style={{ marginTop: 48 }}>
        {/* <div className="ag-theme-material" style={{
            height: '100vh',
            width: '4in',
          }}>
        <AgGridReact rowData={items}
          defaultColDef={{
            flex: 1,
            resizable: true,
          }}
          frameworkComponents={{
            totalValueRenderer: TotalValueRenderer,
            
          }}
          owClassRules={{
            'sick-days-warning': function (params) {
              var numSickDays = params.data.sickDays;
              return numSickDays > 5 && numSickDays <= 7;
            },
            'sick-days-breach': 'data.sickDays >= 8',
          }}
          >
          <AgGridColumn cellClicked={(parms)=>alert('0')} width={80} suppressMenu={true} filter="agTextColumnFilter" headerName="#"  resizable={true} field="cas_id"></AgGridColumn>
          <AgGridColumn width={150}  headerName="Item Name" suppressMenu={true} filter="agTextColumnFilter" resizable={true} field="name"></AgGridColumn>
          <AgGridColumn suppressMenu={true} width={150}  headerName="Model"  resizable={true} field="model"></AgGridColumn>
          <AgGridColumn width={150}  headerName="Quantity" suppressMenu={true} filter="agTextColumnFilter" resizable={true} field="stockout"></AgGridColumn>
          <AgGridColumn width={150}  headerName="Rate" suppressMenu={true} filter="agTextColumnFilter" resizable={true}  field="rate"></AgGridColumn>
          <AgGridColumn cellRenderer="totalValueRenderer" headerName="Total" ></AgGridColumn>
          </AgGridReact>
          </div> */}
          {/* <Table dataSource={items}
          pagination={false}
          >
            <Table.Column title="Items" dataIndex='name' />
            <Table.Column title="Pcode" dataIndex='product_id' />
            <Table.Column title="Quantity" render={(text,record)=>record.stockout || record.stockin} />
            <Table.Column title="Rate" dataIndex='rate' />
            <Table.Column title="Amount" render={(text,record)=>record.rate * (record.stockout || record.stockin)} />
          </Table> */}
        </div>
  
        {/* <div style={{ marginTop: 48 }}>
          <Col span={8} offset={16}>
            <table>
              <tr>
                <th>Gross Total :</th>
                <td>Rs. {salesData?.net_amount}</td>
              </tr>
              <tr>
                <th>Paid Amount :</th>
                <td>Rs. {salesData?.paid_amount}</td>
              </tr>
              <tr>
                <th>Discount :</th>
                <td>Rs. {salesData?.discount}</td>
              </tr>
              <tr>
                <th>Nett Total :</th>
                <td>Rs. {salesData?.net_amount}</td>
              </tr>
            </table>
          </Col>
        </div> */}
  
        <div style={{ marginTop: 48, textAlign: 'center' }}>
        {salesData?.bill_no}
        </div>
  
        <div style={{ marginTop: 48, textAlign: 'center' }}>
        {salesData?.remarks}
        </div>
      </div>
      <div gutter={24} style={{ marginTop: 32,display:"flex",flexDirection:'row',justifyContent:'space-between' }} >
          <div>
          <strong>Received By ...............</strong>
              </div>
              <div>
              <strong>Paid By ...............</strong>
              </div>
              <div>
              <strong>Verified By ...............</strong><br/>
              {toDate.toLocaleString()}<br/>
              {days[toDate.getDay()]}<br/>
              </div>
          </div>
            </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color='default'>
              Close
            </Button>
            <Button onClick={handleDelete} color='default'>
              Delete
            </Button>
            {/* <Download data={salesData?}/> */
            }
             <Button onClick={handleClose} color='default'>
              Share
            </Button>
              {/* <ViberShareButton title="test"/> */}
            <ReactToPrint
          trigger={() => <Button onClick={printDocument} color='primary'>
          Print
        </Button>}
          content={() => componentRef.current}
        />
            {/* <Button onClick={printDocument} color='primary'>
              Save
            </Button> */}
            {/* <Button onClick={() => onClose(filterData)} color='primary'>
              Filter
            </Button> */}
          </DialogActions>
        </Dialog>
        </Page>
      </Document>
    );
  };
  export default VoucherFormat;
  