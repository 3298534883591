import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../../Components/Title';
import { useParams } from 'react-router-dom';
import * as actions from '../../../store/DashboardActions';
import { useDispatch, useSelector } from 'react-redux';
import { Paper, Switch } from '@material-ui/core';
import ShowLoadingOrEmpty from '../../Components/ShowLoadingOrEmpty';
import Product from 'modules/inventory/Product';
import UnstyledSwitcher from 'components/UnstyledSwitcher';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

export default function Category() {
  const classes = useStyles();
    const vendorId = useSelector((state) => state.authReducer.user.vendorId);
    const [categories, setCategories] = useState([]);
  const categoryList = useSelector(
    (state) => state.dashboardReducer.categories
  );
  const { categoryId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!categoryId) {
      dispatch(actions.get_category(vendorId));
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // console.log(categoryList);
    setCategories(categoryList);
  }, [categoryList]);

  useEffect(() => {
    // console.log('clear');
    setCategories([]);
    dispatch(actions.get_category(vendorId,categoryId));
    // eslint-disable-next-line
  }, [categoryId]);

  const handleChange = (e, categoryId, index) => {
    // console.log(categories);
    // console.log(e.target.checked, { index });
    const newList = categories;
    newList[index] = { ...newList[index], status: e.target.checked ? 1 : 0 };
    setCategories([...newList]);
    dispatch(
      actions.update_category_status(categoryId, e.target.checked ? 1 : 0)
    );
  };

  return (
    <Paper className={classes.paper}>
      <Title>Category</Title>
      {categories.length > 0 ? (
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Parent Id</TableCell>
              <TableCell>Product Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((row, index) => (
              <TableRow key={row.category_id}>
                <TableCell>
                  <Link to={`/dashboard/categories/${row.category_id}`}>
                    {row.category_id}
                  </Link>
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.parent_id}</TableCell>
                <TableCell>
                  {row.total_product === 0 ? (
                    row.total_product
                  ) : (
                    <Link
                      to={`/dashboard/products/category/${row.category_id}`}
                    >
                      {row.total_product}
                    </Link>
                  )}
                </TableCell>
                <TableCell>
                  <UnstyledSwitcher
                    defaultValue={row.status === 1}
                    checked={row.status === 1}
                    onChange={(e) => handleChange(e, row.category_id, index)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <ShowLoadingOrEmpty message='No Categories Found' />
      )}

      <div className={classes.seeMore}>
        <Link color='primary' href='#'>
          {categories.length > 0
            ? 'Total Categories: ' + categories.length
            : 'No categries'}
        </Link>
      </div>
      <Product vId={9} cId={20}/>
    </Paper>
  );
}
