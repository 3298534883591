import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
  } from '@material-ui/core';
  import React, { useEffect, useState } from 'react';
  import Title from '../../../Components/Title';
  import { useSelector, useDispatch } from 'react-redux';
  import * as actions from '../../../../store/DashboardActions';
  import StockAppRules from './StockAppRules';
  import { ValidateForm, ValidateInput } from '../../../../../utils/validateForm';
  const initialData = {
    vendor_id: '',
    mainappname: '',
    apptyp: '',
    subdomain: '',
    db_name: '',
    viber_api: '',
    appcode: '',
    pan: '',
    start_date: '',
    valid_till: '',
    contact:'',
  };
  
  const AddContact = ({ open, handleClose, editStockData }) => {
    const vendorList = useSelector((state) => state.dashboardReducer.vendors);
    const dispatch = useDispatch();
    const [stockData, setStockData] = useState(initialData);
    const [errors, setErrors] = useState(initialData);
  
    const onChangeHandler = (e) => {
      setStockData({
        ...stockData,
        [e.target.name]: e.target.value,
      });
    };
  
    useEffect(() => {
      if (vendorList.length === 0) {
        dispatch(actions.get_vendors());
      }
    }, [vendorList, dispatch]);
  
    useEffect(() => {
      // console.log(editStockData);
      if (editStockData) {
        setStockData(editStockData);
        console.log("🚀 ~ file: AddContact.jsx ~ line 58 ~ useEffect ~ editStockData", editStockData)
      } else {
        setStockData(initialData);
      }
    }, [editStockData]);
  
    const inputValidation = (e) => {
      let errorMsg = ValidateInput(e.target.name, e.target.value, StockAppRules);
      setErrors({
        ...errors,
        [e.target.name]: errorMsg,
      });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // console.log(stockData);
      let errorMsgs = ValidateForm(stockData, StockAppRules);
      setErrors({ ...errorMsgs });
      let validated = Object.values(errorMsgs).join('').length === 0;
      if (validated) {
        dispatch(actions.add_stock_app_main(stockData));
        handleClose();
      }
    };
  
    const handleUpdate = (e) => {
      e.preventDefault();
      // console.log(stockData);
    //   let errorMsgs = ValidateForm(stockData, StockAppRules);
    //   setErrors({ ...errorMsgs });
    //   let validated = Object.values(errorMsgs).join('').length === 0;
      if (true) {
        dispatch(actions.add_contact_to_stockapp(stockData));
        handleClose();
      }
    };
    return (
      <Dialog
        open={open}
        fullScreen={false}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>
          <Title>Assign Contact for {stockData?.mainappname}</Title>
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <FormControl fullWidth error={errors.vendor_id}>
                  {/* <InputLabel shrink>Contact</InputLabel> */}
                  <TextField
                  margin='dense'
                  name='contact'
                  value={stockData.contact}
                  error={errors.contact}
                  helperText={errors.contact}
                  label='Contact'
                  onChange={onChangeHandler}
                  onBlur={inputValidation}
                  type='text'
                  fullWidth
                />
                  <FormHelperText>{errors.vendor_id}</FormHelperText>
                </FormControl>
              </Grid>
            {/*   <Grid item xs={2}>
                <Button>Add</Button>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin='dense'
                  name='mainappname'
                  value={stockData.mainappname}
                  error={errors.mainappname}
                  helperText={errors.mainappname}
                  label='Main app name'
                  onChange={onChangeHandler}
                  onBlur={inputValidation}
                  type='text'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth error={errors.apptyp}>
                  <InputLabel shrink>App Type</InputLabel>
                  <Select
                    name='apptyp'
                    value={stockData.apptyp}
                    onChange={onChangeHandler}
                    onBlur={inputValidation}
                  >
                    <MenuItem value='Stock Management'>Stock Management</MenuItem>
                  </Select>
                  <FormHelperText>{errors.apptyp}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin='dense'
                  id='name'
                  name='subdomain'
                  value={stockData.subdomain}
                  onChange={onChangeHandler}
                  onBlur={inputValidation}
                  label='Subdomain'
                  type='text'
                  error={errors.subdomain}
                  helperText={errors.subdomain}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin='dense'
                  id='name'
                  name='db_name'
                  value={stockData.db_name}
                  error={errors.db_name}
                  helperText={errors.db_name}
                  onChange={onChangeHandler}
                  onBlur={inputValidation}
                  label='DB Name'
                  type='text'
                  fullWidth
                />
              </Grid><Grid item xs={6}>
                <TextField
                  margin='dense'
                  id='pan'
                  name='pan'
                  value={stockData.pan}
                  error={errors.pan}
                  helperText={errors.pan}
                  onChange={onChangeHandler}
                  onBlur={inputValidation}
                  label='Pan No'
                  type='text'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin='dense'
                  id='name'
                  name='viber_api'
                  value={stockData.viber_api}
                  error={errors.viber_api}
                  helperText={errors.viber_api}
                  onChange={onChangeHandler}
                  onBlur={inputValidation}
                  label='Viber API'
                  type='text'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin='dense'
                  id='name'
                  name='appcode'
                  value={stockData.appcode}
                  error={errors.appcode}
                  helperText={errors.appcode}
                  onChange={onChangeHandler}
                  onBlur={inputValidation}
                  label='App Code'
                  type='text'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin='dense'
                  name='start_date'
                  value={stockData.start_date}
                  error={errors.start_date}
                  helperText={errors.start_date}
                  onChange={onChangeHandler}
                  onBlur={inputValidation}
                  label='Start Date'
                  type='date'
                  fullWidth
                 
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin='dense'
                  label='End Date'
                  value={stockData.valid_till}
                  name='valid_till'
                  error={errors.valid_till}
                  helperText={errors.valid_till}
                  onChange={onChangeHandler}
                  onBlur={inputValidation}
                  type='date'
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>*/}
            </Grid> 
          </form>
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={handleClose}>
            Cancel
          </Button>
          {editStockData ? (
            <Button variant='contained' color='primary' onClick={handleUpdate}>
              Update
            </Button>
          ) : (
            <Button variant='contained' color='primary' onClick={handleSubmit}>
              Create
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };
  
  export default AddContact;
  