import { setToasterState } from '../../common/Toast/services/ToasterAction';
import { GetRequest, PostRequest, PutRequest } from '../../plugins/axios';
import * as actions from './DashboardTypes';
import axios from 'axios';
import api from '../../api/api'
import { API_URL } from "config";


// TODO:Seperate Inventory actions To productsActions
export const setProducts = (payload, clear) => {
  return {
    type: actions.GET_PRODUCTS,
    payload: payload,
    clear,
  };
};
export const setOpenings = (payload, clear) => {
  return {
    type: actions.GET_OPENINGS,
    payload: payload,
    clear,
  };
};
export const setDaybook = (payload, clear) => {
  return {
    type: actions.GET_DAYBOOK,
    payload: payload,
    clear,
  };
};
export const setCreditors = (payload, clear) => {
  return {
    type: actions.GET_CREDITORS,
    payload: payload,
    clear,
  };
};
export const setMonthlyReport = (payload, clear) => {
  return {
    type: actions.GET_MONTHLY_REPORT,
    payload: payload,
    clear,
  };
};
export const setProfitbyBills = (payload, clear) => {
  return {
    type: actions.GET_PROFIT_BY_BILLS,
    payload: payload,
    clear,
  };
};
export const setMenu = (payload, clear) => {
  return {
    type: actions.GET_MENU,
    payload: payload,
    clear,
  };
};
export const setStaff = (payload, clear) => {
  return {
    type: actions.GET_STAFF,
    payload: payload,
    clear,
  };
};

export const setNote = (payload, clear) => {
  return {
    type: actions.GET_NOTE,
    payload: payload,
    clear,
  };
};
export const setAdmin = (payload, clear) => {
  return {
    type: actions.GET_ADMIN,
    payload: payload,
    clear,
  };
};
export const setApps = (payload) => {
  return {
    type: actions.GET_APPS,
    payload: payload,
  };
};

export const setCategories = (payload) => {
  return {
    type: actions.GET_CATEGORIES,
    payload: payload,
  };
};

export const setManufacturer = (payload) => {
  return {
    type: actions.GET_MANUFACTURER,
    payload: payload,
  };
};

export const setVendors = (payload) => {
  return {
    type: actions.GET_VENDORS,
    payload: payload,
  };
};
export const setAttendence = (payload) => {
  return {
    type: actions.GET_ATTENDANCE,
    payload: payload,
  };
};
export const setStaffDetails = (payload) => {
  return {
    type: actions.GET_STAFFDETAILS,
    payload: payload,
  };
};
export const setCustomerProfile = (payload) => {
  return {
    type: actions.GET_CUSTOMERPROFILE,
    payload: payload,
  };
};
export const setDrawerOpener = (payload) => {
  return {
    type: actions.GET_DRAWER_OPENER,
    payload: payload,
  };
};
export const setDrawerOpen = (payload) => {
  return {
    type: actions.GET_DRAWER_OPEN,
    payload: payload,
  };
};
export const setHamOpener = (payload) => {
  return {
    type: actions.GET_HAM_OPENER,
    payload: payload,
  };
};
export const setBotMessages = (payload) => {
  return {
    type: actions.GET_BOT_MESSAGES,
    payload: payload,
  };
};
export const setCustomers = (payload) => {
  return {
    type: actions.GET_CUSTOMERS,
    payload: payload,
  };
};
export const setAppointments = (payload) => {
  return {
    type: actions.GET_APPOINTMENTS,
    payload: payload,
  };
};
export const setSalesCasid = (payload) => {
  return {
    type: actions.GET_SALES_CASID,
    payload: payload,
  };
};
export const setOrders = (payload) => {
  return {
    type: actions.GET_ORDERS,
    payload: payload,
  };
};
export const setLoading = (payload) => {
  return {
    type: actions.GET_LOADING,
    payload: payload,
  };
};

export const setOpenEnv = (payload) => {
  return {
    type: actions.GET_OPEN_ENV,
    payload: payload,
  };
};
export const setEnvData = (payload) => {
  return {
    type: actions.GET_ENV_DATA,
    payload: payload,
  };
};

export const setDisplayStock = (payload) => {
  return {
    type: actions.GET_DISPLAY_STOCK,
    payload: payload,
  };
};

export const getDisplayStock =
  (
    clear = false,
    { state },
    cancel
  ) =>
  (dispatch) => {
    dispatch(setDisplayStock(true));
        // console.log(res.data);
    dispatch(
      setToasterState({
        open: true,
        title: 'success',
        name: 'Get Creditors!',
        message: 'Successfully Get Creditors!',
      })
    );
  };
export const get_products =
  (
    clear = false,
    { page, search,stock, categoryId, vendorId, image,status, subtract, price, limit },
    cancel
  ) =>
  (dispatch) => {
    dispatch(setLoading(true));
    GetRequest(
      `${api.productAPI}`,
      { page, search, categoryId, vendorId, stock,image, status, subtract, price, limit },
      { cancelToken: cancel.token }
    )
      .then((res) => {
        // console.log(res.data);
        dispatch(setProducts(res.data.content, clear));
        dispatch(
          setToasterState({
            open: true,
            title: 'success',
            name: 'Get Products!',
            message: `Successfully Get Products!`,
          })
        );
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (axios.isCancel(err)) {
          // console.log('Request canceled', err.message);
        } else {
          // console.log(err?.response?.data);
          dispatch(
            setToasterState({
              open: true,
              title: 'error',
              name: 'Get Products!',
              message:
                err?.response?.data?.message ||
                'Error Getting Products, Check Server!',
            })
          );
        }
      });
  };
  export const get_products_from_category =
  (
    clear = false,
    { categoryId, vendorId,},
    cancel
  ) =>
  (dispatch) => {
    dispatch(setLoading(true));
    // console.log('sending',categoryId,vendorId);
    GetRequest(
      `${api.productsFromCategory}`,
      {categoryId, vendorId},
      { cancelToken: cancel.token }
    )
      .then((res) => {
        // console.log(res.data);
        dispatch(setProducts(res.data.content, clear));
        dispatch(
          setToasterState({
            open: true,
            title: 'success',
            name: 'Get Products!',
            message: 'Successfully Get Products!',
          })
        );
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (axios.isCancel(err)) {
          // console.log('Request canceled', err.message);
        } else {
          // console.log(err?.response?.data);
          dispatch(
            setToasterState({
              open: true,
              title: 'error',
              name: 'Get Products!',
              message:
                err?.response?.data?.message ||
                'Error Getting Products, Check Server!',
            })
          );
        }
      });
  };
  export const get_openings =
  (
    clear = false,
    { subdomain,bill_type },
    cancel
  ) =>
  (dispatch) => {
    dispatch(setLoading(true));
    GetRequest(
      `${api.openingAmountAPI}`,
      { subdomain,bill_type },
    )
      .then((res) => {
        // console.log("graph data",res.data);
        dispatch(setOpenings(res.data.content, clear));
        dispatch(
          setToasterState({
            open: true,
            title: 'success',
            name: 'Get Openings!',
            message: 'Successfully Get Openings!',
          })
        );
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (axios.isCancel(err)) {
          // console.log('Request canceled', err.message);
        } else {
          // console.log(err?.response?.data);
          dispatch(
            setToasterState({
              open: true,
              title: 'error',
              name: 'Get Openings!',
              message:
                err?.response?.data?.message ||
                'Error Getting Openings, Check Server!',
            })
          );
        }
      });
  };
export const get_daybook =
  (
    clear = false,
    { dateString, odString,subdomain,endDate,itemwise },
    cancel
  ) =>
  (dispatch) => {
    dispatch(setLoading(true));
    GetRequest(`${api.daybookAPI}`, {
      startDate: dateString,
      opDate: odString,
      subdomain,
      endDate,
      itemwise:itemwise,
    })
      .then((res) => {
        console.log("daybook data hello",dateString,odString,subdomain,endDate,res.data);
        dispatch(setDaybook(res.data.content, clear));
        dispatch(
          setToasterState({
            open: true,
            title: 'success',
            name: 'Get Daybook!',
            message: 'Successfully Get Daybook!',
          })
        );
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (axios.isCancel(err)) {
          // console.log('Request canceled', err.message);
        } else {
          // console.log(err?.response?.data);
          dispatch(
            setToasterState({
              open: true,
              title: 'error',
              name: 'Get Daybook!',
              message:
                err?.response?.data?.message ||
                'Error Getting Daybook, Check Server!',
            })
          );
        }
      });
  };
  export const get_menu =
  (
    clear = false,
    { permission },
    cancel
  ) =>
  (dispatch) => {
    let per=permission ? permission : "100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126";
    dispatch(setLoading(true));
    // console.log("permisoon",permission);
    GetRequest(`${api.menuAPI}`, {
      permission:per,
    })
      .then((res) => {
        // console.log("menu data",res.data.content);
        dispatch(setMenu(res.data.content, clear));
        dispatch(
          setToasterState({
            open: true,
            title: 'success',
            name: 'Get Menu!',
            message: 'Successfully Get Menu!',
          })
        );
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (axios.isCancel(err)) {
          // console.log('Request canceled', err.message);
        } else {
          // console.log(err?.response?.data);
          dispatch(
            setToasterState({
              open: true,
              title: 'error',
              name: 'Get Daybook!',
              message:
                err?.response?.data?.message ||
                'Error Getting Daybook, Check Server!',
            })
          );
        }
      });
  };
export const get_creditors =
  (
    clear = false,
    { limit,bill_type,dateString, odString,subdomain },
    cancel
  ) =>
  (dispatch) => {
    dispatch(setLoading(true));
    GetRequest(`${api.creditorsAPI}`, {
      subdomain,
      bill_type,
      limit,
    })
      .then((res) => {
        // console.log(res.data);
        dispatch(setCreditors(res.data.content, clear));
        dispatch(
          setToasterState({
            open: true,
            title: 'success',
            name: 'Get Creditors!',
            message: 'Successfully Get Creditors!',
          })
        );
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (axios.isCancel(err)) {
          // console.log('Request canceled', err.message);
        } else {
          // console.log(err?.response?.data);
          dispatch(
            setToasterState({
              open: true,
              title: 'error',
              name: 'Get Creditors!',
              message:
                err?.response?.data?.message ||
                'Error Getting Creditors, Check Server!',
            })
          );
        }
      });
  };
export const get_staff =
  (
    clear = false,
    { subdomain },
    cancel
  ) =>
  (dispatch) => {
    dispatch(setLoading(true));
    GetRequest(`${api.staffAPI}`, { })
      .then((res) => {
        // console.log(res.data);
        dispatch(setStaff(res.data.content, clear));
        dispatch(
          setToasterState({
            open: true,
            title: 'success',
            name: 'Get Staff!',
            message: 'Successfully Get Staff!',
          })
        );
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (axios.isCancel(err)) {
          // console.log('Request canceled', err.message);
          dispatch(
            setToasterState({
              open: true,
              title: 'error',
              name: 'Get Staff!',
              message:'Request Cancled!',
            })
          );
        } else {
          // console.log(err?.response?.data);
          dispatch(
            setToasterState({
              open: true,
              title: 'error',
              name: 'Get Staff!',
              message:
                err?.response?.data?.message ||
                'Error Getting Staff, Check Server!',
            })
          );
        }
      });
  };
  export const get_note =
  (
    clear = false,
    { subdomain },
    cancel
  ) =>
  (dispatch) => {
    dispatch(setLoading(true));
    GetRequest(`${api.noteAPI}`, { })
      .then((res) => {
        // console.log(res.data);
       dispatch(setNote(res.data.content, clear));
        dispatch(
          setToasterState({
            open: true,
            title: 'success',
            name: 'Get Note!',
            message: 'Successfully Get Note!',
          })
        );
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (axios.isCancel(err)) {
          // console.log('Request canceled', err.message);
        } else {
          // console.log(err?.response?.data);
          dispatch(
            setToasterState({
              open: true,
              title: 'error',
              name: 'Get note!',
              message:
                err?.response?.data?.message ||
                'Error Getting note, Check Server!',
            })
          );
        }
      });
  };
  export const get_admin =
  (
    clear = false,
    { subdomain },
    cancel
  ) =>
  (dispatch) => {
    dispatch(setLoading(true));
    GetRequest(`${api.adminAPI}`, {    })
      .then((res) => {
        console.log("data:::::::::::::::;",res.data);
        dispatch(setAdmin(res.data.content, clear));
        dispatch(
          setToasterState({
            open: true,
            title: 'success',
            name: 'Get Admins!',
            message: 'Successfully Get Admins!',
          })
        );
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (axios.isCancel(err)) {
          // console.log('Request canceled', err.message);
        } else {
          // console.log(err?.response?.data);
          dispatch(
            setToasterState({
              open: true,
              title: 'error',
              name: 'Get Staff!',
              message:
                err?.response?.data?.message ||
                'Error Getting Staff, Check Server!',
            })
          );
        }
      });
  };
export const get_orders = () => (dispatch) => {
  dispatch(setLoading(true));
  GetRequest(`${api.orderAPI}`)
    .then((res) => {
      // console.log(res.data);
      dispatch(setOrders(res.data.content));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      // console.log(err?.response?.data);
      dispatch(
        setToasterState({
          open: true,
          title: 'error',
          name: 'Get Orders!',
          message:
            err?.response?.data?.message ||
            'Error Getting Orders, Check Server!',
        })
      );
    });
};

export const update_product_status = (productId, status) => (dispatch) => {
  PutRequest(`${api.productUpdateStatus}/${productId}`, { status })
    .then((res) => {
      // console.log(res.data);
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'Update Product Status!',
          message: 'Successfully updated product status!',
        })
      );
    })
    .catch((err) => {
      // console.log(err?.response?.data);
      dispatch(
        setToasterState({
          open: true,
          title: 'error',
          name: 'Update Product Status!',
          message:
            err?.response?.data?.message || 'Error Updating product status!',
        })
      );
    });
};

export const get_stock_app_mains = () => (dispatch) => {
  dispatch(setLoading(true));
  GetRequest(`${api.stockAppMains}`)
    .then((res) => {
      // console.log(res.data);
      dispatch(setApps(res.data.content));
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'Get Stock App Main!',
          message: 'Successfully Get Stock App Main!',
        })
      );
    })
    .catch((err) => {
      dispatch(setLoading(false));
      // console.log(err?.response?.data);
      dispatch(
        setToasterState({
          open: true,
          title: 'error',
          name: 'Get Stock App Main!',
          message:
            err?.response?.data?.message || 'Error Getting Stock App Main!',
        })
      );
    });
};

export const get_bot_messages = () => (dispatch) => {
  dispatch(setLoading(true));
  GetRequest(`${api.botMessageAPI}`)
    .then((res) => {
      // console.log(res.data);
      dispatch(setBotMessages(res.data.content));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      // console.log(err?.response);
      dispatch(
        setToasterState({
          open: true,
          title: 'error',
          name: 'Get Bot Messages!',
          message:
            err?.response?.data?.message || 'Error Retriving BOT Messages!',
        })
      );
    });
};
export const get_customers = (search) => (dispatch) => {
  dispatch(setLoading(true));
  // TODO:: implement pagination
  GetRequest(`${api.creditorsAPI}`, { search,limit:true })
    .then((res) => {
      // console.log(res.data);
      dispatch(setCustomers(res.data.content));
    })
    .catch((err) => {
      // console.log(err);
      if (axios.isCancel(err)) {
        // console.log('Request canceled', err.message);
      } else {
        dispatch(setLoading(false));
        dispatch(
          setToasterState({
            open: true,
            title: 'error',
            name: 'Get Customers!',
            message:
              err?.response?.data?.message || 'Error Retriving Customers!',
          })
        );
      }
    });
};
export const get_appointments = (payload) => (dispatch) => {
  dispatch(setLoading(true));
  // TODO:: implement pagination
  GetRequest(`${api.noteAppointmentAPI}`, payload)
    .then((res) => {
      // console.log(res.data);
      dispatch(setAppointments(res.data.content));
    })
    .catch((err) => {
      // console.log(err);
      if (axios.isCancel(err)) {
        // console.log('Request canceled', err.message);
      } else {
        dispatch(setLoading(false));
        dispatch(
          setToasterState({
            open: true,
            title: 'error',
            name: 'Get Appointments!',
            message:
              err?.response?.data?.message || 'Error Retriving Appointments!',
          })
        );
      }
    });
};
export const get_customers_by_casid = (casId,subdomain) => (dispatch) => {
  dispatch(setLoading(true));
  // TODO:: implement pagination
  GetRequest(`${api.customerAPI}`, { search:casId,subdomain })
    .then((res) => {
      // console.log(res.data);
      dispatch(setCustomers(res.data.content));
    })
    .catch((err) => {
      // console.log(err);
      if (axios.isCancel(err)) {
        // console.log('Request canceled', err.message);
      } else {
        dispatch(setLoading(false));
        dispatch(
          setToasterState({
            open: true,
            title: 'error',
            name: 'Get Customers!',
            message:
              err?.response?.data?.message || 'Error Retriving Customers!',
          })
        );
      }
    });
};
export const get_sales_item_from_cas_id = (casId,subdomain,startDate,endDate) => (dispatch) => {
  dispatch(setLoading(true));
  // TODO:: implement pagination
  GetRequest(`${api.salesItemFromCasIdAPI}?page=0`, { cas_id:casId,subdomain,startDate,endDate })
    .then((res) => {
      // console.log(res.data);
      dispatch(setSalesCasid(res.data.content));
    })
    .catch((err) => {
      // console.log(err);
      if (axios.isCancel(err)) {
        // console.log('Request canceled', err.message);
      } else {
        dispatch(setLoading(false));
        dispatch(
          setToasterState({
            open: true,
            title: 'error',
            name: 'Get Sales!',
            message:
              err?.response?.data?.message || 'Error Retriving Sales!',
          })
        );
      }
    });
};

export const add_customer = (payload) => (dispatch) => {
  PostRequest(`${api.customerPostAPI}`, payload)
    .then((res) => {
      // console.log('Done', payload);
      // dispatch(get_customers({subdomain:payload.subdomain}));
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'Add Customer!',
          message: 'Successfully Added Customer!',
        })
      );
    })
    .catch((err) => {
      // console.log("cas err",err?.response?.data);
      dispatch(
        setToasterState({
          open: true,
          title: 'error',
          name: 'Add Customer!',
          message:
            err?.response?.data?.message || 'Error Adding Customer!',
        })
      );
    });
};


export const add_appointment = (payload) => (dispatch) => {
  PutRequest(`${api.appointmentPostAPI}`, payload)
    .then((res) => {
      console.log('Done', res);
      dispatch(get_appointments('',payload));
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'Add Appointment!',
          message: 'Successfully Added Appointment!',
        })
      );
    })
    .catch((err) => {
     // console.log("cas err",err?.response?.data);
      dispatch(
        setToasterState({
          open: true,
          title: 'error',
          name: 'Add Appointment!',
          message:
            err?.response?.data?.message || 'Error Adding Appointment!',
        })
      );
    });
};
export const update_appointment = (payload) => (dispatch) => {
  PutRequest(`${api.updateAppointmentPostAPI}`, payload)
    .then((res) => {
      console.log('Done', res);
      dispatch(get_appointments('',payload));
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'Update Appointment!',
          message: 'Successfully  Updated!',
        })
      );
    })
    .catch((err) => {
      // console.log("cas err",err?.response?.data);
      dispatch(
        setToasterState({
          open: true,
          title: 'error',
          name: 'Add Appointment!',
          message:
            err?.response?.data?.message || 'Error updating Appointment!',
        })
      );
    });
};
export const update_staff = (payload) => (dispatch) => {
  PutRequest(`${api.staffAPI}`, payload)
    .then((res) => {
      console.log('Done', res);
      dispatch(get_appointments('',payload));
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'Update Staff Details!',
          message: 'Successfully  Updated!',
        })
      );
    })
    .catch((err) => {
      // console.log("cas err",err?.response?.data);
      dispatch(
        setToasterState({
          open: true,
          title: 'error',
          name: 'Add Staff!',
          message:
            err?.response?.data?.message || 'Error updating Satff Details!',
        })
      );
    });
};
export const add_staff = (payload) => (dispatch) => {
  PostRequest(`${api.staffPostAPI}`, payload)
    .then((res) => {
      let token = axios.CancelToken.source();
      dispatch(get_staff());
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'Add staff!',
          message: 'Successfully Added staff!',
        })
      );
    })
    .catch((err) => {
      // console.log("cas err",err?.response?.data);
      dispatch(
        setToasterState({
          open: true,
          title: 'error',
          name: 'Add staff!',
          message:
            err?.response?.data?.message || 'Error Adding staff!',
        })
      );
    });
};
export const add_stock_app_main = (payload) => (dispatch) => {
  PostRequest(`${api.stockAppByPosts}`, payload)
    .then((res) => {
      // console.log('Done', res.data);
      dispatch(get_stock_app_mains());
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'Add Stock App Main!',
          message: 'Successfully Added Stock App Main!',
        })
      );
    })
    .catch((err) => {
      // console.log(err?.response?.data);
      dispatch(
        setToasterState({
          open: true,
          title: 'error',
          name: 'Add Stock App Main!',
          message:
            err?.response?.data?.message || 'Error Adding Stock App Main!',
        })
      );
    });
};
export const add_documents = (payload) => (dispatch) => {
  console.log(api.documentPostAPI);
  PostRequest(`${api.documentPostAPI}`, payload)
    .then((res) => {
      // console.log('Done', res.data);
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'Document Added!',
          message: 'Successfully Added Document!',
        })
      );
    })
    .catch((err) => {
      dispatch(
        setToasterState({
          open: true,
          title: 'error',
          name: 'Add Notes failed!',
          message:
            err?.response?.data?.message || 'Error Adding Notes!',
        })
      );
    });
};

export const add_note = (payload) => (dispatch) => {
  console.log(api.notePostAPI);
  PutRequest(`${api.notePostAPI}`, payload)
    .then((res) => {
      // console.log('Done', res.data);
      dispatch(get_note(true,payload,false))
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'Document Added!',
          message: 'Successfully Added Document!',
        })
      );
    })
    .catch((err) => {
      dispatch(
        setToasterState({
          open: true,
          title: 'error',
          name: 'Add Notes failed!',
          message:
            err?.response?.data?.message || 'Error Adding Notes!',
        })
      );
    });
};

export const add_cash = (payload) => (dispatch) => {
  console.log(api.cashPostAPI);
  PostRequest(`${api.cashPostAPI}`, payload)
    .then((res) => {
      // console.log('Done', res.data);
      dispatch(get_note(true,payload,false))
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'Cash Adjusted!',
          message: 'Successfully Added Cash Adjust!',
        })
      );
    })
    .catch((err) => {
      dispatch(
        setToasterState({
          open: true,
          title: 'error',
          name: 'Adjust Cash failed!',
          message:
            err?.response?.data?.message || 'Error Adjust Cash!',
        })
      );
    });
};
export const add_contact_to_stockapp = (payload) => (dispatch) => {
  PostRequest(`${api.addContactToStockapp}`, payload)
    .then((res) => {
      // console.log('Done', res.data);
      dispatch(get_stock_app_mains());
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'Add Contact to Stock App!',
          message: 'Successfully Added Contact!',
        })
      );
    })
    .catch((err) => {
      // console.log(err?.response?.data);
      dispatch(
        setToasterState({
          open: true,
          title: 'error',
          name: 'Add Stock App Main!',
          message:
            err?.response?.data?.message || 'Error Adding Stock App Main!',
        })
      );
    });
};
export const add_product = (payload) => (dispatch) => {
  PostRequest(`${api.productPostAPI}`, payload)
    .then((res) => {
      // console.log('Done', res.data);
      dispatch(get_products(payload));
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'Add Products!',
          message: 'Successfully Added Products!',
        })
      );
    })
    .catch((err) => {
      // console.log(err?.response?.data);
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'Add Products!',
          message:
            err?.response?.data?.message || 'Adding Products with some error!',
        })
      );
    });
};
export const update_product = (payload) => (dispatch) => {
  // console.log(payload);
  PutRequest(`${api.productAPI}/${payload.product_id}`, {...payload,languageId:1})
    .then((res) => {
      // console.log('Done', res.data);
      dispatch(get_products());
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'Update Products!',
          message: 'Successfully Updateed Products!',
        })
      );
    })
    .catch((err) => {
      // console.log(err?.response?.data);
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'Update Products!',
          message:
            err?.response?.data?.message || 'Updateing Products with some error!',
        })
      );
    });
};
export const update_stock_app_main = (payload) => (dispatch) => {
  PutRequest(`${api.updateStockAPI}/${payload.id}`, payload)
    .then((res) => {
      // console.log('Done', res.data);
      dispatch(get_stock_app_mains());
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'Update Stock App Main!',
          message: 'Successfully Updated Stock App Main!',
        })
      );
    })
    .catch((err) => {
      // console.log(err?.response?.data);
      dispatch(
        setToasterState({
          open: true,
          title: 'error',
          name: 'Updating Stock App Main!',
          message:
            err?.response?.data?.message || 'Error Updating Stock App Main!',
        })
      );
    });
};
export const update_customer = (payload) => (dispatch) => {
  PostRequest(`${api.updateCustomerAPI}`, payload)
    .then((res) => {
      // console.log('Done', res.data);
      dispatch(get_customers({subdomain:payload.subdomain}));
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'Update Customer!',
          message: 'Successfully Updated Customer!',
        })
      );
    })
    .catch((err) => {
      // console.log(err?.response?.data);
      dispatch(
        setToasterState({
          open: true,
          title: 'error',
          name: 'Updating Customer!',
          message:
            err?.response?.data?.message || 'Error Updating Customer!',
        })
      );
    });
};

export const add_bot_message = (payload) => (dispatch) => {
  PostRequest(`${api.botMessageByPost}`, payload)
    .then((res) => {
      // console.log('Done', res.data);
      dispatch(get_bot_messages());
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'Add Bot Message!',
          message: 'Successfully Added Bot Message!',
        })
      );
    })
    .catch((err) => {
      // console.log(err?.response?.data);
      dispatch(
        setToasterState({
          open: true,
          title: 'error',
          name: 'Add bot message!',
          message:
            err?.response?.data?.message || 'Error Adding bot message!',
        })
      );
    });
};

export const get_manufacturer = () => (dispatch) => {
  dispatch(setLoading(true));
  GetRequest(`${api.productManufacturerAPI}`)
    .then((res) => {
      dispatch(setManufacturer(res.data.message));
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'Get Manufacturer!',
          message: 'Successfully Retrived Vendors!',
        })
      );
    })
    .catch((err) => {
      dispatch(setLoading(false));
      // console.log(err?.response?.data);
      dispatch(
        setToasterState({
          open: true,
          title: 'error',
          name: 'Get manufacturer!',
          message:
            err?.response?.data?.message ||
            'Error Getting Vendor, Check Server!',
        })
      );
    });
};


export const get_vendors = () => (dispatch) => {
  dispatch(setLoading(true));
  GetRequest(`${api.vendorAPI}`)
    .then((res) => {
      dispatch(setVendors(res.data.message));
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'Get Vendors!',
          message: 'Successfully Retrived Vendors!',
        })
      );
    })
    .catch((err) => {
      dispatch(setLoading(false));
      // console.log(err?.response?.data);
      dispatch(
        setToasterState({
          open: true,
          title: 'error',
          name: 'Get Stock App Main!',
          message:
            err?.response?.data?.message ||
            'Error Getting Vendor, Check Server!',
        })
      );
    });
};

export const get_attendance = (payload) => (dispatch) => {
  dispatch(setLoading(true));
  GetRequest(`${api.staffAttendenceAPI}`,payload)
    .then((res) => {
      dispatch(setAttendence(res.data.content));
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'Get Attendence!',
          message: 'Successfully Retrived attendence!',
        })
      );
    })
    .catch((err) => {
      dispatch(setLoading(false));
      console.log(err?.response?.data);
      dispatch(
        setToasterState({
          open: true,
          title: 'error',
          name: 'Get Stock App Main!',
          message:
            err?.response?.data?.message ||
            'Error Getting Vendor, Check Server!',
        })
      );
    });
};
export const get_staffDetails = (payload) => (dispatch) => {
  dispatch(setLoading(true));
  GetRequest(`${api.staffAPI}/${payload.userid}`,payload)
    .then((res) => {
      dispatch(setStaffDetails(res.data.content[0]));
      console.log("🚀 ~ file: DashboardActions.js ~ line 1287 ~ .then ~ res.data.content", res.data.content)
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'Get StaffDetails!',
          message: 'Successfully Retrived Staff Details!',
        })
      );
    })
    .catch((err) => {
      console.log("🚀 ~ file: DashboardActions.js ~ line 1298 ~ err", err)
      dispatch(setLoading(false));
      dispatch(
        setToasterState({
          open: true,
          title: 'error',
          name: 'Get Staff!',
          message:
            err?.response?.data?.message ||
            'Error Getting Staff Details, Check Server!',
        })
      );
    });
};
export const get_customerProfile = (payload) => (dispatch) => {
  dispatch(setLoading(true));
  GetRequest(`${api.customerDetailsAPI}`,payload)
    .then((res) => {
      dispatch(setCustomerProfile(res.data.content));
      console.log("🚀 ~ file: DashboardActions.js ~ line 1287 ~ .then ~ res.data.content", res.data.content)
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'Get Customer Details!',
          message: 'Successfully Retrived Customer Details!',
        })
      );
    })
    .catch((err) => {
      console.log("🚀 ~ file: DashboardActions.js ~ line 1298 ~ err", err)
      dispatch(setLoading(false));
      dispatch(
        setToasterState({
          open: true,
          title: 'error',
          name: 'Get Customer!',
          message:
            err?.response?.data?.message ||
            'Error Getting Customer Details, Check Server!',
        })
      );
    });
};
export const get_category=
  (vendorId,categoryId = 0) =>
  (dispatch) => {
    dispatch(setLoading(true));

    let url = '';
    if (categoryId) {
      url = `${api.categoryAPI}/${categoryId}`;
    } else {
      url = `${api.categoryAPI}`;
    }
    GetRequest(url,{vendorId})
      .then((res) => {
        dispatch(setCategories(res.data.content));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        // console.log(err?.response?.data);
        dispatch(
          setToasterState({
            open: true,
            title: 'error',
            name: categoryId ? 'Get Category!' : 'Get Sub-Category!',
            message:
              err?.response?.data?.message || 'Error Retrived Categories!',
          })
        );
      });
  };

export const update_category_status = (categoryId, status) => (dispatch) => {
  dispatch(setLoading(true));

  PutRequest(`${api.categoryAPI}/${categoryId}`, { categoryId, status })
    .then((res) => {
      dispatch(get_category());
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'Update Category Status!',
          message: 'Successfully Updated Category Status!',
        })
      );
    })
    .catch((err) => {
      dispatch(setLoading(false));
      // console.log(err?.response?.data);
      dispatch(
        setToasterState({
          open: true,
          title: 'error',
          name: 'Update Category Status!',
          message: err?.response?.data?.message || 'Error Retrived Categories!',
        })
      );
    });
};
export const set_viber_web_hook = (url) => (dispatch) => {
  PostRequest(`${api.viberWebhookAPI}`, { url })
    .then((res) => {
      dispatch(
        setToasterState({
          open: true,
          title: 'success',
          name: 'SET Viber Web HOOK!',
          message: 'Successfully Set Viber Web Hook!',
        })
      );
    })
    .catch((err) => {
      // console.log(err?.response?.data);
      dispatch(
        setToasterState({
          open: true,
          title: 'error',
          name: 'SET Viber Web HOOK!',
          message:
            err?.response?.data?.message || 'Error Setting Viber Web Hook!',
        })
      );
    });
};

export const get_env_data = () => (dispatch) => {
  GetRequest(`${api.envAPI}`)
    .then((res) => {
      // console.log(res.data);
      dispatch(setEnvData(res.data));
    })
    .catch((err) => {
      // console.log(err?.response?.data);
      dispatch(
        setToasterState({
          open: true,
          title: 'error',
          name: 'SET Viber Web HOOK!',
          message:
            err?.response?.data?.message || 'Error Setting Viber Web Hook!',
        })
      );
    });
};
export const get_monthly_report =
  (
    clear = false,
    { bill_type, subdomain },
    cancel
  ) =>
  (dispatch) => {
    dispatch(setLoading(true));
    GetRequest(`${api.monthlyReportsAPI}​`, {
      subdomain,
      bill_type:'SALE',
    })
      .then((res) => {
        // console.log(res.data);
        dispatch(setMonthlyReport(res.data.content, clear));
        dispatch(
          setToasterState({
            open: true,
            title: 'success',
            name: 'Get Monthly Report!',
            message: 'Successfully Get Monthly Report!',
          })
        );
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (axios.isCancel(err)) {
          // console.log('Request canceled', err.message);
        } else {
          // console.log(err?.response?.data);
          dispatch(
            setToasterState({
              open: true,
              title: 'error',
              name: 'Get Monthly Report!',
              message:
                err?.response?.data?.message ||
                'Error Getting Monthly Report, Check Server!',
            })
          );
        }
      });
  };
  export const get_profit_by_bills =
  (
    clear = false,
    { subdomain, startDate, endDate },
    cancel
  ) =>
  (dispatch) => {
    dispatch(setLoading(true));
    GetRequest(`${api.profitLossByDateAPI}​`, {
      subdomain,
      startDate,
      endDate
    })
      .then((res) => {
        // console.log(res.data);
        dispatch(setProfitbyBills(res.data.content, clear));
        dispatch(
          setToasterState({
            open: true,
            title: 'success',
            name: 'Get Profit By Bills!',
            message: 'Successfully Get Profit By Bills!',
          })
        );
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (axios.isCancel(err)) {
          // console.log('Request canceled', err.message);
        } else {
          // console.log(err?.response?.data);
          dispatch(
            setToasterState({
              open: true,
              title: 'error',
              name: 'Get Profit By Bills!',
              message:
                err?.response?.data?.message ||
                'Error Getting Profit By Bills, Check Server!',
            })
          );
        }
      });
    };
