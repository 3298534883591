import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Paper, TextField } from '@material-ui/core';
import Title from 'modules/Dashboard/Components/Title';
import BillFormat from 'modules/inventory/daybook/Components/BillFormat';
import { useParams } from "react-router-dom";
import jwt_decode from 'jwt-decode';

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));


export default function PublicBillView() {
    let { subdomain, connect_id } = useParams();
    console.log("subdomain",subdomain);
    console.log("connect id",connect_id);
    const token = 'eyJ0eXAiO... /// jwt token';
    // const decoded = jwt_decode(token);
    // console.log(decoded);

    const myRef = useRef();
    const [open, setOpen] = useState(true);
    const handleClose = () => {
        return true;
    }

    return (
        <Paper >
            <Title>Bill Details Page</Title>
            {/* {true ? 'not found':'found'} */}
            <BillFormat ref={myRef} open={open} connect_id={connect_id} handleClose={handleClose} subdomain={subdomain} />
        </Paper>
    );
}
