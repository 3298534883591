  import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import AccessTime from "@material-ui/icons/AccessTime";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import BugReport from "@material-ui/icons/BugReport";
import Cloud from "@material-ui/icons/Cloud";
import Code from "@material-ui/icons/Code";
import api from "api/api";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import axios from "axios";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import { CDN_URL } from "config";
import * as actions from 'modules/store/DashboardActions';
import NepaliDate from "nepali-date-converter";
import { GetRequest } from "plugins/axios";
import React, { useEffect, useState } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import {
  completedTasksChart, dailySalesChart,
  emailsSubscriptionChart
} from "variables/charts.js";
import Graph from "./Graph";
import TopCard from "./topCard";
  
  
  
  
  
  
  
  const useStyles = makeStyles(styles);
  
  export default function Dashboard() {
    const subdomain = useSelector((state) => state.authReducer.user.subdomain);
    let heading ='Sales';
    const classes = useStyles();

var Chartist = require("chartist");

    
    const [amount, setAmount] = useState([0,0,0,0,0]);
    const [sales, setSales] = useState([]);
    const [expenses, setExpenses] = useState([0,0,0,0]);
    const [maxExpenses, setMaxExpenses] = useState(1000);
    const [maxSales, setMaxSales] = useState(1000);
    const [date, setDate] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [axiosToken, setAxiosToken] = useState('');
    const [openingData, setOpeningData] = useState([]);
    const dispatch = useDispatch();
    // const cardColor = ['#0073E4', '#0D8319', '#FBB500', '#F54141', '#0073E4'];
    const cardColor = ['warning', 'success', 'danger', 'info', 'success', 'success', 'success', 'success', 'success', 'success', 'success'];
    const cardIcon = ['content_copy', 'person', 'money', 'info_outline', 'info_outline', 'info_outline', 'success', 'success', 'success', 'success', 'success'];
    const cardPost = ['pcs', 'ppl', '|-', '|-', 'info_outline', 'info_outline', 'success', 'success', 'success', 'success', 'success'];
    const StaffList = useSelector((state) => state.dashboardReducer.staff);
    const username = useSelector((state) => state.authReducer.user.name);
    const openings = useSelector((state)=> state.dashboardReducer.openings);
    
    const [product, setProduct] = useState({});
    const [category, setCategory] = useState('');
    const [categoryText, setCategoryText] = useState('');
    const [level, setLevel] = useState(0);
    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openDetails, setOpenDetails] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [cards, setCards] = useState([0,0,0,0]);
    const [dashReports, setDashReports] = useState([]);
    const [logins, setLogins] = useState([]);
    let cardColorIndex = -1;
    const [staffData,setStaffData] = useState([
      ["1", "Dakota Rice", "$36,738", "Niger"],
    ])
    useEffect(()=>{
      setDataList(openings);
    },[openings]);
    
    useEffect(()=>{
      
      if(subdomain){
        let token = axios.CancelToken.source();
        setAxiosToken(token);
        let bill_type='SALE';
        dispatch(actions.get_openings(true, {subdomain,bill_type}, token));
        const {obdate, obamount} = openings;
        // console.log("opening",openings,obamount,obdate);
        //     ammount.map((data)=>{
        // setOpeningData(...openingData,{data.date,data.ammount})
        //     })
      }
      
    },[subdomain]);
    // console.log(StaffList);
    const getDashboardCardData = (subdomain) => {
      const nepaliDate = new NepaliDate();
      GetRequest(`${api.loginData}`, {
        subdomain,
      })
      .then((res) => {
        setLogins(res.data.content);
        // console.log("data>>>>>>>>..",dashReports);
        // setSavings(res.data.content);
      })
      GetRequest(`${api.dashboardCardAPI}`, {
        subdomain,
        vendorId:9,
        today: `${nepaliDate.getYear()}/${
          nepaliDate.getMonth() + 1 > 9
          ? nepaliDate.getMonth() + 1
          : `0${nepaliDate.getMonth() + 1}`
        }/${nepaliDate.getDate()}`,
      })
      .then((res) => {
        setCards(res.data.content);
        // setSavings(res.data.content);
        
      })
      .catch((err) => {
        alert('Error Geting Dashboard Card');
      });
    };
    
    
    useEffect(()=>{
      getDashboardCardData(subdomain);
      get_product_details();
    },[subdomain])
    
    useEffect(() => {
      if (dataList) {
        let amount = [];
        let sales = [];
        let expenses = [];
        let date = [];
        let dateE = [];
        dataList.reverse().forEach((data, index) => {
          // amount.push(data.amount);
          sales.push(data.amount);
          // expenses.push(data.amount);
          date.push(data.bill_date.slice(-5));
          // setAmount([...amount, { x: data.date, y: data.amount }]);
        });
        // data.reverse().forEach((data, index) => {
        //   amount.push(data.amount);
        //   date?.push(data.bill_date.slice(-5));
          // setAmount([...amount, { x: data.date, y: data.amount }]);
        // });
        data2.reverse().forEach((data, index) => {
          expenses.push(data.amount);
          dateE.push(data.bill_date.slice(-5));
          // setAmount([...amount, { x: data.date, y: data.amount }]);
        });
        setDate(date.slice(0,7));
        // console.log("date",date);
        // setAmount(amount.slice(0,7));
        setSales(sales.slice(0,7));
        setMaxSales(Math.max.apply(null, amount));
        setExpenses(expenses.slice(0,7));
        setMaxExpenses(Math.max.apply(null, expenses));
        console.log(Math.max.apply(null, expenses));
        // console.log("graph data",date,amount);
      }
    }, [dataList, heading,subdomain]);
    
    const get_product_details = () => {
      setLoading(true);
      GetRequest(`${api.topsalesAPI}`, { top:'SALE',subdomain })
      .then((res) => {
        setLoading(false);
        setData1(res.data.content);
        // console.log("top sales::",res.data.content);
      })
      .catch((err) => {
        
      });
      GetRequest(`${api.savingBookAPI}`, { top:'PURCHASE',subdomain })
      .then((res) => {
        setLoading(false);
        setData(res.data.content);
        console.log("🚀 ~ file: Dashboard.js ~ line 191 ~ .then ~ res.data.content", res.data.content)
      })
      GetRequest(`${api.topsalesAPI}`, { top:'EXPENSE',subdomain })
      .then((res) => {
        setLoading(false);
        setData2(res.data.content);
        // console.log("top sales::",res.data.content);
      })
    };
    
    return (
      <div>
          { loading ? (
            <GridContainer>
<GridItem xs={12} sm={6} md={3}><Skeleton height={180} width={400}/></GridItem>
<GridItem xs={12} sm={6} md={3}><Skeleton height={180} width={400}/></GridItem>
<GridItem xs={12} sm={6} md={3}><Skeleton height={180} width={400}/></GridItem>
<GridItem xs={12} sm={6} md={3}><Skeleton height={180} width={400}/></GridItem>
          </GridContainer>
          ) : (
      <GridContainer>
      {cards.map((card, index) =>
        Object.keys(card).map((key, i) => {
          cardColorIndex++;
          return (
            <TopCard
            key={cardColorIndex}
            page={'product'}
            title={key}
            value={card[key]}
            borderColor={cardColor[cardColorIndex]}
            icon={cardIcon[cardColorIndex]}
            postfix={cardPost[cardColorIndex]}
            />
            );
          })
          )}
          {/* <GridItem xs={12} sm={6} md={3}>
          <Card>
          <CardHeader color="success" stats icon>
          <CardIcon color="success">
          <Store />
          </CardIcon>
          <p className={classes.cardCategory}>Revenue</p>
          <h3 className={classes.cardTitle}>$34,245</h3>
          </CardHeader>
          <CardFooter stats>
          <div className={classes.stats}>
          <DateRange />
          Last 24 Hours
          </div>
          </CardFooter>
          </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
          <Card>
          <CardHeader color="danger" stats icon>
          <CardIcon color="danger">
          <Icon>info_outline</Icon>
          </CardIcon>
          <p className={classes.cardCategory}>Fixed Issues</p>
          <h3 className={classes.cardTitle}>75</h3>
          </CardHeader>
          <CardFooter stats>
          <div className={classes.stats}>
          <LocalOffer />
          Tracked from Github
          </div>
          </CardFooter>
          </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
          <Card>
          <CardHeader color="info" stats icon>
          <CardIcon color="info">
          <Accessibility />
          </CardIcon>
          <p className={classes.cardCategory}>Followers</p>
          <h3 className={classes.cardTitle}>+245</h3>
          </CardHeader>
          <CardFooter stats>
          <div className={classes.stats}>
          <Update />
          Just Updated
          </div>
          </CardFooter>
          </Card>
        </GridItem> */}
        </GridContainer>
        )}

        <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
        <Card chart>
        <CardHeader 
        // color="success"
        >
        <Graph heading={'Expenses'} type={false}/>
        {/* <ChartistGraph
        className="ct-chart"
        // data={dailySalesChart.data}
        data={{
          labels: date,
          series: [amount],
        }}
        type="Line"
        options={{
          lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          color:'white',
          high: maxSales, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        }}
        listener={dailySalesChart.animation}
        /> */}
        </CardHeader>
        <CardBody>
        <h4 className={classes.cardTitle}>Weekly Expenses</h4>
        <p className={classes.cardCategory}>
        <span className={classes.successText}>
        <ArrowUpward className={classes.upArrowCardCategory} /> 55%
        </span>{" "}
        increase in today sales.
        </p>
        </CardBody>
        <CardFooter chart>
        <div className={classes.stats}>
        <AccessTime /> updated 4 minutes ago
        </div>
        </CardFooter>
        </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
        <Card chart>
        <CardHeader
        // color="warning"
        >
        <Graph heading={'Business'} type={true}/>

        </CardHeader>
        <CardBody>
        <h4 className={classes.cardTitle}>Sales</h4>
        <p className={classes.cardCategory}>Last 7 days Performance</p>
        </CardBody>
        <CardFooter chart>
        <div className={classes.stats}>
        <AccessTime /> Up to date
        </div>
        </CardFooter>
        </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
        <Card chart>
        <CardHeader 
        // color="danger"
        >
        {/* <ChartistGraph
        className="ct-chart"
        
        // data={completedTasksChart.data}
        data={{
          labels: date,
          series: [expenses],
        }}
        type="Line"
        options={{
          lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          color:'white',
          high:  maxExpenses, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        }}
        listener={completedTasksChart.animation}
        /> */}
        <Graph heading={'Sales'} type={false}/>
        </CardHeader>
        <CardBody>
        <h4 className={classes.cardTitle}>Purchase Chart</h4>
        <p className={classes.cardCategory}>last 7 days purchase stat</p>
        </CardBody>
        <CardFooter chart>
        <div className={classes.stats}>
        <AccessTime /> updated sent 2 minutes ago
        </div>
        </CardFooter>
        </Card>
        </GridItem>
        </GridContainer>
        <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
        <CustomTabs
        title="Business:"
        headerColor="primary"
        tabs={[
          {
            tabName: "Bank & Cash",
            tabIcon: BugReport,
            tabContent: (
              <Table>
              <TableRow >
                  <TableCell>SN</TableCell>
                  <TableCell>Account</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Amount</TableCell>
                  </TableRow >
              {data.map((data,index)=>{
                return (
                  <TableRow >
                  <TableCell>{index+1}</TableCell>
                  <TableCell>
                  <img
          width='30px'
          src={`${CDN_URL}/${data?.image}`}
        />
        </TableCell>
                  <TableCell>{data?.name}</TableCell>
                  <TableCell>{data?.amount}</TableCell>
                  </TableRow >
                  )
                })}
                </Table>
              ),
            },
            {
              tabName: "Sales",
              tabIcon: Code,
              tabContent: (
                <Table>
                  <TableBody>
                <TableRow >
                    <TableCell>SN</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Amount</TableCell>
                    </TableRow >
                {data1.map((data,index)=>{
                  return (
                    <TableRow >
                    <TableCell>{index+1}</TableCell>
                    <TableCell>{data.bill_date}</TableCell>
                    <TableCell>{data.amount}</TableCell>
                    </TableRow >
                    )
                  })}
                   </TableBody>
                  </Table>
                
                ),
              },
              {
                tabName: "Expenses",
                tabIcon: Cloud,
                tabContent: (
                  <Table>
                      <TableBody>
                  <TableRow >
                      <TableCell>SN</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Amount</TableCell>
                      </TableRow >
                  {data2.map((data,index)=>{
                    return (
                      <TableRow >
                      <TableCell>{index+1}</TableCell>
                      <TableCell>{data.bill_date}</TableCell>
                      <TableCell>{data.amount}</TableCell>
                      </TableRow >
                      )
                    })}
                    </TableBody>
                    </Table>
                  ),
                },
              ]}
              />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
              <Card>
              <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>Security</h4>
              <p className={classes.cardCategoryWhite}>
              Recent logins to your site, Access Time control.
              </p>
              </CardHeader>
              <CardBody>
              <Table>
                <TableBody>
              <TableRow >
                  <TableCell>SN</TableCell>
                  <TableCell>User</TableCell>
                  <TableCell>Last Login</TableCell>
                  <TableCell>Sessions</TableCell>
                  </TableRow >
              {logins.map((data,index)=>{
                return (
                  <TableRow >
                  <TableCell>{index+1}</TableCell>
                  <TableCell>{data.fullname}</TableCell>
                  <TableCell>{data.lastlogin}</TableCell>
                  <TableCell>{data.sessions}</TableCell>
                  </TableRow >
                  )
                })}
                </TableBody>
                </Table>
                </CardBody>
                </Card>
                </GridItem>
                </GridContainer>
                </div>
                );
              }
              