import './App.css';
import React, { useEffect } from 'react';
import MainRoute from './routes';
import { useDispatch } from 'react-redux';
import * as actions from './store/action';
import Toast from './common/Toast';
import TawkMessengerReactUmd from '@tawk.to/tawk-messenger-react';
import { hotjar } from 'react-hotjar';




const App = () => {
  console.log('home re-rending')
  let hjid=3077481;
  let hjsv=6;
  hotjar.initialize(hjid, hjsv);
  hotjar.identify('USER_ID', { userProperty: 'value' });
  const dispatch = useDispatch();
  useEffect(() => {
    reauthenticate().then((r) => true);
    // eslint-disable-next-line
  }, []);

  const reauthenticate = async () => {
    await dispatch(actions.reauthenticate());
  };
  return (
    <>
      <TawkMessengerReactUmd
                propertyId="5f01ff2f760b2b560e6fc72e"
                widgetId="default"/>
      <MainRoute />
      <Toast />
    </>
  );
};

export default App;
