import React, { useEffect, useState } from 'react';
import api from 'api/api';
import { GetRequest } from 'plugins/axios';
  
  export default function CompanyDetails() {
    const [vendor,setVendor] = useState([0,0,0,0]);
    console.log("🚀 ~ file: index.js ~ line 21 ~ CompanyDetails ~ vendor", vendor)
    useEffect(()=>{
    GetRequest(`${api.appdataAPI}`,{}).then((res)=>{
      setVendor(res.data.content)
      // console.log("fetch company data",res.data.content);
    })
  },[])
    return (
      <div>
        <div padder>
          {
          vendor &&
    // Object.keys(vendor[0]).map((prop) =>  {return(<Text>test</Text>)})
    Object.keys(vendor[0])?.map((data) => (
            <p
              style={{
                backgroundColor: '#f3f3f3',
                margin: 5,
                padding: 5,
                borderRadius: 5,
              }}
            >
              {data} : {vendor[0][data]}
            </p>
          ))
          }
        </div>
      </div>
    );
  }
  